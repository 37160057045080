<template>
    <form @submit.prevent="updateItem">
        <div class="row mb-30">
            <div class="col-12 mb-30">
                <SingleSelectField labelText="Do you have any medical conditions?"
                                   :options="medicalConditionOptions"
                                   v-model="selectedOptionForMedicalCondition"
                                   selectId="medicalConditionOptions"
                                   :isRequired=true
                                   :selectedItem="selectedOptionForMedicalCondition"
                />
            </div>

            <div class="col-12">
                <TextAreaInputField :config="{
                        class: 'form-control-gray mb-30',
                        placeholder: 'Enter information',
                        inputFieldType: 'text',
                    }" labelText="If yes, please provide more information" v-model="medical_condition"
                                    :isRequired=isMedicalConditionRequired />
            </div>
        </div>

        <div class="row mb-30">
            <div class="col-12 mb-30">
                <SingleSelectField labelText="Do you have any Special Educational Needs?"
                                   :options="specialNeedsOptions"
                                   v-model="selectedOptionForSpecialNeeds"
                                   selectId="specialNeedsOptions"
                                   :isRequired=true
                                   :selectedItem="selectedOptionForSpecialNeeds" />
            </div>

            <div class="col-12">
                <TextAreaInputField :config="{
                        class: 'form-control-gray mb-30',
                        placeholder: 'Enter information',
                        inputFieldType: 'text',
                    }" labelText="If yes, please provide more information" v-model="educational_needs"
                                    :isRequired=isSpecialNeedsRequired />
            </div>
        </div>

        <div class="row mb-30">
            <div class="col-12 mb-30">
                <SingleSelectField labelText="Do you have any learning differences and/ or delays?"
                                   :options="learningDelaysOptions"
                                   v-model="selectedOptionForLearningDelays"
                                   selectId="learningDelaysOptions"
                                   :isRequired=true
                                   :selectedItem="selectedOptionForLearningDelays"/>
            </div>

            <div class="col-12">
                <TextAreaInputField :config="{
                        class: 'form-control-gray mb-30',
                        placeholder: 'Enter information',
                        inputFieldType: 'text',
                    }" labelText="If yes, please provide more information" v-model="learning_delay"
                                    :isRequired=isLearningDelayRequired />
            </div>
        </div>

        <div class="row mb-30">
            <div class="col-12 mb-30">
                <SingleSelectField labelText="Do you have any allergies?"
                                   :options="allergyOptions"
                                   v-model="selectedOptionForAllergies"
                                   selectId="allergyOptions"
                                   :isRequired=true
                                   :selectedItem="selectedOptionForAllergies"/>
            </div>

            <div class="col-12">
                <TextAreaInputField :config="{
                        class: 'form-control-gray mb-30',
                        placeholder: 'Enter information',
                        inputFieldType: 'text',
                    }" labelText="If yes, please provide more information" v-model="allergies"
                                    :isRequired=isAllergyRequired />
            </div>
        </div>

        <div class="row mb-30">
            <div class="col-12 mb-30">
                <SingleSelectField labelText="Do you have any special dietary?"
                                   :options="specialDietOptions"
                                   v-model="selectedOptionForSpecialDiets"
                                   selectId="specialDietOptions"
                                   :isRequired=true
                                   :selectedItem="selectedOptionForSpecialDiets"/>
            </div>

            <div class="col-12">
                <TextAreaInputField :config="{
                        class: 'form-control-gray mb-30',
                        placeholder: 'Enter information',
                        inputFieldType: 'text',
                    }" labelText="If yes, please provide more information" v-model="special_dietary"
                                    :isRequired=isSpecialDietRequired />
            </div>
        </div>

        <RoundedButton buttonText="Save Changes" class="custom-button" buttonType="submit" :loading="buttonLoading"/>

    </form>
</template>
<script>
import TextAreaInputField from "@/components/common/TextAreaInputField.vue";
import RoundedButton from "@/components/common/RoundedButton.vue";
import {onMounted, ref, watch} from "vue";
import {getValidToken} from '@/utils/helpers'
import {checkAndRefreshToken} from "@/utils/auth";
import {API_BASE_URL} from "@/config";
import SelectDropdown from "@/components/common/SelectDropdown.vue";
import SingleSelectField from "@/components/common/SingleSelectField.vue";


export default {
    props: {
        itemData: {
            type: Object,
            required: true
        }
    },
    components: {
        SingleSelectField,
        SelectDropdown,
        TextAreaInputField,
        RoundedButton,
    },
    setup(props, {emit}) {
        const buttonLoading = ref(false)
        const message = ref('')

        const selectedOptionForMedicalCondition = ref('')
        const selectedOptionForSpecialNeeds = ref('')
        const selectedOptionForLearningDelays = ref('')
        const selectedOptionForAllergies = ref('')
        const selectedOptionForSpecialDiets = ref('')

        const medical_condition = ref(props.itemData.medical_condition)
        const educational_needs = ref(props.itemData.educational_needs)
        const learning_delay = ref(props.itemData.learning_delay)
        const allergies = ref(props.itemData.allergies)
        const special_dietary = ref(props.itemData.special_dietary)

        const medicalConditionOptions = ref([])
        const specialNeedsOptions = ref([])
        const learningDelaysOptions = ref([])
        const allergyOptions = ref([])
        const specialDietOptions = ref([])

        const isMedicalConditionRequired = ref(false)
        const isSpecialNeedsRequired = ref(false)
        const isLearningDelayRequired = ref(false)
        const isAllergyRequired = ref(false)
        const isSpecialDietRequired = ref(false)

        // Flag to prevent recursive updates
        const medicalConditionUpdated = ref(false);
        const specialNeedsUpdated = ref(false);
        const learningDelaysUpdated = ref(false);
        const allergyUpdated = ref(false);
        const specialDietUpdated = ref(false);

        onMounted(() => {
            fetchMedicalConditionOptions()
            fetchSpecialNeedOptions()
            fetchLearningDelayOptions()
            fetchSpecialDietOptions()
            fetchAllergiesOptions()

            setTimeout(() => {
                selectedOptionForMedicalCondition.value = props.itemData.any_medical_condition === true ? 'Yes' : 'No'
                selectedOptionForSpecialNeeds.value = props.itemData.any_educational_needs === true ? 'Yes' : 'No'
                selectedOptionForLearningDelays.value = props.itemData.any_learning_delay === true ? 'Yes' : 'No'
                selectedOptionForAllergies.value = props.itemData.any_allergies === true ? 'Yes' : 'No'
                selectedOptionForSpecialDiets.value = props.itemData.any_special_dietary === true ? 'Yes' : 'No'
            }, 500)
        })

        // Medical Conditions
        const fetchMedicalConditionOptions = () => {
            medicalConditionOptions.value = [
                {label: "Yes", value: 'Yes'},
                {label: "No", value: 'No'},
            ]
        }

        watch(medicalConditionOptions, (newData) => {
            if (!medicalConditionUpdated.value) {
                selectedOptionForMedicalCondition.value = newData.length > 0 ? newData[0].value : '';
            }
            medicalConditionUpdated.value = false; // Reset flag after watch
        });

        // Allergies
        const fetchAllergiesOptions = () => {
            allergyOptions.value = [
                {label: "Yes", value: 'Yes'},
                {label: "No", value: 'No'},
            ]
        }

        watch(allergyOptions, (newData) => {
            if (!allergyUpdated.value) {
                selectedOptionForAllergies.value = newData.length > 0 ? newData[0].value : '';
            }

            allergyUpdated.value = false; // Reset flag after watch
        });

        // Special Diets
        const fetchSpecialDietOptions = () => {
            specialDietOptions.value = [
                {label: "Yes", value: 'Yes'},
                {label: "No", value: 'No'},
            ]
        }

        watch(specialDietOptions, (newData) => {
            if (!specialDietUpdated.value) {
                selectedOptionForSpecialDiets.value = newData.length > 0 ? newData[0].value : '';
            }

            specialDietUpdated.value = false; // Reset flag after watch
        });


        // Special Needs
        const fetchSpecialNeedOptions = () => {
            specialNeedsOptions.value = [
                {label: "Yes", value: 'Yes'},
                {label: "No", value: 'No'},
            ]
        }

        watch(specialNeedsOptions, (newData) => {
            if (!specialNeedsUpdated.value) {
                selectedOptionForSpecialNeeds.value = newData.length > 0 ? newData[0].value : '';
            }

            specialNeedsUpdated.value = false; // Reset flag after watch
        });

        // Learning Delays
        const fetchLearningDelayOptions = () => {
            learningDelaysOptions.value = [
                {label: "Yes", value: 'Yes'},
                {label: "No", value: 'No'},
            ]
        }

        watch(learningDelaysOptions, (newData) => {
            if (!learningDelaysUpdated.value) {
                selectedOptionForLearningDelays.value = newData.length > 0 ? newData[0].value : '';
            }
            learningDelaysUpdated.value = false; // Reset flag after watch
        });

        // Listen for selected options
        watch(() => selectedOptionForMedicalCondition.value, (value) => {
            isMedicalConditionRequired.value = value === 'Yes';
        })

        watch(() => selectedOptionForSpecialNeeds.value, (value) => {
            isSpecialNeedsRequired.value = value === 'Yes';
        })

        watch(() => selectedOptionForLearningDelays.value, (value) => {
            isLearningDelayRequired.value = value === 'Yes';
        })


        // OTHERS
        const showLoaders = () => {
            buttonLoading.value = true;
        };

        const hideLoaders = () => {
            buttonLoading.value = false;
        };

        const apiUrl = `${API_BASE_URL}/student/update-student?user_id=${props.itemData.id}`
        console.log(apiUrl)
        const updateItem = async () => {
            const item = {
                medical_condition: medical_condition.value,
                educational_needs: educational_needs.value,
                learning_delay: learning_delay.value,
                allergies: allergies.value,
                special_dietary: special_dietary.value,
                any_educational_needs:selectedOptionForSpecialNeeds.value === 'Yes',
                any_learning_delay: selectedOptionForLearningDelays.value === 'Yes',
                any_medical_condition: selectedOptionForMedicalCondition.value === 'Yes',
                any_special_dietary: selectedOptionForSpecialDiets.value === 'Yes',
                any_allergies: selectedOptionForAllergies.value === 'Yes',
            }


            try {
                showLoaders()
                await checkAndRefreshToken()
                const accessToken = getValidToken()

                const response = await fetch(apiUrl, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    },
                    body: JSON.stringify(item)
                })

                if (response.ok) {
                    const result = await response.json();

                    if (result.code === 200) {
                        message.value = result.message;
                        const msg = {
                            apiResponse: "Medical conditions updated",
                            type: "success",
                        }

                        emit("closeFormModal", msg);
                        emit("refreshPage");
                    } else {
                        console.log("error", result.message);
                        emit("closeFormModal", {
                            apiResponse: result.message,
                            type: "success",
                        });
                    }
                } else {
                    const result = await response.json();
                    console.log("error", result.message);
                }
            } catch (error) {
                console.error("An error occured:", error);
            } finally {
                hideLoaders();
            }
        }

        return {
            buttonLoading,
            updateItem,
            selectedOptionForMedicalCondition,
            selectedOptionForSpecialNeeds,
            selectedOptionForLearningDelays,
            medical_condition,
            educational_needs,
            learning_delay,
            medicalConditionOptions,
            specialNeedsOptions,
            learningDelaysOptions,
            isMedicalConditionRequired,
            isSpecialNeedsRequired,
            isLearningDelayRequired,
            selectedOptionForAllergies,
            selectedOptionForSpecialDiets,
            allergies,
            special_dietary,
            allergyOptions,
            specialDietOptions,
            isAllergyRequired,
            isSpecialDietRequired,
        }
    }
}
</script>
<style scoped>
@media (max-width: 767px) {
    .first_name, .country {
        margin-bottom: 30px;
    }
}

@media (max-width: 992px) {
    .first_name, .country {
        margin-bottom: 30px;
    }
}
</style>