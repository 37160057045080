<template>
    <!-- start page title -->
    <div class="row">
        <div class="col-12">
            <div class="page-title-box">
                <div class="page-title-right">
                    <RoundedButton
                        buttonText="Add Parent"
                        iconText="mdi-plus"
                        @clicked="openFormModal"
                        v-if="hasPermission('add_parents')"
                    />
                </div>
                <h4 class="page-title">Manage Parents</h4>
            </div>
        </div>
    </div>

    <div class="row">
        <StatCards
            statCardText="Total Parents"
            :statsCardNumber="numOfParents"
        />
        <StatCards
            statCardText="Active Parents"
            :statsCardNumber="activeParents"
        />
        <StatCards
            statCardText="Deactivated Parents"
            :statsCardNumber="inActiveParents"
        />
    </div>

    <div class="row mainContent">
        <div class="col-sm-12">
            <div class="card-box">
                <TableView
                    :data="parentsList"
                    :headers="tableHeaders"
                    :id="getIdForActions"
                    :showSearchField="true"
                    :detailsData="getDataForDetailsView"
                    :loading="loading"
                    :searchStringFromParent.sync="searchStringFromChild"
                    :viewAction="viewAction"
                    :editAction="editAction"
                    :deactivateAction="openConfirmationDialog"
                    :showActionsColumn="true"
                    :searchFields="searchFieldsData"
                    :totalPages="totalPages"
                    :currentPage.sync="currentPage"
                    :changePage="changePage"
                    @searchWord="searchForItem"
                    :changeItemsPerPage="changeItemsPerPage"
                    :itemsPerPage="itemsPerPage"
                    viewPermission="view_parents"
                    editPermission="modify_parents"
                    deactivatePermission="deactivate_parents"
                />
            </div>
        </div>
    </div>

    <InfoModal
        :isModalOpen="isConfirmationDialogOpen"
        @closeModal="closeConfirmationDialog"
        :successImageURL="
            require('../../../assets/images/icons/success_icon.svg')
        "
        :errorImageURL="require('../../../assets/images/icons/error_icon.svg')"
        header="Hold on!"
        :paragraph="deactivateConfirmationParagraph"
        :buttonTxt="deactivateButtonText"
        :buttonAction="performAction"
        buttonColor="error"
        routerLinkText="No, Cancel Action"
        buttonLink="/"
        :linkAction="cancelAction"
        :loading="buttonLoader"
    >
    </InfoModal>

    <FormModal
        :isFormModalOpen="isFormModalOpen"
        @closeFormModal="closeFormModal1"
        modalHeader="Add Parent"
    >
        <CreateParent
            @closeFormModal="closeFormModal"
            @message="showFlashMessage"
        />
    </FormModal>

    <FormModal
        :isFormModalOpen="isEditFormModalOpen"
        @closeFormModal="closeEditFormModal"
        modalHeader="Edit Parent"
    >
        <EditParent
            @closeFormModal="closeEditFormModal"
            @message="showFlashMessage"
            :itemData="itemDataForEditing"
        />
    </FormModal>

    <MessageSliderCopy
        :isVisible="displayMessage"
        :messageText="messageText"
        :messageType="messageType"
    />

    <InfoModal
        :isModalOpen="isFlashModalOpen"
        @closeModal="closeFlashModal"
        :isSuccess="true"
        header="Parent Added"
        :paragraph="`You have successfully added '${fullName}'. You can view and manage this parent's profile under 'Manage Parents'.`"
        buttonTxt="Add Another Parent"
        routerLinkText="Manage Parents"
        :linkAction="createItemFlashLinkAction"
        :buttonAction="buttonAction"
    >
    </InfoModal>
</template>
<script>
import {computed, onActivated, onMounted, ref, watch} from "vue";
import RoundedButton from "@/components/common/RoundedButton.vue";
import TableView from "@/components/common/TableView.vue";
import FormModal from "@/components/common/FormModal.vue";
import InfoModal from "@/components/common/InfoModals.vue";
import MessageSliderCopy from "@/components/common/MessageSliderCopy.vue";
import CreateParent from "@/components/layouts/GodAdmin/Parents/CreateParent.vue";
import {flashMessage} from "@/utils/flashMessage.js";
import {useRouter} from "vue-router";
import getParents from "@/composables/getParents";
import {API_BASE_URL} from "@/config.js";
import {capitalizeFirstLetter, formatDateTime, getUserInitials, getValidToken, hasPermission,} from "@/utils/helpers";
import {checkAndRefreshToken} from "@/utils/auth";
import StatCards from "@/components/common/StatCards.vue";
import EditParent from "@/components/layouts/GodAdmin/Parents/EditParent.vue";
import {useUserStore} from "@/stores/user";

export default {
    components: {
        EditParent,
        RoundedButton,
        TableView,
        FormModal,
        MessageSliderCopy,
        CreateParent,
        InfoModal,
        StatCards,
    },
    emits: ["showLoader", "flashMessage", "data-loaded"],
    setup(props, { emit }) {
        document.title = "Manage Parents";
        const {
            parentsPayloadData,
            fetchParentsData,
            loading,
            formattedParentsData,
            parentsData,
        } = getParents();
        const isFormModalOpen = ref(false);
        const isEditFormModalOpen = ref(false);
        const displayMessage = ref(false);
        const buttonLoader = ref(false);
        const messageText = ref("");
        const messageType = ref("");
        const newMessage = ref("");
        const showDetailsOrIcons = ref(false);
        const router = useRouter();
        const accessToken = getValidToken();
        const isConfirmationDialogOpen = ref(false);
        const isFlashModalOpen = ref(false);
        const fullName = ref("");
        const currentPage = ref(1);
        const itemsPerPage = ref(10); // Default items per page
        const totalPages = ref(0);
        const itemData = ref(null);
        const numOfParents = ref(0);
        const activeParents = ref(0);
        const inActiveParents = ref(0);
        const parentsList = ref([]);

        const buttonAction = () => {
            isFlashModalOpen.value = false;
            openFormModal();
        };

        const openFlashModal = () => {
            isFlashModalOpen.value = true;
        };

        const closeFlashModal = async () => {
            isFlashModalOpen.value = false;
            await fetchParentsData(currentPage.value, itemsPerPage.value);
        };

        const createItemFlashLinkAction = () => {
            closeFlashModal();
        };

        const openFormModal = () => {
            isFormModalOpen.value = true;
        };

        const closeFormModal = (name) => {
            isFormModalOpen.value = false;

            fullName.value = name;
            isFlashModalOpen.value = true;
        };

        const closeFormModal1 = () => {
            isFormModalOpen.value = false;
        };

        const openEditFormModal = () => {
            isEditFormModalOpen.value = true;
        };

        const closeEditFormModal = async (message) => {
            isEditFormModalOpen.value = false;
            await fetchParentsData(currentPage.value, itemsPerPage.value);
        };

        const searchStringFromChild = ref("");
        const searchFieldsData = [
            "user.name",
            "user.email",
            "contact",
            "school",
            "status",
            "gender",
        ];

        watch(parentsData, () => {
            parentsList.value = formattedParentsData.value;
            totalPages.value = parentsPayloadData.value.total_pages;

            const stats = parentsPayloadData.value.results;
            numOfParents.value = stats.num_of_parents;
            activeParents.value = stats.num_of_active_parents;
            inActiveParents.value = stats.num_of_deactivated_parents;
        });

        onMounted(() => {
            fetchParentsData(currentPage.value, itemsPerPage.value);
            emit("data-loaded");
        });

        onActivated(() => {
            fetchParentsData(currentPage.value, itemsPerPage.value);
        });

        const getIdForActions = computed(() => {
            return parentsList.value.map((item) => {
                return item.user.id;
            });
        });

        const getDataForDetailsView = computed(() => {
            return parentsData.value;
        });

        const viewAction = (item, id) => {
            router.push({
                name: "ShowParent",
                params: { id: id },
            });
        };

        const itemDataForEditing = ref(null);
        const editAction = (item, item_id) => {
            itemData.value = item;

            // Find the item to edit
            const itemForEditing = parentsData.value.find(
                (r) => r.user_id === item_id,
            );
            itemDataForEditing.value = {
                firstName: itemForEditing.first_name,
                lastName: itemForEditing.last_name,
                gender: itemForEditing._gender,
                email: itemForEditing.email,
                country: itemForEditing.country,
                state: itemForEditing.state,
                address: itemForEditing.address,
                workAddress: itemForEditing.work_address,
                workEmail: itemForEditing.work_email,
                workMsisdn: itemForEditing.work_msisdn,
                relationshipToStudent: itemForEditing.relationship_to_student,
                childFirstLanguage: itemForEditing.child_first_language,
                agreeWithTerms: itemForEditing.agree_with_terms,
                hasEmailedChildKyc: itemForEditing.has_emailed_child_kyc,
                id: item_id,
            };

            openEditFormModal();
        };

        const deactivateAction = async (id) => {
            // console.log('item id', id)
            const apiUrl = `${API_BASE_URL}/parent/delete-parent`;
            try {
                await checkAndRefreshToken();

                buttonLoader.value = true;
                const response = await fetch(apiUrl, {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    },
                    body: JSON.stringify({
                        parent_id: id,
                        reason: "",
                    }),
                });

                if (response.ok) {
                    const result = await response.json();

                    if (result.code === 200) {
                        closeConfirmationDialog();

                        const msg = {
                            apiResponse: "Parent status updated",
                            type: "success",
                        };
                        showFlashMessage(msg);
                    } else {
                        const msg = {
                            apiResponse: result.message,
                            type: "success",
                        };
                        showFlashMessage(msg);
                        console.log("error", result.message);
                    }
                } else {
                    const result = await response.json();
                    console.log("error", result.message);
                }
            } catch (error) {
                console.error("An error occured:", error);
            } finally {
                buttonLoader.value = false;
            }
        };

        const messageFromChild = ref("");
        const handleMessageFromChild = (message) => {
            messageFromChild.value = message;
        };

        const showFlashMessage = (msg) => {
            flashMessage(
                msg.apiResponse,
                msg.type,
                messageText,
                messageType,
                displayMessage,
            );
        };

        const tableHeaders = computed(() => {
            return [
                { label: "Name", key: "name" },
                { label: "School", key: "school" },
                { label: "Contact Number", key: "contact" },
                { label: "Gender", key: "gender" },
                { label: "Added On", key: "date" },
                { label: "Acct Status", key: "status" },
            ];
        });

        const itemId = ref("null");
        const itemStatus = ref("");
        const deactivateConfirmationParagraph = ref("");
        const deactivateButtonText = ref("");

        const openConfirmationDialog = (item, id) => {
            itemId.value = id;

            itemStatus.value =
                item.isDeactivated === false ? "deactivate" : "activate";
            deactivateConfirmationParagraph.value = `You are about to ${itemStatus.value} this parent's account. Are you sure you want to continue this action?.`;
            deactivateButtonText.value = `Yes, ${capitalizeFirstLetter(itemStatus.value)} Parent`;
            isConfirmationDialogOpen.value = true;
        };

        const closeConfirmationDialog = () => {
            isConfirmationDialogOpen.value = false;
            fetchParentsData(currentPage.value, itemsPerPage.value);
        };

        const performAction = () => {
            deactivateAction(itemId.value);
        };

        const cancelAction = () => {
            closeConfirmationDialog();
        };

        const changePage = (page) => {
            if (page >= 1 && page <= totalPages.value) {
                currentPage.value = page;

                fetchParentsData(currentPage.value, itemsPerPage.value);
            }
        };

        const changeItemsPerPage = (perPage) => {
            itemsPerPage.value = perPage;
            currentPage.value = 1; // Reset to the first page when changing items per page

            fetchParentsData(currentPage.value, perPage);
        };

        const searchForItem = async (word) => {
            const apiUrl = `${API_BASE_URL}/parent/search-parent?query=${word}`;
            try {
                loading.value = true;
                await checkAndRefreshToken();

                const response = await fetch(apiUrl, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });

                if (response.ok) {
                    const result = await response.json();

                    if (result.code === 200) {
                        parentsList.value = result.data.map((parent) => {
                            // Format the created at
                            const { date, time } = formatDateTime(
                                parent.created_at,
                            );

                            const formattedParent = {
                                user: {
                                    name: capitalizeFirstLetter(
                                        `${parent.first_name} ${parent.last_name}`,
                                    ),
                                    email: parent.email,
                                    initials: getUserInitials(
                                        parent.first_name,
                                        parent.last_name,
                                    ),
                                    avatarColor: "primary",
                                    id: parent.user_id,
                                },
                                contact: parent.msisdn,
                                gender: parent._gender,
                                createdAt: date,
                                status: parent.isDeactivated
                                    ? "Deactivated"
                                    : "Active",
                            };

                            return {
                                ...formattedParent,
                            };
                        });

                        totalPages.value = parentsList.value.length;
                    } else {
                        const msg = {
                            apiResponse: result.message,
                            type: "success",
                        };
                        showFlashMessage(msg);
                        console.log("error", result.message);
                    }
                } else {
                    const result = await response.json();
                    console.log("error", result.message);
                }
            } catch (error) {
                console.error("An error occured:", error);
            } finally {
                loading.value = false;
            }
        };

        const userStore = useUserStore();
        const user = ref(userStore.user);
        const userPermissions = ref(user.value.permissions);
        const checkPermission = (permission) =>
            hasPermission(userPermissions, permission);

        return {
            tableHeaders,
            parentsData,
            totalPages,
            formattedParentsData,
            getIdForActions,
            loading,
            searchStringFromChild,
            searchFieldsData,
            isFormModalOpen,
            openFormModal,
            closeFormModal,
            closeFormModal1,
            handleMessageFromChild,
            showFlashMessage,
            displayMessage,
            itemDataForEditing,
            messageText,
            messageType,
            newMessage,
            showDetailsOrIcons,
            viewAction,
            editAction,
            deactivateAction,
            InfoModal,
            isConfirmationDialogOpen,
            openConfirmationDialog,
            closeConfirmationDialog,
            performAction,
            cancelAction,
            buttonLoader,
            isEditFormModalOpen,
            openEditFormModal,
            closeEditFormModal,
            itemData,
            isFlashModalOpen,
            openFlashModal,
            closeFlashModal,
            buttonAction,
            fullName,
            createItemFlashLinkAction,
            deactivateConfirmationParagraph,
            deactivateButtonText,
            getDataForDetailsView,
            changePage,
            changeItemsPerPage,
            currentPage,
            itemsPerPage,
            numOfParents,
            activeParents,
            inActiveParents,
            searchForItem,
            parentsList,
            hasPermission: checkPermission,
        };
    },
};
</script>
<style></style>
