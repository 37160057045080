<template>
    <div class="row">
        <div class="col-12">
            <div class="page-title-box">
                <BreadCrumbs/>
            </div>
        </div>
    </div>

    <div class="row" v-if="itemData">
        <div class="col-sm-4">
            <div class="card-box">
                <div class="card-content">
                    <div class="header-content text-center">
                        <img src="../../../assets/images/icons/college_logo.png" alt="school logo" height="150"
                             width="150"/>

                        <h4 class="mb-0">{{ itemData.payer }}</h4>
                        <p class="mb-0">{{ itemData.customer_email }}</p>
                    </div>

                    <div class="middle-content mb-100">
                        <div class="details-content">
                            <img src="../../../assets/images/icons/chart_icon.svg" alt="Mobile Icon"/>
                            <div class="details">
                                <p class="muted-text-sm">Total Transaction Volume</p>
                                <p class="result-text">
                                    {{ formatCurrency(itemData.total_transaction_volume) }}
                                </p>
                            </div>
                        </div>

                        <div class="details-content">
                            <img src="../../../assets/images/icons/chart_icon.svg" alt="Mobile Icon"/>
                            <div class="details">
                                <p class="muted-text-sm">Pending Transactions</p>
                                <p class="result-text">{{ itemData.total_transaction_pending }}</p>
                            </div>
                        </div>

                        <div class="details-content">
                            <img src="../../../assets/images/icons/chart_icon.svg" alt="Mobile Icon"/>
                            <div class="details">
                                <p class="muted-text-sm">Completed Transactions</p>
                                <p class="result-text">{{ itemData.total_transaction_completed }}</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div class="col-sm-8">
            <div class="card-box-no-pd">
                <div class="page-title-box mb-30">
                    <h4 class="page-title-gray">Transaction Details</h4>
                </div>

                <div class="card-content border-bottom plr-50">
                    <div class="row border-bottom">
                        <div class="col-sm-12 col-lg-6">
                            <div class="table-responsive">
                                <table class="table table-borderless">
                                    <tr>
                                        <td class="muted-text">Transaction Reference</td>
                                        <td class="bold bolder-text">{{ itemData.reference }}</td>
                                    </tr>

                                    <tr>
                                        <td class="muted-text">Date</td>
                                        <td class="bold bolder-text">{{ userCreatedAt}}</td>
                                    </tr>

                                    <tr>
                                        <td class="muted-text">Amount</td>
                                        <td class="bold bolder-text">{{ formatCurrency(itemData.amount) }}</td>
                                    </tr>

                                    <tr>
                                        <td class="muted-text">Payer</td>
                                        <td class="bold bolder-text">
                                            {{ payerName }}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td class="muted-text">Purpose</td>
                                        <td class="bold bolder-text">{{ capitalizeFirstLetter(itemData.purpose) }}</td>
                                    </tr>

                                    <tr>
                                        <td class="muted-text">Status</td>
                                        <td class="bold bolder-text">
                                                <span class="badge badge-success badge-pill"
                                                      v-if="itemData.status === 'success'">Success</span>
                                            <span class="badge badge-warning badge-pill" v-else>Pending</span>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>


                    <div class="row border-bottom mt-20">
                        <div class="col-sm-12 col-lg-6">
                            <div class="table-responsive">
                                <table class="table table-borderless">
                                    <tr>
                                        <td colspan="2">
                                            <h4>Billing Information</h4>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="muted-text">Payment Method</td>
                                        <td class="bold bolder-text">{{ capitalizeFirstLetter(itemData.channel) }}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td class="muted-text">Payer Email</td>
                                        <td class="bold bolder-text">{{ itemData.customer_email }}</td>
                                    </tr>

                                    <tr>
                                        <td class="muted-text">Payer Phone</td>
                                        <td class="bold bolder-text">{{ payerPhone }}</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>


                    <div class="row mt-20 mb-50">
                        <div class="col-12">
                            <div class="detail-group">
                                <div class="mb-10">
                                    <span class="muted-text-sm bold">Description</span>
                                </div>
                                <p>This transaction covers the plan's billing duration</p>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>
</template>

<script>
import BreadCrumbs from "@/components/common/BreadCrumbs.vue";
import {onMounted, ref, watch} from "vue";
import {capitalizeFirstLetter, formatCurrency, formatDateTime, getValidToken} from '@/utils/helpers'
import {checkAndRefreshToken} from '@/utils/auth';
import {API_BASE_URL} from "@/config.js";
import {useRoute} from "vue-router";


export default {
    components: {
        BreadCrumbs,
    },
    props: {
        id: String
    },
    emits: ['showLoader', 'show-loader', 'flashMessage', 'data-loaded'],
    setup(props, {emit}) {
        const itemData = ref(null)
        const userCreatedAt = ref('')
        const loading = ref(null)
        const router = useRoute()
        const itemId = router.params.id
        const accessToken = getValidToken();
        const payerName = ref('')
        const payerPhone = ref('')

        const fetchItemData = async () => {
            const apiUrl = `${API_BASE_URL}/transaction/${itemId}`;

            try {
                emit('show-loader')
                await checkAndRefreshToken()

                const response = await fetch(apiUrl, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    }
                })

                if (response.ok) {
                    const result = await response.json()
                    itemData.value = result.data

                    // console.log('result', result.data)
                } else {
                    console.log("response", response);
                    console.error("Error fetching data from the API");
                }
            } catch (error) {
                console.error('An error occured: ', error)
            }
        }

        onMounted(() => {
            fetchItemData()
        })

        watch(itemData, (item) => {
            const {date, time} = formatDateTime(item.created_at)
            userCreatedAt.value = `${date}, ${time}`
            payerName.value = capitalizeFirstLetter(item.payer) ?? 'N/A'
            payerPhone.value = item.phone_number ?? 'N/A'

            emit('data-loaded')
        })

        return {
            loading,
            itemData,
            userCreatedAt,
            fetchItemData,
            capitalizeFirstLetter,
            formatCurrency,
            payerName,
            payerPhone
        }
    }
};
</script>

<style scoped>

.detail-content-group > p {
    margin: 0;
}

.detail-group {
    padding-left: 4px;
}

.border-bottom {
    border-bottom: 2px solid #F5F6FA;
}

.header-content {
    padding: 30px 0 20px 0;
    position: relative;
    border-bottom: 1px solid #e9eaf3;
}

p {
    color: #9a9a9a;
    font-size: 16px;
}

.details-content {
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding: 30px 30px 0 30px;
}

.details-content:last-child {
    border-bottom: 1px solid #e9eaf3;
    padding-bottom: 30px;
}

.details-content > img {
    margin-right: 15px;
    margin-top: 5px;
}

.details-content .details > p {
    margin-bottom: 0;
}

.logo-text > img {
    margin-right: 15px;
    margin-top: 2px;
}

.logo-text > p {
    color: #333333;
}

.detail-group > p,
.result-text,
.data-title {
    color: #333;
}

.item > .count {
    color: #2f5bcf;
}

.card-box {
    padding: 0 15px 0 15px
}


.table-borderless thead {
    color: #9a9a9a;
}

.table-borderless thead {
    background-color: #f5f6fa;
}

.table-borderless th:first-child {
    border-top-left-radius: 10px;
}

.table-borderless th:last-child {
    border-top-right-radius: 10px;
}

.tableTwo td {
    color: #333;
}

.table td, .table th {
    padding: 10px;
}
</style>