<template>


    <div class="row" v-if="itemData">

        <div class="col-sm-12">
            <div class="card-box-no-pd">
                <div class="page-title-box mb-30">
                    <h4 class="page-title-gray">School Details</h4>

                    <ul class="tab-container">
                        <li v-for="(tab, index) in tabs" :key="index" @click="selectTab(index)" class="tab"
                            :class="{ 'active-tab': activeTab === index }">
                            {{ tab }}
                        </li>
                    </ul>
                </div>

                <div v-show="activeTab === 0">
                    <div class="card-content border-bottom plr-50 pb-30" v-if="schoolData != null">
                        <div class="row">
                            <div class="col-lg-3 col-sm-12 text-center m-auto">
                                <div v-if="schoolData.logo">
                                    <img
                                        class="mb-20 d-block mx-auto rounded-circle img-thumbnail"
                                        :src="schoolData.logo"
                                        height="150"
                                        width="150"
                                        alt="school logo"
                                        @error="handleSchoolImageError"
                                    />
                                </div>

                                <AvatarForInitials :avatarText="getUserInitialsForName(schoolName)" iconColor="primary"
                                                   class="avatar-md-150 mb-30 m-auto" v-else/>

                                <input ref="schoolImageFileInput" type="file" accept=".png, .jpg"
                                       @change="selectSchoolImage"
                                       style="display: none"/>

                                <a href="#" class="bold primary-text" @click.prevent="triggerSchoolImageFilePicker"
                                   v-if="currentUserType === 'school-admin'">
                                    Change Image</a>
                            </div>

                            <div class="col-lg-9 col-sm-12">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="detail-group mb-30">
                                            <span class="muted-text-sm">Name</span>
                                            <p>{{ capitalizeFirstLetter(schoolData.name) }}</p>
                                        </div>

                                        <div class="detail-group mb-30">
                                            <span class="muted-text-sm">Contact Number</span>
                                            <p>{{ schoolData.msisdn }}</p>
                                        </div>

                                        <div class="detail-group mb-30">
                                            <span class="muted-text-sm">Country</span>
                                            <p>{{ capitalizeFirstLetter(schoolData.country) }}</p>
                                        </div>

                                        <div class="detail-group mb-30">
                                            <span class="muted-text-sm">Registration Number</span>
                                            <p>{{ schoolData.reg_number }}</p>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="detail-group mb-30" v-if="schoolData.subcription_plan">
                                            <span class="muted-text-sm">Subscription</span>
                                            <p>{{ schoolData.subcription_plan }}</p>
                                        </div>
                                        <div class="detail-group mb-30" v-if="currentSubscriptionPlan">
                                            <span class="muted-text-sm">Next Billing Date</span>
                                            <p>{{ currentSubscriptionPlan }}</p>
                                        </div>
                                        <div class="detail-group mb-30">
                                            <span class="muted-text-sm">State</span>
                                            <p>{{ schoolData.state }}</p>
                                        </div>
                                        <div class="detail-group mb-30">
                                            <span class="muted-text-sm">Status</span>
                                            <p>{{ schoolData.isDeactivated ? 'Inactive' : 'Active' }}</p>
                                        </div>
                                    </div>

                                    <div class="col-sm-12">
                                        <div class="detail-group mb-30">
                                            <span class="muted-text-sm">Residential Address</span>
                                            <p>{{ schoolData.address }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-show="activeTab === 1">
                    <div class="card-content border-bottom plr-50 pb-30">
                        <div class="row">
                            <div class="col-lg-3 col-sm-12 text-center m-auto">
                                <div v-if="itemData.profile_image">
                                    <img
                                        class="mb-20 d-block mx-auto rounded-circle img-thumbnail"
                                        :src="itemData.profile_image"
                                        height="150"
                                        width="150"
                                        alt="school logo"
                                        @error="handleImageError"
                                    />
                                </div>

                                <AvatarForInitials :avatarText="getUserInitialsForName(userName)" iconColor="primary"
                                                   class="avatar-md-150 mb-30 m-auto" v-else/>

                                <input ref="fileInput" type="file" accept=".png, .jpg"
                                       style="display: none" @change="selectImage"/>

                                <a href="#" class="bold primary-text" @click.prevent="triggerFilePicker"
                                   v-if="currentUserType === 'teacher'">
                                    Change Image</a>
                            </div>

                            <div class="col-lg-9 col-sm-12">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="detail-group mb-30">
                                            <span class="muted-text-sm">Full Name</span>
                                            <p>{{ userName }}</p>
                                        </div>

                                        <div class="detail-group mb-30">
                                            <span class="muted-text-sm">Contact Number</span>
                                            <p>{{ itemData.msisdn }}</p>
                                        </div>

                                        <div class="detail-group mb-30" v-if="itemData.country">
                                            <span class="muted-text-sm">Country of Residence</span>
                                            <p>{{ itemData.country }}</p>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="detail-group mb-30">
                                            <span class="muted-text-sm">Gender</span>
                                            <p>{{ gender }}</p>
                                        </div>
                                        <div class="detail-group mb-30">
                                            <span class="muted-text-sm">Email Address</span>
                                            <p>{{ itemData.email }}</p>
                                        </div>
                                        <div class="detail-group mb-30" v-if="itemData.state">
                                            <span class="muted-text-sm">State of Residence</span>
                                            <p>{{ itemData.state }}</p>
                                        </div>
                                    </div>

                                    <div class="col-sm-12">
                                        <div class="detail-group mb-30">
                                            <span class="muted-text-sm">Residential Address</span>
                                            <p>{{ itemData.residence }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-show="activeTab === 2">
                    <div class="card-content border-bottom plr-50 pb-30">

                        <div class="row mb-30">
                            <div class="col-lg-8 col-sm-12">

                                <div class="accordion custom-accordion" id="custom-accordion-one">

                                    <div class="card mb-0" v-for="(faq, index) in schoolFAQ" :key="faq">
                                        <div class="card-header" id="headingNine">
                                            <h5 class="m-0 position-relative d-flex">
                                                <a class="custom-accordion-title text-reset d-block mr-10"
                                                   data-toggle="collapse" :href="`#collapse${index}`"
                                                   aria-expanded="true" :aria-controls="`collapse${index}`">
                                                    Q. {{ capitalizeFirstLetterInSentence(faq.question) }}?
                                                    <i class="mdi mdi-chevron-down accordion-arrow"></i>
                                                </a>

                                                <a href="#" @click="deleteSchoolFAQData(faq.id)">
                                                    <img src="../../../assets/images/icons/trash_icon.svg"
                                                         alt="Delete Icon"/>
                                                </a>
                                            </h5>
                                        </div>

                                        <div :id="`collapse${index}`" class="collapse"
                                             aria-labelledby="headingFour"
                                             data-parent="#custom-accordion-one">
                                            <div class="card-body">
                                                {{ capitalizeFirstLetterInSentence(faq.answer) }}

                                                <RegularButton buttonText="Edit" iconText="mdi-plus"
                                                               color="yellow"
                                                               class="btn btn-dark btn-sm btn-soft-dark d-block mt-3"
                                                               @clicked="editSchoolFAQData(faq.id)"/>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>


                        <div class="row mb-50">
                            <div class="col-12">
                                <RegularButton buttonText="Add FAQ" iconText="mdi-plus"
                                               color="yellow"
                                               class="custom-button"
                                               @clicked="openCreateFormModal"/>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <FormModal :isFormModalOpen="isEditFormModalOpen" @closeFormModal="closeEditFormModal"
               modalHeader="Edit FAQ">
        <EditFaq @closeFormModal="closeEditFormModal" :itemData="itemDataForEditing"/>
    </FormModal>


    <FormModal :isFormModalOpen="isCreateFormModalOpen" @closeFormModal="closeCreateFormModal"
               modalHeader="Add FAQ">
        <AddFaq @closeFormModal="closeCreateFormModal"/>
    </FormModal>

</template>

<script>
import {onMounted, ref, watch} from "vue";
import {
    capitalizeFirstLetter,
    capitalizeFirstLetterInSentence,
    compressImage,
    formatDateTime,
    getCurrentUser,
    getSchoolId,
    getUserInitialsForName,
    getValidToken,
    truncateText
} from '@/utils/helpers'
import {API_BASE_URL} from "@/config.js";
import {checkAndRefreshToken} from "@/utils/auth";
import RoundedButton from "@/components/common/RoundedButton.vue";
import FormModal from "@/components/common/FormModal.vue";
import EditSchoolUser from "@/views/School_Admin/Users/EditSchoolUser.vue";
import RegularButton from "@/components/common/RegularButton.vue";
import EditFaq from "@/views/School_Admin/Profile/EditFaq.vue";
import AddFaq from "@/views/School_Admin/Profile/AddFaq.vue";
import SmModal from "@/components/common/SmModal.vue";
import ChangeImageModal from "@/views/School_Admin/Profile/ChangeImageModal.vue";
import AvatarForInitials from "@/components/common/AvatarForInitials.vue";


export default {
    components: {
        AvatarForInitials,
        ChangeImageModal, SmModal, AddFaq, EditFaq, RegularButton, EditSchoolUser, FormModal, RoundedButton
    },
    props: {
        id: String
    },
    emits: ['showLoader', 'show-loader', 'flashMessage', 'data-loaded', 'flash-message'],
    setup(props, {emit}) {
        const itemData = ref(null)
        const userName = ref('')
        const userRole = ref('')
        const userCreatedAt = ref('')
        const currentUser = getCurrentUser()
        const accessToken = getValidToken();
        const tabs = ref(['School Information', 'Personal Settings', 'FAQs'])
        const activeTab = ref(0)
        const isCreateFormModalOpen = ref(false);
        const isEditFormModalOpen = ref(false);
        const isChangeImageModalOpen = ref(false);
        const schoolFAQ = ref(null)
        const faqQuestion = ref(null)
        const schoolData = ref(null)
        const currentSubscriptionPlan = ref('')

        const openCreateFormModal = () => {
            isCreateFormModalOpen.value = true;
        };

        const closeCreateFormModal = (msg) => {
            isCreateFormModalOpen.value = false;

            if (msg) {
                flashAlertMessage(msg.type, msg.apiResponse)
            }

            fetchSchoolFAQData()
        };

        const itemDataForEditing = ref(null)
        const editSchoolFAQData = (faqId) => {
            const itemForEditing = schoolFAQ.value.find((r) => r.id === faqId)
            itemDataForEditing.value = {
                question: itemForEditing.question,
                answer: itemForEditing.answer,
                id: faqId
            }

            openEditFormModal()
        }

        const openEditFormModal = () => {
            isEditFormModalOpen.value = true;
        };

        const closeEditFormModal = (msg) => {
            isEditFormModalOpen.value = false;

            if (msg) {
                flashAlertMessage(msg.type, msg.apiResponse)
            }

            fetchSchoolFAQData()
        };

        const gender = ref('')
        const currentUserType = currentUser.userType
        const itemId = currentUser.user_id
        const fetchAdminData = async () => {

            try {
                const schoolId = getSchoolId()

                const apiUrl = ref('');
                if (currentUserType === 'admin') {
                    apiUrl.value = `${API_BASE_URL}/admin/get-admin/${itemId}`
                }

                if (currentUserType === 'school-admin') {
                    apiUrl.value = `${API_BASE_URL}/school/${schoolId}/get-admin/${itemId}`
                }

                if (currentUserType === 'teacher') {
                    apiUrl.value = `${API_BASE_URL}/teacher/get-teacher/${itemId}`
                }

                await checkAndRefreshToken()

                emit('show-loader')
                const response = await fetch(apiUrl.value, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    }
                })

                if (response.ok) {
                    const result = await response.json()
                    itemData.value = result.data

                    // console.log('result', result.data)
                } else {
                    console.log("response", response);
                    console.error("Error fetching data from the API");
                }
            } catch (error) {
                console.error('An error occured: ', error)
            }
        }

        const fetchSchoolFAQData = async () => {

            try {
                const schoolId = getSchoolId()
                const apiUrl = `${API_BASE_URL}/school/${schoolId}/faq`;

                await checkAndRefreshToken()

                emit('show-loader')
                const response = await fetch(apiUrl, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    }
                })

                if (response.ok) {
                    const result = await response.json()
                    schoolFAQ.value = result.data

                    // console.log('result', result.data)
                } else {
                    console.log("response", response);
                    console.error("Error fetching data from the API");
                }
            } catch (error) {
                console.error('An error occured: ', error)
            } finally {
                emit('data-loaded')
            }
        }

        const deleteSchoolFAQData = async (faqId) => {

            try {
                const schoolId = getSchoolId()
                const apiUrl = `${API_BASE_URL}/school/${schoolId}/faq/${faqId}`;

                await checkAndRefreshToken()

                emit('show-loader')
                const response = await fetch(apiUrl, {
                    method: 'DELETE',
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    }
                })

                if (response.ok) {
                    await fetchSchoolFAQData()
                    flashAlertMessage('success', 'Success!, You have deleted the FAQ.')
                } else {
                    console.log("response", response);
                    console.error("Error fetching data from the API");
                }
            } catch (error) {
                console.error('An error occured: ', error)
            } finally {
                emit('data-loaded')
            }
        }

        onMounted(async () => {
            await fetchAdminData()
            await fetchSchoolFAQData()

            schoolData.value = JSON.parse(localStorage.getItem('schoolDetails'))
        })

        const flashAlertMessage = (alertType, alertMessage) => {
            emit('flash-message', {
                alertType: alertType,
                alertMessage: alertMessage
            })
        }

        watch(itemData, (item) => {
            if (currentUserType === 'admin') {
                gender.value = item.gender
                userName.value = capitalizeFirstLetter(item.first_name, item.last_name)
            }

            if (currentUserType === 'school-admin') {
                gender.value = item._gender
                userName.value = capitalizeFirstLetter(item.name)
            }

            if (currentUserType === 'teacher') {
                gender.value = item._gender
                userName.value = capitalizeFirstLetter(item.first_name, item.last_name)
            }

            userRole.value = capitalizeFirstLetter(item.role_name)
            const {date, time} = formatDateTime(item.created_at)
            userCreatedAt.value = `${date}, ${time}`

            emit('data-loaded')
        })

        const schoolName = ref('')
        watch(schoolData, (item) => {
            currentSubscriptionPlan.value = item.next_subscription_plan ? truncateText(item.next_subscription_plan, 16, false) : ''
            schoolName.value = capitalizeFirstLetter(item.name)
        })

        const selectTab = (index) => {
            activeTab.value = index;
        };

        const openChangeImageModal = () => {
            isChangeImageModalOpen.value = true
        }

        const closeChangeImageModal = () => {
            isChangeImageModalOpen.value = false
        }

        const selectedImage = ref('')
        const fileInput = ref(null);
        const selectImage = async (event) => {
            const file = event.target.files[0];

            if (file && (file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/jpg')) {
                try {
                    // Set the compressed image as the selected image
                    selectedImage.value = await compressImage(file, 300, 300, 1.5);
                    await handleFileChange()
                } catch (error) {
                    console.error('Error compressing image: ', error)
                } finally {
                    // Reset the value to allow selecting the same file again
                    if (fileInput.value) {
                        fileInput.value.value = ''; // Reset the file input
                    }
                }
            } else {
                // Handle invalid file type
                flashAlertMessage('error', 'Invalid file type. Please select a PNG or JPEG image.')
                console.error('Invalid file type. Please select a PNG or JPEG image.');
            }
        };

        const triggerFilePicker = () => {
            if (fileInput.value) {
                fileInput.value.click(); // This simulates a click on the file input, opening the file picker dialog
            }
        }

        const handleFileChange = async () => {
            try {
                emit('show-loader')
                const apiUrl = `${API_BASE_URL}/teacher/change-profile-image?user_id=${itemId}`;
                await checkAndRefreshToken()

                const profileImage = {
                    profile_image: selectedImage.value
                }

                const response = await fetch(apiUrl, {
                    method: 'PUT',
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(profileImage)
                })

                if (response.ok) {
                    const result = await response.json()

                    if (result.code === 200) {
                        flashAlertMessage('success', 'Profile image successfully updated')
                        await fetchAdminData()

                        // console.log('result', result.data)
                    } else {
                        flashAlertMessage('error', 'An error occured')
                        console.log("code error", result);
                    }
                } else {
                    console.log("ok error", response);
                    console.error("Error sending data to the API");
                }
            } catch (error) {
                console.error('An error occured: ', error)
            } finally {
                emit('data-loaded')
            }
        }

        const triggerSchoolImageFilePicker = () => {
            if (schoolImageFileInput.value) {
                schoolImageFileInput.value.click(); // This simulates a click on the file input, opening the file picker dialog
            }
        }


        const selectedSchoolImage = ref('')
        const schoolImageFileInput = ref(null)
        const selectSchoolImage = async (event) => {
            const file = event.target.files[0];

            if (file && (file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/jpg')) {
                const reader = new FileReader();

                reader.onload = async (e) => {
                    schoolData.value.logo = e.target.result

                    try {
                        // Set the compressed image as the selected image
                        selectedSchoolImage.value = await compressImage(file, 100, 100, 0.8);
                        await uploadSchoolImage()

                    } catch (error) {
                        console.error('Error compressing image: ', error)
                    }
                }

                reader.onerror = (error) => {
                    console.error('Error reading file: ', error);
                };

                reader.readAsDataURL(file);

            } else {
                // Handle invalid file type
                flashAlertMessage('error', 'Invalid file type. Please select a PNG or JPEG image.')
                console.error('Invalid file type. Please select a PNG or JPEG image.');
            }

            if (schoolImageFileInput.value) {
                schoolImageFileInput.value.value = ''; // Reset the file input
            }
        };

        const uploadSchoolImage = async () => {
            try {
                emit('show-loader')
                const apiUrl = `${API_BASE_URL}/school/${schoolData.value.id}`;
                await checkAndRefreshToken()

                const schoolLogo = {
                    logo: selectedSchoolImage.value
                }

                const response = await fetch(apiUrl, {
                    method: 'PUT',
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(schoolLogo)
                })

                if (response.ok) {
                    const result = await response.json()

                    if (result.code === 200) {
                        flashAlertMessage('success', 'Profile image successfully updated')
                        await fetchAdminData()
                        // console.log('result', result.data)
                    } else {
                        flashAlertMessage('error', 'An error occured')
                        console.log("code error", result);
                    }
                } else {
                    console.log("ok error", response);
                    console.error("Error sending data to the API");
                }
            } catch (error) {
                console.error('An error occured: ', error)
            } finally {
                emit('data-loaded')
            }
        }

        const handleImageError = () => {
            itemData.value.profile_image = null
        }

        const handleSchoolImageError = () => {
            schoolData.value.logo = null
        }

        return {
            schoolImageFileInput,
            selectSchoolImage,
            selectedSchoolImage,
            uploadSchoolImage,
            triggerSchoolImageFilePicker,
            triggerFilePicker,
            handleImageError,
            handleSchoolImageError,
            selectedImage,
            selectImage,
            handleFileChange,
            itemData,
            isChangeImageModalOpen,
            closeChangeImageModal,
            openChangeImageModal,
            userName,
            userRole,
            userCreatedAt,
            currentUser,
            tabs,
            activeTab,
            selectTab,
            openCreateFormModal,
            closeCreateFormModal,
            isCreateFormModalOpen,
            schoolFAQ,
            capitalizeFirstLetterInSentence,
            deleteSchoolFAQData,
            editSchoolFAQData,
            faqQuestion,
            isEditFormModalOpen,
            openEditFormModal,
            closeEditFormModal,
            itemDataForEditing,
            schoolData,
            capitalizeFirstLetter,
            truncateText,
            currentSubscriptionPlan,
            gender,
            schoolName,
            getUserInitialsForName,
            fileInput,
            currentUserType
        }
    }
};
</script>

<style scoped>
.border-bottom {
    border-bottom: 2px solid #F5F6FA;
}

.tab-container {
    display: flex;
    background-color: #F8FAFC;
    padding-left: 50px;
}

.tab {
    list-style-type: none;
    margin: 0 20px;
    text-align: center;
    cursor: pointer;
    padding: 10px;
}

.tab:first-child {
    margin: 0;
    padding-left: 0;
}

.active-tab {
    border-bottom: 2px solid #0d44b2;
}

.mb-80 {
    margin-bottom: 80px;
}

.header-content {
    padding: 30px 0 20px 0;
    position: relative;
    border-bottom: 1px solid #e9eaf3;
}

p {
    color: #9a9a9a;
    font-size: 16px;
}

.details-content {
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding: 15px 30px 0 30px;
}

.details-content:last-child {
    border-bottom: 1px solid #e9eaf3;
    padding-bottom: 20px;
}

.details-content img {
    vertical-align: top;
    margin-right: 15px;
    margin-top: 5px;
}

.details-content .details > .subHeader {
    font-weight: 500;
    color: #333333;
}

.details-content .details > p {
    margin-bottom: 0;
}

.logo-text {
    display: flex;
    align-content: center;
    align-items: flex-start;
}

.logo-text > img {
    margin-right: 15px;
    margin-top: 2px;
}

.logo-text > p {
    color: #333333;
}

.bottom-content {
    padding: 20px 30px 0 30px;
    margin-bottom: 50px;
}

.detail-group > p,
.result-text {
    color: #333;
}

.item {
    display: flex;
    justify-content: space-between;
}

.item > .count {
    color: #2f5bcf;
}

.buttons-content {
    padding: 0 15px 40px 15px;
}

.card-box {
    padding: 0 15px 0 15px
}

.rounded-circle {
    height: 150px !important;
    width: 150px !important;
    object-fit: contain;
}
</style>