<template>

    <div class="row" v-if="itemData">
        <div class="col-sm-12 col-md-3">
            <div class="card-box">
                <div class="card-content">
                    <div class="header-content text-center">
                        <AvatarForInitials :avatarText="getUserInitialsForName(schoolName)" iconColor="danger"
                                           class="avatar-md-150 mb-30 m-auto"/>

                        <h4 class="mb-0">{{ itemName }}</h4>
                    </div>

                    <div class="middle-content mb-50">
                        <div class="details-content">
                            <img src="../../../assets/images/icons/calendar_icon.svg" alt="Mobile Icon"/>
                            <div class="details">
                                <p class="muted-text-sm">Added On</p>
                                <p class="result-text">{{ createdAt }}</p>
                            </div>
                        </div>

                        <div class="details-content">
                            <img src="../../../assets/images/icons/folder_icon.svg" class="data-img" alt="Mobile Icon"/>

                            <div class="detail-content-group">
                                <p class="muted-text-sm">Projects</p>
                                <p class="result-text">{{ itemData.projects.length }}</p>
                            </div>
                        </div>

                        <div class="details-content">
                            <img src="../../../assets/images/icons/folder_icon.svg" class="data-img" alt="Mobile Icon"/>

                            <div class="detail-content-group">
                                <p class="muted-text-sm">Students</p>
                                <p class="result-text">{{ itemData.students.length }}</p>
                            </div>
                        </div>

                        <div class="details-content">
                            <img src="../../../assets/images/icons/folder_icon.svg" class="data-img" alt="Mobile Icon"/>

                            <div class="detail-content-group">
                                <p class="muted-text-sm">Co-Learners</p>
                                <p class="result-text">{{ itemData.teachers.length }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="buttons-content">
                        <RegularButtonBlock buttonText="Edit Learning Group" iconText="mdi-account-edit"
                                            class="custom-button mb-3" @clicked="editAction"/>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-sm-12 col-md-9">
            <div class="row">
                <div class="col-12">
                    <div class="page-title-box">
                        <BreadCrumbs/>
                    </div>
                </div>
            </div>

            <div class="card-box-no-pd">
                <div class="page-title-box mb-30">
                    <h4 class="page-title-gray">Learning Group Details</h4>

                    <ul class="tab-container">
                        <li v-for="(tab, index) in tabs" :key="index" @click="selectTab(index)" class="tab"
                            :class="{ 'active-tab': activeTab === index }">
                            {{ tab }}
                        </li>
                    </ul>
                </div>

                <div v-show="activeTab === 0">
                    <div class="card-content border-bottom plr-50">
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="detail-group mb-30">
                                    <span class="muted-text-sm">Name</span>
                                    <p>{{ itemName }}</p>
                                </div>

                                <div class="detail-group mb-30">
                                    <span class="muted-text-sm">Description</span>
                                    <p>{{ capitalizeFirstLetterInSentence(itemData.description) }}</p>
                                </div>

                                <div class="detail-group mb-30">
                                    <span class="muted-text-sm">Date Created</span>
                                    <p>{{ createdAt }}</p>
                                </div>
                                <div class="detail-group mb-30">
                                    <span class="muted-text-sm">Created By</span>
                                    <p>{{ capitalizeFirstLetter(itemData.creator_name) }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-show="activeTab === 1">
                    <div class="card-content border-bottom plr-50">
                        <div class="row m-0">
                            <div class="col-12 mb-20 pl-0">
                                <p class="p-0" v-if="itemData.projects.length === 0">No Projects Yet</p>

                                <div class="row" v-else>
                                    <div class="col-lg-4 col-sm-12" v-for="project in itemData.projects">
                                        <div class="card card-project bg-light">
                                            <div class="card-body">
                                                <h5 class="card-title bold">
                                                    {{ capitalizeFirstLetter(project.name) }}
                                                </h5>
                                                <p class="card-text text-dark mb-3">
                                                    {{ truncateText(capitalizeFirstLetter(project.description), 30) }}
                                                </p>
                                                <router-link :to="{ name: 'ShowProject', params: {id: project.id} }"
                                                             class="btn btn-dark btn-sm waves-effect">
                                                    <span class="bold"> View </span>
                                                </router-link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-show="activeTab === 2">
                    <div class="card-content border-bottom plr-50">
                        <div class="row m-0">
                            <div class="col-12 mb-20 pl-0">
                                <p class="p-0" v-if="itemData.students.length === 0">No Students Yet</p>

                                <div class="row" v-else>
                                    <UserAvatarBox2 v-for="student in itemData.students" :key="student"
                                                    class="col-lg-3 col-sm-12 mb-1 mr-1"
                                                    :studentFirstName="student.first_name"
                                                    :viewAction="viewAction"
                                                    :studentLastName="student.last_name"
                                                    :student="student"
                                                    :id="student.user_id"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-show="activeTab === 3">
                    <div class="card-content border-bottom plr-50">
                        <div class="row m-0">
                            <div class="col-12 mb-20 pl-0">
                                <p class="p-0" v-if="itemData.teachers.length === 0">No Co-Learners Yet</p>

                                <div class="row" v-else>
                                    <UserAvatarBox2 v-for="teacher in itemData.teachers" :key="teacher"
                                                    class="col-lg-3 col-sm-12 mb-1 mr-1"
                                                    :studentFirstName="teacher.first_name" :viewAction="viewTeachers"
                                                    :studentLastName="teacher.last_name" :id="teacher.user_id"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div v-show="activeTab === 4">
                    <div class="card-content border-bottom plr-50">
                        <Comments
                            :deleteCommentUrl="deleteCommentUrl"
                            :fetchCommentUrl="fetchCommentUrl"
                            :updateCommentUrl="updateCommentUrl"
                            :addCommentUrl="addCommentUrl"/>
                    </div>
                </div>

                <div v-show="activeTab === 5">
                    <div class="card-content border-bottom plr-50">
                        <Files
                            :fileAddURL="fileAddURL"
                            :fileFetchURL="fileFetchURL"
                            :fileDeleteURL="fileDeleteURL"
                            @feedback="showAlertForFiles"
                            @loading="showFilesLoader"
                        />
                    </div>
                </div>


                <FormModal :isFormModalOpen="isEditFormModalOpen" @closeFormModal="closeEditFormModal"
                           modalHeader="Edit Learning Group">
                    <EditLearningGroup @closeFormModal="closeEditFormModal"
                                       :itemData="itemDataForEditing" @refreshPage="fetchItemData"/>
                </FormModal>
            </div>
        </div>
    </div>
</template>

<script>
import Switch from "@/components/common/Switch.vue";
import RegularButtonBlock from "@/components/common/RegularButtonBlock.vue";
import BreadCrumbs from "@/components/common/BreadCrumbs.vue";
import {onMounted, ref, watch} from "vue";
import {
    capitalizeFirstLetter,
    capitalizeFirstLetterInSentence,
    formatDateTime,
    getSchoolId,
    getUserInitialsForName,
    getValidToken,
    truncateText
} from '@/utils/helpers'
import {API_BASE_URL} from "@/config.js";
import MessageSliderCopy from "@/components/common/MessageSliderCopy.vue";
import FormModal from "@/components/common/FormModal.vue";
import {useRoute, useRouter} from "vue-router";
import UserAvatarBox2 from "@/components/common/UserAvatarBox2.vue";
import {checkAndRefreshToken} from "@/utils/auth";
import EditLearningGroup from "@/views/School_Admin/LearningGroups/EditLearningGroup.vue";
import AvatarForInitials from "@/components/common/AvatarForInitials.vue";
import Comments from "@/components/common/Comments.vue";
import RegularButton from "@/components/common/RegularButton.vue";
import Files from "@/components/common/Files.vue";


export default {
    emits: ['feedback', 'show-loader', 'data-loaded'],
    components: {
        Files,
        RegularButton,
        Comments,
        AvatarForInitials,
        EditLearningGroup,
        UserAvatarBox2,
        FormModal,
        Switch,
        RegularButtonBlock,
        BreadCrumbs,
        MessageSliderCopy
    },
    props: {
        id: String
    },
    setup(props, {emit}) {
        const isEditFormModalOpen = ref(false);
        const itemData = ref(null)
        const itemName = ref('')
        const createdAt = ref('')
        const loading = ref(null)
        const checkedStatus = ref(false)
        const switchTextLabel = ref('')
        const messageText = ref("");
        const messageType = ref("");
        const displayMessage = ref(false);
        const router = useRoute()
        const newRouter = useRouter()
        const itemId = router.params.id
        const accessToken = getValidToken()
        const tabs = ref(['Group Info', 'Projects', 'Students', 'Co-Learners', 'Updates', 'Files'])
        const activeTab = ref(0)
        const schoolName = ref('')
        const schoolEmail = ref('')
        const schoolId = getSchoolId()
        const fetchCommentUrl = `${API_BASE_URL}/school/${schoolId}/learning-groups/${itemId}/comment`
        const addCommentUrl = `${API_BASE_URL}/school/${schoolId}/learning-groups/${itemId}/comment`
        const deleteCommentUrl = `${API_BASE_URL}/school/${schoolId}/learning-groups/${itemId}/comment`
        const updateCommentUrl = `${API_BASE_URL}/school/${schoolId}/learning-groups/${itemId}/comment`
        const fileFetchURL = `${API_BASE_URL}/school/${schoolId}/learning-groups/${itemId}/file`
        const fileAddURL = `${API_BASE_URL}/school/${schoolId}/learning-groups/${itemId}/file`
        const fileDeleteURL = `${API_BASE_URL}/school/${schoolId}/learning-groups/${itemId}/file`

        const fetchItemData = async () => {
            const apiUrl = `${API_BASE_URL}/school/${schoolId}/learning-groups/${itemId}`;

            try {
                await checkAndRefreshToken()

                emit('show-loader')
                const response = await fetch(apiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    }
                })

                if (response.ok) {
                    const result = await response.json()
                    itemData.value = result.message

                    // console.log('result', result.message)
                } else {
                    console.log("response", response);
                    console.error("Error fetching data from the API");
                }
            } catch (error) {
                console.error('An error occured: ', error)
            }
        }

        onMounted(() => {
            fetchItemData()

            const schoolDetails = JSON.parse(localStorage.getItem('schoolDetails'))
            schoolName.value = schoolDetails.name
            schoolEmail.value = schoolDetails.email
        })

        watch(itemData, (item) => {
            itemName.value = capitalizeFirstLetter(item.name)
            const {date, time} = formatDateTime(item.created_on)
            createdAt.value = `${date}, ${time}`
            checkedStatus.value = !item.isDeactivated
            switchTextLabel.value = item.isDeactivated ? 'Group Deactivated' : 'Group Active'

            if (item) {
                emit('data-loaded')
            }
        })

        const selectTab = (index) => {
            activeTab.value = index;
        };

        const openEditFormModal = () => {
            isEditFormModalOpen.value = true;
        };

        const closeEditFormModal = async (msg) => {
            isEditFormModalOpen.value = false;

            if (msg) {
                flashAlertMessage(msg.type, msg.apiResponse)
            }

            await fetchItemData()
        };


        const itemDataForEditing = ref(null)
        const editAction = () => {
            itemDataForEditing.value = {
                name: itemData.value.name,
                description: itemData.value.description,
                id: props.id
            }

            openEditFormModal()
        }

        const handleSwitchToggle = () => {
            const userId = props.id
            toggleUserStatus(userId)
        }

        const toggleUserStatus = async (id) => {
            const schoolId = getSchoolId()
            const apiUrl = `${API_BASE_URL}/school/${schoolId}/learning-groups/${id}/toggle-status`

            try {
                await checkAndRefreshToken()

                loading.value = true
                const response = await fetch(apiUrl, {
                    method: 'PUT',
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`
                    }
                })

                if (response.ok) {
                    const result = await response.json()

                    if (result.code === 200) {
                        flashAlertMessage("success", "Group status updated")
                        await fetchItemData()
                    } else {
                        flashAlertMessage("error", result.message)
                        console.log('error', result.message)
                    }
                } else {
                    const result = await response.json();
                    flashAlertMessage("error", result.message)
                    console.log("error", result.message);
                }
            } catch (error) {
                flashAlertMessage("error", error)
                console.error("An error occured:", error);
            } finally {
                loading.value = false
            }
        }

        const flashAlertMessage = (alertType, alertMessage) => {
            emit('flash-message', {
                alertType: alertType,
                alertMessage: alertMessage
            })
        }

        const showAlertForFiles = (feedback) => {
            if (feedback.type === 'success') {
                flashAlertMessage('success', feedback.message)
            } else {
                flashAlertMessage('error', feedback.message)
            }
        }

        const viewAction = (student) => {
            console.log('student', student)
            // newRouter.push({name: 'ShowSchoolStudent', params: {id: student}})
        }

        const viewTeachers = (colearner) => {
            console.log('colearner', colearner)
            newRouter.push({name: 'ShowSchoolColearner', params: {id: colearner.user_id}})
        }

        const showFilesLoader = (loading) => {
            if (loading === 'show-files-loader') {
                emit('show-loader')
            }

            if (loading === 'files-data-loaded') {
                emit('data-loaded')
            }
        }

        return {
            showFilesLoader,
            showAlertForFiles,
            loading,
            itemData,
            itemName,
            createdAt,
            checkedStatus,
            switchTextLabel,
            handleSwitchToggle,
            messageText,
            messageType,
            displayMessage,
            editAction,
            closeEditFormModal,
            isEditFormModalOpen,
            itemDataForEditing,
            selectTab,
            fetchItemData,
            capitalizeFirstLetter,
            tabs,
            activeTab,
            capitalizeFirstLetterInSentence,
            schoolName,
            schoolEmail,
            viewAction,
            viewTeachers,
            getUserInitialsForName,
            truncateText,
            itemId,
            deleteCommentUrl,
            fetchCommentUrl,
            addCommentUrl,
            fileFetchURL,
            fileAddURL,
            fileDeleteURL,
            updateCommentUrl
        }
    }
};
</script>

<style scoped>

.detail-content-group > p {
    margin: 0;
}

.border-bottom {
    border-bottom: 2px solid #F5F6FA;
}

p {
    color: #9a9a9a;
    font-size: 16px;
}

.details-content {
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding: 30px 30px 0 30px;
}

.detail-content-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.details-content:last-child {
    padding-bottom: 30px;
}

.details-content:first-child {
    border-bottom: 1px solid #e9eaf3;
    padding-bottom: 30px;
}

.details-content > img {
    margin-right: 15px;
    margin-top: 5px;
}

.details-content > img {
    margin-right: 15px;
    margin-top: 5px;
}

.details-content .details > p {
    margin-bottom: 0;
}

.logo-text > img {
    margin-right: 15px;
    margin-top: 2px;
}

.logo-text > p {
    color: #333333;
}

.detail-group > p,
.result-text,
.data-title {
    color: #333;
}

.item > .count {
    color: #2f5bcf;
}

.buttons-content {
    padding: 0 15px 40px 15px;
}

.page-title-gray {
    background-color: #F8FAFC;
    font-size: 1.25rem;
    margin: 0;
    line-height: 75px;
    color: #323a46;
    padding: 20px 50px 0 50px;
}

.tab-container {
    display: flex;
    background-color: #F8FAFC;
    padding-left: 50px;
}

.tab {
    list-style-type: none;
    margin: 0 20px;
    text-align: center;
    cursor: pointer;
    padding: 10px;
}

.tab:first-child {
    margin: 0;
    padding-left: 0;
}

.active-tab {
    border-bottom: 2px solid #0d44b2;
}

.card-project {
    border: 1px solid #B8BCCA !important;
    background-color: #fff !important;
}
</style>