<template>
    <div class="form-group" :class="fieldClass">
        <div class="custom-select-wrapper">
            <label :for="selectId" class="label">
                {{ labelText }}
                <span class="required" v-if="isRequired">*</span>
            </label>

            <select
                :id="selectId" v-model="selectedValue" @change="handleSingleSelectChange"
                :class="selectId"
                :disabled="isDisabled" :required="isRequired">

                <option></option>
                <option v-for="(option, index) in itemOptions" :key="index" :value="option.value">
                    {{ option.label }}
                </option>
            </select>

            <div v-if="loading" class="linear-loader">
                <div class="train"></div>
            </div>

            <div class="invalid-feedback" v-if="errorMessage">
                {{ errorMessage }}
            </div>
        </div>
    </div>
</template>

<script>
import {onMounted, ref, watch} from "vue";

export default {
    props: {
        labelText: {
            type: String,
            default: 'Select Label'
        },
        options: {
            type: [Array, String],
            required: true,
        },
        selectId: {
            type: String,
            default: 'example-select'
        },
        modelValue: [String, Number],
        class: String,
        loading: Boolean,
        selectedItem: [String, Number],
        isDisabled: {
            type: Boolean,
            default: false
        },
        isRequired: {
            type: Boolean,
            default: false
        },
        errorMessage: String,
    },
    setup(props, {emit}) {
        const selectPlaceholder = 'Select an option'
        const selectedValue = ref(0)
        const fieldClass = props.class || ''
        const itemOptions = ref([])

        const handleSingleSelectChange = () => {
            emit('update:modelValue', selectedValue.value);
            emit('change', selectedValue.value);
        };

        // Watch for changes in the selected item and update the selectedValue
        watch(() => props.modelValue, (newVal) => {
            selectedValue.value = newVal;
        });

        onMounted(() => {
            const selectField = $(`.${props.selectId}`)

            selectField.select2({
                placeholder: selectPlaceholder,
            });

            if (props.options.length > 0) {
                selectedValue.value = props.modelValue;
            }

            // Watch for changes in the selected item and update the selectedValue
            watch(() => props.modelValue, (newVal) => {
                selectedValue.value = newVal;
                selectField.val(newVal).trigger('change')
            });

            // Call handleMultipleSelectChange once the component is mounted to emit the initial value
            selectField.on('change', function () {
                selectedValue.value = $(this).val();
                handleSingleSelectChange();
            })

            // Watch for changes in the options and reset selectedValue
            watch(() => props.options, (newOptions) => {
                if (!selectField || selectField.length === 0) {
                    console.error(`Element with class '${props.selectId}' not found.`);
                    return;
                }

                // Check if there are options to update
                if (newOptions.length > 0) {
                    // Clear the Select2 data and unbind the change event
                    selectField.select2('destroy');

                    // Remove all options
                    selectField.empty();

                    // Add new options
                    for (const option of newOptions) {
                        const optionElement = document.createElement('option');
                        optionElement.value = option.value;
                        optionElement.text = option.label;
                        selectField.append(optionElement);
                    }

                    // Reinitialize Select2
                    selectField.select2({
                        placeholder: selectPlaceholder,
                        allowClear: true,
                    });

                    // Set the selected values
                    selectField.val(selectedValue.value).trigger('change');
                }

            });
        })

        return {selectedValue, fieldClass, handleSingleSelectChange, itemOptions}
    }
};
</script>

<style scoped>
.label {
    font-weight: 400;
    font-size: 14px;
}

/* Custom select */
.custom-select-wrapper {
    position: relative;
    display: block;
    width: 100%; /* Adjust the width as needed */
}

.custom-select-wrapper select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    background-color: #f8fafc;
    border: 1px solid #D0D5DD !important;
    color: #333333;
}

.custom-select-wrapper select option:checked {
    color: #b8bcca;
}

.invalid-feedback {
    display: block;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.75rem;
    color: #f1556c;
}


.linear-loader {
    height: 2px;
    /* Adjust the height as needed */
    background-color: #b8bcca;
    /* Adjust the color as needed */
    width: 100%;
    position: relative;
    top: -2.5px;
    left: 0;
    right: 0;
    overflow: hidden;
    /* animation: moveRight 1s linear infinite; Adjust the animation speed as needed */
    //z-index: 9999;
    /* Ensure it's on top of other elements */
}

.train {
    width: 900px;
    /* Adjust the train's width */
    height: 2px;
    /* Should match the height of the loader */
    background-color: #2f5bcf;
    /* Adjust the color as needed */
    position: absolute;
    animation: moveRight 1s linear infinite;
    /* Adjust the animation speed as needed */
    top: 0;
    left: 0;
}

@keyframes moveRight {
    0% {
        left: -50px;
        /* Start off the screen to the left */
    }

    100% {
        left: 100%;
        /* Move to the right of the screen */
    }
}

.required {
    color: red;
    margin-left: 2px;
    font-weight: bolder;
}
</style>