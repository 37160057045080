<template>
    <div class="row">
        <div class="col-12">
            <div class="page-title-box">
                <h4 class="page-title">Messages</h4>
            </div>
        </div>
    </div>

    <div class="row mainContent">
        <div class="col-12">
            <div class="card-box p-0">
                <div class="row card-row">
                    <div class="col-3 users-section">
                        <div class="fixed-items">
                            <div class="navigate-back-header p-20" v-if="showSearchList"
                                 @click="toggleSearchAndChatListBar">
                                <div class="d-flex">
                                    <img src="../../../assets/images/icons/back_arrow.svg" alt="back icon"
                                         class="mr-2 back-icon"/>
                                    <h5 class="semibold muted_text">Back to Chat List</h5>
                                </div>
                            </div>

                            <div class="users-section-header p-20" v-else>
                                <div class="d-flex" @click="toggleSearchAndChatListBar">
                                    <img src="../../../assets/images/icons/plus_bg_icon.svg" alt="plus icon"
                                         class="mr-2"/>
                                    <h5 class="semibold muted_text">New Chat</h5>
                                </div>
                            </div>

                            <div class="search-section">
                                <div class="input-group">
                                    <input type="text"
                                           placeholder="Search..."
                                           class="form-control table_search"
                                           @input="searchChatUser"
                                           v-model="searchTerm"
                                           list="searchResults"
                                    />

                                    <div class="input-group-append">
                                        <span class="input-group-text">
                                            <img id="search" src="../../../assets/images/icons/search_icon.svg"
                                                 alt="Search Icon"/>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <SearchForNewUser v-if="showSearchList" :searchTerm="searchTermValue"
                                          @chatDetails="updateMessageSelectedDetails"/>
                        <ChatList @chatDetails="updateMessageSelectedDetails" :searchTerm="searchTermValue" v-else/>
                    </div>

                    <div class="col-9 messages-section">
                        <div class="empty-state-container" v-if="messageSelectedID === 0">
                            <div class="text-center">
                                <img src="../../../assets/images/icons/empty_state_icon.svg" alt="empty state"
                                     class="mb-3"/>
                                <h5 class="mb-1">Nothing to show yet</h5>
                                <p class="muted_text">Start a conversation or click on an existing chat</p>
                            </div>
                        </div>

                        <div class="chat-messages" v-else>
                            <div class="chat-message-header">
                                <div class="chat-item-group">
                                    <div class="avatar-md mr-10">
                                        <span
                                            :class="`avatar-title bg-soft-success text-success rounded-circle`">
                                            {{ getInitialsForWord(chatUserName) }}
                                        </span>
                                    </div>
                                    <div>
                                        <p class="m-0 semibold f-16">
                                            {{ chatUserName }}
                                        </p>
                                        <span class="muted-text-sm f-14">Admin</span>
                                    </div>
                                </div>
                            </div>

                            <div class="chat-message-content">
                                <div class="linear-loader" v-if="chatLoader">
                                    <div class="train"></div>
                                </div>

                                <div class="chat-section" ref="chatContainer">
                                    <ChatMessageView v-for="chat in chatMessages" :key="chat"
                                                     :messageType=getMessageType(chat.sender)
                                                     :message=chat.content
                                                     :timestamp=chat.time
                                    />
                                </div>

                                <div class="chat-input-section">
                                    <img src="../../../assets/images/icons/add_icon.svg"
                                         alt="Add File Icon"/>

                                    <textarea placeholder="Type a message here" class="send-chat-message-input"
                                              v-model="messageContent" ref="chatTextArea" @input="resizeTextArea"
                                              @keydown="handleEnterKey"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import RoundedButton from "@/components/common/RoundedButton.vue";
import {nextTick, onMounted, ref, watch} from "vue";
import ChatList from "@/views/God_Admin/Messaging/ChatList.vue";
import SearchForNewUser from "@/views/God_Admin/Messaging/SearchForNewUser.vue";
import ChatMessageView from "@/views/God_Admin/Messaging/ChatMessageView.vue";
import {checkAndRefreshToken} from "@/utils/auth";
import {getInitialsForWord, getValidToken} from "@/utils/helpers";
import {API_BASE_URL} from "@/config";

export default {
    props: {},
    components: {ChatMessageView, SearchForNewUser, ChatList, RoundedButton},
    emits: ['showLoader', 'flashMessage', 'data-loaded'],
    setup(props, {emit}) {
        document.title = 'Messages'
        const showSearchList = ref(false)
        const messageSelectedID = ref(0)
        const chatMessages = ref([])
        const chatLoader = ref(false)
        const messageContent = ref('')
        const chatContainer = ref(null)
        const chatTextArea = ref(null)
        const chatUserName = ref('')
        const searchTerm = ref('')
        const searchTermValue = ref('')
        const searchResults = ref([]);

        onMounted(() => {
            emit('data-loaded')
        })

        const toggleSearchAndChatListBar = () => {
            searchTerm.value = ''
            showSearchList.value = !showSearchList.value
        }

        const fetchChatMessages = async (id) => {
            try {
                setTimeout(() => {
                    chatTextArea.value.style.height = 25 + 'px'
                }, 100)

                messageContent.value = ''

                chatLoader.value = true
                await checkAndRefreshToken()

                const accessToken = getValidToken()
                if (!accessToken) {
                    console.error('Access token not available')
                }

                const apiURL = `${API_BASE_URL}/message/get-chats/${id}`

                const response = await fetch(apiURL, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                })

                if (response.ok) {
                    const result = await response.json()

                    chatMessages.value = result.data
                } else {
                    console.error('response', response)
                    console.error("Error fetching data from API")
                }

            } catch (error) {
                console.log('An error occurred', error)
            } finally {
                chatLoader.value = false
            }
        }

        const getMessageType = (sender) => {
            return sender === 'me' ? 'sender' : 'receiver'
        }

        const sendMessage = async () => {
            try {
                await checkAndRefreshToken()

                const accessToken = getValidToken()
                if (!accessToken) {
                    console.error('Access token not available')
                }

                const apiURL = `${API_BASE_URL}/message/send-message`
                const newMessage = {
                    receiver: messageSelectedID.value,
                    content: messageContent.value,
                    content_type: "text"
                }

                const response = await fetch(apiURL, {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(newMessage)
                })

                if (response.ok) {
                    await fetchChatMessages(messageSelectedID.value)
                    messageContent.value = ''
                } else {
                    console.error('response', response)
                    console.error("Error fetching data from API")
                }

            } catch (error) {
                console.log('An error occurred', error)
            }
        }


        const updateMessageSelectedDetails = (chatDetails) => {
            messageSelectedID.value = chatDetails.messageSelectedID

            fetchChatMessages(chatDetails.messageSelectedID)

            updateChatUIWithNameOfUser(chatDetails)
        }

        const handleEnterKey = (event) => {
            if (event.key === 'Enter' && !event.shiftKey) {
                event.preventDefault()
                sendMessage()
            } else if (event.key === 'Enter' && event.shiftKey) {
                messageContent.value += '\n';
            }
        }

        const updateChatUIWithNameOfUser = (chatDetails) => {
            chatUserName.value = chatDetails.userName
        }

        watch(() => chatMessages.value, () => {
            scrollChatToBottom()
        })

        const scrollChatToBottom = () => {
            nextTick(() => {
                const container = chatContainer.value

                if (container) {
                    container.scrollTop = container.scrollHeight;
                }
            })
        }

        const resizeTextArea = () => {
            const textArea = chatTextArea.value
            textArea.style.height = 'auto'
            textArea.style.height = textArea.scrollHeight + 'px'
        }

        const searchChatUser = () => {
            searchTermValue.value = searchTerm.value
        }

        return {
            toggleSearchAndChatListBar,
            showSearchList,
            messageSelectedID,
            updateMessageSelectedDetails,
            chatMessages,
            getMessageType,
            chatLoader,
            handleEnterKey,
            messageContent,
            chatContainer,
            chatTextArea,
            resizeTextArea,
            chatUserName,
            getInitialsForWord,
            searchTerm,
            searchTermValue,
            searchResults,
            searchChatUser
        }
    }
}
</script>
<style scoped>
.card-box {
    min-height: 60vh;
    height: 65vh;
}

.users-section {
    border-right: 2px solid #EBEDF3;
    padding-right: 0;
    height: 100%;
    overflow: auto;
}

.users-section-header {
    display: flex;
    justify-content: end;
    border-bottom: 2px solid #EBEDF3;
    cursor: pointer;
}

.navigate-back-header {
    border-bottom: 2px solid #EBEDF3;
    cursor: pointer;
}

.muted_text {
    color: #6e7488
}

.card-row {
    height: 100%;
}

.empty-state-container {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.fixed-items {
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 9999;
}

.search-section {
    display: flex;
    justify-content: center;
    border-bottom: 2px solid #EBEDF3;
    padding-top: 20px;
}

.search-section > .input-group {
    position: relative;
}

datalist {
    position: absolute;
    top: 90%; /* Adjust the distance from the input field */
    left: 10px; /* Adjust left position if needed */
    z-index: 1; /* Ensure the datalist is above other elements */
    /* Add additional styling as needed */
}

.table_search {
    border-radius: 50px;
    padding: 25px 25px 25px 25px;
    background-color: #f5f6fa !important;
    border: none !important;
}

.table_search::placeholder {
    color: #333;
    font-weight: 700;
    font-size: 16px;
}

.input-group {
    width: 100%;
    min-width: 290px;
    padding: 0 20px 20px 20px;
}

.input-group-text {
    background-color: #e9ecef;
}

.input-group > .input-group-append > .input-group-text {
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    border: none;
    background-color: #f5f6fa;
}

.input-group > .input-group-prepend > .input-group-text {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border: none;
    background-color: #f5f6fa;
}

#search {
    height: 20px;
    width: 20px;
    color: #333333;
    margin-right: 5px;
}

.chat-item-group {
    display: flex;
    align-items: center;
}

.avatar-md {
    height: 32px;
    width: 32px;
}

.messages-section {
    padding-right: 12px;
    padding-left: 0;
    height: 100%;
}

.chat-messages {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.chat-message-header {
    border-bottom: 2px solid #EBEDF3;
    padding: 17px 30px;
}

.chat-message-content {
    display: flex;
    flex-direction: column;
    overflow: auto;
    position: relative;
    height: 100%;
}

.chat-section {
    padding: 20px 30px;
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100%;
}

.chat-input-section {
    border-top: 2px solid #EBEDF3;
    display: flex;
    padding: 15px 30px;
    align-items: center;
}

.send-chat-message-input {
    border: none;
    flex: 1;
    margin: 0 30px;
    resize: none;
    overflow-y: hidden;
    height: 25px;
    min-height: 25px;
    max-height: 200px;
    outline: none;
}

.ml-20 {
    margin-left: 20px !important;
}

.linear-loader {
    height: 2px; /* Adjust the height as needed */
    background-color: #89a6f3; /* Adjust the color as needed */
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    overflow: hidden;
}

.train {
    width: 900px; /* Adjust the train's width */
    height: 2px; /* Should match the height of the loader */
    background-color: #2f5bcf; /* Adjust the color as needed */
    position: absolute;
    animation: moveRight 1s linear infinite; /* Adjust the animation speed as needed */
    top: 0;
    left: 0;
}

@keyframes moveRight {
    0% {
        left: -50px; /* Start off the screen to the left */
    }
    100% {
        left: 100%; /* Move to the right of the screen */
    }
}
</style>