<template>
    <div class="overlay" v-if="isOpen">
        <div class="overlay-content" @click="handleOverlayClick">
            <div class="mini-page">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        isOpen: Boolean,
    },
    setup(props, { emit }) {
        const handleOverlayClick = (event) => {
            // Check if the clicked element is the overlay itself (not its children)
            if (event.target === event.currentTarget) {
                // Emit a custom event to notify the parent component to close the mini-page
                emit("closeOverlay");
            }
        };

        return {
            handleOverlayClick,
        };
    },
};
</script>

<style scoped>
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
    z-index: 10000; /* Ensure the overlay is above other content */
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>