<template>

    <div class="row" v-if="itemData">
        <div class="col-sm-12 col-md-3">
            <div class="card-box">
                <div class="card-content">
                    <div class="header-content text-center">
                        <img :src="itemData.profile_image" alt="school logo"
                             class="profile-image rounded-circle img-thumbnail"
                             v-if="itemData.profile_image"/>

                        <AvatarForInitials :avatarText="getUserInitialsForName(itemName)" iconColor="primary"
                                           class="avatar-md-150 mb-30 m-auto" v-else/>

                        <h4 class="mb-0">{{ itemName }}</h4>
                        <p class="mb-0">{{ itemData.email }}</p>
                        <p class="mb-0">{{ itemData.msisdn }}</p>
                    </div>

                    <div class="middle-content mb-50">
                        <div class="details-content">
                            <img src="../../../assets/images/icons/calendar_icon.svg" alt="Mobile Icon"/>
                            <div class="details">
                                <p class="muted-text-sm">Added On</p>
                                <p class="result-text">{{ userCreatedAt }}</p>
                            </div>
                        </div>

                        <div class="details-content">
                            <img src="../../../assets/images/icons/folder_icon.svg" class="data-img" alt="Mobile Icon"/>

                            <div class="detail-content-group">
                                <p class="muted-text-sm">Portal Role</p>
                                <p class="result-text">{{ userRole }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="buttons-content">
                        <RegularButtonBlock buttonText="Edit Admin" iconText="mdi-account-edit"
                                            class="custom-button mb-3" @clicked="editAction"/>

                        <RegularButtonBlock buttonText="Reset Admin Password" iconText="mdi-lock"
                                            class="custom-button error mb-3" @clicked="passwordUpdateAction"/>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-sm-12 col-md-9">
            <div class="row">
                <div class="col-12">
                    <div class="page-title-box">
                        <BreadCrumbs/>
                    </div>
                </div>
            </div>

            <div class="card-box-no-pd">
                <div class="page-title-box mb-30">
                    <h4 class="page-title-gray">Account Admin's Profile</h4>
                </div>

                <div class="card-content border-bottom plr-50">
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="detail-group mb-30">
                                <span class="muted-text-sm">Full Name</span>
                                <p>{{ itemName }}</p>
                            </div>

                            <div class="detail-group mb-30">
                                <span class="muted-text-sm">Contact Number</span>
                                <p>{{ itemData.msisdn }}</p>
                            </div>

                            <div class="detail-group mb-30" v-if="itemData.country">
                                <span class="muted-text-sm">Country of Residence</span>
                                <p>{{ itemData.country }}</p>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="detail-group mb-30">
                                <span class="muted-text-sm">Gender</span>
                                <p>{{ itemData._gender }}</p>
                            </div>
                            <div class="detail-group mb-30">
                                <span class="muted-text-sm">Email Address</span>
                                <p>{{ itemData.email }}</p>
                            </div>
                            <div class="detail-group mb-30" v-if="itemData.state">
                                <span class="muted-text-sm">State of Residence</span>
                                <p>{{ itemData.state }}</p>
                            </div>
                        </div>

                        <div class="col-sm-12">
                            <div class="detail-group mb-30">
                                <span class="muted-text-sm">Residential Address</span>
                                <p>{{ itemData.residence }}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="ptb-30 plr-50">
                    <p class="dark-p mb-10">Turn on/off to activate or deactivate this account</p>
                    <Switch :switchText="switchTextLabel" :checked="checkedStatus" :toggleStatus="handleSwitchToggle"
                            :loading="loading"/>
                </div>

                <FormModal :isFormModalOpen="isEditFormModalOpen" @closeFormModal="closeEditFormModal"
                           modalHeader="Edit Admin">
                    <EditSchoolUser @closeFormModal="closeEditFormModal"
                                         :itemData="itemDataForEditing" @refreshPage="fetchItemData"/>
                </FormModal>

                <FormModal :isFormModalOpen="isPasswordFormModalOpen" @closeFormModal="closePasswordFormModal"
                           modalHeader="Update Admin Password">
                    <ResetUserPassword @closeFormModal="closePasswordFormModal"
                                       :userData="adminEmailAddress"/>
                </FormModal>
            </div>
        </div>
    </div>
</template>

<script>
import Switch from "@/components/common/Switch.vue";
import RegularButtonBlock from "@/components/common/RegularButtonBlock.vue";
import BreadCrumbs from "@/components/common/BreadCrumbs.vue";
import {onMounted, ref, watch} from "vue";
import {
    capitalizeFirstLetter,
    formatDateTime,
    getSchoolId,
    getUserInitialsForName,
    getValidToken
} from '@/utils/helpers'
import {API_BASE_URL} from "@/config.js";
import MessageSliderCopy from "@/components/common/MessageSliderCopy.vue";
import FormModal from "@/components/common/FormModal.vue";
import {useRoute} from "vue-router";
import UserAvatarBox2 from "@/components/common/UserAvatarBox2.vue";
import {checkAndRefreshToken} from "@/utils/auth";
import EditSchoolUser from "@/views/School_Admin/Users/EditSchoolUser.vue";
import ResetUserPassword from "@/views/God_Admin/Users/ResetUserPassword.vue";
import AvatarForInitials from "@/components/common/AvatarForInitials.vue";


export default {
    components: {
        AvatarForInitials,
        ResetUserPassword,
        EditSchoolUser,
        UserAvatarBox2,
        FormModal,
        Switch,
        RegularButtonBlock,
        BreadCrumbs,
        MessageSliderCopy
    },
    props: {
        id: String
    },
    setup(props, {emit}) {
        const isEditFormModalOpen = ref(false);
        const itemData = ref(null)
        const itemName = ref('')
        const userCreatedAt = ref('')
        const userRole = ref('')
        const loading = ref(null)
        const checkedStatus = ref(false)
        const switchTextLabel = ref('')
        const messageText = ref("");
        const messageType = ref("");
        const displayMessage = ref(false);
        const router = useRoute()
        const itemId = router.params.id
        const accessToken = getValidToken()
        const schoolId = getSchoolId()
        const isPasswordFormModalOpen = ref(false);

        const fetchItemData = async () => {

            const apiUrl = `${API_BASE_URL}/school/${schoolId}/get-admin/${itemId}`;

            try {
                emit('show-loader')
                const response = await fetch(apiUrl, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    }
                })

                if (response.ok) {
                    const result = await response.json()
                    itemData.value = result.data

                    // console.log('result', result.data)
                } else {
                    console.log("response", response);
                    console.error("Error fetching data from the API");
                }
            } catch (error) {
                console.error('An error occured: ', error)
            }
        }


        onMounted(() => {
            fetchItemData()
        })

        watch(itemData, (item) => {
            itemName.value = capitalizeFirstLetter(item.name)
            userRole.value = capitalizeFirstLetter(item.role_name)
            const {date, time} = formatDateTime(item.created_at)
            userCreatedAt.value = `${date}, ${time}`
            checkedStatus.value = !item.isDeactivated
            switchTextLabel.value = item.isDeactivated ? 'Account Deactivated' : 'Account Active'

            if (item) {
                emit('data-loaded')
            }
        })

        const selectTab = (index) => {
            activeTab.value = index;
        };

        const openEditFormModal = () => {
            isEditFormModalOpen.value = true;
        };

        const closeEditFormModal = async (msg) => {
            isEditFormModalOpen.value = false;

            if (msg) {
                flashAlertMessage(msg.type, msg.apiResponse)
            }

            await fetchItemData()
        };

        const openPasswordFormModal = () => {
            isPasswordFormModalOpen.value = true;
        };

        const closePasswordFormModal = async (msg) => {
            isPasswordFormModalOpen.value = false;

            if (msg) {
                flashAlertMessage(msg.type, msg.apiResponse)
            }
        };


        const itemDataForEditing = ref(null)
        const editAction = () => {
            itemDataForEditing.value = {
                name: itemData.value.name,
                gender: itemData.value._gender,
                msisdn: itemData.value.msisdn,
                address: itemData.value.residence,
                role: itemData.value.school_role_id,
                id: props.id
            }

            openEditFormModal()
        }

        const adminEmailAddress = ref(null)
        const passwordUpdateAction = () => {
            adminEmailAddress.value = {
                email: itemData.value.email
            }

            openPasswordFormModal()
        }

        const handleSwitchToggle = () => {
            const userId = props.id
            toggleUserStatus(userId)
        }

        const toggleUserStatus = async (id) => {
            const apiUrl = `${API_BASE_URL}/school/${schoolId}/toggle-school-admin-status`

            try {
                await checkAndRefreshToken()

                loading.value = true
                const response = await fetch(apiUrl, {
                    method: 'PUT',
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`
                    },
                    body: JSON.stringify({
                        admin_id: id,
                        reason: 'Not needed anymore'
                    })
                })

                if (response.ok) {
                    const result = await response.json()

                    if (result.code === 200) {
                        flashAlertMessage("success", "Admin status updated")
                        await fetchItemData()
                    } else {
                        flashAlertMessage("error", result.message)
                        console.log('error', result.message)
                    }
                } else {
                    const result = await response.json();
                    flashAlertMessage("error", result.message)
                    console.log("error", result.message);
                }
            } catch (error) {
                flashAlertMessage("error", error)
                console.error("An error occured:", error);
            } finally {
                loading.value = false
            }
        }

        const flashAlertMessage = (alertType, alertMessage) => {
            emit('flash-message', {
                alertType: alertType,
                alertMessage: alertMessage
            })
        }


        return {
            loading,
            itemData,
            itemName,
            userCreatedAt,
            checkedStatus,
            switchTextLabel,
            handleSwitchToggle,
            messageText,
            messageType,
            displayMessage,
            editAction,
            closeEditFormModal,
            isEditFormModalOpen,
            itemDataForEditing,
            selectTab,
            fetchItemData,
            userRole,
            isPasswordFormModalOpen,
            openPasswordFormModal,
            passwordUpdateAction,
            adminEmailAddress,
            closePasswordFormModal,
            getUserInitialsForName
        }
    }
};
</script>

<style scoped>

.detail-content-group > p {
    margin: 0;
}

.border-bottom {
    border-bottom: 2px solid #F5F6FA;
}

p {
    color: #9a9a9a;
    font-size: 16px;
}

.details-content {
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding: 30px 30px 0 30px;
}

.detail-content-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.details-content:last-child {
    padding-bottom: 30px;
}

.details-content:first-child {
    border-bottom: 1px solid #e9eaf3;
    padding-bottom: 30px;
}

.details-content > img {
    margin-right: 15px;
    margin-top: 5px;
}

.details-content > img {
    margin-right: 15px;
    margin-top: 5px;
}

.details-content .details > p {
    margin-bottom: 0;
}

.logo-text > img {
    margin-right: 15px;
    margin-top: 2px;
}

.logo-text > p {
    color: #333333;
}

.detail-group > p,
.result-text,
.data-title {
    color: #333;
}

.item > .count {
    color: #2f5bcf;
}

.buttons-content {
    padding: 0 15px 40px 15px;
}

.page-title-gray {
    background-color: #F8FAFC;
    font-size: 1.25rem;
    margin: 0;
    line-height: 75px;
    color: #323a46;
    padding: 20px 50px 0 50px;
}

.tab-container {
    display: flex;
    background-color: #F8FAFC;
    padding-left: 50px;
}

.tab {
    list-style-type: none;
    margin: 0 20px;
    text-align: center;
    cursor: pointer;
    padding: 10px;
}

.tab:first-child {
    margin: 0;
    padding-left: 0;
}

.active-tab {
    border-bottom: 2px solid #0d44b2;
}
</style>