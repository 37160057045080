<template>
    <div class="row">
        <div class="col-lg-3 col-md-3 col-sm-12">
            <div class="card-box-small">
                <div class="card-content">
                    <div class="header-content text-center">
                        <div v-if="schoolDetails">
                            <img
                                class="mb-20 d-block mx-auto rounded-circle img-thumbnail"
                                :src="schoolDetails.logo"
                                alt="school logo"
                                v-if="schoolDetails.logo"
                                @error="handleSchoolImageError"
                            />

                            <AvatarForInitials :avatarText="getUserInitialsForName(schoolName)" iconColor="info" class="avatar-md-150 mb-30 m-auto" v-else/>
                        </div>

                        <h4 class="mb-0">{{ schoolName }}</h4>
                        <p class="mb-0">{{ schoolEmail }}</p>
                    </div>

                    <div class="middle-content mb-100">
                        <div class="details-content">
                            <img src="../../../assets/images/icons/user_icon.svg" class="data-img" alt="Mobile Icon"/>

                            <div class="detail-content-group">
                                <p class="data-title">Total Students</p>
                                <p class="data-text">{{numOfStudents}}</p>
                            </div>
                        </div>

                        <div class="details-content">
                            <img src="../../../assets/images/icons/activate_icon.svg" class="data-img"
                                 alt="Mobile Icon"/>

                            <div class="detail-content-group">
                                <p class="data-title">Active</p>
                                <p class="data-text">{{activeStudents}}</p>
                            </div>
                        </div>

                        <div class="details-content">
                            <img src="../../../assets/images/icons/deactivate_icon2.svg" class="data-img"
                                 alt="Mobile Icon"/>

                            <div class="detail-content-group">
                                <p class="data-title">Deactivated</p>
                                <p class="data-text">{{ inActiveStudents}}</p>
                            </div>
                        </div>
                    </div>

                    <div class="buttons-content">
                        <RegularButtonBlock buttonText="Add a Student" iconText="mdi-account-plus"
                                            v-if="hasPermission('add_students')"
                                            class="custom-button mb-3" @clicked="openFormModal"/>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-9 col-md-3 col-sm-12">
            <div class="card-box">
                <div class="row">
                    <div class="col-12">
                        <h4 class="bold mb-30">Students</h4>

                        <TableView :data="formattedStudentsWithoutSchoolData" :headers="tableHeaders"
                                   :id="getIdForActions"
                                   :detailsData="getDataForDetailsView" :loading="loading"
                                   :searchStringFromParent.sync="searchStringFromChild"
                                   :viewAction="viewAction"
                                   :editAction="editAction" :showSearchField="true"
                                   :searchFields="searchFieldsData" :totalPages="totalPages"
                                   :currentPage.sync="currentPage"
                                   :changePage="changePage" :showActionsColumn="true"
                                   :changeItemsPerPage="changeItemsPerPage" :itemsPerPage="itemsPerPage"
                                   viewPermission="view_students"
                                   editPermission="modify_students" deactivatePermission="deactivate_students"/>
                    </div>
                </div>
            </div>
        </div>


    </div>


    <FormModal :isFormModalOpen="isFormModalOpen" @closeFormModal="closeFormModal1" modalHeader="Add Student">
        <CreateSchoolStudent @closeFormModal="closeFormModal"/>
    </FormModal>

    <FormModal :isFormModalOpen="isEditFormModalOpen" @closeFormModal="closeEditFormModal" modalHeader="Edit Student">
        <EditSchoolStudent @closeFormModal="closeEditFormModal" :itemData="itemDataForEditing"/>
    </FormModal>

    <InfoModal :isModalOpen="isConfirmationDialogOpen" @closeModal="closeConfirmationDialog"
               :successImageURL="require('../../../assets/images/icons/success_icon.svg')"
               :errorImageURL="require('../../../assets/images/icons/error_icon.svg')" header="Hold on!"
               :paragraph="deactivateConfirmationParagraph"
               :buttonTxt="deactivateButtonText" :buttonAction="performAction" buttonColor="error"
               routerLinkText="No, Cancel Action" buttonLink="/"
               :linkAction="cancelAction" :loading="buttonLoader">
    </InfoModal>

    <InfoModal :isModalOpen="isFlashModalOpen" @closeModal="closeFlashModal" :isSuccess="true" header="Student Added"
               :paragraph="`You have successfully added '${fullName}'. You can view and manage this student's profile under 'Students'.`"
               buttonTxt="Add Another Student" routerLinkText="Manage Students" :linkAction="createItemFlashLinkAction"
               :buttonAction="buttonAction">
    </InfoModal>

</template>

<script>
import RegularButtonBlock from "@/components/common/RegularButtonBlock.vue";
import {computed, onActivated, onMounted, ref, watch} from "vue";
import {capitalizeFirstLetter, getUserInitialsForName, getValidToken, hasPermission} from '@/utils/helpers'
import {checkAndRefreshToken} from '@/utils/auth';
import {API_BASE_URL} from "@/config.js";
import FormModal from "@/components/common/FormModal.vue";
import {useRouter} from "vue-router";
import TableView from "@/components/common/TableView.vue";
import RoundedButton from "@/components/common/RoundedButton.vue";
import InfoModal from "@/components/common/InfoModals.vue";
import EditSchoolParent from "@/views/School_Admin/Parents/EditSchoolParent.vue";
import CreateSchoolStudent from "@/views/School_Admin/Students/CreateSchoolStudent.vue";
import EditSchoolStudent from "@/views/School_Admin/Students/EditSchoolStudent.vue";
import getSchoolStudents from "@/composables/Schools/getSchoolStudents";
import AvatarForInitials from "@/components/common/AvatarForInitials.vue";
import {useUserStore} from "@/stores/user";


export default {
    methods: {getUserInitialsForName},
    components: {
        AvatarForInitials,
        EditSchoolStudent,
        CreateSchoolStudent,
        EditSchoolParent,
        RoundedButton,
        TableView,
        FormModal,
        InfoModal,
        RegularButtonBlock
    },
    props: {
        id: String
    },
    emits: ['showLoader', 'flashMessage', 'data-loaded'],
    setup(props, {emit}) {
        document.title = "Manage Students"
        const {studentsPayloadData, fetchStudentsData, loading, formattedStudentsWithoutSchoolData, studentsData} = getSchoolStudents()
        const isFormModalOpen = ref(false);
        const isEditFormModalOpen = ref(false);
        const displayMessage = ref(false);
        const buttonLoader = ref(false);
        const messageText = ref("");
        const messageType = ref("");
        const newMessage = ref("");
        const showDetailsOrIcons = ref(false)
        const router = useRouter()
        const accessToken = getValidToken();
        const isConfirmationDialogOpen = ref(false);
        const isFlashModalOpen = ref(false);
        const fullName = ref('')
        const currentPage = ref(1);
        const itemsPerPage = ref(10); // Default items per page
        const totalPages = ref(0)
        const itemData = ref(null)
        const numOfStudents = ref(0)
        const activeStudents = ref(0)
        const inActiveStudents = ref(0)
        const schoolName = ref('')
        const schoolEmail = ref('')

        const buttonAction = () => {
            isFlashModalOpen.value = false;
            openFormModal()
        }

        const openFlashModal = () => {
            isFlashModalOpen.value = true;
        };

        const closeFlashModal = async () => {
            isFlashModalOpen.value = false;
            await fetchStudentsData(currentPage.value, itemsPerPage.value)
        };

        const createItemFlashLinkAction = () => {
            closeFlashModal()
        }

        const openFormModal = () => {
            isFormModalOpen.value = true;
        };

        const closeFormModal = (name) => {
            isFormModalOpen.value = false;

            fullName.value = name
            isFlashModalOpen.value = true
        };

        const closeFormModal1 = () => {
            isFormModalOpen.value = false;

            fetchStudentsData(currentPage.value, itemsPerPage.value)
        }

        const openEditFormModal = () => {
            isEditFormModalOpen.value = true;
        };

        const closeEditFormModal = async (msg) => {
            isEditFormModalOpen.value = false;

            if (msg) {
                flashAlertMessage(msg.type, msg.apiResponse)
            }
            await fetchStudentsData(currentPage.value, itemsPerPage.value)
        };

        const searchStringFromChild = ref("");
        const searchFieldsData = ['user.name', 'user.email', 'contact', 'status', 'gender']

        watch(studentsData, () => {
            totalPages.value = studentsPayloadData.value.total_pages

            const stats = studentsPayloadData.value.results
            numOfStudents.value = stats.num_of_students
            activeStudents.value = stats.num_of_active_students
            inActiveStudents.value = stats.num_of_deactivated_students
        })

        const schoolDetails = ref(null)
        onMounted(() => {
            fetchStudentsData(currentPage.value, itemsPerPage.value);
            emit('data-loaded')

            schoolDetails.value = JSON.parse(localStorage.getItem('schoolDetails'))
            schoolName.value = schoolDetails.value.name
            schoolEmail.value = schoolDetails.value.email
        })

        onActivated(() => {
            fetchStudentsData(currentPage.value, itemsPerPage.value);
        })

        const getIdForActions = computed(() => {
            return studentsData.value.map((item) => item.user_id);
        });

        const getDataForDetailsView = computed(() => {
            return studentsData.value
        })

        const viewAction = (item, id) => {
            router.push(
                {
                    name: 'ShowSchoolStudent',
                    params: {id: id}
                })
        }

        const itemDataForEditing = ref(null)
        const editAction = (item, item_id) => {
            itemData.value = item

            // Find the item to edit
            const itemForEditing = studentsData.value.find((r) => r.user_id === item_id);
            itemDataForEditing.value = {
                firstName: itemForEditing.first_name,
                lastName: itemForEditing.last_name,
                age: itemForEditing.age,
                dob: itemForEditing.dob,
                project: itemForEditing.project,
                gender: itemForEditing._gender,
                msisdn: itemForEditing.msisdn,
                email: itemForEditing.email,
                country: itemForEditing.country,
                state: itemForEditing.state,
                address: itemForEditing.address,
                parent: itemForEditing.parent,
                id: item_id
            }

            openEditFormModal()
        }

        const deactivateAction = async (id) => {
            // console.log('item id', id)
            const apiUrl = `${API_BASE_URL}/student/delete-student`
            try {
                await checkAndRefreshToken()

                buttonLoader.value = true
                const response = await fetch(apiUrl, {
                    method: 'DELETE',
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`
                    },
                    body: JSON.stringify({
                        student_id: id,
                        reason: ''
                    })
                })

                if (response.ok) {
                    const result = await response.json()

                    if (result.code === 200) {
                        closeConfirmationDialog()

                        flashAlertMessage('success', "Student status updated")
                    } else {
                        flashAlertMessage('error', result.message)
                        console.log('error', result.message)
                    }
                } else {
                    const result = await response.json();

                    flashAlertMessage('error', result.message)
                    console.log("error", result.message);
                }
            } catch (error) {
                flashAlertMessage('error', result.message)
                console.error("An error occured:", error);
            } finally {
                buttonLoader.value = false
            }
        }

        const messageFromChild = ref("");
        const handleMessageFromChild = (message) => {
            messageFromChild.value = message
        };

        const tableHeaders = computed(() => {
            return [
                {label: "Name", key: "name"},
                {label: "Age", key: "age"},
                {label: "Gender", key: "gender"},
                {label: "Added On", key: "date"},
                {label: "Acct Status", key: "status"}
            ];
        });

        const itemId = ref('null')
        const itemStatus = ref('')
        const deactivateConfirmationParagraph = ref('')
        const deactivateButtonText = ref('')

        const openConfirmationDialog = (item, id) => {
            itemId.value = id

            itemStatus.value = item.isDeactivated === false ? 'deactivate' : 'activate'
            deactivateConfirmationParagraph.value = `You are about to ${itemStatus.value} this students's account. Are you sure you want to continue this action?.`
            deactivateButtonText.value = `Yes, ${capitalizeFirstLetter(itemStatus.value)} Student`
            isConfirmationDialogOpen.value = true;
        };

        const closeConfirmationDialog = () => {
            isConfirmationDialogOpen.value = false;
            fetchStudentsData(currentPage.value, itemsPerPage.value)
        };

        const performAction = () => {
            deactivateAction(itemId.value)
        }

        const cancelAction = () => {
            closeConfirmationDialog()
        }

        const changePage = (page) => {
            if (page >= 1 && page <= totalPages.value) {
                currentPage.value = page;

                fetchStudentsData(currentPage.value, itemsPerPage.value)
            }
        };

        const changeItemsPerPage = (perPage) => {
            itemsPerPage.value = perPage;
            currentPage.value = 1; // Reset to the first page when changing items per page

            fetchStudentsData(currentPage.value, perPage);
        };

        const flashAlertMessage = (alertType, alertMessage) => {
            emit('flash-message', {
                alertType: alertType,
                alertMessage: alertMessage
            })
        }

        const userStore = useUserStore();
        const user = ref(userStore.user)
        const userPermissions = ref(user.value.permissions)
        const checkPermission = (permission) => hasPermission(userPermissions, permission)

        const handleSchoolImageError = () => {
            schoolDetails.value.logo = null
        }

        return {
            handleSchoolImageError,
            schoolDetails,
            tableHeaders,
            studentsData,
            totalPages,
            formattedStudentsWithoutSchoolData,
            getIdForActions,
            loading,
            searchStringFromChild,
            searchFieldsData,
            isFormModalOpen,
            openFormModal,
            closeFormModal,
            closeFormModal1,
            handleMessageFromChild,
            displayMessage,
            itemDataForEditing,
            messageText,
            messageType,
            newMessage,
            showDetailsOrIcons,
            viewAction,
            editAction,
            deactivateAction,
            InfoModal,
            isConfirmationDialogOpen,
            openConfirmationDialog,
            closeConfirmationDialog,
            performAction,
            cancelAction,
            buttonLoader,
            isEditFormModalOpen,
            openEditFormModal,
            closeEditFormModal,
            itemData,
            isFlashModalOpen,
            openFlashModal,
            closeFlashModal,
            buttonAction,
            fullName,
            createItemFlashLinkAction,
            deactivateConfirmationParagraph,
            deactivateButtonText,
            getDataForDetailsView,
            changePage,
            changeItemsPerPage,
            currentPage,
            itemsPerPage,
            numOfStudents,
            activeStudents,
            inActiveStudents,
            schoolName,
            schoolEmail,
            hasPermission: checkPermission
        };

    }

}
</script>

<style scoped>

.data-text {
    color: #0D44B2;
}

.detail-content-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.detail-content-group > p {
    margin: 0;
}

.data-img {
    margin-top: 2px !important;
}

.header-content {
    padding: 30px 0 20px 0;
    position: relative;
    border-bottom: 1px solid #e9eaf3;
}

p {
    color: #9a9a9a;
    font-size: 16px;
}

.details-content {
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding: 30px 30px 0 30px;
}

.details-content:last-child {
    padding-bottom: 30px;
    border-bottom: 1px solid #e9eaf3;
}

.details-content > img {
    margin-right: 15px;
    margin-top: 5px;
}

.details-content .details > p {
    margin-bottom: 0;
}

.logo-text > img {
    margin-right: 15px;
    margin-top: 2px;
}

.logo-text > p {
    color: #333333;
}

.detail-group > p,
.data-title {
    color: #333;
}


.buttons-content {
    padding: 0 15px 40px 15px;
}

.card-box-small {
    padding: 0 15px 0 15px;
    background-color: #fff;
    box-shadow: 0 0.75rem 6rem rgba(56, 65, 74, .03);
    margin-bottom: 24px;
    border-radius: 12px !important;
}

.rounded-circle {
    height: 150px !important;
    width: 150px !important;
    object-fit: contain;
}
</style>