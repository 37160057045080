import {API_BASE_URL} from "@/config.js";
import {capitalizeFirstLetter, formatDateTime, getUserInitialsForName, getValidToken} from "@/utils/helpers.js";
import {computed, ref} from "vue";
import {checkAndRefreshToken} from '@/utils/auth';

const loading = ref(false);
const apiUrl = `${API_BASE_URL}/school/list`;
const schoolsPayloadData = ref(null);
const schoolsData = ref([])

const fetchSchoolsData = async (page, perPage) => {
    try {
        await checkAndRefreshToken()

        const accessToken = getValidToken()
        if (!accessToken) {
            console.error("Access token not available.");
            return;
        }

        loading.value = true;
        const apiUrlWithParams = `${apiUrl}?page=${page}&per_page=${perPage}`

        const response = await fetch(apiUrlWithParams, {
            headers: {
                Authorization: `Bearer ${accessToken}`, // Include the access token in the headers
            },
        });

        if (response.ok) {
            const result = await response.json();

            if (result.code === 200) {
                schoolsPayloadData.value = result.data
                schoolsData.value = result.data.results.schools

            } else {
                console.log("error", result.message);
            }
        } else {
            console.log("response", response);
            console.error("Error fetching data from the API");
        }
    } catch (error) {
        console.error("An error occured:", error);
    } finally {
        loading.value = false;
    }
};


const formattedSchoolsData = computed(() => {
    // Map and format the data
    return schoolsData.value.map((school) => {

        // Format the created at
        const {date, time} = formatDateTime(school.created_at);

        const formattedSchool = {
            itemName: {
                name: capitalizeFirstLetter(school.name),
                initials: getUserInitialsForName(school.name),
                avatarColor: 'success'
            },
            license_number: school.reg_number,
            createdAt: date,
            current_plan: '-',
            next_billing: '-',
            status: school.isDeactivated ? 'Deactivated' : 'Active',
        };

        return {
            ...formattedSchool,
        };
    });
});

const formattedSchoolReportData = computed(() => {
    // Map and format the data
    return schoolsData.value.map((school) => {

        const formattedSchoolReport = {
            name: capitalizeFirstLetter(school.name),
            initials: getUserInitialsForName(school.name),
            state: capitalizeFirstLetter(school.state),
            country: capitalizeFirstLetter(school.country),
        };

        return {
            ...formattedSchoolReport,
        };
    });
});

const getSchools = () => {
    return {schoolsPayloadData, fetchSchoolsData, loading, formattedSchoolsData, schoolsData, formattedSchoolReportData}
}

export default getSchools