<template>
    <!-- Topbar Start -->
    <div class="navbar-custom">
        <div class="container-fluid">
            <ul class="list-unstyled topnav-menu float-right mb-0">
                <li class="dropdown notification-list topbar-dropdown">
                    <a class="nav-link dropdown-toggle nav-user mr-0 waves-effect waves-light" data-toggle="dropdown"
                       href="#" role="button"
                       aria-haspopup="false" aria-expanded="false">

                        <div class="avatar-md m-0">
                            <span
                                :class="`avatar-title bg-soft-success text-success font-20 rounded-circle`">
                                {{ userInitials }}
                            </span>
                        </div>

                        <span class="pro-user-name ml-1">
                            <i class="mdi mdi-chevron-down"></i>
                        </span>
                    </a>

                    <div class="dropdown-menu dropdown-menu-right profile-dropdown">
                        <!-- item-->
                        <div class="dropdown-header noti-title">
                            <h6 class="text-overflow m-0">Welcome {{ userName }} !</h6>
                        </div>

                        <!-- item-->
                        <router-link :to="{ name: 'Profile' }" class="dropdown-item notify-item">
                            <i class="fe-user"></i>
                            <span> My Account </span>
                        </router-link>


                        <div class="dropdown-divider"></div>

                        <!-- item-->
                        <a href="javascript:void(0);" class="dropdown-item notify-item" @click="handleLogout">
                            <i class="fe-log-out"></i>
                            <span>Logout</span>
                        </a>
                    </div>
                </li>
            </ul>

            <ul class="list-unstyled topnav-menu topnav-menu-left m-0">
                <li>
                    <button class="button-menu-mobile waves-effect waves-light" @click="handleButtonClick">
                        <i class="fe-menu"></i>
                    </button>
                </li>
            </ul>

            <div class="logo-box">
                <a href="/" class="logo logo-dark">
                    <span class="logo-sm">
                        <img src="../../../assets/key-logo.png" alt="" height="22" class="logoLg"/>
                    </span>
                    <span class="logo-lg">
                        <img src="../../../assets/key-logo.png" class="logoLg" alt="" height="40"/>
                    </span>
                </a>
            </div>
        </div>

        <div class="linear-loader" v-if="loading">
            <div class="train"></div>
        </div>
    </div>
    <!-- end Topbar -->
</template>

<script>
import {ref, watchEffect} from 'vue';
import {useRouter} from 'vue-router';
import {capitalizeFirstLetter, getCurrentUser, getUserInitials} from "@/utils/helpers";
import {useUserStore} from "@/stores/user";

export default {
    props: {
        loading: Boolean,
        displaySearch: Boolean
    },
    setup(props, {emit}) {
        const router = useRouter()
        const loading = ref(true)
        const currentLoggedInUser = getCurrentUser()
        const userInitials = getUserInitials(currentLoggedInUser.first_name, currentLoggedInUser.last_name)
        const userName = capitalizeFirstLetter(currentLoggedInUser.first_name, currentLoggedInUser.last_name)

        const handleLogout = () => {
            // Reset the user store
            const userStore = useUserStore();
            userStore.$reset()

            // Clear the local storage
            localStorage.clear()

            // Clear cookies
            clearCookies()

            // Redirect to login page
            router.push({name: 'Login'})
        }

        watchEffect(() => {
            loading.value = props.loading;
        });

        const handleButtonClick = () => {
            emit('toggle-menu')
        }

        function clearCookies() {
            let cookies = document.cookie.split(";");

            for (let i = 0; i < cookies.length; i++) {
                let cookie = cookies[i];
                let eqPos = cookie.indexOf("=");
                let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
            }

            console.log("Cookies cleared successfully!");
        }

        return {handleLogout, loading, currentLoggedInUser, userInitials, userName, handleButtonClick}
    }
};
</script>

<style scoped>
.navbar-custom {
    border-bottom: 1px solid #EBEDF3;
    padding: 0;
}

.logoLg {
    margin-left: 20px;
}

.top-search {
    border: 1px solid #f8fafc !important
}

.nav-user {
    display: flex !important;
    align-items: center;
}

.back-icon > img {
    cursor: pointer;
}

.linear-loader {
    height: 8px; /* Adjust the height as needed */
    background-color: #89a6f3; /* Adjust the color as needed */
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    /* animation: moveRight 1s linear infinite; Adjust the animation speed as needed */
    //z-index: 9999; /* Ensure it's on top of other elements */
}

.train {
    width: 900px; /* Adjust the train's width */
    height: 8px; /* Should match the height of the loader */
    background-color: #2f5bcf; /* Adjust the color as needed */
    position: absolute;
    animation: moveRight 1s linear infinite; /* Adjust the animation speed as needed */
    top: 0;
    left: 0;
}

@keyframes moveRight {
    0% {
        left: -50px; /* Start off the screen to the left */
    }
    100% {
        left: 100%; /* Move to the right of the screen */
    }
}

.button-menu-mobile {
    display: none; /* Hide the button by default */
}

/* Media query to show the button on screens with a maximum width of 767px */
@media (max-width: 767px) {
    .button-menu-mobile {
        display: block; /* Show the button on smaller screens */
    }

    .topbar-dropdown {
        padding-top: 12px;
    }
}

@media (max-width:992px) {
    .button-menu-mobile {
        display: block; /* Show the button on smaller screens */
    }
}

.topnav-menu {
    margin: 0 15px !important;
}

</style>