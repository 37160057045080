<template>
    <div class="modal" v-if="isModalOpen">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <div>
                        <h4 class="modal-title semibold" id="multiple-oneModalLabel">
                            {{ modalHeader }}
                        </h4>
                        <p class="mb-0 medium text-muted" v-if="modalSubHeader">
                            {{ modalSubHeader }}
                        </p>
                    </div>

                    <img
                        src="../../assets/images/icons/close_icon.svg"
                        alt="close icon"
                        height="35"
                        width="35"
                        class="close-icon"
                        @click="closeModal"
                    />
                </div>
                <div class="modal-body">
                    <slot></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        isModalOpen: Boolean,
        modalHeader: String,
        modalSubHeader: String,
    },
    setup(props, {emit}) {

        const closeModal = () => {
            emit("closeModal");
        };

        return {closeModal};
    },
};
</script>

<style scoped>
.close-icon {
    cursor: pointer;
}

.modal {
    /* Add styles for modal overlay and positioning */
    /* Example styles: */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow-y: auto; /* Add vertical scrolling */
}

.modal-dialog {
    /* Add styles for modal dialog box */
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    max-width: 500px;
    width: 80%;
    margin: auto;
    max-height: 90vh; /* Set a maximum height (adjust as needed) */
    overflow-y: auto !important; /* Add vertical scrolling within the modal */
}

.modal-content {
    border-radius: 15px;
    padding: 0;
    display: inherit;
    overflow-y: auto !important;
    max-height: 90vh;
}

.modal-content > p {
    color: #333;
    margin-bottom: 40px;
    font-size: 14px;
}

.modal-content > p > span {
    font-weight: bold;
}

.modal-content > h4 {
    margin-bottom: 20px !important;
}

.modal-content > img {
    margin: 0 auto 30px;
}

.modal-content > .link {
    color: #9a9a9a;
    font-size: 16px;
    text-decoration: underline;
    margin-top: 20px;
}

.modal-title {
    color: #333333;
    font-size: 21px;
}

.modal-body {
}

.modal-footer {
    padding: 30px 50px;
    justify-content: flex-start;
}

@media (max-width: 767px) {
    .modal-dialog {
        width: 90%;
    }

    .modal-body {
        padding: 30px 20px 50px;
    }

    .modal-header {
        padding: 20px;
    }
}

@media (max-width: 992px) {
    .modal-dialog {
        width: 90%;
    }

    .modal-body {
        padding: 30px 20px 50px;
    }

    .modal-header {
        padding: 20px;
    }
}
</style>
