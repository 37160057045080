<template>
    <div class="container">
        <div class="linear-loader" v-if="loading">
            <div class="train"></div>
        </div>

        <div class="chats-section">
            <div class="chat-item"
                 v-for="coLearner in originalUserList" :key="coLearner"
                 @click="handleChatItemClick(coLearner)"
            >
                <div class="chat-item-group">
                    <div class="avatar-md mr-10">
                        <img :src="coLearner.profile_image" alt="User Image" class="rounded-circle"
                             v-if="coLearner.profile_image"
                        >

                        <span
                            :class="`avatar-title bg-soft-success text-success font-20 rounded-circle`" v-else>
                            {{ getUserInitials(coLearner.first_name, coLearner.last_name) }}
                        </span>
                    </div>
                    <div>
                        <p class="m-0 semibold f-16">
                            {{ capitalizeFirstLetter(coLearner.first_name, coLearner.last_name) }}
                        </p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
import {onMounted, ref, watch} from "vue";
import getSchoolColearners from "@/composables/Schools/getSchoolColearners";
import {capitalizeFirstLetter, getUserInitials} from "@/utils/helpers";

export default {
    props: {
        searchTerm: String
    },
    components: {},
    setup(props, {emit}) {
        const originalUserList = ref([])

        const {
            fetchColearnersData,
            loading,
            colearnersUserData
        } = getSchoolColearners()

        onMounted(() => {
            fetchColearnersData(1, 500)
        })

        const filterResults = (searchTerm) => {
            if (searchTerm) {
                originalUserList.value = colearnersUserData.value.filter(user => {
                        const fullName = `${user.first_name} ${user.last_name}`.toLowerCase()
                        return fullName.includes(searchTerm.toLowerCase())
                    }
                );
            } else {
                originalUserList.value = colearnersUserData.value
            }
        };

        watch(colearnersUserData, () => {
            originalUserList.value = colearnersUserData.value.slice()
        })


        watch(() => props.searchTerm, (searchWord) => {
            filterResults(searchWord)
        })

        const handleChatItemClick = (coLearner) => {
            const chatDetails = {
                messageSelectedID: coLearner.user_id,
                userName: capitalizeFirstLetter(coLearner.first_name, coLearner.last_name),
                isNewUser: true
            }

            emit('chatDetails', chatDetails)
        }


        return {
            loading,
            originalUserList,
            getUserInitials,
            capitalizeFirstLetter,
            handleChatItemClick
        }
    }
}
</script>
<style scoped>
.container {
    padding: 0 !important;
    position: relative;
}

.chats-section {
    overflow: auto;
}

.chat-item {
    padding: 20px;
    border-bottom: 1px solid #EBEDF3;
}

.chat-item:hover {
    background-color: #f5f6fa;
}

.chat-item-group {
    display: flex;
    align-items: center;
}

.linear-loader {
    height: 2px; /* Adjust the height as needed */
    background-color: #89a6f3; /* Adjust the color as needed */
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    overflow: hidden;
}

.train {
    width: 900px; /* Adjust the train's width */
    height: 2px; /* Should match the height of the loader */
    background-color: #2f5bcf; /* Adjust the color as needed */
    position: absolute;
    animation: moveRight 1s linear infinite; /* Adjust the animation speed as needed */
    top: 0;
    left: 0;
}

@keyframes moveRight {
    0% {
        left: -50px; /* Start off the screen to the left */
    }
    100% {
        left: 100%; /* Move to the right of the screen */
    }
}

.rounded-circle {
    height: 3.5rem !important;
    width: 3.5rem !important;
}
</style>