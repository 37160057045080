<template>
    <div class="form-group">
        <label class="label">{{ labelText }}</label>
        <vue-tel-input :class="fieldClass" :value="localValue" @input="updateValue($event.target.value)" required />
    </div>
</template>
<script>
import { ref } from 'vue';

export default {
    props: {
        config: {
            type: Object,
            required: true,
        },
        errorType: String,
        labelText: String,
        modelValue: String
    },
    setup(props, { emit }) {
        const localValue = ref(props.modelValue);

        const updateValue = (newValue) => {
            
            localValue.value = newValue;
            emit("update:modelValue", newValue); // Emit the updated value
        };

        return { localValue, updateValue }
    },
    computed: {
        fieldClass() {
            return this.config.class
                ? `form-control ${this.config.class}`
                : "form-control";
        }
    },
}
</script>
<style></style>