import {API_BASE_URL} from "@/config.js";
import {
    capitalizeFirstLetter,
    capitalizeFirstLetterInSentence,
    formatDateTime,
    getSchoolId,
    getUserInitialsForName,
    getValidToken,
    truncateText
} from "@/utils/helpers.js";
import {computed, ref} from "vue";
import {checkAndRefreshToken} from '@/utils/auth';

const loading = ref(false);
const projectsData = ref([]);
const projectsPayloadData = ref(null);

const fetchProjectsData = async (page, perPage) => {
    const schoolId = getSchoolId()
    const apiUrl = `${API_BASE_URL}/school/${schoolId}/projects`;

    try {
        await checkAndRefreshToken()

        const accessToken = getValidToken()
        if (!accessToken) {
            console.error("Access token not available.");
            return;
        }

        loading.value = true;
        const apiUrlWithParams = `${apiUrl}?page=${page}&per_page=${perPage}`

        const response = await fetch(apiUrlWithParams, {
            headers: {
                Authorization: `Bearer ${accessToken}`, // Include the access token in the headers
            },
        });

        if (response.ok) {
            const result = await response.json();

            if (result.code === 200) {
                projectsPayloadData.value = result.data
                projectsData.value = result.data.results.projects

            } else {
                console.log("error", result.message);
            }
        } else {
            console.log("response", response);
            console.error("Error fetching data from the API");
        }
    } catch (error) {
        console.error("An error occurred:", error);
    } finally {
        loading.value = false;
    }
};

const formattedProjectsData = computed(() => {
    // Map and format the data
    return projectsData.value.map((project) => {

        // Format the created at
        const {date, time} = formatDateTime(project.created_at);

        const formattedProject = {
            user: {
                name: capitalizeFirstLetter(project.name),
                initials: getUserInitialsForName(project.name),
                avatarColor: 'primary'
            },
            description: truncateText(capitalizeFirstLetterInSentence(project.description), 40),
            date,
            visibility: project.is_private ? 'Private' : 'Public',
            status: capitalizeFirstLetter(project.status),
        };

        return {
            ...formattedProject,
        };
    });
});


const getProjects = () => {
    return {projectsData, fetchProjectsData, loading, formattedProjectsData, projectsPayloadData}
}

export default getProjects