<template>
    <div class="header-content text-center">
        <img
            src="../../../../assets/images/icons/college_logo.png"
            alt="school logo"
            height="150"
            width="150"
        />

        <h4 class="mb-0">Queens College</h4>
        <p>info@queenscollege.com</p>
    </div>

    <div class="middle-content">
        <div class="details-content">
            <img
                src="../../../../assets/images/icons/mobile_icon.svg"
                alt="Mobile Icon"
            />
            <div class="details">
                <p class="subHeader">Contact Number</p>
                <p>+234 801 234 5678</p>
            </div>
        </div>

        <div class="details-content">
            <img
                src="../../../../assets/images/icons/map_icon.svg"
                alt="Mobile Icon"
            />
            <div class="details">
                <p class="subHeader">Location</p>
                <p>56, Sabo-Yaba, Lagos Nigeria.</p>
            </div>
        </div>

        <div class="details-content">
            <img
                src="../../../../assets/images/icons/calendar_icon.svg"
                alt="Mobile Icon"
            />
            <div class="details">
                <p class="subHeader">Added On</p>
                <p>+234 801 234 5678</p>
            </div>
        </div>

        <div class="details-content">
            <img
                src="../../../../assets/images/icons/clock_icon.svg"
                alt="Mobile Icon"
            />
            <div class="details">
                <p class="subHeader">Current Plan</p>
                <p>Silver</p>
            </div>
        </div>

        <div class="details-content">
            <img
                src="../../../../assets/images/icons/clock_icon.svg"
                alt="Mobile Icon"
            />
            <div class="details">
                <p class="subHeader">Next Billing</p>
                <p>February 14, 2020 7:52 AM</p>
            </div>
        </div>
    </div>

    <div class="bottom-content">
        <div class="item">
            <div class="logo-text">
                <img
                    src="../../../../assets/images/icons/user_icon.svg"
                    alt="user icon"
                />
                <p>Teachers</p>
            </div>
            <p class="count">0</p>
        </div>

        <div class="item">
            <div class="logo-text">
                <img
                    src="../../../../assets/images/icons/user_icon.svg"
                    alt="user icon"
                />
                <p>Students</p>
            </div>
            <p class="count">0</p>
        </div>

        <div class="item">
            <div class="logo-text">
                <img
                    src="../../../../assets/images/icons/user_icon.svg"
                    alt="user icon"
                />
                <p>Parents</p>
            </div>
            <p class="count">0</p>
        </div>

        <div class="item">
            <div class="logo-text">
                <img
                    src="../../../../assets/images/icons/user_icon.svg"
                    alt="user icon"
                />
                <p>Administrative Staff</p>
            </div>
            <p class="count">0</p>
        </div>
    </div>

    <div class="buttons-content">
        <RegularButtonBlock
            buttonText="Edit Profile"
            iconText="mdi-account-edit"
            class="custom-button mb-3"
            @clicked="openMiniPage"
        />

        <RegularButtonBlock
            buttonText="Goto Dashboard"
            iconText="mdi-desktop-mac-dashboard"
            color="gray"
            class="custom-button"
            @clicked="openMiniPage"
        />
    </div>
</template>

<script>
import RegularButtonBlock from "@/components/common/RegularButtonBlock.vue";

export default {
    components: { RegularButtonBlock }
};
</script>

<style scoped>
.header-content {
    padding: 30px 0 20px 0;
    position: relative;
    border-bottom: 1px solid #e9eaf3;
}

p {
    color: #9a9a9a;
    font-size: 16px;
}

.details-content {
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding: 15px 40px 0 40px;
}

.details-content:last-child {
    border-bottom: 1px solid #e9eaf3;
    padding-bottom: 20px;
}

.details-content img {
    vertical-align: top;
    margin-right: 15px;
    margin-top: 5px;
}

.details-content .details > .subHeader {
    font-weight: 700;
    color: #333333;
}

.details-content .details > p {
    margin-bottom: 0;
}

.logo-text {
    display: flex;
    align-content: center;
    align-items: flex-start;
}

.logo-text > img {
    margin-right: 15px;
    margin-top: 2px;
}

.logo-text > p {
    color: #333333;
}

.bottom-content {
    padding: 20px 40px 0 40px;
    margin-bottom: 50px;
}
.item {
    display: flex;
    justify-content: space-between;
}

.item > .count {
    color: #2f5bcf;
}

.buttons-content {
    padding: 0 40px 0 40px;
}
</style>