import {API_BASE_URL} from "@/config.js";
import {
    capitalizeFirstLetter,
    formatDateTime,
    getSchoolId,
    getUserInitialsForName,
    getValidToken
} from "@/utils/helpers.js";
import {computed, ref} from "vue";
import {checkAndRefreshToken} from '@/utils/auth';

const loading = ref(false);
const usersData = ref([]);
const usersPayloadData = ref(null);

const fetchUsersData = async (page, perPage) => {
    const schoolId = getSchoolId()
    const apiUrl = `${API_BASE_URL}/school/${schoolId}/school-admins`;

    try {
        await checkAndRefreshToken()

        const accessToken = getValidToken()
        if (!accessToken) {
            console.error("Access token not available.");
            return;
        }

        loading.value = true;
        const apiUrlWithParams = `${apiUrl}?page=${page}&per_page=${perPage}`

        const response = await fetch(apiUrlWithParams, {
            headers: {
                Authorization: `Bearer ${accessToken}`, // Include the access token in the headers
            },
        });

        if (response.ok) {
            const result = await response.json();

            if (result.code === 200) {
                usersPayloadData.value = result.data
                usersData.value = result.data.results.admins

                // console.log('usersData.value', usersData.value)
            } else {
                console.log("error", result.message);
            }
        } else {
            console.log("response", response);
            console.error("Error fetching data from the API");
        }
    } catch (error) {
        console.error("An error occurred:", error);
    } finally {
        loading.value = false;
    }
};

const formattedSchoolUsersData = computed(() => {
    // Map and format the data
    return usersData.value.map((user) => {

        // Format the created at
        const {date, time} = formatDateTime(user.created_at);

        // Replace admin with null
        const roleName = capitalizeFirstLetter(user.role) || capitalizeFirstLetter(user.role);

        const formattedUser = {
            user: {
                name: capitalizeFirstLetter(user.name),
                email: user.email,
                initials: getUserInitialsForName(user.name),
                avatarColor: 'warning'
            },
            contact: user.msisdn,
            role: roleName,
            date,
            status: user.isDeactivated ? 'Deactivated' : 'Active',
        };

        return {
            ...formattedUser,
        };
    });
});


const getSchoolAdmins = () => {
    return {usersData, fetchUsersData, loading, formattedSchoolUsersData, usersPayloadData}
}

export default getSchoolAdmins