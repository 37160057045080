<template>
    <div class="image-input mb-30">
        <label class="image-preview" @click="openFilePicker" :style="`height: ${height}px; width: ${width}px`">

            <span class="circle-content" :class="{ 'has-image': selectedImage }">
                <img v-if="selectedImage" :src="selectedImage" alt="Selected" class="selected-image"/>
                <span v-else class="center-text">Upload Image</span>
                <img src="../../assets/images/icons/camera_icon.svg" alt="camera icon" class="camera-icon">
            </span>

            <input ref="fileInput" type="file" accept=".png, .jpg" @change="handleFileChange" style="display: none"/>

        </label>
    </div>
</template>

<script>
import {ref, watch} from 'vue';
import {compressImage} from "@/utils/helpers";

export default {
    props: {
        imageFile: String,
        height: {
            default: 120
        },
        width: {
            default: 120
        }
    },
    setup(props, {emit}) {
        const selectedImage = ref('');

        // const openFilePicker = () => {
        //     // Trigger the file input click event
        //     const fileInput = document.querySelector('input[type="file"]');
        // };

        const handleFileChange = async (event) => {
            const fileInput = event.target;
            const file = fileInput.files[0];

            if (file && (file.type === 'image/png' || file.type === 'image/jpeg')) {
                try {
                    // Set the compressed image as the selected image
                    selectedImage.value = await compressImage(file, 200, 200, 0.5);

                    emit("update:imageFile", selectedImage.value); // Emit the file name to the parent component
                    emit('imageFileUpdated', selectedImage.value);

                } catch (error) {
                    console.error('Error compressing image: ', error)
                } finally {
                    // Reset the value to allow selecting the same file again
                    fileInput.value = '';
                }
            } else {
                // Handle invalid file type
                console.error('Invalid file type. Please select a PNG or JPEG image.');
            }
        };

        // Watch for changes in the img prop and update the selectedImage
        watch(() => props.imageFile, (newVal) => {
            selectedImage.value = newVal;
        });

        return {
            selectedImage,
            handleFileChange
        };
    }
};
</script>
<style scoped>
.image-preview {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    border: 1px solid #B8BCCA;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
}

.circle-content {
    position: relative;
    width: 100%;
    height: 100%;
}

.selected-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* Ensures the image covers the entire container */
    border-radius: 50%;
}

.center-text {
    position: absolute;
    top: 50%;
    font-size: 7px;
    left: 50%;
    transform: translate(-50%, -50%);
}

.camera-icon {
    position: absolute;
    top: 75%;
    right: 5%;
    transform: translate(50%, -50%);
    font-size: 24px;
    color: #333;
    margin: 5px;
}
</style>