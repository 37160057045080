<template>
    <div class="form-content">
        <form @submit.prevent="updateNewRole">
            <TextInputFieldVue :config="{
                class: 'form-control-gray mb-30',
                placeholder: 'Enter name of Role',
                inputFieldType: 'text',
            }" labelText="Role Name" errorType="nameError" v-model="name" />

            <TextAreaInputField :config="{
                class: 'form-control-gray mb-100',
                placeholder: 'Enter description here',
                inputFieldType: 'description',
            }" labelText="Description" errorType="descriptionError" v-model="description" />

            <RoundedButton buttonText="Update Role" class="custom-button" buttonType="submit" :loading="loading" />
        </form>
    </div>
</template>

<script>
import TextInputFieldVue from "@/components/common/TextInputField.vue";
import TextAreaInputField from "@/components/common/TextAreaInputField.vue";
import RoundedButton from "@/components/common/RoundedButton.vue";
import { ref } from "vue";
import { API_BASE_URL } from "@/config.js";
import { capitalizeFirstLetter, formatDateTime, getValidToken } from '@/utils/helpers'
import { checkAndRefreshToken } from '@/utils/auth';

export default {
    props: {
        roleData: {
            type: Object,
            required: true
        }
    },
    components: {
        TextInputFieldVue,
        TextAreaInputField,
        RoundedButton,
    },
    setup(props, { emit }) {
        const loading = ref(false);
        const loadingSpinner = ref(false);
        const name = ref(props.roleData.name);
        const description = ref(props.roleData.description);
        const accessToken = getValidToken()
        const apiUrl = `${API_BASE_URL}/role-permission/role/${props.roleData.id}`
        const message = ref("");

        const showLoaders = () => {
            loading.value = true;
            loadingSpinner.value = true;
        };

        const hideLoaders = () => {
            loading.value = false;
            loadingSpinner.value = false;
        };

        const updateNewRole = async () => {
            try {
                showLoaders()
                await checkAndRefreshToken()

                const response = await fetch(apiUrl, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    },
                    body: JSON.stringify({
                        name: name.value,
                        description: description.value,
                    }),
                })


                if (response.ok) {
                    const result = await response.json();
                    
                    if (result.code === 200) {
                        message.value = result.message;

                        emit("closeFormModal");
                        emit("message", {
                            apiResponse: message.value,
                            type: "success",
                        });
                    } else {
                        console.log("error", result.message);
                    }
                } else {
                    const result = await response.json();
                    console.log("error", result.message);
                }

            } catch (error) {
                console.error("An error occured:", error)
            } finally {
                hideLoaders();
            }
        }

        return { loading, loadingSpinner, name, description, updateNewRole }
    }
}
</script>
<style scoped>
.textarea {
    margin-bottom: 100px;
}
</style>