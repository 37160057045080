<template>
    <!-- start page title -->
    <div class="row">
        <div class="col-12">
            <div class="page-title-box">
                <h4 class="page-title">Activity Log</h4>
            </div>
        </div>
    </div>

    <div class="row mainContent">
        <div class="col-sm-12">
            <div class="card-box">
                <TableView :data="formattedAuditlogData" :headers="tableHeaders"
                           :showSearchField="true" :loading="loading"
                           :searchStringFromParent.sync="searchStringFromChild"
                           :searchFields="searchFieldsData" :totalPages="totalPages" :currentPage.sync="currentPage"
                           :changePage="changePage" :showActionsColumn="false"
                           :changeItemsPerPage="changeItemsPerPage" :itemsPerPage="itemsPerPage"/>
            </div>
        </div>
    </div>


    <MessageSliderCopy :isVisible="displayMessage" :messageText="messageText" :messageType="messageType"/>
</template>
<script>
import {computed, onMounted, ref, watch} from "vue";
import RoundedButton from "@/components/common/RoundedButton.vue";
import TableView from "@/components/common/TableView.vue";
import FormModal from "@/components/common/FormModal.vue";
import MessageSliderCopy from "@/components/common/MessageSliderCopy.vue";
import {flashMessage} from "@/utils/flashMessage.js";
import {API_BASE_URL} from "@/config.js";
import {capitalizeFirstLetter, formatDateTime, getUserInitialsForName, getValidToken} from '@/utils/helpers'
import {checkAndRefreshToken} from '@/utils/auth';

export default {
    components: {
        RoundedButton,
        TableView,
        FormModal,
        MessageSliderCopy,
    },
    emits: ['showLoader', 'show-loader', 'flashMessage', 'data-loaded'],
    setup(props, {emit}) {
        document.title = 'Manage Activity Logs'
        const auditlogPayload = ref(null)
        const auditlogData = ref([])
        const loading = ref(false)
        const displayMessage = ref(false);
        const messageText = ref("");
        const messageType = ref("");
        const newMessage = ref("");
        const showDetailsOrIcons = ref(false)
        const accessToken = getValidToken();
        const currentPage = ref(1);
        const itemsPerPage = ref(10); // Default items per page
        const totalPages = ref(0)
        const searchStringFromChild = ref("");
        const searchFieldsData = ['user.name', 'action_name', 'action_performed']

        watch(auditlogData, () => {
            totalPages.value = auditlogPayload.value.total_pages
        })

        onMounted(() => {
            fetchAuditLogData(currentPage.value, itemsPerPage.value);
            emit('data-loaded')
        })

        const fetchAuditLogData = async (page, perPage) => {
            const apiUrl = `${API_BASE_URL}/audit/list-audits`
            try {
                await checkAndRefreshToken()

                loading.value = true
                const apiUrlWithParams = `${apiUrl}?page=${page}&per_page=${perPage}`

                const response = await fetch(apiUrlWithParams, {
                    method: 'GET',
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`
                    }
                })

                if (response.ok) {
                    const result = await response.json()

                    if (result.code === 200) {
                        auditlogPayload.value = result.data
                        auditlogData.value = result.data.results.audits
                    } else {
                        const msg = {
                            apiResponse: result.message,
                            type: "success"
                        }
                        showFlashMessage(msg)
                        console.log('error', result.message)
                    }
                } else {
                    const result = await response.json();
                    console.log("error", result.message);
                }
            } catch (error) {
                console.error("An error occured:", error);
            } finally {
                loading.value = false
            }
        }

        const formattedAuditlogData = computed(() => {
            return auditlogData.value.map((activity) => {

                // Format the created at
                const {date, time} = formatDateTime(activity.created_on);

                const formattedAuditLog = {
                    user: {
                        name: capitalizeFirstLetter(activity.creator_name),
                        email: activity.created_by,
                        initials: getUserInitialsForName(activity.creator_name),
                        avatarColor: 'primary'
                    },
                    date: date,
                    time: time,
                    action_performed: activity.action
                };

                return {
                    ...formattedAuditLog,
                };
            })
        })

        const messageFromChild = ref("");
        const handleMessageFromChild = (message) => {
            messageFromChild.value = message
        };

        const showFlashMessage = (msg) => {
            flashMessage(
                msg.apiResponse,
                msg.type,
                messageText,
                messageType,
                displayMessage
            );
        };

        const tableHeaders = computed(() => {
            return [
                {label: "Action Taken By", key: "action_name"},
                {label: "Date", key: "date"},
                {label: "Timestamp", key: "time"},
                {label: "Action Performed", key: "action_performed"},
            ];
        });

        const changePage = (page) => {
            if (page >= 1 && page <= totalPages.value) {
                currentPage.value = page;

                fetchAuditLogData(currentPage.value, itemsPerPage.value)
            }
        };

        const changeItemsPerPage = (perPage) => {
            itemsPerPage.value = perPage;
            currentPage.value = 1; // Reset to the first page when changing items per page

            fetchAuditLogData(currentPage.value, perPage);
        };

        return {
            tableHeaders,
            auditlogData,
            totalPages,
            formattedAuditlogData,
            loading,
            searchStringFromChild,
            searchFieldsData,
            handleMessageFromChild,
            showFlashMessage,
            displayMessage,
            messageText,
            messageType,
            newMessage,
            showDetailsOrIcons,
            changePage,
            changeItemsPerPage,
            currentPage,
            itemsPerPage,
        };
    }
}
</script>
<style></style>