<template>
    <div class="row">
        <div class="col-12">
            <div class="page-title-box">
                <h4 class="page-title">Profile Details</h4>
            </div>
        </div>
    </div>

    <div class="row" v-if="itemData">
        <div class="col-sm-4">
            <div class="card-box">
                <div class="card-content pb-20">
                    <div class="header-content text-center mb-20">
                        <img src="../../../assets/images/icons/college_logo.png" alt="school logo" height="150"
                             width="150"/>

                        <h4 class="mb-0">{{ userName }}</h4>
                        <p class="mb-0">{{ itemData.email }}</p>
                        <p class="mb-20">{{ itemData.msisdn }}</p>
                    </div>

                    <div class="middle-content mb-70">
                        <div class="details-content mb-30">
                            <img src="../../../assets/images/icons/calendar_icon.svg" alt="Mobile Icon"/>
                            <div class="details">
                                <p class="muted-text-sm">Added On</p>
                                <p class="result-text">{{ userCreatedAt }}</p>
                            </div>
                        </div>

                        <div class="details-content">
                            <img src="../../../assets/images/icons/shield_icon.svg" alt="Mobile Icon"/>
                            <div class="details">
                                <p class="muted-text-sm">Portal Role</p>
                                <p class="result-text">{{ userRole }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-sm-8">
            <div class="card-box-no-pd">
                <div class="page-title-box mb-30">
                    <h4 class="page-title-gray">User Profile</h4>
                </div>

                <div class="card-content border-bottom plr-50 pb-30">
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="detail-group mb-30">
                                <span class="muted-text-sm">Full Name</span>
                                <p>{{ userName }}</p>
                            </div>

                            <div class="detail-group mb-30">
                                <span class="muted-text-sm">Contact Number</span>
                                <p>{{ itemData.msisdn }}</p>
                            </div>

                            <div class="detail-group mb-30">
                                <span class="muted-text-sm">Country of Residence</span>
                                <p>{{ itemData.country }}</p>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="detail-group mb-30">
                                <span class="muted-text-sm">Gender</span>
                                <p>{{ itemData.gender }}</p>
                            </div>
                            <div class="detail-group mb-30">
                                <span class="muted-text-sm">Email Address</span>
                                <p>{{ itemData.email }}</p>
                            </div>
                            <div class="detail-group mb-30">
                                <span class="muted-text-sm">State of Residence</span>
                                <p>{{ itemData.state }}</p>
                            </div>
                        </div>

                        <div class="col-sm-12">
                            <div class="detail-group mb-30">
                                <span class="muted-text-sm">Residential Address</span>
                                <p>{{ itemData.residence }}</p>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    </div>
</template>

<script>
import {onMounted, ref, watch} from "vue";
import {capitalizeFirstLetter, formatDateTime, getCurrentUser, getValidToken} from '@/utils/helpers'
import {API_BASE_URL} from "@/config.js";
import {checkAndRefreshToken} from "@/utils/auth";
import RoundedButton from "@/components/common/RoundedButton.vue";


export default {
    components: {RoundedButton},
    props: {
        id: String
    },
    emits: ['showLoader', 'show-loader', 'flashMessage', 'data-loaded', 'flash-message'],
    setup(props, {emit}) {
        const itemData = ref(null)
        const userName = ref('')
        const userRole = ref('')
        const userCreatedAt = ref('')
        const currentUser = getCurrentUser()
        const accessToken = getValidToken();

        const fetchItemData = async () => {

            try {
                const itemId = currentUser.user_id
                const apiUrl = `${API_BASE_URL}/admin/get-admin/${itemId}`;

                await checkAndRefreshToken()

                emit('show-loader')
                const response = await fetch(apiUrl, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    }
                })

                if (response.ok) {
                    const result = await response.json()
                    itemData.value = result.data

                    // console.log('result', result.data)
                } else {
                    console.log("response", response);
                    console.error("Error fetching data from the API");
                }
            } catch (error) {
                console.error('An error occured: ', error)
            }
        }


        onMounted(() => {
            fetchItemData()
        })

        watch(itemData, (item) => {
            userName.value = capitalizeFirstLetter(item.first_name, item.last_name)
            userRole.value = capitalizeFirstLetter(item.role_name)
            const {date, time} = formatDateTime(item.created_at)
            userCreatedAt.value = `${date}, ${time}`

            emit('data-loaded')
        })


        return {
            itemData,
            userName,
            userRole,
            userCreatedAt,
            currentUser,
        }
    }
};
</script>

<style scoped>
.border-bottom {
    border-bottom: 2px solid #F5F6FA;
}

.mb-80 {
    margin-bottom: 80px;
}

.header-content {
    padding: 30px 0 20px 0;
    position: relative;
    border-bottom: 1px solid #e9eaf3;
}

p {
    color: #9a9a9a;
    font-size: 16px;
}

.details-content {
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding: 15px 30px 0 30px;
}

.details-content:last-child {
    border-bottom: 1px solid #e9eaf3;
    padding-bottom: 20px;
}

.details-content img {
    vertical-align: top;
    margin-right: 15px;
    margin-top: 5px;
}

.details-content .details > .subHeader {
    font-weight: 500;
    color: #333333;
}

.details-content .details > p {
    margin-bottom: 0;
}

.logo-text {
    display: flex;
    align-content: center;
    align-items: flex-start;
}

.logo-text > img {
    margin-right: 15px;
    margin-top: 2px;
}

.logo-text > p {
    color: #333333;
}

.bottom-content {
    padding: 20px 30px 0 30px;
    margin-bottom: 50px;
}

.detail-group > p,
.result-text {
    color: #333;
}

.item {
    display: flex;
    justify-content: space-between;
}

.item > .count {
    color: #2f5bcf;
}

.buttons-content {
    padding: 0 15px 40px 15px;
}

.card-box {
    padding: 0 15px 0 15px
}
</style>