<template>
    <form @submit.prevent="saveNewItem">
        <div class="row mb-30">
            <div class="col-12">
                <TextInputField :config="{
                    class: 'form-control-gray',
                    placeholder: 'Enter Plan Name',
                    inputFieldType: 'text',
                }" labelText="Plan Name" errorType="nameError" v-model="name" :isRequired="true"/>
            </div>
        </div>

        <div class="row mb-30">
            <div class="col-12">
                <TextAreaInputField :config="{
                    class: 'form-control-gray',
                    placeholder: 'Enter Description',
                    inputFieldType: 'text',
                }" labelText="Description" errorType="descriptionError" v-model="description" :isRequired="true"/>
            </div>
        </div>

        <div class="row mb-30">
            <div class="col-lg-6">
                <SimpleDropdown labelText="Billing Cycle (Interval)" :options="planCycleOptions"
                                v-model="selectedBillingCycle"
                                selectId="billingOptions"
                                :selectedItem="selectedBillingCycle" :isRequired="true"/>
            </div>

            <div class="col-lg-6">
                <TextInputField :config="{
                    class: 'form-control-gray',
                    placeholder: 'Enter Amount',
                    inputFieldType: 'number',
                }" labelText="Plan Amount" errorType="amountError" v-model="amount" :isRequired="true"/>
            </div>
        </div>


        <RoundedButton buttonText="Submit" class="custom-button" buttonType="submit" :loading="buttonLoading"/>

    </form>
</template>
<script>
import ImageUploadInput from "@/components/common/ImageUploadInput.vue";
import TextInputField from "@/components/common/TextInputField.vue";
import SimpleDropdown from "@/components/common/SimpleDropdown.vue";
import TextAreaInputField from "@/components/common/TextAreaInputField.vue";
import RoundedButton from "@/components/common/RoundedButton.vue";
import PhoneNumberFieldInput from "@/components/common/PhoneNumberFieldInput.vue";
import {onMounted, ref} from "vue";
import {capitalizeFirstLetter, getValidToken} from '@/utils/helpers'
import {checkAndRefreshToken} from '@/utils/auth';
import {API_BASE_URL} from "@/config.js";

export default {
    props: {},
    components: {
        ImageUploadInput,
        TextInputField,
        SimpleDropdown,
        TextAreaInputField,
        RoundedButton,
        PhoneNumberFieldInput,
    },
    setup(props, {emit}) {
        const buttonLoading = ref(false)
        const name = ref('')
        const description = ref('')
        const amount = ref('')
        const features = ref([])
        const selectedBillingCycle = ref('')
        const message = ref('')

        const planCycleOptions = [
            {label: "Select Option", value: ''},
            {label: "1", value: '1'},
            {label: "2", value: '2'},
            {label: "3", value: '3'},
            {label: "4", value: '4'},
            {label: "5", value: '5'},
            {label: "6", value: '6'},
            {label: "7", value: '7'},
            {label: "8", value: '8'},
            {label: "9", value: '9'},
            {label: "10", value: '10'},
            {label: "11", value: '11'},
            {label: "12", value: '12'},
        ]

        onMounted(() => {
            selectedBillingCycle.value = planCycleOptions[0].value;
        })

        // OTHERS
        const showLoaders = () => {
            buttonLoading.value = true;
        };

        const hideLoaders = () => {
            buttonLoading.value = false;
        };

        const apiUrl = `${API_BASE_URL}/subscription/add-plan`
        const saveNewItem = async () => {
            const newItem = {
                name: name.value,
                description: description.value,
                amount: amount.value,
                billing_cycle: selectedBillingCycle.value,
                features: features.value,
            }

            // console.log('newItem', newItem)

            try {
                showLoaders()
                await checkAndRefreshToken()
                const accessToken = getValidToken()

                const response = await fetch(apiUrl, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    },
                    body: JSON.stringify(newItem)
                })


                if (response.ok) {
                    const result = await response.json();

                    if (result.code === 200) {
                        message.value = result.message;

                        const itemName = capitalizeFirstLetter(newItem.name)
                        emit("closeFormModal", itemName);
                    } else {
                        console.log("error", result.message);
                    }
                } else {
                    const result = await response.json();
                    console.log("error", result.message);
                }
            } catch (error) {
                console.error("An error occured:", error);
            } finally {
                hideLoaders();
            }

        }


        return {
            buttonLoading,
            name,
            description,
            amount,
            saveNewItem,
            planCycleOptions,
            selectedBillingCycle
        }
    }
}
</script>
<style></style>