<template>
    <!-- start page title -->
    <div class="row">
        <div class="col-12">
            <div class="page-title-box">
                <div class="page-title-right">
                    <RoundedButton buttonText="Add Role" iconText="mdi-plus" @clicked="openFormModal"
                                   v-if="hasPermission('add_roles')"
                    />
                </div>
                <h4 class="page-title">Manage Roles</h4>
            </div>
        </div>
    </div>

    <div class="row mainContent">
        <div class="col-sm-12">
            <div class="card-box">
                <TableView :data="formattedRolesData" :headers="tableHeaders" :id="idForActions"
                           :detailsData="getDataForDetailsView" :showSearchField="true"
                           :loading="rolesLoader" :searchStringFromParent.sync="searchStringFromChild"
                           :showActionsColumn="true"
                           :viewAction="viewAction" :editAction="editAction" :deleteAction="openConfirmationDialog"
                           :searchFields="['name', 'description']"
                           viewPermission="view_roles"
                           editPermission="modify_role" deletePermission="delete_role"/>
            </div>
        </div>
    </div>

    <InfoModal :isModalOpen="isConfirmationDialogOpen" @closeModal="closeConfirmationDialog"
               :successImageURL="require('../../../assets/images/icons/success_icon.svg')"
               :errorImageURL="require('../../../assets/images/icons/error_icon.svg')" header="Hold on!"
               paragraph="You are about to delete this role. This action cannot be undone once completed. Are you sure you want to continue this action?."
               buttonTxt="Yes, Delete Role" :buttonAction="performAction" buttonColor="error"
               routerLinkText="No, Cancel Action" buttonLink="/"
               :linkAction="cancelAction" :loading="buttonLoader">
    </InfoModal>

    <FormModal :isFormModalOpen="isFormModalOpen" @closeFormModal="closeFormModal" modalHeader="Create Role">
        <CreateRole @closeFormModal="closeFormModal" @message="showFlashMessage"/>
    </FormModal>

    <FormModal :isFormModalOpen="isEditFormModalOpen" @closeFormModal="closeEditFormModal" modalHeader="Edit Role">
        <EditRole @closeFormModal="closeEditFormModal" @message="showFlashMessage" :roleData="roleDataForEditing"/>
    </FormModal>


    <MessageSliderCopy :isVisible="displayMessage" :messageText="messageText" :messageType="messageType"/>
</template>

<script>
import {computed, onActivated, onMounted, ref, watch} from "vue";
import RoundedButton from "@/components/common/RoundedButton.vue";
import TableView from "@/components/common/TableView.vue";
import FormModal from "@/components/common/FormModal.vue";
import EditRole from '@/components/layouts/GodAdmin/Roles/EditRole.vue'
import InfoModal from "@/components/common/InfoModals.vue";
import MessageSliderCopy from "@/components/common/MessageSliderCopy.vue";
import CreateRole from "@/components/layouts/GodAdmin/Roles/CreateRole.vue";
import {flashMessage} from "@/utils/flashMessage.js";
import getRoles from '@/composables/getRoles'
import {useRouter} from 'vue-router';
import {API_BASE_URL} from "@/config.js";
import {useUserStore} from "@/stores/user";
import {getValidToken, hasPermission} from "@/utils/helpers";

export default {
    components: {
        RoundedButton,
        TableView,
        FormModal,
        CreateRole,
        MessageSliderCopy,
        InfoModal,
        EditRole
    },
    emits: ['showLoader', 'show-loader', 'flashMessage', 'data-loaded'],
    setup(props, {emit}) {
        document.title = 'Manage Roles'
        const isFormModalOpen = ref(false);
        const isEditFormModalOpen = ref(false);
        const displayMessage = ref(false);
        const buttonLoader = ref(false);
        const messageText = ref("");
        const messageType = ref("");
        const newMessage = ref("");
        const showDetailsOrIcons = ref(false)
        const router = useRouter()
        const accessToken = getValidToken();
        const isConfirmationDialogOpen = ref(false);
        const roleData = ref(null)
        const roleId = ref('null')

        const openFormModal = () => {
            isFormModalOpen.value = true;
        };

        const closeFormModal = async (message) => {
            isFormModalOpen.value = false;
            await fetchRolesData()
        };

        const openEditFormModal = () => {
            isEditFormModalOpen.value = true;
        };

        const closeEditFormModal = async (message) => {
            isEditFormModalOpen.value = false;
            await fetchRolesData()
        };

        const searchStringFromChild = ref("");
        const {fetchRolesData, rolesData, formattedRolesData, loading: rolesLoader} = getRoles()

        onMounted(async () => {
            await fetchRolesData();
            emit('data-loaded')
        })

        onActivated(() => {
            fetchRolesData();
        })

        const idForActions = ref([])
        watch(() => rolesData.value, (newRolesData) => {
            idForActions.value = newRolesData.map((role) => role.id)
        })


        const getDataForDetailsView = computed(() => rolesData.value)

        const viewAction = (role, id) => {
            router.push({
                name: 'ShowRoles',
                params: {id: id}
            })
        }

        const roleDataForEditing = ref(null)
        const editAction = (role, role_id) => {
            roleData.value = role

            // Find the role for editing
            const roleForEditing = rolesData.value.find((r) => r.id === role_id);

            roleDataForEditing.value = {
                name: roleForEditing.name,
                description: roleForEditing.description,
                id: role_id
            }

            openEditFormModal()
        }

        const deactivateAction = async (id) => {
            const apiUrl = `${API_BASE_URL}/role-permission/role/${id}`
            try {
                buttonLoader.value = true
                const response = await fetch(apiUrl, {
                    method: 'DELETE',
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`
                    },
                    body: JSON.stringify({
                        user_id: id,
                    })
                })

                if (response.ok) {
                    const result = await response.json()

                    if (result.code === 200) {
                        closeConfirmationDialog()

                        const msg = {
                            apiResponse: result.message,
                            type: "success"
                        }
                        showFlashMessage(msg)
                    } else {
                        const msg = {
                            apiResponse: result.message,
                            type: "success"
                        }
                        showFlashMessage(msg)
                        console.log('error', result.message)
                    }
                } else {
                    const result = await response.json();
                    console.log("error", result.message);
                }
            } catch (error) {
                console.error("An error occured:", error);
            } finally {
                buttonLoader.value = false
            }
        }

        const messageFromChild = ref("");
        const handleMessageFromChild = (message) => {
            messageFromChild.value = message
        };

        const showFlashMessage = (msg) => {
            flashMessage(
                msg.apiResponse,
                msg.type,
                messageText,
                messageType,
                displayMessage
            );
        };

        const tableHeaders = computed(() => {
            return [
                {label: "Role Name", key: "name"},
                {label: "Created On", key: "date"},
                {label: "Time Stamp", key: "time"},
                {label: "Created By", key: "createdBy"},
            ];
        });

        const openConfirmationDialog = (detail, id) => {
            roleId.value = id
            isConfirmationDialogOpen.value = true;
        };

        const closeConfirmationDialog = () => {
            isConfirmationDialogOpen.value = false;
            fetchRolesData()
        };

        const performAction = () => {
            deactivateAction(roleId.value)
        }

        const cancelAction = () => {
            closeConfirmationDialog()
        }

        const userStore = useUserStore();
        const user = ref(userStore.user)
        const userPermissions = ref(user.value.permissions)
        const checkPermission = (permission) => hasPermission(userPermissions, permission)

        return {
            tableHeaders,
            formattedRolesData,
            idForActions,
            searchStringFromChild,
            isFormModalOpen,
            openFormModal,
            closeFormModal,
            handleMessageFromChild,
            showFlashMessage,
            displayMessage,
            messageText,
            messageType,
            newMessage,
            showDetailsOrIcons,
            viewAction,
            editAction,
            deactivateAction,
            InfoModal,
            isConfirmationDialogOpen,
            openConfirmationDialog,
            closeConfirmationDialog,
            performAction,
            cancelAction,
            buttonLoader,
            isEditFormModalOpen,
            openEditFormModal,
            closeEditFormModal,
            roleData,
            roleDataForEditing,
            rolesLoader,
            getDataForDetailsView,
            hasPermission: checkPermission
        };
    },
};
</script>

<style scoped>
.mainContent {
    margin-top: 20px;
}
</style>