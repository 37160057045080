import {API_BASE_URL} from "@/config.js";
import {capitalizeFirstLetter, formatDateTime, getUserInitials, getValidToken} from "@/utils/helpers.js";
import {computed, ref} from "vue";
import {checkAndRefreshToken} from '@/utils/auth';

const loading = ref(false);
const apiUrl = `${API_BASE_URL}/teacher/list-teachers`;
const colearnersPayload = ref(null);
const colearnersUserData = ref([])

const fetchColearnersData = async (page, perPage) => {
    try {
        await checkAndRefreshToken()

        const accessToken = getValidToken()
        if (!accessToken) {
            console.error("Access token not available.");
            return;
        }

        loading.value = true;
        const apiUrlWithParams = `${apiUrl}?page=${page}&per_page=${perPage}`

        const response = await fetch(apiUrlWithParams, {
            headers: {
                Authorization: `Bearer ${accessToken}`, // Include the access token in the headers
            },
        });

        if (response.ok) {
            const result = await response.json();

            if (result.code === 200) {
                colearnersPayload.value = result.data
                colearnersUserData.value = result.data.results.teachers
            } else {
                console.log("error", result.message);
            }
        } else {
            console.log("response", response);
            console.error("Error fetching data from the API");
        }
    } catch (error) {
        console.error("An error occured:", error);
    } finally {
        loading.value = false;
    }
};


const formattedColearnersData = computed(() => {
    // Map and format the data
    return colearnersUserData.value.map((colearner) => {

        // Format the created at
        const {date, time} = formatDateTime(colearner.created_at);

        const formattedColearner = {
            user: {
                name: capitalizeFirstLetter(`${colearner.first_name} ${colearner.last_name}`),
                initials: getUserInitials(colearner.first_name, colearner.last_name),
                avatarColor: 'info',
                id: colearner.user_id
            },
            gender: colearner._gender,
            school: capitalizeFirstLetter(colearner.school[0]),
            createdAt: date,
            status: colearner.isDeactivated ? 'Deactivated' : 'Active',
        };

        return {
            ...formattedColearner,
        };
    });
});

const getColearners = () => {
    return {colearnersPayload, fetchColearnersData, loading, formattedColearnersData, colearnersUserData}
}

export default getColearners