<template>
    <form @submit.prevent="addFaq">

        <div class="row mb-30">
            <div class="col-12">
                <TextAreaInputField :config="{
                    class: 'form-control-gray',
                    placeholder: 'Enter Question',
                    inputFieldType: 'text',
                }" labelText="Question" v-model="question"/>
            </div>
        </div>

        <div class="row mb-30">
            <div class="col-12">
                <TextAreaInputField :config="{
                    class: 'form-control-gray',
                    placeholder: 'Enter Answer',
                    inputFieldType: 'text',
                }" labelText="Answer" v-model="answer"/>
            </div>
        </div>

        <RoundedButton buttonText="Submit" class="custom-button" buttonType="submit" :loading="buttonLoading"/>

    </form>
</template>
<script>
import TextAreaInputField from "@/components/common/TextAreaInputField.vue";
import RoundedButton from "@/components/common/RoundedButton.vue";
import {ref} from "vue";
import {getSchoolId, getValidToken} from '@/utils/helpers'
import {checkAndRefreshToken} from '@/utils/auth';
import {API_BASE_URL} from "@/config.js";

export default {
    components: {
        TextAreaInputField,
        RoundedButton,
    },
    setup(props, {emit}) {
        const buttonLoading = ref(false)
        const question = ref('')
        const answer = ref('')

        const addFaq = async () => {

            const allQuestions = {
                faqs: [{
                    question: question.value,
                    answer: answer.value,
                }]
            }

            try {
                buttonLoading.value = true
                await checkAndRefreshToken()
                const accessToken = getValidToken()

                const schoolId = getSchoolId()
                const apiUrl = `${API_BASE_URL}/school/${schoolId}/faq`

                const response = await fetch(apiUrl, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    },
                    body: JSON.stringify(allQuestions)
                })


                if (response.ok) {
                    const result = await response.json();

                    if (result.code === 201) {
                        const msg = {
                            apiResponse: "Success! You have added an FAQ",
                            type: "success",
                        }

                        emit("closeFormModal", msg);
                    } else {
                        console.log("error", result.message);
                    }
                } else {
                    const result = await response.json();
                    console.log("error", result.message);
                }
            } catch (error) {
                console.error("An error occured:", error);
            } finally {
                buttonLoading.value = false
            }
        }


        return {
            buttonLoading,
            addFaq,
            question,
            answer
        }
    }
}
</script>
<style scoped>
@media (max-width: 767px) {
    .first_name, .country {
        margin-bottom: 30px;
    }
}

@media (max-width:992px) {
    .first_name, .country {
        margin-bottom: 30px;
    }
}
</style>