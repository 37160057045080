<template>
    <div class="custom-control custom-switch">
        <input type="checkbox" class="custom-control-input" id="customSwitch1" @change="toggleStatus" v-model="checkedStatus" />
        <label class="custom-control-label" for="customSwitch1">
            {{ switchText }}
        </label>
        <div v-if="loading" class="spinner-border text-secondary ml-2" role="status"></div>
    </div>
</template>

<script>

import {ref, watch} from "vue";

export default {
    props: {
        switchText: {
            type: String,
            default: "Switch 1"
        },
        checked: Boolean,
        toggleStatus: Function,
        loading: Boolean
    },
    setup(props) {
        const checkedStatus = ref(false)

        watch(() => props.checked, (item) => {
            checkedStatus.value = item
        })

        return { checkedStatus }
    }
};
</script>
             
<style scoped>
.custom-control {
    display: flex;
    align-items: center;
}
.custom-control-input:checked~.custom-control-label::before {
    border-color: #38CA89;
    background-color: #38CA89;
}

input[type="checkbox"]:checked+label {
    color: #38CA89;
}

.spinner-border {
    width: 15px;
    height: 15px;
}
</style>
