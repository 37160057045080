<template>
    <!-- Begin page -->
    <div id="wrapper">
        <Navbar :loading="loading" @toggle-menu="toggleMenu" />

        <LeftSideMenu :isMobileMenuOpen="mobileMenuStatus" />

        <!-- ============================================================== -->
        <!-- Start Page Content here -->
        <!-- ============================================================== -->

        <div class="content-page">
            <div class="content">
                <!-- Start Content-->
                <div class="container-fluid">
                    <FlashMessage :showAlert="showAlert" :alertType="alertType" :alertMessage="alertMessage" class="mt-10" />
                    <router-view @data-loaded="closeLoader" @show-loader="showLoader" @flash-message="showMessage"/>
                </div>
                <!-- container -->
            </div>
            <!-- content -->

            <FooterView/>
        </div>

        <!-- ============================================================== -->
        <!-- End Page content -->
        <!-- ============================================================== -->
    </div>
    <!-- END wrapper -->
</template>

<script>
import Navbar from "@/components/partials/GodAdmin/Navbar.vue";
import LeftSideMenu from "@/components/partials/GodAdmin/LeftSideMenu.vue";
import FooterView from "@/components/partials/GodAdmin/Footer.vue";
import {ref} from "vue";
import FlashMessage from "@/components/common/FlashMessage.vue";


export default {
    components: {FlashMessage, Navbar, LeftSideMenu, FooterView},
    setup() {
        const loading = ref(true)
        const alertType = ref("")
        const alertMessage = ref("")
        const showAlert = ref(false);
        const mobileMenuStatus = ref(true);

        const showMessage = (item) => {
            alertType.value = item.alertType
            alertMessage.value = item.alertMessage
            showAlert.value = true

            setTimeout(() => {
                showAlert.value = false;
            }, item.duration ?? 7000);
        }

        const showLoader = () => {
            loading.value = true
        }
        const closeLoader = () => {
            loading.value = false
        }

        const toggleMenu = () => {
            mobileMenuStatus.value = !mobileMenuStatus.value
        }

        return {
            closeLoader,
            loading,
            showLoader,
            showAlert,
            alertType,
            alertMessage,
            showMessage,
            mobileMenuStatus,
            toggleMenu
        }
    },
    mounted() {
        feather.replace();
    },
};
</script>