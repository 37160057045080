<template>
    <form @submit.prevent="updateItem">

        <div class="row mb-30">
            <div class="col-12">
                <TextInputField :config="{
                    class: 'form-control-gray',
                    placeholder: 'Enter School Name',
                    inputFieldType: 'text',
                }" labelText="School Name" v-model="schoolName" :isRequired="true" errorType="schoolNameError"/>
            </div>
        </div>

        <div class="row mb-30">
            <div class="col-12">
                <TextInputField :config="{
                    class: 'form-control-gray',
                    placeholder: 'Enter Contact Number',
                    inputFieldType: 'tel',
                }" labelText="Contact Number" v-model="schoolMsisdn" :isRequired="true" errorType="schoolContactError"/>
            </div>
        </div>

        <div class="row mb-30">
            <div class="col-lg-6 country">
                <SimpleDropdown labelText="Country of Residence" :options="countrySelectOptions"
                                v-model="selectedCountry" :isRequired="true"
                                :loading="countryDropdownLoader" selectId="countryOptions"
                                :selectedItem="selectedCountry" @change="handleCountrySelection"/>
            </div>

            <div class="col-lg-6">
                <SimpleDropdown labelText="State of Residence" :options="stateSelectOptions" v-model="selectedState"
                                :loading="statesDropdownLoader" :isRequired="true"
                                selectId="stateOptions" :selectedItem="selectedState"/>
            </div>
        </div>


        <TextAreaInputField :config="{
            class: 'form-control-gray mb-30',
            placeholder: 'Enter Address',
        }" labelText="School Address" errorType="schoolAddressError" v-model="schoolAddress"/>

        <RoundedButton buttonText="Save Changes" class="custom-button" buttonType="submit" :loading="buttonLoading"/>

    </form>
</template>
<script>
import TextInputField from "@/components/common/TextInputField.vue";
import SimpleDropdown from "@/components/common/SimpleDropdown.vue";
import TextAreaInputField from "@/components/common/TextAreaInputField.vue";
import RoundedButton from "@/components/common/RoundedButton.vue";
import {onMounted, ref, watch} from "vue";
import getCountries from "@/composables/getCountries";
import getStates from "@/composables/getStates";
import {getValidToken} from '@/utils/helpers'
import {checkAndRefreshToken} from "@/utils/auth";
import {API_BASE_URL} from "@/config";
import ImageUploadInput from "@/components/common/ImageUploadInput.vue";


export default {
    props: {
        itemData: {
            type: Object,
            required: true
        }
    },
    components: {
        ImageUploadInput,
        TextInputField,
        SimpleDropdown,
        TextAreaInputField,
        RoundedButton,
    },
    setup(props, {emit}) {
        const buttonLoading = ref(false)
        const message = ref('')
        const {fetchCountriesData, countriesList, loading: countryDropdownLoader} = getCountries()
        const {fetchStatesData, statesList, loading: statesDropdownLoader} = getStates()
        const countrySelectOptions = ref([])
        const stateSelectOptions = ref([])
        const selectedCountry = ref(props.itemData.country)
        const selectedState = ref(props.itemData.state)
        const schoolAddress = ref(props.itemData.schoolAddress)
        const schoolName = ref(props.itemData.schoolName)
        const schoolMsisdn = ref(props.itemData.schoolMsisdn)
        const countryIdMap = ref({});
        const imageFile = ref('')

        onMounted(() => {
            fetchCountriesData()
        })
        // COUNTRIES
        const handleCountrySelection = () => {
            const selectedCountryId = countryIdMap.value[selectedCountry.value]
            fetchStatesData(selectedCountryId)
        }

        // Update the country dropdown when the modal is opened
        const updateCountryOptions = (newData) => {
            countrySelectOptions.value = newData.map(country => ({
                label: country.country_name, value: country.country_name
            }))

            countryIdMap.value = newData.reduce((map, country) => {
                map[country.country_name] = country.id;
                return map;
            }, {});

            if (countrySelectOptions.value.length > 0) {
                selectedCountry.value = props.itemData.country
                handleCountrySelection()
            }
        }

        watch(countriesList, (newData) => {
            updateCountryOptions(newData)
        })

        // STATES
        // Update the states options
        const updateStatesOptions = (newData) => {
            stateSelectOptions.value = newData.map(state => ({
                label: state.state_name,
                value: state.state_name
            }))

            if (stateSelectOptions.value.length > 0) {
                selectedState.value = selectedCountry.value === props.itemData.country ? props.itemData.state : stateSelectOptions.value[0].value
            }
        }

        watch(statesList, (newData) => {
            updateStatesOptions(newData)
        })

        // OTHERS
        const showLoaders = () => {
            buttonLoading.value = true;
        };

        const hideLoaders = () => {
            buttonLoading.value = false;
        };


        const updateItem = async () => {
            const item = {
                name: schoolName.value,
                msisdn: schoolMsisdn.value,
                country: selectedCountry.value,
                state: selectedState.value,
                address: schoolAddress.value,
                profile_image: imageFile.value
            }

            console.log('item', item.profile_image)

            try {
                showLoaders()
                await checkAndRefreshToken()
                const apiUrl = `${API_BASE_URL}/school/${props.itemData.id}`
                const accessToken = getValidToken()

                const response = await fetch(apiUrl, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    },
                    body: JSON.stringify(item)
                })

                if (response.ok) {
                    const result = await response.json();

                    if (result.code === 200) {
                        message.value = result.message;

                        emit("closeFormModal");
                        emit("refreshPage");
                        emit("message", {
                            apiResponse: "School updated successfully",
                            type: "success",
                        });
                    } else {
                        console.log("error", result.message);
                    }
                } else {
                    const result = await response.json();
                    console.log("error", result.message);
                }
            } catch (error) {
                console.error("An error occured:", error);
            } finally {
                hideLoaders();
            }
        }

        const handleImageFileUpdate = (newVal) => {
            imageFile.value = newVal;
        }

        return {
            buttonLoading,
            updateItem,
            countrySelectOptions,
            selectedCountry,
            countryDropdownLoader,
            statesDropdownLoader,
            handleCountrySelection,
            selectedState,
            stateSelectOptions,
            schoolAddress,
            schoolName,
            schoolMsisdn,
            imageFile,
            handleImageFileUpdate
        }
    }
}
</script>
<style scoped>
@media (max-width: 767px) {
    .first_name, .country {
        margin-bottom: 30px;
    }
}

@media (max-width:992px) {
    .first_name, .country {
        margin-bottom: 30px;
    }
}
</style>