import {API_BASE_URL} from "@/config.js";
import {getSchoolId, getValidToken} from "@/utils/helpers.js";
import {ref} from "vue";
import {checkAndRefreshToken} from '@/utils/auth';

const loading = ref(false);
const schoolDashboardData = ref([]);

const fetchSchoolDashboard = async (page, perPage) => {
    const schoolId = getSchoolId()
    const apiUrl = `${API_BASE_URL}/school/school-dashboard/${schoolId}`;
    const schoolDetails = ref(null)

    try {
        await checkAndRefreshToken()

        const accessToken = getValidToken()
        if (!accessToken) {
            console.error("Access token not available.");
            return;
        }

        loading.value = true;

        const response = await fetch(apiUrl, {
            headers: {
                Authorization: `Bearer ${accessToken}`, // Include the access token in the headers
            },
        });

        if (response.ok) {
            const result = await response.json();

            if (result.code === 200) {
                schoolDashboardData.value = result.data

                schoolDetails.value = {
                    name: result.data.name,
                    email: result.data.email,
                    id: result.data.id,
                    address: result.data.address,
                    country: result.data.country,
                    state: result.data.state,
                    msisdn: result.data.msisdn,
                    isDeactivated: result.data.isDeactivated,
                    subcription_plan: result.data.subcription_plan,
                    next_subscription_plan: result.data.next_subscription_plan,
                    reg_number: result.data.reg_number,
                    logo: result.data.logo
                }

                localStorage.setItem(
                    'schoolDetails',
                    JSON.stringify(schoolDetails.value)
                )

            } else {
                console.log("error", result.message);
            }
        } else {
            console.log("response", response);
            console.error("Error fetching data from the API");
        }
    } catch (error) {
        console.error("An error occurred:", error);
    } finally {
        loading.value = false;
    }
};


const getSchoolDashboardData = () => {
    return {schoolDashboardData, fetchSchoolDashboard, loading}
}

export default getSchoolDashboardData