<template>
    <div :class="columnClass">
        <div class="card-box stats-card">
            <p class="mt-0 font-16">{{ statCardText }}</p>
            <h2 class="m-0">
                    <span data-plugin="counterup">
                        {{ statsCardNumber }}
                    </span>
            </h2>

            <div class="loader-container">
                <div class="linear-loader" v-if="loading">
                    <div class="train"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        statCardText: {
            type: String,
            default: 'Stats Card Text'
        },
        statsCardNumber: {
            type: Number,
            default: 0
        },
        columnClass: {
            type: String,
            default: 'col-md-6 col-xl-4'
        },
        loading: Boolean
    }
};
</script>

<style scoped>
.stats-card > p {
    color: #9a9a9a;
}

.stats-card h2 {
    color: #333333;
}

.stats-card {
    border-radius: 10px;
}

.stats-card-blue {
    border-radius: 10px;
    background-color: #2f5bcf;
    color: #fff;
}

.stats-card-blue h2, h4 {
    color: #fff;
}

.linear-loader {
    height: 4px; /* Adjust the height as needed */
    background-color: #89a6f3; /* Adjust the color as needed */
    flex-grow: 1;
    position: absolute;
    top: 1px;
    left: 14px;
    right: 14px;
    overflow: hidden;
    /* animation: moveRight 1s linear infinite; Adjust the animation speed as needed */
    //z-index: 9999; /* Ensure it's on top of other elements */
}

.train {
    width: 900px; /* Adjust the train's width */
    height: 4px; /* Should match the height of the loader */
    background-color: #2f5bcf; /* Adjust the color as needed */
    position: absolute;
    animation: moveRight 1s linear infinite; /* Adjust the animation speed as needed */
    top: 0;
    left: 0;
}

@keyframes moveRight {
    0% {
        left: -50px; /* Start off the screen to the left */
    }
    100% {
        left: 100%; /* Move to the right of the screen */
    }
}

.loader-container {
    display: flex;
}
</style>