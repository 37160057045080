<template>

    <div class="row">
        <div class="col-sm-12 col-md-3">
            <div class="card-box">
                <div class="card-content">
                    <div class="header-content text-center">
                        <AvatarForInitials :avatarText="getUserInitialsForName(schoolName)" iconColor="primary"
                                           class="avatar-md-150 mb-30 m-auto"/>

                        <h4 class="mb-0">{{ schoolName }}</h4>
                        <p class="mb-0">{{ schoolEmail }}</p>
                    </div>

                    <div class="middle-content mb-50" v-if="itemData">
                        <div class="details-content">
                            <img src="../../../assets/images/icons/chart_icon.svg" alt="Mobile Icon"/>
                            <div class="details">
                                <p class="muted-text">Current Plan</p>
                                <p class="result-text">{{ capitalizeFirstLetter(itemData.plan_name) }} Plan</p>
                            </div>
                        </div>

                    </div>

                    <div class="buttons-content" v-if="itemData">
                        <RegularButtonBlock buttonText="Change Plan" iconText="mdi-account-edit"
                                            class="custom-button mb-3" @clicked="changePlan"/>

                        <RegularButtonBlock buttonText="Cancel Subscription" iconText="mdi-power"
                                            class="custom-button error" @clicked="openConfirmationDialog"/>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-sm-12 col-md-9">
            <div class="row">
                <div class="col-12">
                    <div class="page-title-box">
                        <BreadCrumbs/>
                    </div>
                </div>
            </div>

            <div class="card-box-no-pd">
                <div class="page-title-box mb-30">
                    <h4 class="page-title-gray">Subscription Management</h4>

                    <ul class="tab-container" v-if="itemData">
                        <li v-for="(tab, index) in tabs" :key="index" @click="selectTab(index)" class="tab"
                            :class="{ 'active-tab': activeTab === index }">
                            {{ tab }}
                        </li>
                    </ul>
                </div>

                <div v-show="activeTab === 0" v-if="itemData">
                    <div class="card-content border-bottom plr-50 pb-30">

                        <div class="row borderBottom1">
                            <div class="col-sm-6 col-md-6">
                                <div class="table-responsive">
                                    <table class="table table-borderless">
                                        <tr>
                                            <td class="muted-text">Plan</td>
                                            <td class="bold bolder-text">{{ capitalizeFirstLetter(itemData.plan_name) }}
                                                Plan
                                            </td>
                                        </tr>

                                        <tr>
                                            <td class="muted-text">Billing Cycle</td>
                                            <td class="bold bolder-text">Monthly</td>
                                        </tr>

                                        <tr>
                                            <td class="muted-text">Amount</td>
                                            <td class="bold bolder-text">{{ formatCurrency(itemData.amount) }}</td>
                                        </tr>

                                        <tr>
                                            <td class="muted-text">Next Billing</td>
                                            <td class="bold bolder-text">{{ nextBillingDate }}</td>
                                        </tr>

                                        <tr>
                                            <td class="muted-text">Status</td>
                                            <td class="bold bolder-text">
                                                <span class="badge badge-success badge-pill"
                                                      v-if="itemData.status === 'active'">Active</span>
                                                <span class="badge badge-warning badge-pill" v-else>Pending</span>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>


                        <div class="featuresSection mt-30">
                            <h3>Features</h3>
                            <ul v-for="feature in itemData.features" :key="feature">
                                <li>{{ feature }}</li>
                            </ul>
                        </div>

                    </div>
                </div>

                <div v-show="activeTab === 0" v-else>
                    <div class="card-content border-bottom plr-50 pb-30">

                        <div class="row">
                            <div class="col-sm-6 col-md-6">
                                <p>You have no active subscription</p>

                                <RegularButton buttonText="Click to subscribe to a new plan"
                                               class="custom-button mt-20" @clicked="subscribeToNewPlan"/>
                            </div>
                        </div>

                    </div>
                </div>

                <div v-show="activeTab === 1" v-if="itemData">
                    <div class="card-content border-bottom plr-50">
                        <div class="row m-0">
                            <div class="col-12 mb-20 pl-0">
                                <TableView :data="formattedSubscriptionHistoryData" :headers="tableHeaders"
                                           :loading="historyLoader"
                                           :searchStringFromParent.sync="searchStringFromChild"
                                           :searchFields="searchFieldsData" :totalPages="totalPages"
                                           :currentPage.sync="currentPage" :showActionsColumn="false"
                                           :changePage="changePage"
                                           :changeItemsPerPage="changeItemsPerPage" :itemsPerPage="itemsPerPage"/>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>


    <InfoModal :isModalOpen="isConfirmationDialogOpen" @closeModal="closeConfirmationDialog"
               :successImageURL="require('../../../assets/images/icons/success_icon.svg')"
               :errorImageURL="require('../../../assets/images/icons/error_icon.svg')" header="Hold on!"
               :paragraph="deactivateConfirmationParagraph"
               :buttonTxt="deactivateButtonText" :buttonAction="performAction" buttonColor="error"
               routerLinkText="No, Cancel Action" buttonLink="/"
               :linkAction="cancelAction" :loading="buttonLoader">
    </InfoModal>
</template>

<script>
import Switch from "@/components/common/Switch.vue";
import RegularButtonBlock from "@/components/common/RegularButtonBlock.vue";
import BreadCrumbs from "@/components/common/BreadCrumbs.vue";
import {computed, onMounted, ref, watch} from "vue";
import {
    capitalizeFirstLetter,
    capitalizeFirstLetterInSentence,
    formatCurrency,
    formatDateTime,
    getSchoolId,
    getUserInitialsForName,
    getValidToken
} from '@/utils/helpers'
import FormModal from "@/components/common/FormModal.vue";
import UserAvatarBox2 from "@/components/common/UserAvatarBox2.vue";
import {capitaliseFirstLetter} from "../../../../public/frontend_assets/libs/@fullcalendar/core/main.min";
import {useRouter} from "vue-router";
import SchoolPlanSelection from "@/views/School_Admin/Subscriptions/SchoolPlanSelection.vue";
import AvatarForInitials from "@/components/common/AvatarForInitials.vue";
import {API_BASE_URL} from "@/config";
import {checkAndRefreshToken} from "@/utils/auth";
import RegularButton from "@/components/common/RegularButton.vue";
import InfoModal from "@/components/common/InfoModals.vue";
import TableView from "@/components/common/TableView.vue";


export default {
    methods: {capitalizeFirstLetter, getUserInitialsForName, formatCurrency},
    components: {
        TableView,
        InfoModal,
        RegularButton,
        AvatarForInitials,
        UserAvatarBox2,
        FormModal,
        Switch,
        RegularButtonBlock,
        BreadCrumbs,
    },
    props: {
        id: String
    },
    emits: ['showLoader', 'show-loader', 'flashMessage', 'data-loaded', 'flash-message'],
    setup(props, {emit}) {
        document.title = "View Subscriptions"
        const itemData = ref(null)
        const itemName = ref('')
        const createdAt = ref('')
        const loading = ref(null)
        const historyLoader = ref(null)
        const buttonLoader = ref(null)
        const tabs = ref(['Current Plan', 'History'])
        const activeTab = ref(0)
        const router = useRouter()
        const schoolName = ref('')
        const schoolEmail = ref('')
        const subscriptionHistoryList = ref([])
        const isConfirmationDialogOpen = ref(false);
        const currentPage = ref(1);
        const itemsPerPage = ref(5); // Default items per page
        const totalPages = ref(0)
        const searchStringFromChild = ref("");
        const searchFieldsData = ['plan_name', 'billing_cycle', 'amount', 'next_billing_date']

        onMounted(() => {
            fetchSubscriptionInfo()

            const schoolDetails = JSON.parse(localStorage.getItem('schoolDetails'))
            schoolName.value = schoolDetails.name
            schoolEmail.value = schoolDetails.email
        })

        const selectTab = (index) => {
            activeTab.value = index;

            if (index === 1) {
                fetchHistoryList(currentPage, itemsPerPage)
            } else {
                fetchSubscriptionInfo()
            }
        };

        const nextBillingDate = ref("")
        const planDuration = ref("")

        watch(itemData, () => {
            if (itemData) {
                const {date, time} = formatDateTime(itemData.value.next_billing_date)
                nextBillingDate.value = date
            }
        })

        const fetchSubscriptionInfo = async () => {
            const schoolId = getSchoolId()
            const apiUrl = `${API_BASE_URL}/school/${schoolId}/subcription`;

            try {
                emit('show-loader')
                await checkAndRefreshToken()

                const accessToken = getValidToken()
                if (!accessToken) {
                    console.error("Access token not available.");
                    return;
                }

                const response = await fetch(apiUrl, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    }
                })

                if (response.ok) {
                    const result = await response.json()

                    if (result.code === 200) {
                        itemData.value = result.data

                        // console.log('result', result)
                    } else {
                        console.log("error", result.message);
                    }
                } else {
                    console.log("response", response);
                    console.error("Error fetching data from the API");
                }

            } catch (error) {
                console.error("An error occured:", error);
            } finally {
                emit('data-loaded')
            }
        }

        const changePlan = () => {
            router.push({name: 'SchoolPlanSelection'})
        }

        const subscribeToNewPlan = () => {
            router.push({name: 'SchoolPlanSelection'})
        }

        const cancelSubscription = async () => {
            const schoolId = getSchoolId()
            const apiUrl = `${API_BASE_URL}/school/${schoolId}/subcription`;

            try {
                buttonLoader.value = true
                await checkAndRefreshToken()

                const accessToken = getValidToken()
                if (!accessToken) {
                    console.error("Access token not available.");
                    return;
                }

                const response = await fetch(apiUrl, {
                    method: 'DELETE',
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    }
                })

                if (response.ok) {
                    const result = await response.json()

                    if (result.code === 200) {
                        flashAlertMessage('primary', result.message)
                        closeConfirmationDialog()
                        await router.push({name: 'SchoolPlanSelection'})
                    } else {
                        console.log("error", result.message);
                    }
                } else {
                    console.log("response", response);
                    console.error("Error fetching data from the API");
                }

            } catch (error) {
                console.error("An error occured:", error);
            } finally {
                emit('data-loaded')
                buttonLoader.value = false
            }
        }

        const payloadData = ref(null)
        const fetchHistoryList = async (page, perPage) => {
            const schoolId = getSchoolId()
            const apiUrl = `${API_BASE_URL}/school/${schoolId}/subcription/history?page=${currentPage.value}&per_page=${itemsPerPage.value}`;

            try {
                historyLoader.value = true
                await checkAndRefreshToken()

                const accessToken = getValidToken()
                if (!accessToken) {
                    console.error("Access token not available.");
                    return;
                }

                const response = await fetch(apiUrl, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    }
                })

                if (response.ok) {
                    const result = await response.json()

                    if (result.code === 200) {
                        payloadData.value = result.data
                        subscriptionHistoryList.value = result.data.results

                        // console.log('result', result)
                    } else {
                        console.log("error", result.message);
                    }
                } else {
                    console.log("response", response);
                    console.error("Error fetching data from the API");
                }

            } catch (error) {
                console.error("An error occured:", error);
            } finally {
                historyLoader.value = false
            }
        }

        watch(subscriptionHistoryList, () => {
            totalPages.value = payloadData.value.total_pages
        })

        const formattedSubscriptionHistoryData = computed(() => {
            // Map and format the data
            return subscriptionHistoryList.value.map((item) => {

                const formattedSubscriptionHistory = {
                    plan_name: item.plan_name,
                    billing_cycle: "1 Month",
                    amount: formatCurrency(item.amount),
                    next_billing_date: item.next_billing_date
                };

                return {
                    ...formattedSubscriptionHistory,
                };
            });
        });

        const flashAlertMessage = (alertType, alertMessage) => {
            emit('flash-message', {
                alertType: alertType,
                alertMessage: alertMessage
            })
        }

        const deactivateConfirmationParagraph = ref('')
        const deactivateButtonText = ref('')
        const openConfirmationDialog = () => {
            deactivateConfirmationParagraph.value = `You are about to cancel your subscription. Are you sure you want to continue this action?.`
            deactivateButtonText.value = `Yes, Cancel Subscription`
            isConfirmationDialogOpen.value = true;
        };

        const closeConfirmationDialog = () => {
            isConfirmationDialogOpen.value = false;
        };

        const performAction = () => {
            cancelSubscription()
        }

        const cancelAction = () => {
            closeConfirmationDialog()
        }

        const tableHeaders = computed(() => {
            return [
                {label: "Plan", key: "plan_name"},
                {label: "Billing Cycle", key: "billing_cycle"},
                {label: "Amount", key: "amount"},
                {label: "Next Billing Date", key: "next_billing_date"}
            ];
        });

        const changePage = (page) => {
            if (page >= 1 && page <= totalPages.value) {
                currentPage.value = page;

                fetchHistoryList(currentPage.value, itemsPerPage.value)
            }
        };

        const changeItemsPerPage = (perPage) => {
            itemsPerPage.value = perPage;
            currentPage.value = 1; // Reset to the first page when changing items per page

            fetchHistoryList(currentPage.value, perPage);
        };


        return {
            loading,
            itemData,
            buttonLoader,
            itemName,
            createdAt,
            selectTab,
            capitaliseFirstLetter,
            tabs,
            activeTab,
            capitalizeFirstLetterInSentence,
            changePlan,
            cancelSubscription,
            schoolName,
            schoolEmail,
            subscribeToNewPlan,
            nextBillingDate,
            planDuration,
            formattedSubscriptionHistoryData,
            openConfirmationDialog,
            performAction,
            cancelAction,
            deactivateConfirmationParagraph,
            deactivateButtonText,
            isConfirmationDialogOpen,
            closeConfirmationDialog,
            currentPage,
            itemsPerPage,
            totalPages,
            changePage,
            changeItemsPerPage,
            tableHeaders,
            searchStringFromChild,
            searchFieldsData,
            historyLoader
        }
    }
};
</script>

<style scoped>

.row {
    font-size: 16px !important;
}

.detail-content-group > p {
    margin: 0;
    color: #333;
}

.border-bottom {
    border-bottom: 2px solid #F5F6FA;
}

.borderBottom1 {
    border-bottom: 1px solid #F5F6FA;
}

p {
    color: #9a9a9a;
}

.bolder-text {
    margin: 0;
    color: #333;
}

.details-content {
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding: 30px 30px 0 30px;
}

.detail-content-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.details-content:last-child {
    padding-bottom: 30px;
}

.details-content:first-child {
    border-bottom: 1px solid #e9eaf3;
    padding-bottom: 30px;
}

.details-content > img {
    margin-right: 15px;
    margin-top: 5px;
}

.details-content > img {
    margin-right: 15px;
    margin-top: 5px;
}

.details-content .details > p {
    margin-bottom: 0;
}

.logo-text > img {
    margin-right: 15px;
    margin-top: 2px;
}

.logo-text > p {
    color: #333333;
}

.detail-group > p,
.result-text,
.data-title {
    color: #333;
}

.item > .count {
    color: #2f5bcf;
}

.buttons-content {
    padding: 0 15px 40px 15px;
}

.page-title-gray {
    background-color: #F8FAFC;
    font-size: 1.25rem;
    margin: 0;
    line-height: 75px;
    color: #323a46;
    padding: 20px 50px 0 50px;
}

.tab-container {
    display: flex;
    background-color: #F8FAFC;
    padding-left: 50px;
}

.tab {
    list-style-type: none;
    margin: 0 20px;
    text-align: center;
    cursor: pointer;
    padding: 10px;
}

.tab:first-child {
    margin: 0;
    padding-left: 0;
}

.active-tab {
    border-bottom: 2px solid #0d44b2;
}

.table-borderless thead {
    color: #9a9a9a;
}

.table-borderless thead {
    background-color: #f5f6fa;
}

.table-borderless th:first-child {
    border-top-left-radius: 10px;
}

.table-borderless th:last-child {
    border-top-right-radius: 10px;
}

.tableTwo td {
    color: #333;
}

.active {
    color: #38ca89;
}

.inactive {
    color: #f24e1e;
}
</style>