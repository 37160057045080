<template>
    <div class="row justify-content-center">
        <div class="col-xl-10">


            <!-- Pricing Title-->
            <div class="text-center">
                <h3 class="mb-2">Our <b>Plans</b></h3>
                <p class="text-muted w-50 m-auto">
                    We have plans and prices that fit your needs perfectly. Make the managemement of your school a
                    success with any of these plans.
                </p>
            </div>

            <!-- Plans -->
            <div class="row my-3">

                <div :class="activePlans.length > 1 ? 'col-md-4 col-sm-12' : 'col-md-4 mx-auto'"
                     v-for="plan in activePlans" :key="plan">

                    <div class="card card-pricing pb-20">
                        <div class="card-body text-center">
                            <p class="card-pricing-plan-name font-weight-bold text-uppercase">{{ plan.name }}</p>
                            <span class="card-pricing-icon text-primary">
                                <i class="fe-aperture"></i>
                            </span>
                            <h2 class="card-pricing-price">
                                {{ formatCurrency(plan.amount) }}
                            </h2>
                            <p class="mb-0"><span> {{ getMonthString(plan.bill_cycle) }}</span></p>
                            <ul class="card-pricing-features hidden">
                                <li>10 GB Storage</li>
                                <li>500 GB Bandwidth</li>
                                <li>No Domain</li>
                                <li>1 User</li>
                                <li>Email Support</li>
                                <li>24x7 Support</li>
                            </ul>

                            <RegularButton buttonText="Subscribe"
                                           class="custom-button mt-20" @clicked="createPlan(plan)"/>

                        </div>
                    </div> <!-- end Pricing_card -->
                </div> <!-- end col -->
            </div>

        </div> <!-- end col-->
    </div>
</template>
<script>
import {onMounted, ref, watch} from "vue";
import getPlans from "@/composables/getPlans";
import {capitalizeFirstLetter, dd, formatCurrency, getMonthString, getSchoolId, getValidToken} from "@/utils/helpers";
import RoundedButton from "@/components/common/RoundedButton.vue";
import RegularButton from "@/components/common/RegularButton.vue";
import PaystackPop from '@paystack/inline-js';
import RegularButtonBlock from "@/components/common/RegularButtonBlock.vue";
import router from "@/router";
import {API_BASE_URL, PAYSTACK_PUBLIC_KEY} from "@/config";
import {checkAndRefreshToken} from "@/utils/auth";

export default {
    methods: {getMonthString, formatCurrency},
    components: {RegularButtonBlock, RegularButton, RoundedButton},
    setup(props, {emit}) {
        document.title = "Select Plan"
        const {fetchPlansData, plansData} = getPlans()
        const activePlans = ref([])
        const currentSchool = JSON.parse(localStorage.getItem('schoolDetails'))
        const publicKey = PAYSTACK_PUBLIC_KEY
        const schoolEmail = currentSchool.email
        const schoolName = capitalizeFirstLetter(currentSchool.name)
        const schoolId = currentSchool.id

        onMounted(() => {
            emit('show-loader')
            fetchPlansData()
        })

        watch(plansData, (items) => {
            emit('data-loaded')

            items.forEach(item => {
                item.buttonLoading = false;
            });

            activePlans.value = items.filter(item => item.isActive)
        })

        const generateUniqueReference = () => {
            // Get the current timestamp in milliseconds
            const timestamp = new Date().getTime();

            // Generate a random number (you can use other methods for uniqueness)
            const random = Math.floor(Math.random() * 10000);

            // Combine the timestamp and random number to create a unique reference
            return `${timestamp}-${random}`;
        }


        const processPayment = async (plan) => {
            if (plan.amount === "0") {
                flashAlertMessage('primary', `You have subscribed to the ${capitalizeFirstLetter(plan.name)} plan! Redirecting to your subscription page`, 3000)

                setTimeout(() => {
                    router.push({name: 'SubscriptionDetail'})
                }, 3000);
            } else {
                const data = {
                    key: publicKey,
                    channels: ['card'],
                    email: schoolEmail,
                    first_name: schoolName,
                    amount: plan.amount * 100,
                    reference: generateUniqueReference(),
                    currency: "NGN",
                    metadata: {
                        schoolId: schoolId,
                        planId: plan.id,
                        planName: plan.name,
                        planDuration: plan.bill_cycle
                    },
                    onSuccess: success,
                    onCancel: close
                }


                const paystack = new PaystackPop()
                paystack.newTransaction(data)
            }
        }

        const success = (transaction) => {
            flashAlertMessage('success', 'Your Payment is Successful!. Redirecting to your subscription page', 5000)

            setTimeout(() => {
                router.push({name: 'SubscriptionDetail'})
            }, 5000);
        }

        const close = () => {
            flashAlertMessage('warning', "You canceled the payment attempt")
        }

        const createPlan = async (plan) => {
            // Create a subscription
            const schoolId = getSchoolId()
            const apiUrl = `${API_BASE_URL}/school/${schoolId}/subcription`;

            try {
                emit('show-loader')
                await checkAndRefreshToken()

                const accessToken = getValidToken()
                if (!accessToken) {
                    console.error("Access token not available.");
                    return;
                }

                const bodyData = {
                    plan_id: plan.id,
                    recurring: true
                }

                const response = await fetch(apiUrl, {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(bodyData)
                })

                if (response.ok) {
                    const result = await response.json()

                    if (result.code === 200) {
                        dd('Subscription created')
                        await processPayment(plan)
                    } else {
                        console.log("error", result.message);
                    }
                }
            } catch (error) {
                console.error("An error occured:", error);
            } finally {
                emit('data-loaded')
            }
        }

        const flashAlertMessage = (alertType, alertMessage, duration = 7000) => {
            emit('flash-message', {
                alertType: alertType,
                alertMessage: alertMessage,
                duration: duration
            })
        }

        return {
            activePlans,
            createPlan,
            close,
            publicKey,
            schoolEmail,
            generateUniqueReference,
            schoolId,
            schoolName,
        }
    }
}
</script>
<style scoped>
.custom-button {
    border-radius: 4px;
    background-color: #2f5bcf;
    color: white;
    font-size: 14px;
    letter-spacing: 0.1px;
    font-weight: bolder;
    padding: 10px 24px;
    border: none;
}
</style>
