<template>
    <div class="row mb-30">
        <div class="col-12">
            <form @submit.prevent="submitForm">
                <div class="row" v-if="checklistData.length === 0">
                    <div class="col-12 text-center">
                        <h4 class="bold">There seems to be an error while loading the
                            questions.</h4>
                    </div>
                </div>

                <div class="table-responsive">
                    <table class="table table-hover table-borderless">
                        <thead>
                        <tr>
                            <th>S/N</th>
                            <th>Question</th>
                            <th>Options</th>
                        </tr>
                        </thead>

                        <tbody>
                        <tr v-if="checklistLoader" class="loader-row">
                            <td :colspan="3">
                                <div class="linear-loader">
                                    <div class="train"></div>
                                </div>
                            </td>
                        </tr>

                        <tr v-for="(question, index) in projectChecklistAnswers" :key="index">
                            <td class="text-center">{{ index + 1 }}</td>
                            <td class="text-wrap">
                                {{ capitalizeFirstLetterInSentence(question.question) }}
                            </td>
                            <td class="text-nowrap">
                                <input type="radio"
                                       value="correct"
                                       :id="`input_yes_${index}`"
                                       :name="`question_yes_${index}`"
                                       v-model="projectChecklistAnswers[index].answer"
                                       :checked="getAnswer(index) === 'correct'"
                                       class="mr-1">
                                <label :for="`input_yes_${index}`" class="mr-2 mb-0">Correct</label>

                                <input type="radio"
                                       v-model="projectChecklistAnswers[index].answer"
                                       value="incorrect"
                                       :id="`input_no_${index}`"
                                       :name="`question_no_${index}`"
                                       :checked="getAnswer(index) === 'incorrect'"
                                       class="mr-1">
                                <label :for="`input_no_${index}`" class="mr-2 mb-0">Incorrect</label>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </form>

            <RoundedButton buttonText="Save Changes" class="custom-button" :loading="loading" @click="updateItems"/>
        </div>
    </div>
</template>
<script>
import TextInputFieldVue from "@/components/common/TextInputField.vue";
import SingleSelectField from "@/components/common/SingleSelectField.vue";
import RegularButton from "@/components/common/RegularButton.vue";
import {ref} from "vue";
import RoundedButton from "@/components/common/RoundedButton.vue";
import {API_BASE_URL} from "@/config";
import {capitalizeFirstLetterInSentence, getSchoolId, getValidToken} from "@/utils/helpers";
import {checkAndRefreshToken} from "@/utils/auth";

export default {
    props: {
        itemData: {
            type: Object,
            required: true
        }
    },
    components: {RoundedButton, RegularButton, SingleSelectField, TextInputFieldVue},
    setup(props, {emit}) {
        const loading = ref(false)
        const checklistData = ref(props.itemData.items ?? [])
        const checklistLoader = ref(props.itemData.checklistLoader)
        const checklist = ref([])
        const projectChecklistAnswers = ref(checklistData.value.map((question, index) => {
            const existingAnswer = props.itemData.projectChecklistData[index];

            return {
                question: question.question,
                answer: existingAnswer ? existingAnswer.answer : ''
            }
        }))

        const getAnswer = (index) => {
            return projectChecklistAnswers.value[index]?.answer || 'N/A';
        };

        const submitForm = () => {
            checklist.value = checklistData.value.map((question, index) => {
                return {
                    question: question.question,
                    answer: projectChecklistAnswers.value[index]?.answer
                }
            })
        }

        const updateItems = async () => {
            submitForm()

            try {
                const schoolId = getSchoolId()
                const apiUrl = `${API_BASE_URL}/school/${schoolId}/projects/${props.itemData.id}`
                loading.value = true
                const accessToken = getValidToken()
                const checklistData = {
                    project_checklist: checklist.value
                }

                await checkAndRefreshToken()

                const response = await fetch(apiUrl, {
                        method: "PUT",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${accessToken}`,
                        },
                        body: JSON.stringify(checklistData)
                    }
                );

                if (response.ok) {
                    const result = await response.json();
                    if (result.code === 200) {
                        const msg = {
                            type: 'success',
                            message: 'Success! You have updated the checklist details'
                        }
                        emit('closeFormModal', msg)
                    } else {
                        console.log("error", result.message);
                    }
                } else {
                    const result = await response.json();
                    console.log("error", result.message);
                }
            } catch (error) {
                console.error("An error occured:", error);
            } finally {
                loading.value = false
            }
        }

        return {
            checklistData,
            updateItems,
            loading,
            checklistLoader,
            submitForm,
            capitalizeFirstLetterInSentence,
            getAnswer,
            projectChecklistAnswers
        }
    }
}
</script>
<style scoped>
.table td {
    padding: 10px
}

.table-borderless thead,
.table-borderless td {
    border-bottom: 1px solid #f1f1f1;
}

.table-borderless thead {
    color: #9a9a9a;
}

.table-borderless thead {
    background-color: #f5f6fa;
}

.table-borderless th:first-child {
    border-top-left-radius: 10px;
}

.table-borderless th:last-child {
    border-top-right-radius: 10px;
}

.tableEmail {
    font-size: 11px;
    color: #9a9a9a;
}

td {
    vertical-align: middle !important;
}

.linear-loader {
    height: 3px;
    /* Adjust the height as needed */
    background-color: #89a6f3;
    /* Adjust the color as needed */
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    /* animation: moveRight 1s linear infinite; Adjust the animation speed as needed */
    //z-index: 9999;
    /* Ensure it's on top of other elements */
}

.train {
    width: 900px;
    /* Adjust the train's width */
    height: 7px;
    /* Should match the height of the loader */
    background-color: #2f5bcf;
    /* Adjust the color as needed */
    position: absolute;
    animation: moveRight 1s linear infinite;
    /* Adjust the animation speed as needed */
    top: 0;
    left: 0;
}

@keyframes moveRight {
    0% {
        left: -50px;
        /* Start off the screen to the left */
    }

    100% {
        left: 100%;
        /* Move to the right of the screen */
    }
}

tr.loader-row td {
    padding: 0 !important;
}
</style>