import {API_BASE_URL} from "@/config.js";
import {getValidToken} from "@/utils/helpers.js";
import {ref} from "vue";
import {checkAndRefreshToken} from '@/utils/auth';

const loading = ref(false);
const apiUrl = `${API_BASE_URL}/subscription/subscription-page-info`;
const subscriptionInfoData = ref([])

const fetchSubscriptionInfoData = async () => {
    try {
        await checkAndRefreshToken()

        const accessToken = getValidToken()
        if (!accessToken) {
            console.error("Access token not available.");
            return;
        }

        loading.value = true;

        const response = await fetch(apiUrl, {
            headers: {
                Authorization: `Bearer ${accessToken}`, // Include the access token in the headers
            },
        });

        if (response.ok) {
            const result = await response.json();

            if (result.code === 200) {
                subscriptionInfoData.value = result.data
            } else {
                console.log("error", result.message);
            }
        } else {
            console.log("response", response);
            console.error("Error fetching data from the API");
        }
    } catch (error) {
        console.error("An error occured:", error);
    } finally {
        loading.value = false;
    }
};


const getSubscriptionInfo = () => {
    return {fetchSubscriptionInfoData, loading, subscriptionInfoData}
}

export default getSubscriptionInfo