<template>
    <div class="avatar-box text-center">
        <!--        <img src="../../assets/images/users/user-3.jpg" class="img-fluid rounded-circle"-->
        <!--             alt="user-img"/>-->

        <div class="avatar-md">
            <span
                :class="`avatar-title bg-soft-success text-success font-20 rounded-circle`">
                {{ getUserInitials(studentFirstName, studentLastName) }}
            </span>
        </div>

        <h5 class="mb-1">{{ capitalizeFirstLetter(studentFirstName, studentLastName) }}</h5>
        <p class="semibold" v-if="email">{{ email }}</p>
        <p class="semibold" v-if="studentSchool">{{ studentSchool }}</p>

        <div class="actions">
            <a href="#" @click.prevent="viewAction(id)">
                <img src="../../assets/images/icons/detail_icon.svg" alt="View Icon"/>
            </a>
        </div>
    </div>
</template>
<script>
import {capitalizeFirstLetter, getUserInitials} from "@/utils/helpers.js";

export default {
    props: {
        studentFirstName: {
            type: String,
            default: "Default"
        },
        studentLastName: {
            type: String,
            default: "Text"
        },
        email: {
            type: String
        },
        studentSchool: {
            type: String
        },
        viewAction: Function,
        toggleStatusAction: Function,
        id: Number,
        student: Object
    },
    components: {},
    setup() {
        return {getUserInitials, capitalizeFirstLetter}
    }
}
</script>
<style scoped>
.avatar-box {
    border: 1px solid #B8BCCA;
    width: 95%;
    border-radius: 5px;
    padding: 16px 0;
}

.avatar-md {
    margin: 0 auto 16px;
}

.avatar-box > img {
    width: 50px;
    height: 50px;
    margin-bottom: 16px;
}

.avatar-box > h5 {
    font-size: 16px;
}

.avatar-box > p {
    color: #0D44B2;
    font-size: 10px;
    margin-bottom: 16px;
}

.actions {

}
</style>