<template>

    <div class="row" v-if="itemData">
        <div class="col-sm-12 col-md-3">
            <div class="card-box">
                <div class="card-content">
                    <div class="header-content text-center">
                        <AvatarForInitials :avatarText="getUserInitialsForName(itemName)" iconColor="primary"
                                           class="avatar-md-150 mb-30 m-auto"/>

                        <h4 class="mb-0">{{ itemName }}</h4>
                    </div>

                    <div class="middle-content mb-20">
                        <div class="details-content">
                            <img src="../../../assets/images/icons/calendar_icon.svg" alt="Mobile Icon"/>
                            <div class="details">
                                <p class="muted-text-sm bold">Added On</p>
                                <p class="result-text">{{ createdAt }}</p>
                            </div>
                        </div>

                        <div class="details-content">
                            <img src="../../../assets/images/icons/folder_icon.svg" class="data-img" alt="Mobile Icon"/>

                            <div class="detail-content-group">
                                <p class="muted-text-sm bold">Students</p>
                                <p class="result-text">{{ itemData.students.length ?? 0 }}</p>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>

        <div class="col-sm-12 col-md-9">
            <div class="row">
                <div class="col-12">
                    <div class="page-title-box">
                        <BreadCrumbs/>
                    </div>
                </div>
            </div>

            <div class="card-box-no-pd">
                <div class="page-title-box mb-30">
                    <h4 class="page-title-gray">Project Details</h4>

                    <ul class="tab-container">
                        <li v-for="(tab, index) in tabs" :key="index" @click="selectTab(index)" class="tab"
                            :class="{ 'active-tab': activeTab === index }">
                            {{ tab }}
                        </li>
                    </ul>
                </div>

                <div v-show="activeTab === 0">
                    <div class="card-content border-bottom plr-50 pb-30">
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="detail-group mb-30">
                                    <span class="muted-text-sm bold">Project Name</span>
                                    <p>{{ itemData.name }}</p>
                                </div>

                                <div class="detail-group mb-30">
                                    <span class="muted-text-sm bold">Academic Year</span>
                                    <p>{{ itemData.academic_year }}</p>
                                </div>

                                <div class="detail-group mb-30">
                                    <span class="muted-text-sm bold">Academic Term</span>
                                    <p>{{ itemData.term }}</p>
                                </div>

                                <div class="detail-group mb-30">
                                    <span class="muted-text-sm bold">Project Type</span>
                                    <p>{{ itemData.project_type }}</p>
                                </div>

                                <div class="detail-group mb-30">
                                    <span class="muted-text-sm bold">Learning Group</span>
                                    <p>{{ learningGroupList }}</p>
                                </div>

                                <div class="detail-group mb-30">
                                    <span class="muted-text-sm bold">Curriculum</span>
                                    <p>{{ itemData.curriculum ?? 'N/A' }}</p>
                                </div>
                            </div>

                            <div class="col-sm-6">
                                <div class="detail-group mb-30">
                                    <span class="muted-text-sm bold">Project Duration</span>
                                    <p>{{ itemData.project_duration }}</p>
                                </div>
                                <div class="detail-group mb-30">
                                    <span class="muted-text-sm bold">Lead Co-learner</span>
                                    <p>{{ leadTeacherName }}</p>
                                </div>

                                <div class="detail-group mb-30" v-if="colearnerNames">
                                    <span class="muted-text-sm bold">Supporting Co-learners</span>
                                    <p>{{ colearnerNames }}</p>
                                </div>

                                <div class="detail-group mb-30" v-if="SMEs">
                                    <span class="muted-text-sm bold">Subject Matter Expert(s)</span>
                                    <p>{{ SMEs }}</p>
                                </div>

                                <div class="detail-group mb-30" v-if="keywords">
                                    <span class="muted-text-sm bold">Keywords</span>
                                    <p>{{ keywords }}</p>
                                </div>

                                <div class="detail-group mb-30">
                                    <span class="muted-text-sm bold">Status</span>
                                    <p>
                                        {{ capitalizeFirstLetter(itemData.status) }}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="row" v-if="itemData.status !== 'approved'">
                            <div class="col-12">
                                <RegularButton buttonText="Edit" iconText="mdi-circle-edit-outline" color="yellow"
                                               class="custom-button"
                                               v-if="hasPermission('modify_projects')"
                                               @clicked="openRegistrationDetailsFormModal"/>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-show="activeTab === 1">
                    <div class="card-content border-bottom plr-50 pb-30">
                        <div class="row mb-30">
                            <div class="col-12">
                                <div class="detail-group mb-30">
                                    <span class="muted-text-sm bold">Project Aim</span>
                                    <p>{{ itemData.project_aim }}</p>
                                </div>

                                <div class="detail-group mb-30">
                                    <span class="muted-text-sm bold">Project Description</span>
                                    <p>{{ itemData.description }}</p>
                                </div>

                                <div class="detail-group mb-30">
                                    <span class="muted-text-sm bold mb-3">Guiding Questions</span>
                                    <ul v-for="(item, index) in itemData.problem_to_solve" :key="item"
                                        class="m-0">
                                        <li>{{ getProblemName(item, index + 1, false) }}</li>
                                    </ul>
                                </div>

                                <div class="detail-group mb-30">
                                    <span class="muted-text-sm bold">Web Link</span>
                                    <p>{{ itemData.weblinks }}</p>
                                </div>

                                <div class="detail-group mb-30">
                                    <span class="muted-text-sm bold">Key Milestones</span>
                                    <p>{{ itemData.milestones }}</p>
                                </div>

                                <div class="detail-group">
                                    <span class="muted-text-sm bold">Final Product</span>
                                    <p>{{ finalProduct }}</p>

                                    <span class="muted-text-sm bold">Details</span>
                                    <p>{{ finalProductDetails }}</p>
                                </div>
                            </div>
                        </div>

                        <div class="row" v-if="itemData.status !== 'approved'">
                            <div class="col-12">
                                <RegularButton buttonText="Edit" iconText="mdi-circle-edit-outline" color="yellow"
                                               v-if="hasPermission('modify_projects')"
                                               class="custom-button" @clicked="openIdeaDetailsFormModal"/>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-show="activeTab === 2">
                    <div class="card-content border-bottom plr-50 pb-30">
                        <div class="row" v-for="(item, index) in itemData.learning_goals" :key="index"
                             v-if="itemData.learning_goals.length > 0">
                            <div class="col-sm-6">
                                <div class="detail-group mb-30">
                                    <span class="muted-text-sm bold">Learning Goal Category</span>
                                    <p>{{ Object.keys(item)[0] }}</p>
                                </div>
                            </div>

                            <div class="col-sm-6">
                                <div class="detail-group mb-30">
                                    <span class="muted-text-sm bold">Learning Goals</span>
                                    <div>
                                        {{ getLearningGoals(item[Object.keys(item)[0]]) }}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row" v-else>
                            <div class="col-12 mb-30">
                                No Learning Goals Added
                            </div>
                        </div>

                        <div class="row" v-if="itemData.status !== 'approved'">
                            <div class="col-12">
                                <RegularButton :buttonText="itemData.learning_goals.length > 0 ? 'Edit' : 'Add New'"
                                               iconText="mdi-circle-edit-outline" color="yellow"
                                               class="custom-button"
                                               v-if="hasPermission('modify_projects')"
                                               @clicked="openLearningGoalsFormModal"/>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-show="activeTab === 3">
                    <div class="card-content border-bottom plr-50">
                        <div class="row">
                            <div class="col-12">
                                <div class="detail-group">
                                    <div v-if="projectActivitiesData && projectActivitiesData.length > 0">
                                        <p>{{ completedActivities }} out of
                                            {{ calculateWorkingDays(itemData.project_duration) }} activities
                                            completed</p>
                                        <div id="bar" class="progress mb-3" style="height: 7px;">
                                            <div
                                                class="bar progress-bar progress-bar-striped progress-bar-animated bg-success"
                                                :style="`width: ${getCompletedActivityPercentage()}%`"></div>
                                        </div>
                                    </div>

                                    <table class="table table-borderless table-hover mt-20">
                                        <thead>
                                        <tr>
                                            <th class="text-center">S/N</th>
                                            <th>Name</th>
                                            <th>Description</th>
                                            <th>Start Date</th>
                                            <th>Finish Date</th>
                                            <th>Status</th>
                                            <th>Actions</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="(activity, index) in projectActivitiesData" :key="index">
                                            <td class="text-center">{{ index + 1 }}</td>
                                            <td>{{ activity.name }}</td>
                                            <td>{{ activity.description }}</td>
                                            <td>{{ truncateText(activity.start_date, 16, false) }}</td>
                                            <td>{{ truncateText(activity.finish_date, 16, false) }}</td>
                                            <td>
                                                <span :class="`badge badge-${getActivityBadgeColor(activity)}`">
                                                    {{ capitalizeFirstLetter(activity.status) }}
                                                </span>
                                            </td>

                                            <td>
                                                <a href="#" @click.prevent="viewActivity(activity, activity.id)">
                                                    <img class="mr-2" src="../../../assets/images/icons/detail_icon.svg"
                                                         alt="View Icon"/>
                                                </a>

                                                <a href="#" @click.prevent="editActivity(activity, activity.id)">
                                                    <img class="mr-2" src="../../../assets/images/icons/edit_icon.svg"
                                                         alt="Edit Icon"/>
                                                </a>

                                                <a href="#" @click.prevent="openConfirmationDialog(activity.id)">
                                                    <img class="mr-2"
                                                         src="../../../assets/images/icons/deactivate_icon.svg"
                                                         alt="Delete Icon"/>
                                                </a>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>

                                    <div class="alert alert-success alert-dismissible fade show mb-2 bold"
                                         role="alert">
                                        You can create only {{ calculateWorkingDays(itemData.project_duration) }}
                                        activities
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="projectActivitiesData">
                            <div class="row mb-50"
                                 v-if="projectActivitiesData.length < calculateWorkingDays(itemData.project_duration)">
                                <div class="col-12">
                                    <RegularButton buttonText="Add Activity" iconText="mdi-circle-edit-outline"
                                                   color="yellow"
                                                   v-if="hasPermission('modify_projects')"
                                                   class="custom-button"
                                                   @clicked="openActivityCreateFormModal"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-show="activeTab === 4">
                    <div class="card-content border-bottom plr-50">
                        <div class="row">
                            <div class="col-12">
                                <div class="detail-group mb-30">
                                    <div class="row" v-if="itemData.project_checklist.length > 0">
                                        <div class="col-12">
                                            <table class="table table-borderless table-hover mt-20">
                                                <thead>
                                                <tr>
                                                    <th class="text-center">S/N</th>
                                                    <th class="text-wrap">Question</th>
                                                    <th>Answer</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr v-for="(question, index) in itemData.project_checklist"
                                                    :key="index">
                                                    <td class="text-center">{{ index + 1 }}</td>
                                                    <td class="text-wrap">
                                                        {{ capitalizeFirstLetterInSentence(question.question) }}
                                                    </td>
                                                    <td class="text-nowrap">
                                                        {{ question.answer ? formatWords(question.answer) : 'Not Set' }}
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div class="row" v-else>
                                        <div class="col-12 mb-30">
                                            No Checklist Item Added
                                        </div>
                                    </div>

                                    <div class="row" v-if="itemData.status !== 'approved'">
                                        <div class="col-12">
                                            <RegularButton
                                                :buttonText="checklistData.length !== 0 ? 'Edit' : 'Add New'"
                                                iconText="mdi-circle-edit-outline" color="yellow"
                                                class="custom-button"
                                                v-if="hasPermission('modify_projects')"
                                                @clicked="openChecklistFormModal"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-show="activeTab === 5">
                    <div class="card-content border-bottom plr-50">
                        <div class="row">
                            <div class="col-12">
                                <Comments
                                    :deleteCommentUrl="deleteCommentUrl"
                                    :updateCommentUrl="updateCommentUrl"
                                    :fetchCommentUrl="fetchCommentUrl"
                                    :addCommentUrl="addCommentUrl"/>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-show="activeTab === 6">
                    <div class="card-content border-bottom plr-50">
                        <div class="row">
                            <div class="col-12">
                                <Files
                                    :fileAddURL="fileAddURL"
                                    :fileFetchURL="fileFetchURL"
                                    :fileDeleteURL="fileDeleteURL"
                                    @feedback="showAlertForFiles"
                                    @loading="showFilesLoader"
                                />
                            </div>
                        </div>
                    </div>
                </div>


                <div class="ptb-30 plr-50">
                    <p class="dark-p mb-10">Toggle the switch below to set this project as private or public</p>
                    <Switch :switchText="switchTextLabel" :checked="checkedStatus" :toggleStatus="handleSwitchToggle"
                            :loading="loading"/>
                </div>

                <div class="ptb-30 plr-50 approve-project-section" v-if="itemData.status!== 'approved' && hasPermission('approve_projects')">
                    <p class="dark-p mb-0">Project Status:
                        <span :class="`badge badge-${getProjectStatusColor()}`">
                            {{ capitalizeFirstLetter(itemData.status) }}
                        </span>
                    </p>

                    <RegularButton buttonText="Approve Project" iconText="mdi-circle-edit-outline"
                                   color="yellow"
                                   class="custom-button"
                                   :loading="projectApprovalLoader"
                                   @clicked="setProjectToApproved"/>
                </div>

                <FormModal :isFormModalOpen="isActivityCreateFormModalOpen"
                           @closeFormModal="closeActivityCreateFormModal"
                           modalHeader="Create Activity">
                    <CreateActivity @closeFormModal="closeActivityCreateFormModal" :projectId="projectId"
                                    :activities="itemData.activities.length"
                                    :project_duration="itemData.project_duration" @refresh_page="refreshActivityList"/>
                </FormModal>


                <FormModal :isFormModalOpen="isActivityEditFormModalOpen" @closeFormModal="closeActivityEditFormModal"
                           modalHeader="Edit Activity">
                    <EditActivity @closeFormModal="closeActivityEditFormModal" :itemData="itemDataToEdit"/>
                </FormModal>


                <FormModal :isFormModalOpen="isActivityDetailsFormModalOpen"
                           @closeFormModal="closeActivityDetailsFormModal"
                           modalHeader="View Activity Details">
                    <ShowActivities @closeFormModal="closeActivityDetailsFormModal" :itemDetails="itemDetails"
                                    @openFormModal="openActivityEditFormModal"/>
                </FormModal>

                <FormModal :isFormModalOpen="isRegistrationDetailsFormModalOpen"
                           @closeFormModal="closeRegistrationDetailsFormModal"
                           modalHeader="Edit Project Registration Details">
                    <EditProjectRegistrationDetails @closeFormModal="closeRegistrationDetailsFormModal"
                                                    :itemData="registrationData"/>
                </FormModal>

                <FormModal :isFormModalOpen="isIdeaDetailsFormModalOpen" @closeFormModal="closeIdeaDetailsFormModal"
                           modalHeader="Edit Project Idea Details">
                    <EditProjectIdeaDetails @closeFormModal="closeIdeaDetailsFormModal" :itemData="ideaData"/>
                </FormModal>

                <FormModal :isFormModalOpen="isLearningGoalsDetailsFormModalOpen"
                           @closeFormModal="closeLearningGoalsFormModal"
                           modalHeader="Edit Project Learning Goals">
                    <EditProjectLearningGoals @closeFormModal="closeLearningGoalsFormModal"
                                              :itemData="learningGoalsData"/>
                </FormModal>

                <FormModal :isFormModalOpen="isChecklistFormModalOpen" @closeFormModal="closeChecklistFormModal"
                           modalHeader="Edit Project Checklist">
                    <EditProjectChecklist @closeFormModal="closeChecklistFormModal" :itemData="checklistItems"/>
                </FormModal>

                <InfoModal :isModalOpen="isConfirmationDialogOpen" @closeModal="closeConfirmationDialog"
                           :successImageURL="require('../../../assets/images/icons/success_icon.svg')"
                           :errorImageURL="require('../../../assets/images/icons/error_icon.svg')" header="Hold on!"
                           :paragraph="deactivateConfirmationParagraph"
                           :buttonTxt="deactivateButtonText" :buttonAction="deleteActivity" buttonColor="error"
                           routerLinkText="No, Cancel Action" buttonLink="/"
                           :linkAction="cancelAction" :loading="buttonLoader">
                </InfoModal>
            </div>
        </div>
    </div>
</template>

<script>
import Switch from "@/components/common/Switch.vue";
import RegularButtonBlock from "@/components/common/RegularButtonBlock.vue";
import BreadCrumbs from "@/components/common/BreadCrumbs.vue";
import {computed, onMounted, ref, watch} from "vue";
import {
    calculateWorkingDays,
    capitalizeFirstLetter,
    capitalizeFirstLetterInSentence,
    convertDateFormat,
    formatDateTime,
    formatWords,
    getSchoolId,
    getUserInitialsForName,
    getValidToken,
    hasPermission,
    truncateText
} from '@/utils/helpers'
import {API_BASE_URL} from "@/config.js";
import MessageSliderCopy from "@/components/common/MessageSliderCopy.vue";
import FormModal from "@/components/common/FormModal.vue";
import {useRoute} from "vue-router";
import UserAvatarBox2 from "@/components/common/UserAvatarBox2.vue";
import {checkAndRefreshToken} from "@/utils/auth";
import EditLearningGroup from "@/views/School_Admin/LearningGroups/EditLearningGroup.vue";
import RegularButton from "@/components/common/RegularButton.vue";
import ProjectActivities from "@/views/School_Admin/Projects/Activities/ProjectActivities.vue";
import RoundedButton from "@/components/common/RoundedButton.vue";
import CreateActivity from "@/views/School_Admin/Projects/Activities/CreateActivity.vue";
import EditActivity from "@/views/School_Admin/Projects/Activities/EditActivity.vue";
import ShowActivities from "@/views/School_Admin/Projects/Activities/ShowActivities.vue";
import AvatarForInitials from "@/components/common/AvatarForInitials.vue";
import EditProjectRegistrationDetails from "@/views/School_Admin/Projects/Partials/EditProjectRegistrationDetails.vue";
import EditProjectIdeaDetails from "@/views/School_Admin/Projects/Partials/EditProjectIdeaDetails.vue";
import EditProjectLearningGoals from "@/views/School_Admin/Projects/Partials/EditProjectLearningGoals.vue";
import EditProjectChecklist from "@/views/School_Admin/Projects/Partials/EditProjectChecklist.vue";
import getProjectActivities from "@/composables/Schools/getProjectActivities";
import InfoModal from "@/components/common/InfoModals.vue";
import getChecklist from "@/composables/getProjectChecklist";
import Comments from "@/components/common/Comments.vue";
import {useUserStore} from "@/stores/user";
import Files from "@/components/common/Files.vue";


export default {
    emits: ['show-loader', 'data-loaded'],
    components: {
        Files,
        Comments,
        InfoModal,
        EditProjectChecklist,
        EditProjectLearningGoals,
        EditProjectIdeaDetails,
        EditProjectRegistrationDetails,
        AvatarForInitials,
        ShowActivities,
        EditActivity,
        CreateActivity,
        RoundedButton,
        ProjectActivities,
        RegularButton,
        EditLearningGroup,
        UserAvatarBox2,
        FormModal,
        Switch,
        RegularButtonBlock,
        BreadCrumbs,
        MessageSliderCopy
    },
    setup(props, {emit}) {
        const {fetchChecklistData, checklistData, loading: checklistLoader} = getChecklist()
        const buttonLoader = ref(false);
        const isEditFormModalOpen = ref(false);
        const isActivityCreateFormModalOpen = ref(false);
        const isActivityEditFormModalOpen = ref(false);
        const isActivityDetailsFormModalOpen = ref(false);
        const isRegistrationDetailsFormModalOpen = ref(false);
        const isIdeaDetailsFormModalOpen = ref(false);
        const isLearningGoalsDetailsFormModalOpen = ref(false);
        const isChecklistFormModalOpen = ref(false);
        const itemData = ref(null)
        const itemName = ref('')
        const createdAt = ref('')
        const loading = ref(null)
        const checkedStatus = ref(false)
        const switchTextLabel = ref('')
        const messageText = ref("");
        const messageType = ref("");
        const displayMessage = ref(false);
        const router = useRoute()
        const projectId = router.params.id
        const accessToken = getValidToken()
        const tabs = ref([
            'Registration',
            'Idea',
            'Learning Goals',
            'Calendar',
            'Checklist',
            'Updates',
            'Files',
        ])
        const activeTab = ref(0)
        const colearnerNames = ref('')
        const learningGroupList = ref('')
        const SMEs = ref('')
        const keywords = ref('')
        const leadTeacherName = ref('')
        const {
            projectActivityData,
            fetchProjectActivities,
            loading: projectActvityLoader
        } = getProjectActivities()
        const projectActivitiesData = ref(null)
        const checklistItems = ref(null)
        const schoolId = getSchoolId()
        const fetchCommentUrl = `${API_BASE_URL}/school/${schoolId}/projects/${projectId}/comment`;
        const addCommentUrl = `${API_BASE_URL}/school/${schoolId}/projects/${projectId}/comment`;
        const deleteCommentUrl = `${API_BASE_URL}/school/${schoolId}/projects/${projectId}/comment`;
        const updateCommentUrl = `${API_BASE_URL}/school/${schoolId}/projects/${projectId}/comment`;
        const completedActivities = ref(0)
        const projectApprovalLoader = ref(false)
        const fileFetchURL = `${API_BASE_URL}/school/${schoolId}/projects/${projectId}/file`
        const fileAddURL = `${API_BASE_URL}/school/${schoolId}/projects/${projectId}/file`
        const fileDeleteURL = `${API_BASE_URL}/school/${schoolId}/projects/${projectId}/file`

        const registrationData = ref(null)
        const openRegistrationDetailsFormModal = () => {
            registrationData.value = {
                name: itemData.value.name,
                academic_year: itemData.value.academic_year,
                term: itemData.value.term,
                project_type: itemData.value.project_type,
                project_duration: itemData.value.project_duration,
                lead_teacher: itemData.value.lead_teacher,
                colearnerNames: itemData.value.supporting_teachers,
                keywords: itemData.value.key_words,
                smes: itemData.value.subject_matter,
                learning_groups: itemData.value.learning_groups,
                curriculum: itemData.value.curriculum,
                id: projectId
            }

            isRegistrationDetailsFormModalOpen.value = true
        }

        const closeRegistrationDetailsFormModal = (msg) => {
            if (msg) {
                flashAlertMessage(msg.type, msg.message)
            }

            isRegistrationDetailsFormModalOpen.value = false
            fetchItemData()
        }

        const ideaData = ref(null)
        const openIdeaDetailsFormModal = () => {
            ideaData.value = {
                aim: itemData.value.project_aim,
                description: itemData.value.description,
                webLinks: itemData.value.weblinks,
                milestones: itemData.value.milestones,
                selectedFinalProduct: itemData.value.final_product.key,
                finalProduct: itemData.value.final_product.details,
                questions: itemData.value.problem_to_solve ?? [],
                id: projectId
            }

            isIdeaDetailsFormModalOpen.value = true
        }

        const closeIdeaDetailsFormModal = (msg) => {
            if (msg) {
                flashAlertMessage(msg.type, msg.message)
            }

            isIdeaDetailsFormModalOpen.value = false
            fetchItemData()
        }

        const learningGoalsData = ref(null)
        const openLearningGoalsFormModal = () => {
            learningGoalsData.value = {
                learning_goals: itemData.value.learning_goals,
                id: projectId
            }

            isLearningGoalsDetailsFormModalOpen.value = true
        }

        const closeLearningGoalsFormModal = (msg) => {
            if (msg) {
                flashAlertMessage(msg.type, msg.message)
            }

            isLearningGoalsDetailsFormModalOpen.value = false
            fetchItemData()
        }

        watch(projectActivityData, (item) => {
            projectActivitiesData.value = item
            completedActivities.value = item.reduce((total, item) => {
                if (item.status === 'completed') {
                    total++;
                }
                return total;
            }, 0);
        })

        const openChecklistFormModal = () => {
            checklistItems.value = {
                id: projectId,
                items: checklistData,
                checklistLoader: checklistLoader,
                projectChecklistData: itemData.value.project_checklist
            }

            isChecklistFormModalOpen.value = true
        }

        const closeChecklistFormModal = (msg) => {
            if (msg) {
                flashAlertMessage(msg.type, msg.message)
            }

            isChecklistFormModalOpen.value = false
            fetchItemData()
        }

        const fetchItemData = async () => {
            const schoolId = getSchoolId()
            const apiUrl = `${API_BASE_URL}/school/${schoolId}/projects/${projectId}`;

            try {
                await checkAndRefreshToken()

                emit('show-loader')
                const response = await fetch(apiUrl, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    }
                })

                if (response.ok) {
                    const result = await response.json()
                    itemData.value = result.message

                    // console.log('result', result.message)
                } else {
                    console.log("response", response);
                    console.error("Error fetching data from the API");
                }
            } catch (error) {
                console.error('An error occured: ', error)
            }
        }

        onMounted(() => {
            fetchItemData()
            fetchProjectActivities(projectId)
            fetchChecklistData()
        })

        const finalProduct = ref('')
        const finalProductDetails = ref('')
        watch(itemData, (item) => {
            if (item) {
                itemName.value = capitalizeFirstLetter(item.name)
                const {date, time} = formatDateTime(item.created_at)
                createdAt.value = `${date}, ${time}`
                checkedStatus.value = item.is_private
                switchTextLabel.value = item.is_private ? 'Project set to Private' : 'Project set to Public'
                leadTeacherName.value = item.lead_teacher ?
                    capitalizeFirstLetter(item.lead_teacher.first_name, item.lead_teacher.last_name) : '-'
                finalProduct.value = item.final_product ? item.final_product.key : '-'
                finalProductDetails.value = item.final_product ? item.final_product.details : '-'

                setTimeout(() => {
                    keywords.value = getKeywords(item.key_words)
                    colearnerNames.value = getTeacherNames(item.supporting_teachers)
                    SMEs.value = getSMENames(item.subject_matter)
                    learningGroupList.value = getLearningGroupsMEs(item.learning_groups)

                    emit('data-loaded')
                }, 200)
            }
        })

        const selectTab = (index) => {
            activeTab.value = index;
        };

        const openEditFormModal = () => {
            isEditFormModalOpen.value = true;
        };

        const closeEditFormModal = async (msg) => {
            isEditFormModalOpen.value = false;

            if (msg) {
                flashAlertMessage(msg.type, msg.apiResponse)
            }

            await fetchItemData()
        };


        const openActivityCreateFormModal = () => {
            if (itemData.value.activities.length >= calculateWorkingDays(itemData.value.project_duration)) {
                flashAlertMessage('error', 'You have reached the maximum limit and cannot create more activities, delete an activity to proceed')
            } else {
                isActivityCreateFormModalOpen.value = true;
            }
        };

        const closeActivityCreateFormModal = async (msg) => {
            isActivityCreateFormModalOpen.value = false;

            if (msg) {
                flashAlertMessage(msg.type, msg.apiResponse)
            }

            await fetchItemData()
            await fetchProjectActivities(projectId)
        };

        const itemDataToEdit = ref(null)
        const openActivityEditFormModal = (itemDataForEditing) => {
            itemDataToEdit.value = itemDataForEditing
            isActivityEditFormModalOpen.value = true;
        };

        const closeActivityEditFormModal = async (msg) => {
            isActivityEditFormModalOpen.value = false;

            if (msg) {
                flashAlertMessage(msg.type, msg.apiResponse)
            }

            await fetchItemData()
            await fetchProjectActivities(projectId)
        };

        const itemDetails = ref(null)
        const openActivityDetailsModal = (item) => {
            itemDetails.value = item
            isActivityDetailsFormModalOpen.value = true;
        }


        const closeActivityDetailsFormModal = async (msg) => {
            isActivityDetailsFormModalOpen.value = false;

            if (msg) {
                flashAlertMessage(msg.type, msg.apiResponse)
            }

            await fetchItemData()
        };

        const getIdForActivityActions = computed(() => {
            return projectActivitiesData.value.map((item) => item.id);
        });

        const getDataForActivityDetailsView = computed(() => {
            return projectActivitiesData.value
        })

        const viewActivity = (item, itemId) => {
            // Find the item to edit
            const itemDetails = projectActivitiesData.value.find((r) => r.id === itemId);

            const data = {
                name: itemDetails.name,
                description: itemDetails.description,
                start_date: itemDetails.start_date,
                finish_date: itemDetails.finish_date,
                learning_objectives: itemDetails.learning_objectives,
                resources: itemDetails.resources,
                supporting_weblinks: itemDetails.supporting_weblinks,
                ways_to_extend: itemDetails.ways_to_extend,
                projectId: itemDetails.project_id,
                id: itemId
            }

            openActivityDetailsModal(data)
        }

        const projectDataForEditing = ref(null)
        const editActivity = (item, itemId) => {
            const itemForEditing = projectActivityData.value.find((r) => r.id === itemId);

            projectDataForEditing.value = {
                name: itemForEditing.name,
                description: itemForEditing.description,
                start_date: convertDateFormat(itemForEditing.start_date),
                finish_date: convertDateFormat(itemForEditing.finish_date),
                learning_objectives: itemForEditing.learning_objectives,
                resources: itemForEditing.resources,
                supporting_weblinks: itemForEditing.supporting_weblinks,
                ways_to_extend: itemForEditing.ways_to_extend,
                projectId: itemForEditing.project_id,
                status: itemForEditing.status,
                id: itemId
            }

            openActivityEditFormModal(projectDataForEditing.value)
        }

        const activityId = ref('null')
        const deactivateConfirmationParagraph = ref('')
        const deactivateButtonText = ref('')
        const isConfirmationDialogOpen = ref(false);
        const openConfirmationDialog = (id) => {
            activityId.value = id

            deactivateConfirmationParagraph.value = `You are about to delete this activity. Are you sure you want to continue this action?.`
            deactivateButtonText.value = `Yes, Delete Activity`
            isConfirmationDialogOpen.value = true;
        };

        const closeConfirmationDialog = () => {
            isConfirmationDialogOpen.value = false;
            fetchProjectActivities(projectId)
        };
        const deleteActivity = async () => {
            const schoolId = getSchoolId()
            const apiUrl = `${API_BASE_URL}/school/${schoolId}/activity/${activityId.value}`

            try {
                const accessToken = getValidToken()
                await checkAndRefreshToken()

                buttonLoader.value = true
                const response = await fetch(apiUrl, {
                    method: 'DELETE',
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`
                    }
                })

                if (response.ok) {
                    const result = await response.json()

                    if (result.code === 200) {
                        const msg = {
                            apiResponse: "Success, Activity Deleted",
                            type: "success",
                        }

                        flashAlertMessage(msg.type, msg.apiResponse)
                        closeConfirmationDialog()
                    } else {
                        flashAlertMessage('error', result.message)
                        console.log('error', result.message)
                    }
                } else {
                    const result = await response.json();

                    flashAlertMessage('error', result.message)
                    console.log("error", result.message);
                }
            } catch (error) {
                flashAlertMessage('error', result.message)
                console.error("An error occured:", error);
            } finally {
                buttonLoader.value = false
            }
        }


        const itemDataForEditing = ref(null)
        const editAction = () => {
            itemDataForEditing.value = {
                name: itemData.value.name,
                description: itemData.value.description,
                id: projectId
            }

            openEditFormModal()
        }

        const handleSwitchToggle = () => {
            toggleUserStatus(projectId)
        }

        const toggleUserStatus = async (id) => {
            const schoolId = getSchoolId()
            const apiUrl = `${API_BASE_URL}/school/${schoolId}/projects/${projectId}`

            try {
                await checkAndRefreshToken()

                loading.value = true
                const response = await fetch(apiUrl, {
                    method: 'PUT',
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`
                    },
                    body: JSON.stringify({
                        is_private: !checkedStatus.value
                    })
                })

                if (response.ok) {
                    const result = await response.json()

                    if (result.code === 200) {
                        flashAlertMessage("success", "Project status updated")
                        await fetchItemData()
                    } else {
                        flashAlertMessage("error", result.message)
                        console.log('error', result.message)
                    }
                } else {
                    const result = await response.json();
                    flashAlertMessage("error", result.message)
                    console.log("error", result.message);
                }
            } catch (error) {
                flashAlertMessage("error", error)
                console.error("An error occured:", error);
            } finally {
                loading.value = false
            }
        }

        const flashAlertMessage = (alertType, alertMessage) => {
            emit('flash-message', {
                alertType: alertType,
                alertMessage: alertMessage
            })
        }

        const getTeacherNames = (teachers) => {
            if (teachers == null) {
                console.log('teachers is null or undefined');
                return '';
            }

            if (teachers.length > 0) {
                const names = teachers.map((teacher) => `${capitalizeFirstLetter(teacher.first_name)} ${capitalizeFirstLetter(teacher.last_name)}`);
                return names.join(', ');
            }
        };

        const getSMENames = (smes) => {
            if (smes == null) {
                console.log('sme is null or undefined');
                return '';
            }

            if (smes.length > 0) {
                const names = smes.map((sme) => `${capitalizeFirstLetter(sme.name)} ${capitalizeFirstLetter(sme.surname)}`);
                return names.join(', ');
            }
        };

        const getKeywords = (keywords) => {
            if (keywords == null) {
                console.log('keywords is null or undefined');
                return '';
            }

            if (keywords.length > 0) {
                const names = keywords.map((keyword) => capitalizeFirstLetter(keyword));
                return names.join(', ');
            }
        };

        const getLearningGoals = (goals) => {
            if (goals == null) {
                console.log('goals is null or undefined');
                return '';
            }

            if (goals.length > 0) {
                const names = goals.map((goal) => capitalizeFirstLetter(goal));
                return names.join(', ');
            }
        };

        const getLearningGroupsMEs = (groups) => {
            if (groups == null) {
                console.log('groups is null or undefined');
                return '';
            }

            if(groups.length > 0) {
                const names = groups.map((item) => capitalizeFirstLetter(item.name));
                return names.join(', ');
            }
        };

        const showFlashMessage = (msg) => {
            flashAlertMessage(msg.type, msg.apiResponse)
        }

        const getProblemName = (item, index, isNotSaved = true) => {
            const propName = `problem_${index}`;

            if (isNotSaved) {
                return `${item[propName]}_${index}`;
            } else {
                return item[propName];
            }
        }

        const refreshActivityList = () => {
            fetchItemData()
        }

        const performAction = () => {
            deleteActivity()
        }

        const cancelAction = () => {
            closeConfirmationDialog()
        }

        const userStore = useUserStore();
        const user = ref(userStore.user)
        const userPermissions = ref(user.value.permissions)
        const checkPermission = (permission) => hasPermission(userPermissions, permission)

        const getActivityBadgeColor = (activity) => {
            if (activity.status === 'open') {
                return 'warning'
            }

            if (activity.status === 'completed') {
                return 'success'
            }
        }


        const getCompletedActivityPercentage = () => {
            const totalActivities = projectActivitiesData.value.length;
            const completedActivitiesCount = projectActivitiesData.value.filter(item => item.status === 'completed').length;

            if (totalActivities === 0) return 0; // To avoid division by zero

            const percentage = (completedActivitiesCount / totalActivities) * 100;
            return percentage.toFixed(2);
        }

        const getProjectStatusColor = () => {
            if (itemData.value.status === 'pending') {
                return 'warning'
            }

            if (itemData.value.status === 'approved') {
                return 'success'
            }
        }

        const setProjectToApproved = async () => {
            const schoolId = getSchoolId()
            const apiUrl = `${API_BASE_URL}/school/${schoolId}/projects/${projectId}`

            try {
                await checkAndRefreshToken()

                projectApprovalLoader.value = true
                const response = await fetch(apiUrl, {
                    method: 'PUT',
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`
                    },
                    body: JSON.stringify({
                        status: "approved"
                    })
                })

                if (response.ok) {
                    const result = await response.json()

                    if (result.code === 200) {
                        flashAlertMessage("success", "Project has been approved!")
                        await fetchItemData()
                    } else {
                        flashAlertMessage("error", result.message)
                        console.log('error', result.message)
                    }
                } else {
                    const result = await response.json();
                    flashAlertMessage("error", result.message)
                    console.log("error", result.message);
                }
            } catch (error) {
                flashAlertMessage("error", error)
                console.error("An error occured:", error);
            } finally {
                projectApprovalLoader.value = false
            }
        }

        const showAlertForFiles = (feedback) => {
            if (feedback.type === 'success') {
                flashAlertMessage('success', feedback.message)
            } else {
                flashAlertMessage('error', feedback.message)
            }
        }

        const showFilesLoader = (loading) => {
            if (loading === 'show-files-loader') {
                emit('show-loader')
            }

            if (loading === 'files-data-loaded') {
                emit('data-loaded')
            }
        }

        return {
            showAlertForFiles,
            showFilesLoader,
            loading,
            projectApprovalLoader,
            setProjectToApproved,
            getProjectStatusColor,
            getActivityBadgeColor,
            getCompletedActivityPercentage,
            getLearningGoals,
            getProblemName,
            showFlashMessage,
            itemData,
            itemName,
            createdAt,
            itemDataToEdit,
            checkedStatus,
            switchTextLabel,
            handleSwitchToggle,
            messageText,
            messageType,
            displayMessage,
            editAction,
            openRegistrationDetailsFormModal,
            closeEditFormModal,
            isEditFormModalOpen,
            itemDataForEditing,
            selectTab,
            fetchItemData,
            tabs,
            activeTab,
            capitalizeFirstLetterInSentence,
            colearnerNames,
            openIdeaDetailsFormModal,
            openLearningGoalsFormModal,
            projectId,
            openActivityCreateFormModal,
            closeActivityEditFormModal,
            isActivityCreateFormModalOpen,
            closeActivityCreateFormModal,
            openActivityEditFormModal,
            isActivityEditFormModalOpen,
            calculateWorkingDays,
            capitalizeFirstLetter,
            isActivityDetailsFormModalOpen,
            closeActivityDetailsFormModal,
            openActivityDetailsModal,
            itemDetails,
            getUserInitialsForName,
            keywords,
            refreshActivityList,
            isRegistrationDetailsFormModalOpen,
            isIdeaDetailsFormModalOpen,
            isLearningGoalsDetailsFormModalOpen,
            closeRegistrationDetailsFormModal,
            closeIdeaDetailsFormModal,
            closeLearningGoalsFormModal,
            registrationData,
            ideaData,
            learningGoalsData,
            isChecklistFormModalOpen,
            openChecklistFormModal,
            closeChecklistFormModal,
            checklistData,
            leadTeacherName,
            finalProductDetails,
            finalProduct,
            SMEs,
            learningGroupList,
            projectActivitiesData,
            getIdForActivityActions,
            getDataForActivityDetailsView,
            viewActivity,
            editActivity,
            deleteActivity,
            openConfirmationDialog,
            closeConfirmationDialog,
            isConfirmationDialogOpen,
            deactivateConfirmationParagraph,
            deactivateButtonText,
            cancelAction,
            buttonLoader,
            performAction,
            projectActvityLoader,
            truncateText,
            formatWords,
            checklistItems,
            fetchCommentUrl,
            addCommentUrl,
            deleteCommentUrl,
            hasPermission: checkPermission,
            completedActivities,
            fileFetchURL,
            fileAddURL,
            fileDeleteURL,
            updateCommentUrl
        }

    }
};
</script>

<style scoped>

.badge {
    font-size: 16px;
}

.detail-content-group > p {
    margin: 0;
}

.border-bottom {
    border-bottom: 2px solid #F5F6FA;
}

p {
    color: #9a9a9a;
    font-size: 16px;
}

.details-content {
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding: 30px 30px 0 30px;
}

.detail-content-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.details-content:last-child {
    padding-bottom: 30px;
}

.details-content:first-child {
    border-bottom: 1px solid #e9eaf3;
    padding-bottom: 30px;
}

.details-content > img {
    margin-right: 15px;
    margin-top: 5px;
}

.details-content > img {
    margin-right: 15px;
    margin-top: 5px;
}

.details-content .details > p {
    margin-bottom: 0;
}

.logo-text > img {
    margin-right: 15px;
    margin-top: 2px;
}

.logo-text > p {
    color: #333333;
}

.detail-group > p,
.result-text,
.data-title {
    color: #333;
}

.item > .count {
    color: #2f5bcf;
}

.buttons-content {
    padding: 0 15px 10px 15px;
}

.page-title-gray {
    background-color: #F8FAFC;
    font-size: 1.25rem;
    margin: 0;
    line-height: 75px;
    color: #323a46;
    padding: 20px 50px 0 50px;
}

.tab-container {
    display: flex;
    background-color: #F8FAFC;
    padding-left: 50px;
}

.tab {
    list-style-type: none;
    margin: 0 20px;
    text-align: center;
    cursor: pointer;
    padding: 10px;
}

.tab:first-child {
    margin: 0;
    padding-left: 0;
}

.active-tab {
    border-bottom: 2px solid #0d44b2;
}

.mr-50 {
    margin-right: 50px;
}

.mr-30 {
    margin-right: 30px;
}

.table td, th {
    padding: 10px
}

.table-borderless thead {
    color: #9a9a9a;
}

.table-borderless thead {
    background-color: #f5f6fa;
}

td {
    vertical-align: middle !important;
}

.table-borderless thead {
    background-color: #dadbdd;
}

.table-borderless thead {
    color: #333;
}

.table-borderless td:last-child {
    border-bottom: none;
}


.table-borderless th:first-child {
    border-top-left-radius: 5px;
}

.table-borderless th:last-child {
    border-top-right-radius: 5px;
}

.approve-project-section {
    border-top: 1px solid #e9eaf3;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
</style>