<template>
    <div class="row">
        <div class="col-12">
            <div class="page-title-box">
                <BreadCrumbs/>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <h1>TBD</h1>
        </div>
    </div>
</template>
<script>
import BreadCrumbs from "@/components/common/BreadCrumbs.vue";

export default {
    props: {},
    components: {BreadCrumbs},
    setup() {

    }
}
</script>
<style></style>