<template>
    <div v-if="currentPage === 1">
        <SingleSelectField labelText="Select School" :options="schoolSelectOptions"
                           v-model="selectedSchool" class="mb-50"
                           :loading="schoolDropdownLoader" selectId="schoolOptions"
                           :selectedItem="selectedSchool"/>
    </div>

    <div v-if="currentPage === 2">

        <div class="row mb-30">
            <div class="col-lg-6 col-sm-12">
                <TextInputField :config="{
                    class: 'form-control-gray',
                    placeholder: 'Enter First Name',
                    inputFieldType: 'text',
                }" labelText="First Name" :errorMessage="firstNameError" v-model="firstName" :isRequired="true"/>
            </div>

            <div class="col-lg-6 col-sm-12">
                <TextInputField :config="{
                    class: 'form-control-gray',
                    placeholder: 'Enter Last Name',
                    inputFieldType: 'text',
                }" labelText="Last Name" :errorMessage="lastNameError" v-model="lastName" :isRequired="true"/>
            </div>
        </div>

        <div class="row mb-30">
            <div class="col-12">
                <SingleSelectField labelText="Gender" :options="genderSelectOptions" v-model="selectedGender"
                                   selectId="genderOptions" :selectedItem="selectedGender" :loading="genderLoader"
                                   :isRequired="true"/>
            </div>
        </div>

        <div class="row mb-50">
            <div class="col-lg-6 col-sm-12">
                <TextInputField :config="{
                        class: 'form-control-gray',
                        placeholder: 'DD - MM - YYYY',
                        inputFieldType: 'date',
                    }" labelText="Date of Birth" v-model="dob" :isRequired="true"
                                :errorMessage="dobError"/>
            </div>

            <div class="col-lg-6 col-sm-12">
                <TextInputField :config="{
                    class: 'form-control-gray',
                    placeholder: 'Enter Age',
                    inputFieldType: 'number',
                }" labelText="Age" v-model="age" :errorMessage="ageError" :isRequired="true"/>
            </div>
        </div>
    </div>

    <div v-if="currentPage === 3">
        <div class="row mb-30">
            <div class="col-12">
                <TextInputField :config="{
                    class: 'form-control-gray',
                    placeholder: 'Enter Contact Number',
                    inputFieldType: 'tel',
                }" labelText="Contact Number" v-model="msisdn" :isRequired="true"/>
            </div>
        </div>

        <div class="row mb-30">
            <div class="col-12">
                <TextInputField :config="{
                        class: 'form-control-gray',
                        placeholder: 'Enter Email Address',
                        inputFieldType: 'email'}"
                                labelText="Contact Email Address" v-model="email" :isRequired="true"/>
            </div>
        </div>

        <div class="row mb-30">
            <div class="col-lg-6 col-sm-12">
                <SingleSelectField labelText="Country of Residence" :options="countrySelectOptions"
                                   v-model="selectedCountry"
                                   :loading="countryDropdownLoader" selectId="countryOptions" :isRequired="true"
                                   :selectedItem="selectedCountry" @change="handleCountrySelection"/>
            </div>

            <div class="col-lg-6 col-sm-12">
                <SingleSelectField labelText="State of Residence" :options="stateSelectOptions" v-model="selectedState"
                                   :loading="statesDropdownLoader" :isRequired="true"
                                   selectId="stateOptions" :selectedItem="selectedState"/>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <TextAreaInputField :config="{
                    class: 'form-control-gray mb-30',
                    placeholder: 'Enter Address',
                    inputFieldType: 'address',}" labelText="Residential Address" errorType="addressError"
                                    v-model="address"/>
            </div>
        </div>

    </div>


    <div class="mt-20 table-pagination">
        <ul class="pagination pagination-rounded">
            <li v-for="page in pages" :key="page" :class="{ active: currentPage === page }" class="page-item">
                <a href="#" class="page-link" @click="changePage(page)">{{ page }}</a>
            </li>
        </ul>

        <RoundedButton :buttonText="currentPage < pages ? 'Next' : 'Submit'" class="custom-button"
                       @click="nextPage" :loading="buttonLoading"/>
    </div>

</template>
<script>
import TextInputField from "@/components/common/TextInputField.vue";
import SimpleDropdown from "@/components/common/SimpleDropdown.vue";
import TextAreaInputField from "@/components/common/TextAreaInputField.vue";
import RoundedButton from "@/components/common/RoundedButton.vue";
import getSchools from "@/composables/getSchools";
import {onMounted, ref, watch} from "vue";
import getCountries from "@/composables/getCountries";
import getStates from "@/composables/getStates";
import {capitalizeFirstLetter, getValidToken} from '@/utils/helpers'
import {checkAndRefreshToken} from '@/utils/auth'
import getGender from "@/composables/getGender";
import {API_BASE_URL} from "@/config";
import ImageUploadInput from "@/components/common/ImageUploadInput.vue";
import SingleSelectField from "@/components/common/SingleSelectField.vue";

export default {
    components: {
        SingleSelectField,
        TextInputField,
        SimpleDropdown,
        TextAreaInputField,
        RoundedButton,
        ImageUploadInput
    },
    setup(props, {emit}) {
        const buttonLoading = ref(false)
        const message = ref('')
        const {
            fetchSchoolsData, loading: schoolDropdownLoader, schoolsData
        } = getSchools()
        const {fetchCountriesData, countriesList, loading: countryDropdownLoader} = getCountries()
        const {fetchStatesData, statesList, loading: statesDropdownLoader} = getStates()
        const {fetchGender, genderList, loading: genderLoader} = getGender()
        const currentPage = ref(1);
        const schoolSelectOptions = ref([])
        const genderSelectOptions = ref([])
        const countrySelectOptions = ref([])
        const stateSelectOptions = ref([])
        const selectedSchool = ref('')
        const selectedGender = ref('')
        const selectedCountry = ref('')
        const selectedState = ref('')
        const pages = 3
        const firstName = ref('')
        const lastName = ref('')
        const msisdn = ref('')
        const email = ref('')
        const dob = ref('')
        const age = ref('')
        const address = ref('')
        const countryIdMap = ref({});
        const firstNameError = ref('')
        const lastNameError = ref('')
        const dobError = ref('')
        const ageError = ref('')
        const imageFile = ref('')

        onMounted(() => {
            fetchSchoolsData(1, 200)
        })

        const updateGenderOptions = (newData) => {
            genderSelectOptions.value = newData.map(item => ({
                label: capitalizeFirstLetter(item.label), value: item.value
            }))

            if (genderSelectOptions.value.length > 0) {
                selectedGender.value = genderSelectOptions.value[0].value;
            }
        }

        watch(genderList, (newData) => {
            updateGenderOptions(newData)
        })

        const updateSchoolOptions = (newData) => {
            schoolSelectOptions.value = newData.map(item => ({
                label: capitalizeFirstLetter(item.name), value: item.id
            }))

            if (schoolSelectOptions.value.length > 0) {
                selectedSchool.value = schoolSelectOptions.value[0].value;
            }
        }

        watch(schoolsData, (newData) => {
            updateSchoolOptions(newData)
        })

        const validatePage2 = () => {
            let isValid = true

            firstNameError.value = ''
            lastNameError.value = ''
            dobError.value = ''
            ageError.value = ''

            if (firstName.value.length === 0) {
                firstNameError.value = 'Enter a valid first name'
                isValid = false
            }


            if (lastName.value.length === 0) {
                lastNameError.value = 'Enter a valid last name'
                isValid = false
            }

            if (dob.value.length === 0) {
                dobError.value = 'Enter a valid date of birth'
                isValid = false
            }

            if (age.value.length === 0) {
                ageError.value = 'Enter a valid age'
                isValid = false
            }

            return isValid
        }


        const nextPage = () => {
            if (currentPage.value === 1) {
                if (currentPage.value < pages) {
                    currentPage.value++;
                }

                fetchGender()
            } else if (currentPage.value === 2) {
                if (validatePage2() && currentPage.value < pages) {
                    currentPage.value++;
                } else {
                    console.log('Form validation failed')
                }
            } else if (currentPage.value === 3 && validatePage3()) {
                saveNewItem()
            }

        };

        watch(currentPage, (newData) => {
            if (newData === 1) {
                fetchSchoolsData(1, 200)
            } else if (newData === 2) {
                fetchGender()
            } else {
                fetchCountriesData()
            }
        })

        const changePage = (page) => {
            if (currentPage.value === 1) {
                if (page === 3) {
                    currentPage.value = 2
                    validatePage2()
                } else {
                    currentPage.value = page;
                }
            } else if (currentPage.value === 2) {
                if (page === 3 && validatePage2()) {
                    currentPage.value = page;
                } else if (page === 1) {
                    currentPage.value = page;
                }
            } else {
                currentPage.value = page;
            }
        }

        // COUNTRIES
        const handleCountrySelection = () => {
            const selectedCountryId = countryIdMap.value[selectedCountry.value]
            fetchStatesData(selectedCountryId)
        }

        // Update the country dropdown when the modal is opened
        const updateCountryOptions = (newData) => {
            countrySelectOptions.value = newData.map(country => ({
                label: country.country_name, value: country.country_name
            }))

            countryIdMap.value = newData.reduce((map, country) => {
                map[country.country_name] = country.id;
                return map;
            }, {});

            if (countrySelectOptions.value.length > 0) {
                selectedCountry.value = 'Nigeria'
                handleCountrySelection()
            }
        }

        watch(countriesList, (newData) => {
            updateCountryOptions(newData)
        })

        // STATES
        // Update the states options
        const updateStatesOptions = (newData) => {
            stateSelectOptions.value = newData.map(state => ({
                label: state.state_name, value: state.state_name
            }))

            if (stateSelectOptions.value.length > 0) {
                selectedState.value = stateSelectOptions.value[0].value;
            }
        }

        watch(statesList, (newData) => {
            updateStatesOptions(newData)
        })

        // OTHERS
        const showLoaders = () => {
            buttonLoading.value = true;
        };

        const hideLoaders = () => {
            buttonLoading.value = false;
        };

        const apiUrl = `${API_BASE_URL}/student/add-student`
        const saveNewItem = async () => {
            const newItem = {
                school_id: selectedSchool.value,
                first_name: firstName.value,
                last_name: lastName.value,
                gender: selectedGender.value,
                dob: dob.value,
                age: age.value,
                email: email.value,
                msisdn: msisdn.value,
                country: selectedCountry.value,
                state: selectedState.value,
                address: address.value,
                profile_image: imageFile.value,
                middle_name: '-',
                why_use_us: '-',
                interest: '-'
            }

            try {
                showLoaders()
                await checkAndRefreshToken()
                const accessToken = getValidToken()

                const response = await fetch(apiUrl, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    },
                    body: JSON.stringify(newItem)
                })


                if (response.ok) {
                    const result = await response.json();

                    if (result.code === 200) {
                        message.value = result.message;

                        const itemFullName = capitalizeFirstLetter(`${newItem.first_name} ${newItem.last_name}`)
                        emit("closeFormModal", itemFullName);
                    } else {
                        console.log("error", result.message);
                    }
                } else {
                    const result = await response.json();
                    console.log("error", result.message);
                }
            } catch (error) {
                console.error("An error occured:", error);
            } finally {
                hideLoaders();
            }
        }

        const handleImageFileUpdate = (newVal) => {
            imageFile.value = newVal;
        }

        return {
            buttonLoading,
            saveNewItem,
            currentPage,
            schoolSelectOptions,
            selectedSchool,
            schoolDropdownLoader,
            nextPage,
            pages,
            changePage,
            firstName,
            lastName,
            genderSelectOptions,
            selectedGender,
            msisdn,
            email,
            countrySelectOptions,
            selectedCountry,
            countryDropdownLoader,
            statesDropdownLoader,
            handleCountrySelection,
            selectedState,
            stateSelectOptions,
            address,
            genderLoader,
            dob,
            age,
            firstNameError,
            lastNameError,
            dobError,
            ageError,
            imageFile,
            handleImageFileUpdate
        }
    }
}
</script>
<style scoped>
.table-pagination {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.page-item.active .page-link {
    border-color: #2f5bcf;
    z-index: 3;
    color: #fff;
}

.page-item:first-child .page-link {
    margin-left: 0;
}

.pagination-rounded .page-link {
    border-radius: 30px !important;
    margin: 0 3px;
    border: none;
}

.page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.9rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #323a46;
    background-color: #fff;
    border: 1px solid #dee2e6;
}
</style>