import {API_BASE_URL} from "@/config.js";
import {
    capitalizeFirstLetter,
    dd, formatCurrency,
    formatDateTime,
    getUserInitials,
    getUserInitialsForName,
    getValidToken, truncateText
} from "@/utils/helpers.js";
import {computed, ref} from "vue";
import {checkAndRefreshToken} from '@/utils/auth';

const loading = ref(false);
const apiUrl = `${API_BASE_URL}/transaction/all`;
const transactionsPayload = ref(null);
const transactionsData = ref([])

const fetchTransactionData = async (page, perPage) => {
    try {
        await checkAndRefreshToken()

        const accessToken = getValidToken()
        if (!accessToken) {
            console.error("Access token not available.");
            return;
        }

        loading.value = true;
        const apiUrlWithParams = `${apiUrl}?page=${page}&per_page=${perPage}`

        const response = await fetch(apiUrlWithParams, {
            headers: {
                Authorization: `Bearer ${accessToken}`, // Include the access token in the headers
            },
        });

        if (response.ok) {
            const result = await response.json();

            if (result.code === 200) {
                transactionsPayload.value = result.data
                transactionsData.value = result.data.results.transactions

            } else {
                console.log("error", result.message);
            }
        } else {
            console.log("response", response);
            console.error("Error fetching data from the API");
        }
    } catch (error) {
        console.error("An error occured:", error);
    } finally {
        loading.value = false;
    }
};


const formattedTransactionData = computed(() => {
    // Map and format the data
    return transactionsData.value.map((item) => {

        // Format the created at
        const {date, time} = formatDateTime(item.created);

        const formattedTransaction = {
            // transaction_reference: 'TXN-1234',
            createdAt: date,
            amount: formatCurrency(item.amount),
            payer: capitalizeFirstLetter(item.payer) || '-',
            status: capitalizeFirstLetter(item.status),
        };

        return {
            ...formattedTransaction,
        };
    });
});

const getTransactions = () => {
    return {transactionsPayload, fetchTransactionData, loading, formattedTransactionData, transactionsData}
}

export default getTransactions