<template>
    <form @submit.prevent="updateItem">
        <div class="row mb-30">
            <div class="col-lg-6 col-sm-12">
                <TextInputField :config="{
                    class: 'form-control-gray',
                    placeholder: 'Enter Name',
                    inputFieldType: 'text',
                }" labelText="Name" errorType="nameError" v-model="name"/>
            </div>

            <div class="col-lg-6 col-sm-12">
                <SingleSelectField labelText="Gender" :options="genderSelectOptions" v-model="selectedGender"
                                   selectId="genderOptions" :selectedItem="selectedGender" :loading="genderLoader"/>
            </div>
        </div>

        <div class="row mb-30">
            <div class="col-12">
                <TextAreaInputField :config="{
                class: 'form-control-gray',
                placeholder: 'Enter Address',
                inputFieldType: 'address',
            }" labelText="Residential Address" errorType="addressError" v-model="address"/>
            </div>
        </div>

        <div class="row mb-50">
            <div class="col-12">
                <SingleSelectField labelText="Role on Portal" :options="rolesSelectOptions" v-model="selectedRole"
                                   class="mb-30"
                                   :loading="dropdownLoader" :isRequired="true"
                                   selectId="roleOptions" :selectedItem="selectedRole"/>
            </div>
        </div>


        <RoundedButton buttonText="Submit" class="custom-button" buttonType="submit" :loading="buttonLoading"/>

    </form>
</template>
<script>
import TextInputField from "@/components/common/TextInputField.vue";
import SimpleDropdown from "@/components/common/SimpleDropdown.vue";
import TextAreaInputField from "@/components/common/TextAreaInputField.vue";
import RoundedButton from "@/components/common/RoundedButton.vue";
import {onMounted, ref, watch} from "vue";
import {capitalizeFirstLetter, getSchoolId, getValidToken} from '@/utils/helpers'
import getGender from "@/composables/getGender";
import {checkAndRefreshToken} from "@/utils/auth";
import {API_BASE_URL} from "@/config";
import getSchoolRoles from "@/composables/Schools/getSchoolRoles";
import SingleSelectField from "@/components/common/SingleSelectField.vue";


export default {
    props: {
        itemData: {
            type: Object,
            required: true
        }
    },
    components: {
        SingleSelectField,
        TextInputField,
        SimpleDropdown,
        TextAreaInputField,
        RoundedButton,
    },
    setup(props, {emit}) {
        const buttonLoading = ref(false)
        const {fetchGender, genderList, loading: genderLoader} = getGender()
        const {fetchRolesData, rolesData, loading: dropdownLoader} = getSchoolRoles()
        const genderSelectOptions = ref([])
        const rolesSelectOptions = ref([])
        const selectedGender = ref()
        const selectedRole = ref(props.itemData.role)
        const name = ref(props.itemData.name)
        const address = ref(props.itemData.address)

        onMounted(() => {
            fetchGender()
            fetchRolesData()

            setTimeout(() => {
                selectedGender.value = props.itemData.gender
                selectedRole.value = props.itemData.role
            }, 1000)
        })

        const updateGenderOptions = (newData) => {
            console.log('item', newData)
            genderSelectOptions.value = newData.map(item => ({
                label: capitalizeFirstLetter(item.label),
                value: item.value
            }))
        }

        watch(genderList, (newData) => {
            updateGenderOptions(newData)
        })

        const updateRoleOptions = (newData) => {
            rolesSelectOptions.value = newData.map(role => ({
                label: capitalizeFirstLetter(role.name),
                value: role.id
            }));
        }

        watch(rolesData, (newData) => {
            updateRoleOptions(newData)
        })


        // OTHERS
        const showLoaders = () => {
            buttonLoading.value = true;
        };

        const hideLoaders = () => {
            buttonLoading.value = false;
        };

        const schoolId = getSchoolId()
        const apiUrl = `${API_BASE_URL}/school/${schoolId}/update-school-admin?user_id=${props.itemData.id}`

        const updateItem = async () => {
            const item = {
                name: name.value,
                gender: selectedGender.value,
                role: selectedRole.value,
                residence: address.value,
            }

            // console.log('item', item)

            try {
                showLoaders()
                await checkAndRefreshToken()
                const accessToken = getValidToken()

                const response = await fetch(apiUrl, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    },
                    body: JSON.stringify(item)
                })


                if (response.ok) {
                    const result = await response.json();

                    if (result.code === 200) {

                        const msg = {
                            apiResponse: "Admin details updated successfully",
                            type: "success"
                        }

                        emit("closeFormModal", msg);
                        emit("refreshPage");
                    } else {
                        console.log("error", result.message);
                    }
                } else {
                    const result = await response.json();
                    console.log("error", result.message);
                }
            } catch (error) {
                console.error("An error occured:", error);
            } finally {
                hideLoaders();
            }
        }

        return {
            buttonLoading,
            updateItem,
            name,
            genderSelectOptions,
            selectedGender,
            address,
            genderLoader,
            selectedRole,
            rolesSelectOptions,
            dropdownLoader
        }
    }
}
</script>