import {ref} from "vue";

const genderList = ref([])
const loading = ref(false);


const fetchGender = () => {
    loading.value = true

    setTimeout(() => {
        genderList.value = [
            {label: "Male", value: 'Male'},
            {label: "Female", value: 'Female'}
        ];

        loading.value = false
    }, 1000)

}

const getGender = () => {
    return {fetchGender, genderList, loading}
}
export default getGender