import {API_BASE_URL} from "@/config.js";
import {
    capitalizeFirstLetter,
    getSchoolId,
    getUserInitialsForName,
    getValidToken,
    truncateText
} from "@/utils/helpers.js";
import {computed, ref} from "vue";
import {checkAndRefreshToken} from '@/utils/auth';

const loading = ref(false);
const projectActivityData = ref([]);

const fetchProjectActivities = async (projectId, page, perPage) => {
    const schoolId = getSchoolId()
    const apiUrl = `${API_BASE_URL}/school/${schoolId}/project/${projectId}`;

    try {
        await checkAndRefreshToken()

        const accessToken = getValidToken()
        if (!accessToken) {
            console.error("Access token not available.");
            return;
        }

        loading.value = true;
        const apiUrlWithParams = `${apiUrl}?page=${page}&per_page=${perPage}`

        const response = await fetch(apiUrlWithParams, {
            headers: {
                Authorization: `Bearer ${accessToken}`, // Include the access token in the headers
            },
        });

        if (response.ok) {
            const result = await response.json();

            if (result.code === 200) {
                projectActivityData.value = result.data

                // console.log('projectActivityData.value', projectActivityData.value)
            } else {
                console.log("error", result.message);
            }
        } else {
            console.log("response", response);
            console.error("Error fetching data from the API");
        }
    } catch (error) {
        console.error("An error occurred:", error);
    } finally {
        loading.value = false;
    }
};

const formattedActivityData = computed(() => {
    // Map and format the data
    return projectActivityData.value.map((item) => {

        const formattedActivity = {
            user: {
                name: capitalizeFirstLetter(item.name),
                initials: getUserInitialsForName(item.name),
                avatarColor: 'warning'
            },
            description: truncateText(item.description, 50),
            start_date: truncateText(item.start_date, 16, false),
            finish_date: truncateText(item.finish_date, 16, false),
        };

        return {
            ...formattedActivity,
        };
    });
});


const getProjectActivities = () => {
    return {projectActivityData, fetchProjectActivities, loading, formattedActivityData}
}

export default getProjectActivities