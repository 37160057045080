<template>
    <div class="row logo-box-content">
        <div class="col-lg-2 col-sm-12 p-0 image-container">
            <div class="image-input">
                <label class="image-preview">

                    <span class="circle-content" :class="{ 'has-image': selectedImage }">
                        <img v-if="selectedImage" :src="selectedImage" alt="Selected" class="selected-image"/>
                        <img src="../../../assets/images/icons/camera_icon.svg" alt="camera icon" class="camera-icon" v-else>
                    </span>

                    <input ref="fileInput" type="file" accept=".png, .jpg" @change="handleFileChange"
                           style="display: none"/>

                </label>
            </div>
        </div>

        <div class="col-lg-10 col-sm-12 content-box">
            <img src="@/assets/images/icons/upload_image_icon.png"
                 height="30" width="30"
                 alt="Upload Image">

            <h5 class="bold text-muted">Click here to upload your image</h5>
        </div>
    </div>

    <div class="button-container">
        <RegularButton
            buttonText="Cancel"
            class="btn white btn-outline-secondary"
            @clicked="openChangeImageModal"/>

        <RegularButton
            buttonText="Save Changes"
            class="custom-button"
            @clicked="openChangeImageModal"/>
    </div>
</template>
<script>
import ImageUploadInput from "@/components/common/ImageUploadInput.vue";
import {ref} from "vue";
import {compressImage} from "@/utils/helpers";
import RegularButton from "@/components/common/RegularButton.vue";

export default {
    props: {},
    components: {RegularButton, ImageUploadInput},
    setup() {
        const selectedImage = ref('')

        const handleFileChange = async (event) => {
            const fileInput = event.target;
            const file = fileInput.files[0];

            if (file && (file.type === 'image/png' || file.type === 'image/jpeg')) {
                try {
                    // Set the compressed image as the selected image
                    selectedImage.value = await compressImage(file, 200, 200, 0.5);
                    // console.log('file.name', selectedImage.value)

                    // emit("update:imageFile", selectedImage.value); // Emit the file name to the parent component
                    // emit('imageFileUpdated', selectedImage.value);

                } catch (error) {
                    console.error('Error compressing image: ', error)
                } finally {
                    // Reset the value to allow selecting the same file again
                    fileInput.value = '';
                }
            } else {
                // Handle invalid file type
                console.error('Invalid file type. Please select a PNG or JPEG image.');
            }
        };

        return {
            selectedImage,
            handleFileChange
        }
    }
}
</script>
<style scoped>
.content-box {
    border: 0.5px solid #B8BCCA;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 50px 0;
}

.image-preview {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 0.5px solid #B8BCCA;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
}

.circle-content {
    position: relative;
    width: 100%;
    height: 100%;
}

.selected-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.camera-icon {
    position: absolute;
    top: 50%;
    bottom: 50%;
    color: #333;
    height: 25px;
    width: 25px;
    transform: translate(65%, -50%);
}

.logo-box-content {
    padding: 0 1rem 2rem 1rem;
    border-bottom: 0.5px solid #B8BCCA;
}

.button-container {
    display: flex;
    justify-content: space-between;
    padding-top: 1.5rem;
}
</style>