<template>
    <form @submit.prevent="updateAdminPassword">
        <div class="row">
            <div class="col-12 mb-30">
                <TextInputField :config="{
                    class: 'form-control-gray',
                    placeholder: 'Enter Password',
                    inputFieldType: 'password'
                }" labelText="Password" :errorMessage="passwordError" v-model="password"/>
            </div>

            <div class="col-12 mb-50">
                <TextInputField :config="{
                    class: 'form-control-gray',
                    placeholder: 'Confirm Password',
                    inputFieldType: 'password'
                }" labelText="Confirm Password" :errorMessage="confirmPasswordError" v-model="confirmPassword"/>
            </div>

            <p class="error">{{ passwordMismatchError }}</p>
        </div>

        <RoundedButton buttonText="Update Password" class="custom-button" buttonType="submit" :loading="buttonLoading"/>

    </form>
</template>
<script>
import TextInputField from "@/components/common/TextInputField.vue";
import RoundedButton from "@/components/common/RoundedButton.vue";
import {ref} from "vue";
import {checkAndRefreshToken} from "@/utils/auth";
import {getValidToken} from "@/utils/helpers";
import {API_BASE_URL} from "@/config";

export default {
    props: {
        userData: {
            type: Object,
            required: true
        }
    },
    components: {
        TextInputField,
        RoundedButton
    },
    setup(props, {emit}) {
        const password = ref('')
        const confirmPassword = ref('')
        const passwordError = ref('')
        const confirmPasswordError = ref('')
        const passwordMismatchError = ref('')
        const message = ref('')
        const buttonLoading = ref(false)

        const validateForm = () => {
            let isValid = true

            passwordError.value = ''
            confirmPasswordError.value = ''

            if (password.value.length === 0) {
                passwordError.value = 'Enter a password'
                isValid = false
            }

            if (confirmPassword.value.length === 0) {
                confirmPasswordError.value = 'Re-enter the password'
                isValid = false
            }

            if (password.value !== confirmPassword.value) {
                passwordMismatchError.value = 'The passwords do not match'
                isValid = false
            }

            return isValid
        }
        const updateAdminPassword = async () => {
            if (validateForm()) {
                try {
                    buttonLoading.value = true
                    await checkAndRefreshToken()
                    const accessToken = getValidToken()
                    const apiUrl = `${API_BASE_URL}/auth/set-password`

                    // console.log('apiUrl', apiUrl)

                    const response = await fetch(apiUrl, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${accessToken}`,
                        },
                        body: JSON.stringify({
                            email: props.userData.email,
                            password: password.value
                        })
                    })


                    if (response.ok) {
                        const result = await response.json();

                        if (result.code === 200) {
                            message.value = result.message;
                            const msg = {
                                apiResponse: "Password updated successfully!",
                                type: "success",
                            }

                            emit("closeFormModal", msg);
                        } else {
                            console.log("code error", result.message);
                            const msg = {
                                apiResponse: result.message,
                                type: "success",
                            }

                            emit("message", msg);
                        }
                    } else {
                        const result = await response.json();
                        console.log("ok error", result.message);
                    }
                } catch (error) {
                    console.error("An error occured:", error);
                } finally {
                    buttonLoading.value = false
                }
            }
        }

        return {
            updateAdminPassword,
            password,
            confirmPassword,
            passwordMismatchError,
            confirmPasswordError,
            passwordError,
            buttonLoading
        }
    }
}
</script>
<style scoped>
.error {
    color: #f1556c !important;
}
</style>