<template>
    <div class="row">
        <div class="col-12">
            <form @submit.prevent="searchForResult">
                <TextInputFieldVue :config="{
                        class: 'form-control-gray mb-10',
                        placeholder: 'Enter project search words',
                        inputFieldType: 'text',
                    }" labelText="Project Name" v-model="searchTerm" :isRequired="true" :shouldAutofocus="true"/>

                <RegularButton buttonText="Search" class="btn btn-blue" color="yellow" buttonType="submit"
                               :loading="loading"/>
            </form>

            <div v-if="searchResults.length > 0">
                <h2 class="mt-50">Search Results</h2>

                <div class="card card-project" v-for="project in searchResults" :key="project">
                    <div class="card-body">
                        <h5 class="card-title mb-1 bold">{{ capitalizeFirstLetter(project.name) }}</h5>

                        <p class="card-text mb-0">
                            {{ project.description }}
                        </p>

                        <div>
                            <span class="badge badge-blue mr-1 mb-10" v-for="word in project.key_words" :key="word">
                                {{ word }}
                            </span>
                        </div>

                        <a href="javascript:void(0);" class="btn btn-outline-dark btn-sm waves-effect"
                           @click="viewAction(project)">
                            View Project Details
                        </a>
                    </div>
                </div>
            </div>

            <div v-if="searchPerformed && searchResults.length === 0">
                <h2 class="mt-50">No Results Found</h2>
            </div>
        </div>


        <!-- Full width modal content -->
        <div class="modal" v-if="isModalOpen">
            <div class="modal-dialog modal-full-width">
                <div class="modal-content">
                    <div class="modal-body">
                        <h1>{{ capitalizeFirstLetter(projectDetails.term) }} - {{ projectDetails.academic_year }}</h1>
                        <h3 class="mb-30">{{ capitalizeFirstLetter(projectDetails.name) }}</h3>

                        <div class="table-responsive">
                            <table class="table table-bordered table-striped">

                                <tr>
                                    <td class="bold">Overview</td>
                                    <td>
                                        {{ capitalizeFirstLetter(projectDetails.description) }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="bold">Aim</td>
                                    <td>
                                        {{ capitalizeFirstLetter(projectDetails.project_aim) }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="bold">Guiding Questions</td>
                                    <td>
                                        <ul class="mb-0">
                                            <li v-for="(item, index) in projectDetails.problem_to_solve" :key="item">
                                                {{ getProblemName(item, index + 1, false) }}
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="bold">Project Type</td>
                                    <td>
                                        {{ capitalizeFirstLetter(projectDetails.project_type) }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="bold">Keywords</td>
                                    <td>
                                        {{ getKeywords(projectDetails.key_words) }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="bold">Curriculum</td>
                                    <td>
                                        {{ capitalizeFirstLetter(projectDetails.curriculum) }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="bold">Weblink</td>
                                    <td>
                                        {{ projectDetails.weblinks }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="bold">Key Milestones</td>
                                    <td>
                                        {{ capitalizeFirstLetter(projectDetails.milestones) }}
                                    </td>
                                </tr>
                                <template v-if="projectDetails.final_product">
                                    <tr>
                                        <td class="bold">Final Product</td>
                                        <td>{{ projectDetails.final_product.key }}</td>
                                    </tr>
                                    <tr>
                                        <td class="bold">Final Product Details</td>
                                        <td>
                                            {{ capitalizeFirstLetter(projectDetails.final_product.details) }}
                                        </td>
                                    </tr>
                                </template>
                                <tr>
                                    <td class="bold">Learning Goals</td>
                                    <td>
                                        <p class="mb-0" v-for="(item, index) in projectDetails.learning_goals"
                                           :key="index">
                                            <span class="bold">{{ Object.keys(item)[0] }}: </span>
                                            {{ getLearningGoals(item[Object.keys(item)[0]]) }}
                                        </p>
                                    </td>
                                </tr>

                            </table>
                        </div>

<!--                        <p>This project was created for <span class="bold">{{ projectDetails.school }}</span>, you can-->
<!--                            send a request to chat with the creator by clicking this link - -->
<!--                            <a href="#">Send Message</a>-->
<!--                        </p>-->

                    </div>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-light" @click="closeModal">Close</button>
                        <button type="button" class="btn btn-primary" @click="navigateToProjectEditPage">Continue with
                            Project Details
                        </button>
                    </div>
                </div><!-- /.modal-content -->
            </div><!-- /.modal-dialog -->
        </div><!-- /.modal -->
    </div>
</template>
<script>
import TextInputFieldVue from "@/components/common/TextInputField.vue";
import RoundedButton from "@/components/common/RoundedButton.vue";
import {ref} from "vue";
import RegularButton from "@/components/common/RegularButton.vue";
import {checkAndRefreshToken} from "@/utils/auth";
import {capitalizeFirstLetter, getValidToken, truncateText} from "@/utils/helpers";
import {API_BASE_URL} from "@/config.js";
import {useRouter} from "vue-router";

export default {
    methods: {truncateText, capitalizeFirstLetter},
    props: {},
    components: {RegularButton, RoundedButton, TextInputFieldVue},
    setup(props, {emit}) {
        const loading = ref(false)
        const searchTerm = ref('')
        const searchResults = ref([])
        const searchPerformed = ref(false)
        const router = useRouter()
        const isModalOpen = ref(false)

        const searchForResult = async () => {
            try {
                await checkAndRefreshToken()

                const accessToken = getValidToken()
                if (!accessToken) {
                    console.error("Access token not available.");
                    return;
                }

                loading.value = true;
                const apiUrl = `${API_BASE_URL}/school/projects/search?query=${searchTerm.value}`
                const response = await fetch(apiUrl, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`, // Include the access token in the headers
                    },
                });

                if (response.ok) {
                    const result = await response.json();

                    if (result.code === 200) {
                        searchResults.value = result.data

                        // console.log("result", result.data);
                    } else {
                        console.log("error", result.message);
                    }
                } else {
                    console.log("response", response);
                    console.error("Error fetching data from the API");
                }
            } catch (error) {
                console.error("An error occured:", error);
            } finally {
                loading.value = false;
                searchPerformed.value = true
            }
        };

        const projectDetails = ref(null)
        const viewAction = (project) => {
            projectDetails.value = {
                name: project.name,
                academic_year: project.academic_year,
                term: project.term,
                project_type: project.project_type,
                project_duration: project.project_duration,
                key_words: project.key_words,
                project_aim: project.project_aim,
                description: project.description,
                weblinks: project.weblinks,
                milestones: project.milestones,
                final_product: project.final_product,
                problem_to_solve: project.problem_to_solve,
                learning_goals: project.learning_goals,
                project_checklist: project.project_checklist,
                activities: project.activities,
                school: project.school,
                curriculum: project.curriculum
            }

            isModalOpen.value = true
        }

        const closeModal = () => {
            isModalOpen.value = false
        };

        const navigateToProjectEditPage = () => {
            localStorage.setItem('projectData', JSON.stringify(projectDetails.value))

            router.push(
                {
                    name: 'EditProjectFromTemplate'
                }
            )
        }

        const getKeywords = (keywords) => {
            const names = keywords.map((keyword) => capitalizeFirstLetter(keyword));
            return names.join(', ');
        };

        const getProblemName = (item, index, isNotSaved = true) => {
            const propName = `problem_${index}`;

            if (isNotSaved) {
                return `${item[propName]}_${index}`;
            } else {
                return item[propName];
            }
        }

        const getLearningGoals = (goals) => {
            if (goals.length > 0) {
                const names = goals.map((goal) => capitalizeFirstLetter(goal));
                return names.join(', ');
            }
        };

        return {
            loading,
            searchResults,
            searchTerm,
            searchForResult,
            searchPerformed,
            viewAction,
            isModalOpen,
            closeModal,
            projectDetails,
            getKeywords,
            getProblemName,
            getLearningGoals,
            navigateToProjectEditPage
        }
    }
}
</script>
<style scoped>
.card {
    margin-bottom: 10px;
}

.card-project {
    border: 1px solid #B8BCCA !important;
    background-color: #fff !important;
}

.close-icon {
    cursor: pointer;
}

.modal {
    /* Add styles for modal overlay and positioning */
    /* Example styles: */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow-y: auto; /* Add vertical scrolling */
}

.modal-dialog {
    /* Add styles for modal dialog box */
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    max-height: 90vh; /* Set a maximum height (adjust as needed) */
    overflow-y: auto !important; /* Add vertical scrolling within the modal */
}

.modal-content {
    border-radius: 15px;
    padding: 0;
    display: inherit;
    overflow-y: auto !important;
    max-height: 90vh;
}

.table {
    color: #333;
}

.modal-full-width {
    width: 70%;
}

@media (max-width: 767px) {
    .modal-full-width {
        width: 100%;
    }
}

@media (max-width: 992px) {
    .modal-full-width {
        width: 100%;
    }
}
</style>