import {API_BASE_URL} from "@/config.js";
import {
    capitalizeFirstLetter,
    formatCurrency,
    formatDateTime,
    getUserInitialsForName,
    getValidToken,
    truncateText
} from "@/utils/helpers.js";
import {computed, ref} from "vue";
import {checkAndRefreshToken} from '@/utils/auth';

const loading = ref(false);
const apiUrl = `${API_BASE_URL}/subscription/list-subscriptions`;
const subscriptionsPayload = ref(null);
const subscriptionData = ref([])

const fetchSubscriptionData = async (page, perPage) => {
    try {
        await checkAndRefreshToken()

        const accessToken = getValidToken()
        if (!accessToken) {
            console.error("Access token not available.");
            return;
        }

        loading.value = true;
        const apiUrlWithParams = `${apiUrl}?page=${page}&per_page=${perPage}`

        const response = await fetch(apiUrlWithParams, {
            headers: {
                Authorization: `Bearer ${accessToken}`, // Include the access token in the headers
            },
        });

        if (response.ok) {
            const result = await response.json();

            if (result.code === 200) {
                subscriptionsPayload.value = result.data
                subscriptionData.value = result.data.results.subscriptions

            } else {
                console.log("error", result.message);
            }
        } else {
            console.log("response", response);
            console.error("Error fetching data from the API");
        }
    } catch (error) {
        console.error("An error occured:", error);
    } finally {
        loading.value = false;
    }
};


const formattedSubscriptionData = computed(() => {
    // Map and format the data
    return subscriptionData.value.map((item) => {

        // Format the created at
        const {date, time} = formatDateTime(item.created_at);

        const formattedSubscription = {
            user: {
                name: capitalizeFirstLetter(item.school_name),
                initials: getUserInitialsForName(item.school_name),
                avatarColor: 'dark'
            },
            createdAt: date,
            current_plan: capitalizeFirstLetter(item.plan_name),
            amount: formatCurrency(item.amount),
            next_billing_date: item.next_billing_date ? truncateText(item.next_billing_date, 16, false) : '-',
            status: capitalizeFirstLetter(item.status),
        };

        return {
            ...formattedSubscription,
        };
    });
});

const getSubscriptions = () => {
    return {subscriptionsPayload, fetchSubscriptionData, loading, formattedSubscriptionData, subscriptionData}
}

export default getSubscriptions