<template>
    <form @submit.prevent="handleSubmit">
        <div class="form-group mb-3">
            <label for="simpleinput" class="label">Name:</label>
            <input
                type="text"
                id="simpleinput"
                class="form-control"
                placeholder="Enter Text"
                v-model="name"
                required
            />

            <div class="invalid-feedback" v-if="nameError">
                Name should be at least 3 characters
            </div>
        </div>

        <div class="form-group">
            <div class="custom-select-wrapper">
                <label for="example-select" class="label">Input Select</label>
                <select id="example-select">
                    <option value="" selected>Select an option</option>
                    <option value="option1">Option 1</option>
                    <option value="option2">Option 2</option>
                    <option value="option3">Option 3</option>
                </select>
            </div>
        </div>

        <div class="form-group mb-3">
            <label for="example-password" class="label">Password</label>
            <input
                type="password"
                id="example-password"
                class="form-control"
                placeholder="Enter Password"
                v-model="password"
                required
            />

            <div class="invalid-feedback" v-if="passwordError">
                Password should be at least 6 characters
            </div>
        </div>

        <div class="form-group mb-3">
            <label for="example-password" class="label">Select</label>
            <select
                class="js-example-placeholder-single form-control"
                name="state"
            >
                <option></option>
                <option value="AL">Alabama</option>
                <option value="OH">Ohio</option>
                <option value="OK">Oklahoma</option>
                <option value="OR">Oregon</option>
                <option value="PA">Pennsylvania</option>
                <option value="RI">Rhode Island</option>
                <option value="SC">South Carolina</option>
                <option value="SD">South Dakota</option>
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <option value="VT">Vermont</option>
                <option value="WY">Wyoming</option>
            </select>
        </div>

        <div class="form-group mb-3">
            <label for="example-password" class="label">Multi Select</label>
            <select
                class="js-example-placeholder-multiple form-control"
                name="states[]"
                multiple="multiple"
            >
                <option value="AL">Alabama</option>
                <option value="OH">Ohio</option>
                <option value="OK">Oklahoma</option>
                <option value="OR">Oregon</option>
                <option value="PA">Pennsylvania</option>
                <option value="RI">Rhode Island</option>
                <option value="SC">South Carolina</option>
                <option value="SD">South Dakota</option>
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <option value="VT">Vermont</option>
                <option value="WY">Wyoming</option>
            </select>
        </div>

        <div class="form-group mb-3">
            <TextAreaInputFieldVue
                :config="{
                    class: 'form-control-gray',
                    placeholder: 'Enter your name',
                    inputFieldType: 'name',
                }"
                labelText="Name"
                errorType="nameError"
                v-model="name"
            />
        </div>

        <!-- Submit button -->
        <RegularButton
            buttonText="Submit Form"
            iconText="mdi-content-save"
            class="custom-button"
        />
    </form>
</template>

<script>
import { ref } from "vue";
import RegularButton from "./RegularButton.vue";
import TextAreaInputFieldVue from "./TextAreaInputField.vue";

export default {
    components: { RegularButton, TextAreaInputFieldVue },
    setup() {
        const name = ref("");
        let nameError = ref("");
        const password = ref("");
        let passwordError = ref("");
        let hasErrors = null;

        // Validation
        const handleSubmit = () => {
            console.log("got here");

            // Reset error messages
            clearErrorMessages();

            // Validate each field
            if (name.value.length < 3) {
                nameError.value = true;
            }

            if (password.value.length < 5) {
                passwordError.value = true;
            }

            // Check if any errors exist (at least one field is empty)
            hasErrors = nameError || passwordError;

            if (!hasErrors) {
                console.log("in here", name.value);
            }
        };

        // Clear error messages
        const clearErrorMessages = () => {
            nameError.value = "";
            passwordError.value = "";
        };

        return {
            name,
            password,
            nameError,
            passwordError,
            handleSubmit,
        };
    },
    mounted() {
        $(".js-example-placeholder-single").select2({
            placeholder: "Select a state",
        });
        $(".js-example-placeholder-multiple").select2({
            placeholder: "Select a state",
        });
    },
};
</script>

<style scoped>
.label {
    font-weight: 400;
    font-size: 14px;
}

.form-control {
    background-color: #f8fafc !important;
    border: 1px solid #b8bcca !important;
    color: #333333 !important;
    padding: 10px;
}

/* Custom select */
.custom-select-wrapper {
    position: relative;
    display: block;
    width: 100%; /* Adjust the width as needed */
}

.custom-select-wrapper select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    background-color: #f8fafc;
    border: 1px solid #b8bcca;
    color: #333333;
}

.custom-select-wrapper::after {
    content: url("../../assets/images/icons/arrow_drop_down.svg"); /* Replace with the path to your external SVG icon */
    width: 15px; /* Adjust the width of the icon as needed */
    height: 15px;
    position: absolute;
    top: 60%;
    right: 15px; /* Adjust the right value to control the position of the caret */
    transform: translateY(-50%);
    pointer-events: none;
}

.custom-select-wrapper select option:checked {
    color: #b8bcca;
}

.invalid-feedback {
    display: block;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.75rem;
    color: #f1556c;
}
</style>