<template>
    <div class="row p-20">
        <div class="col-lg-7 col-sm-12 mx-auto">
            <div class="text-center">
            <img src="../../assets/key-logo.png" alt="logo" width="200" class="mt-20"/>
            <h2 class="text-center bold mt-20 mb-50">Sign up as a parent</h2>
            </div>

            <div class="row">
                <div class="col-lg-6 col-sm-12 mb-30">
                    <TextInputField :config="{
                    class: 'form-control-gray',
                    placeholder: 'Enter First Name',
                    inputFieldType: 'text',
                }" labelText="First Name" :errorMessage="firstNameError" v-model="firstName" :isRequired="true"/>
                </div>

                <div class="col-lg-6 col-sm-12 mb-30">
                    <TextInputField :config="{
                    class: 'form-control-gray',
                    placeholder: 'Enter Last Name',
                    inputFieldType: 'text',
                }" labelText="Last Name" :errorMessage="lastNameError" v-model="lastName" :isRequired="true"/>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-6 col-sm-12 mb-30">
                    <SimpleDropdown labelText="Gender" :options="genderSelectOptions" v-model="selectedGender"
                                    selectId="genderOptions" :selectedItem="selectedGender" :loading="genderLoader"
                                    :isRequired="true"/>
                </div>

                <div class="col-lg-6 col-sm-12 mb-30">
                    <TextInputField :config="{
                    class: 'form-control-gray',
                    placeholder: 'Enter Age',
                    inputFieldType: 'number',
                }" labelText="Age" v-model="age" :errorMessage="ageError" :isRequired="true"/>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-6 col-sm-12 mb-30">
                    <TextInputField :config="{
                    class: 'form-control-gray',
                    placeholder: 'Enter Contact Number',
                    inputFieldType: 'tel',
                }" labelText="Contact Number" v-model="msisdn" :errorMessage="msisdnError" :isRequired="true"/>
                </div>

                <div class="col-lg-6 col-sm-12 mb-30">
                    <TextInputField :config="{
                        class: 'form-control-gray',
                        placeholder: 'Enter Email Address',
                        inputFieldType: 'email'}"
                                    labelText="Contact Email Address" v-model="email" :errorMessage="emailError"
                                    :isRequired="true"/>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-6 col-sm-12 mb-30">
                    <SingleSelectField labelText="Country of Residence" :options="countrySelectOptions"
                                       v-model="selectedCountry"
                                       :loading="countryDropdownLoader" selectId="countryOptions" :isRequired="true"
                                       :selectedItem="selectedCountry" @change="handleCountrySelection"/>
                </div>

                <div class="col-lg-6 col-sm-12 mb-30">
                    <SingleSelectField labelText="State of Residence" :options="stateSelectOptions"
                                       v-model="selectedState"
                                       :loading="statesDropdownLoader" :isRequired="true"
                                       selectId="stateOptions" :selectedItem="selectedState"/>
                </div>
            </div>

            <div class="row">
                <div class="col-12 mb-30">
                    <TextAreaInputField :config="{
                    class: 'form-control-gray',
                    placeholder: 'Enter Address',
                    inputFieldType: 'address',}" labelText="Residential Address"
                                        v-model="address"/>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-6 col-sm-12 mb-30">
                    <TextInputField :config="{
                        class: 'form-control-gray',
                        placeholder: 'Enter Email Address',
                        inputFieldType: 'email'}"
                                    labelText="Work Email" v-model="workEmail" :isRequired="true"
                                    :errorMessage="workEmailError"/>
                </div>

                <div class="col-lg-6 col-sm-12 mb-30">
                    <TextInputField :config="{
                    class: 'form-control-gray',
                    placeholder: 'Enter Contact Number',
                    inputFieldType: 'tel',
                }" labelText="Work Number" v-model="workMsisdn" :isRequired="true" :errorMessage="workMsisdnError"/>
                </div>
            </div>

            <div class="row">
                <div class="col-12 mb-30">
                    <TextAreaInputField :config="{
                    class: 'form-control-gray',
                    placeholder: 'Enter Address',
                    inputFieldType: 'address',}" labelText="Office Address"
                                        v-model="workAddress"/>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-6 col-sm-12">
                    <SingleSelectField
                        class="mb-30"
                        labelText="Father or Mother?"
                        :options="parentOptions"
                        v-model="selectedParent"
                        selectId="parentOptions"
                        :selectedItem="selectedParent"/>
                </div>

                <div class="col-lg-6 col-sm-12 mb-30">
                    <TextInputField :config="{
                    class: 'form-control-gray',
                    inputFieldType: 'text',
                    placeholder: 'English, French...'
                        }" labelText="Child's First Language"
                                    v-model="child_first_language"
                                    isRequired/>
                </div>
            </div>


            <div class="row">
                <div class="col-lg-6 col-sm-12">
                    <SingleSelectField
                        class="mb-30"
                        labelText="Has this parent sent the child's birth certificate?"
                        :options="yesOrNoOptions"
                        v-model="selectedConfirmationOption"
                        selectId="yesOrNoOptions"
                        :selectedItem="selectedConfirmationOption"/>
                </div>

                <div class="col-lg-6 col-sm-12">
                    <SingleSelectField
                        class="mb-30"
                        labelText="Has this parent agreed to terms and conditions?"
                        :options="termsAndConditionsOptions"
                        v-model="selectedTermsAndConditionsOption"
                        selectId="termsAndConditionsOptions"
                        :selectedItem="selectedTermsAndConditionsOption"/>
                </div>
            </div>

            <RoundedButton buttonText="Submit" class="custom-button mb-100"
                           @click.prevent="saveParent" :loading="buttonLoading"/>

        </div>
    </div>
</template>
<script>
import TextInputField from "@/components/common/TextInputField.vue";
import SimpleDropdown from "@/components/common/SimpleDropdown.vue";
import TextAreaInputField from "@/components/common/TextAreaInputField.vue";
import SingleSelectField from "@/components/common/SingleSelectField.vue";
import getCountries from "@/composables/getCountries";
import getStates from "@/composables/getStates";
import getGender from "@/composables/getGender";
import {onMounted, ref, watch} from "vue";
import {capitalizeFirstLetter, getValidToken} from "@/utils/helpers";
import {API_BASE_URL} from "@/config";
import {checkAndRefreshToken} from "@/utils/auth";
import {useRoute} from "vue-router";
import Swal from "sweetalert2";
import RoundedButton from "@/components/common/RoundedButton.vue";

export default {
    props: {},
    components: {RoundedButton, SingleSelectField, TextAreaInputField, SimpleDropdown, TextInputField},
    setup() {
        const {fetchCountriesData, countriesList, loading: countryDropdownLoader} = getCountries()
        const {fetchStatesData, statesList, loading: statesDropdownLoader} = getStates()
        const {fetchGender, genderList, loading: genderLoader} = getGender()
        const genderSelectOptions = ref([])
        const countrySelectOptions = ref([])
        const stateSelectOptions = ref([])
        const selectedGender = ref('')
        const selectedCountry = ref('')
        const selectedState = ref('')
        const firstName = ref('')
        const lastName = ref('')
        const msisdn = ref('')
        const email = ref('')
        const dob = ref('')
        const age = ref('')
        const address = ref('')
        const countryIdMap = ref({});
        const firstNameError = ref('')
        const lastNameError = ref('')
        const dobError = ref('')
        const ageError = ref('')
        const imageFile = ref('')
        const workEmail = ref('')
        const workMsisdn = ref('')
        const workAddress = ref('')
        const workEmailError = ref('')
        const workMsisdnError = ref('')
        const workAddressError = ref('')
        const msisdnError = ref('')
        const emailError = ref('')
        const workCountrySelectOptions = ref([])
        const workStateSelectOptions = ref([])
        const selectedWorkCountry = ref('')
        const selectedWorkState = ref('')
        const yesOrNoOptions = ref([])
        const selectedConfirmationOption = ref('')
        const selectedTermsAndConditionsOption = ref('')
        const termsAndConditionsOptions = ref([])
        const child_first_language = ref('')
        const parentOptions = ref([])
        const buttonLoading = ref(false)
        const router = useRoute()
        const schoolId = router.params.id
        const selectedParent = ref('')
        const stateType = ref('')

        onMounted(() => {
            fetchGender()
            fetchCountriesData()

            setTimeout(() => {
                yesOrNoOptions.value = [
                    {label: "Yes", value: 'yes'},
                    {label: "No", value: 'no'}
                ]

                termsAndConditionsOptions.value = [
                    {label: "Yes", value: 'yes'},
                    {label: "No", value: 'no'}
                ]

                parentOptions.value = [
                    {label: "Father", value: 'father'},
                    {label: "Mother", value: 'mother'}
                ]
            }, 500)
        })

        const updateGenderOptions = (newData) => {
            genderSelectOptions.value = newData.map(item => ({
                label: capitalizeFirstLetter(item.label), value: item.value
            }))

            if (genderSelectOptions.value.length > 0) {
                selectedGender.value = genderSelectOptions.value[0].value;
            }
        }

        watch(genderList, (newData) => {
            updateGenderOptions(newData)
        })

        // COUNTRIES
        const handleWorkCountrySelection = () => {
            const selectedCountryId = countryIdMap.value[selectedWorkCountry.value]
            stateType.value = 'work'
            fetchStatesData(selectedCountryId)
        }

        const handleCountrySelection = () => {
            const selectedCountryId = countryIdMap.value[selectedCountry.value]
            stateType.value = 'general';
            fetchStatesData(selectedCountryId)
        }

        // Update the country dropdown when the modal is opened
        const updateCountryOptions = (newData) => {
            countrySelectOptions.value = newData.map(country => ({
                label: country.country_name, value: country.country_name
            }))

            countryIdMap.value = newData.reduce((map, country) => {
                map[country.country_name] = country.id;
                return map;
            }, {});

            if (countrySelectOptions.value.length > 0) {
                selectedCountry.value = 'Nigeria'
                handleCountrySelection()

                selectedWorkCountry.value = 'Nigeria'
                handleWorkCountrySelection()
            }
        }

        watch(countriesList, (newData) => {
            updateCountryOptions(newData)
        })

        // STATES
        // Update the states options
        const updateStatesOptions = (newData) => {
            stateSelectOptions.value = newData.map(state => ({
                label: state.state_name, value: state.state_name
            }))

            // Apply state updates based on the stateType when new data is fetched
            if (stateType.value === 'work' && stateSelectOptions.value.length > 0) {
                if (!selectedWorkState.value || !stateSelectOptions.value.some(option => option.value === selectedWorkState.value)) {
                    selectedWorkState.value = stateSelectOptions.value[0].value;
                }
                stateType.value = ''; // Reset after use to avoid stale states
            } else if (stateType.value === 'general' && stateSelectOptions.value.length > 0) {
                if (!selectedState.value || !stateSelectOptions.value.some(option => option.value === selectedState.value)) {
                    selectedState.value = stateSelectOptions.value[0].value;
                }
                stateType.value = ''; // Reset after use to avoid stale states
            }
        }

        watch(statesList, (newData) => {
            if (newData) {
                updateStatesOptions(newData);
            }
        })

        const validateForm = () => {
            let isValid = true

            firstNameError.value = ''
            lastNameError.value = ''
            ageError.value = ''
            msisdnError.value = ''
            emailError.value = ''
            workEmailError.value = ''
            workMsisdnError.value = ''

            if (firstName.value.length === 0) {
                firstNameError.value = 'Enter a valid first name'
                isValid = false
            }


            if (lastName.value.length === 0) {
                lastNameError.value = 'Enter a valid last name'
                isValid = false
            }

            if (age.value.length === 0) {
                ageError.value = 'Enter a valid age'
                isValid = false
            }

            if (msisdn.value.length === 0) {
                msisdnError.value = 'Enter a valid contact number'
                isValid = false
            }

            if (email.value.length === 0) {
                emailError.value = 'Enter a valid email address'
                isValid = false
            }

            if (workMsisdn.value.length === 0) {
                workMsisdnError.value = 'Enter a valid contact number'
                isValid = false
            }

            if (workEmail.value.length === 0) {
                workEmailError.value = 'Enter a valid email address'
                isValid = false
            }

            return isValid
        }

        const apiUrl = `${API_BASE_URL}/parent/add-parent`
        const saveParent = async () => {
            const newItem = {
                school_id: schoolId,
                first_name: firstName.value,
                last_name: lastName.value,
                gender: selectedGender.value,
                age: age.value,
                email: email.value,
                msisdn: msisdn.value,
                country: selectedCountry.value,
                state: selectedState.value,
                address: address.value,
                work_email: workEmail.value,
                work_msisdn: workMsisdn.value,
                work_address: workAddress.value,
                child_first_language: child_first_language.value,
                languages_spoken_at_home: child_first_language.value,
                has_emailed_child_kyc: selectedConfirmationOption.value === 'yes',
                agree_with_terms: selectedTermsAndConditionsOption.value === 'yes',
                date_to_join: 'N/A',
                relationship_to_student: selectedParent.value,
                work_country: selectedWorkCountry.value,
                work_state: selectedWorkState.value,
                token: 'keyhub_invite'
            }

            try {
                if (validateForm()) {
                    buttonLoading.value = true
                    await checkAndRefreshToken()
                    const accessToken = getValidToken()

                    const response = await fetch(apiUrl, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(newItem)
                    })


                    if (response.ok) {
                        const result = await response.json();

                        if (result.code === 200) {
                            await Swal.fire({
                                title: 'Success',
                                text: "You have signed up on Key Academy, please check your mail to complete set up for your account",
                                icon: 'success',
                            }).then(() => {
                                window.location.reload();
                            });
                        } else {
                            console.log("code error", result.message);
                            await Swal.fire({
                                text: 'An error occurred',
                                icon: 'error',
                            })
                        }
                    } else {
                        const result = await response.json();
                        console.log("error", result.message);
                        await Swal.fire({
                            text: 'An error occurred',
                            icon: 'error',
                        })
                    }
                }
            } catch (error) {
                console.error("An error occured:", error);
                await Swal.fire({
                    text: 'An error occurred',
                    icon: 'error',
                })
            } finally {
                buttonLoading.value = false
            }
        }

        return {
            buttonLoading,
            firstName,
            lastName,
            genderSelectOptions,
            selectedGender,
            msisdn,
            email,
            countrySelectOptions,
            selectedCountry,
            countryDropdownLoader,
            statesDropdownLoader,
            handleCountrySelection,
            selectedState,
            stateSelectOptions,
            address,
            genderLoader,
            dob,
            age,
            firstNameError,
            lastNameError,
            dobError,
            ageError,
            imageFile,
            workEmail,
            workMsisdn,
            workAddress,
            workEmailError,
            workMsisdnError,
            workAddressError,
            workCountrySelectOptions,
            workStateSelectOptions,
            selectedWorkCountry,
            selectedWorkState,
            handleWorkCountrySelection,
            msisdnError,
            emailError,
            yesOrNoOptions,
            selectedConfirmationOption,
            selectedTermsAndConditionsOption,
            termsAndConditionsOptions,
            child_first_language,
            selectedParent,
            parentOptions,
            saveParent,
        }
    }
}
</script>
