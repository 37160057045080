<template>
    <div class="row">
        <div class="col-12">
            <div class="page-title-box">
                <BreadCrumbs/>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-sm-8">
            <div class="card-box-no-pd">
                <div class="page-title-box mb-30">
                    <h4 class="page-title-gray">Role Details</h4>
                </div>

                <div class="card-content border-bottom plr-50 pb-100">
                    <div class="row">

                        <div class="col-sm-12 role-details">

                            <div class="detail-group mb-50">
                                <span class="muted-text-sm">Role Name</span>
                                <p>{{ roleName }}</p>
                            </div>

                            <div class="detail-group">
                                <span class="muted-text-sm">Description</span>
                                <p>{{ roleDescription }}.</p>
                            </div>

                        </div>
                    </div>
                </div>


            </div>
        </div>
    </div>
</template>

<script>
import Switch from "@/components/common/Switch.vue";
import RegularButtonBlock from "@/components/common/RegularButtonBlock.vue";
import BreadCrumbs from "@/components/common/BreadCrumbs.vue";
import {onMounted, ref, watch} from "vue";
import {useRoute} from "vue-router";
import {capitalizeFirstLetter, getSchoolId, getValidToken} from '@/utils/helpers'
import {API_BASE_URL} from "@/config";
import {checkAndRefreshToken} from "@/utils/auth";

export default {
    props: {

    },
    components: {
        Switch,
        RegularButtonBlock,
        BreadCrumbs,
    },
    emits: ['showLoader', 'show-loader', 'flashMessage', 'data-loaded', 'flash-message'],
    setup(props, {emit}) {
        const router = useRoute()
        const itemId = router.params.id
        const accessToken = getValidToken();
        const roleData = ref(null)
        const roleName = ref('')
        const roleDescription = ref('')


        const fetchRoleData = async () => {
            try {
                const schoolId = getSchoolId()
                const apiUrl = `${API_BASE_URL}/school/${schoolId}/roles/${itemId}`;

                await checkAndRefreshToken()
                emit('show-loader')

                const response = await fetch(apiUrl, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    }
                })

                if (response.ok) {
                    const result = await response.json()
                    roleData.value = result.message

                    console.log('result', result.message)
                } else {
                    console.log("response", response);
                    console.error("Error fetching data from the API");
                }
            } catch (error) {
                console.error('An error occured: ', error)
            } finally {
                emit('data-loaded')
            }
        }


        onMounted(() => {
            fetchRoleData()
        })


        watch(roleData, (item) => {
            roleName.value = capitalizeFirstLetter(item.role_name)
            roleDescription.value = item.description || 'No description added'
            emit('data-loaded')
        })

        return {roleName, roleDescription}
    }
};
</script>

<style scoped>
.border-bottom {
    border-bottom: 2px solid #F5F6FA;
}

.role-details {
    position: relative;
}

.mb-80 {
    margin-bottom: 80px;
}

.permission {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0;
    border-bottom: 1px solid #F6F6F6;
}

.permission > p {
    color: #333
}

.permission-image {
    border-left: 1px solid #F6F6F6;
    padding-left: 20px;
}

.pb-100 {
    padding-bottom: 100px;
}
</style>