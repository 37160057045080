<template>
    <div class="loaderCard" v-if="isLoaderModalOpen">
        <div class="loader-dialog">
            <div class="loaderBox">
                <div class="loader"></div>
                <p>Loading, give it a sec...</p>
                <div class="loaderFooter">
                    <img
                        src="../../assets/images/icons/warning_icon.svg"
                        alt="warning icon"
                        height="20px"
                        width="20px"
                    />
                    <p>Ensure you have internet connection</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        isLoaderModalOpen: Boolean,
    },
    setup(props, { emit }) {
        const closeLoaderModal = () => {
            emit("closeLoaderModal");
        };

        return { closeLoaderModal };
    },
};
</script>

<style scoped>
.loaderCard {
    /* Add styles for modal overlay and positioning */
    /* Example styles: */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    outline: 0;
    z-index: 9999;
}

.loader-dialog {
    /* Add styles for modal dialog box */
    background-color: #fff;
    border-radius: 40px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    width: 350px;
    display: flex;
    justify-content: center;
}

.loaderBox {
    background-color: #fff;
    border-radius: 15px;
    width: 100%;
    text-align: center;
    padding: 30px 0 0 0;
}

.loader {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 4px solid #2f5bcf;
    border-top: 5px solid #f1f1f1;
    animation: spin 0.5s linear infinite; /* Apply the animation */
    margin: 30px auto;
}

.loaderBox > p {
    font-weight: 700;
    color: #333;
    margin-bottom: 20px;
}

.loaderFooter {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff8eb;
    padding: 15px;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
}

.loaderFooter > img {
    margin-right: 10px;
}

.loaderFooter > p {
    font-size: 13px;
    margin-bottom: 0;
    color: #333;
    font-weight: bold;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>