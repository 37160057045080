import {createRouter, createWebHistory} from 'vue-router'
import MasterLayout from '../views/God_Admin/Layouts/MasterLayout.vue'
import ComponentsLayout from '../views/God_Admin/ComponentsLayout.vue'
import Dashboard from '../views/God_Admin/Reports/Dashboard.vue'
import RolesLayout from '../views/God_Admin/Roles/RolesLayout.vue'
import UsersLayout from '../views/God_Admin/Users/UsersLayout.vue'
import UsersIndex from '../views/God_Admin/Users/UsersIndex.vue'
import ShowUser from '../views/God_Admin/Users/ShowUser.vue'
import NoBgLayout from '../views/NoBgLayout.vue'
import Login from '../views/Auth/Login.vue'
import jwtDecode from 'jwt-decode'
import useRefreshToken from '@/utils/refreshToken';
import ColearnersLayout from '../views/God_Admin/Colearners/ColearnersLayout'
import ColearnersList from '../views/God_Admin/Colearners/ColearnersList'
import ShowColearner from '../views/God_Admin/Colearners/ShowColearner'
import SchoolsLayout from "@/views/God_Admin/Schools/SchoolsLayout.vue";
import SchoolsList from "@/views/God_Admin/Schools/SchoolsList.vue";
import StudentsList from "@/views/God_Admin/Students/StudentsList.vue";
import ShowStudent from "@/views/God_Admin/Students/ShowStudent.vue";
import StudentsLayout from "@/views/God_Admin/Students/StudentsLayout.vue";
import ParentsLayout from "@/views/God_Admin/Parents/ParentsLayout.vue";
import ParentsList from "@/views/God_Admin/Parents/ParentsList.vue";
import ShowParent from "@/views/God_Admin/Parents/ShowParent.vue";
import {getCurrentUser, getValidToken} from "@/utils/helpers";
import PasswordReset from "@/views/Auth/PasswordReset.vue";
import PasswordEmail from "@/views/Auth/PasswordEmail.vue";
import PlansList from "@/views/God_Admin/Plans/PlansList.vue";
import PlansLayout from "@/views/God_Admin/Plans/PlansLayout.vue";
import ShowPlan from "@/views/God_Admin/Plans/ShowPlan.vue";
import PermissionsList from "@/views/God_Admin/Permissions/PermissionsList.vue";
import HorizontalMenuLayout from "@/views/HorizontalMenuLayout.vue";
import SchoolDashboard from "@/views/School_Admin/Reports/SchoolDashboard.vue";
import SchoolParentList from "@/views/School_Admin/Parents/SchoolParentList.vue";
import SchoolParentsLayout from "@/views/School_Admin/Parents/SchoolParentsLayout.vue";
import ShowSchoolParent from "@/views/School_Admin/Parents/ShowSchoolParent.vue";
import SchoolStudentsList from "@/views/School_Admin/Students/SchoolStudentsList.vue";
import SchoolsStudentsLayout from "@/views/School_Admin/Students/SchoolsStudentsLayout.vue";
import ShowSchoolStudent from "@/views/School_Admin/Students/ShowSchoolStudent.vue";
import ShowSchoolColearners from "@/views/School_Admin/CoLearners/ShowSchoolColearners.vue";
import SchoolColearnersList from "@/views/School_Admin/CoLearners/SchoolColearnersList.vue";
import SchoolColearnerLayout from "@/views/School_Admin/CoLearners/SchoolColearnerLayout.vue";
import SchoolUsersList from "@/views/School_Admin/Users/SchoolUsersList.vue";
import ShowSchoolUser from "@/views/School_Admin/Users/ShowSchoolUser.vue";
import SchoolUsersLayout from "@/views/School_Admin/Users/SchoolUsersLayout.vue";
import LearningGroupLayout from "@/views/School_Admin/LearningGroups/LearningGroupLayout.vue";
import LearningGroupsList from "@/views/School_Admin/LearningGroups/LearningGroupsList.vue";
import ShowLearningGroup from "@/views/School_Admin/LearningGroups/ShowLearningGroup.vue";
import ProjectsLayout from "@/views/School_Admin/Projects/ProjectsLayout.vue";
import ProjectsList from "@/views/School_Admin/Projects/ProjectsList.vue";
import ShowProject from "@/views/School_Admin/Projects/ShowProject.vue";
import SchoolRolesLayout from "@/views/School_Admin/Roles/SchoolRolesLayout.vue";
import SchoolRolesList from "@/views/School_Admin/Roles/SchoolRolesList.vue";
import ShowSchoolRoles from "@/views/School_Admin/Roles/ShowSchoolRoles.vue";
import RolesList from "@/views/God_Admin/Roles/RolesList.vue";
import ShowRoles from "@/views/God_Admin/Roles/ShowRoles.vue";
import Profile from "@/views/God_Admin/Profile/Profile.vue";
import SchoolProfile from "@/views/School_Admin/Profile/SchoolProfile.vue";
import SchoolPermissionList from "@/views/School_Admin/Permissions/SchoolPermissionList.vue";
import SchoolPlanSelection from "@/views/School_Admin/Subscriptions/SchoolPlanSelection.vue";
import SubscriptionDetail from "@/views/School_Admin/Subscriptions/SubscriptionDetail.vue";
import AuditLogLayout from "@/views/God_Admin/Audit_log/AuditLogLayout.vue";
import AuditLogList from "@/views/God_Admin/Audit_log/AuditLogList.vue";
import ShowAuditLog from "@/views/God_Admin/Audit_log/ShowAuditLog.vue";
import KeywordsList from "@/views/God_Admin/Keywords/KeywordsList.vue";
import SMEList from "@/views/School_Admin/SMEs/SMEList.vue";
import SetupProject from "@/views/School_Admin/Projects/SetupProject.vue";
import SubscriptionsLayout from "@/views/God_Admin/Subscriptions/SubscriptionsLayout.vue";
import SubscriptionList from "@/views/God_Admin/Subscriptions/SubscriptionList.vue";
import ShowSubscription from "@/views/God_Admin/Subscriptions/ShowSubscription.vue";
import TransactionsLayout from "@/views/God_Admin/Transactions/TransactionsLayout.vue";
import TransactionsList from "@/views/God_Admin/Transactions/TransactionsList.vue";
import ShowTransaction from "@/views/God_Admin/Transactions/ShowTransaction.vue";
import EditProjectFromTemplate from "@/views/School_Admin/Projects/EditProjectFromTemplate.vue";
import AcademicYearList from "@/views/School_Admin/AcademicYears/AcademicYearList.vue";
import TermsList from "@/views/School_Admin/Terms/TermsList.vue";
import ChecklistList from "@/views/God_Admin/ProjectsChecklist/ChecklistList.vue";
import ListSchoolMessages from "@/views/School_Admin/Messages/ListSchoolMessages.vue";
import ListMessages from "@/views/God_Admin/Messaging/ListMessages.vue";
import CurriculumList from "@/views/God_Admin/Curriculum/CurriculumList.vue";
import ParentRegistration from "@/views/Public_Pages/ParentRegistration.vue";
import ColearnerRegistration from "@/views/Public_Pages/ColearnerRegistration.vue";
import NewUserPassword from "@/views/Auth/NewUserPassword.vue";

const {refreshToken} = useRefreshToken();

const isAuthenticated = () => {
    const token = getValidToken()

    if (!token) {
        return false
    }

    const decodedToken = jwtDecode(token)
    const currentTime = Date.now() / 1000

    const isTokenExpired = decodedToken.exp < currentTime

    // Check if the user is authenticated by verifying the presence of a token in localStorage
    // console.log('isTokenExpired', isTokenExpired)
    return !isTokenExpired
}

// Create a route guard to protect routes that require authentication
const requireAuth = async (to, from, next) => {
    const currentUser = getCurrentUser();
    const userType = currentUser ? currentUser.userType : null;

    if (!isAuthenticated() && to.name !== 'Login') {
        try {
            await refreshToken();

            if (isAuthenticated()) {
                next();
            } else {
                next('/auth/login');
            }
        } catch (error) {
            console.error('Error refreshing token:', error);
            next('/auth/login');
        }
    } else {
        if (userType !== 'admin' && isAuthenticated()) {
            // Check if the current route is already SchoolDashboard to prevent infinite loop
            if (to.name !== 'SchoolDashboard') {
                next();
            } else {
                // We're already on SchoolDashboard, no need to redirect
                next();
            }
        } else {
            next();
        }
    }
};


const routes = [
    {
        path: '/',
        name: 'MasterLayout',
        component: MasterLayout,
        beforeEnter: requireAuth,
        children: [
            {
                path: '',
                name: 'Dashboard',
                component: Dashboard,
                meta: {
                    breadcrumb: 'Home'
                },
            },
            {
                path: 'components-layout',
                name: 'ComponentsLayout',
                component: ComponentsLayout,
            },
            {
                path: '/roles',
                component: RolesLayout,
                meta: {
                    breadcrumb: 'Roles'
                },
                children: [
                    {
                        path: '',
                        name: 'RolesList',
                        component: RolesList,
                        meta: {
                            breadcrumb: 'Roles'
                        },
                    },
                    {
                        path: 'details/:id',
                        name: 'ShowRoles',
                        component: ShowRoles,
                        meta: {
                            breadcrumb: 'Role Details'
                        },
                    }
                ]
            },
            {
                path: 'chat',
                name: 'ListMessages',
                component: ListMessages,
            },
            {
                path: '/users',
                component: UsersLayout,
                meta: {
                    breadcrumb: 'Users'
                },
                children: [
                    {
                        path: '',
                        name: 'UsersIndex',
                        component: UsersIndex,
                        meta: {
                            breadcrumb: 'Users'
                        },
                    },
                    {
                        path: 'details/:id',
                        name: 'ShowUser',
                        component: ShowUser,
                        meta: {
                            breadcrumb: 'User Profile'
                        },
                    }
                ]
            },
            {
                path: '/colearners',
                component: ColearnersLayout,
                meta: {
                    breadcrumb: 'Co-learners'
                },
                children: [
                    {
                        path: '',
                        name: 'ColearnersList',
                        component: ColearnersList,
                        meta: {
                            breadcrumb: 'Co-learners'
                        },
                    },
                    {
                        path: 'details/:id',
                        name: 'ShowColearner',
                        component: ShowColearner,
                        meta: {
                            breadcrumb: 'Co-learner Profile'
                        },
                        props: true
                    }
                ]
            },
            {
                path: '/schools',
                component: SchoolsLayout,
                meta: {
                    breadcrumb: 'Schools'
                },
                children: [
                    {
                        path: '',
                        name: 'SchoolsList',
                        component: SchoolsList,
                        meta: {
                            breadcrumb: 'Schools'
                        },
                    }
                ]
            },
            {
                path: '/students',
                component: StudentsLayout,
                meta: {
                    breadcrumb: 'Students'
                },
                children: [
                    {
                        path: '',
                        name: 'StudentsList',
                        component: StudentsList,
                        meta: {
                            breadcrumb: 'Students'
                        },
                    },
                    {
                        path: 'details/:id',
                        name: 'ShowStudent',
                        component: ShowStudent,
                        meta: {
                            breadcrumb: 'Student Profile'
                        },
                        props: true
                    }
                ]
            },
            {
                path: '/parents',
                component: ParentsLayout,
                meta: {
                    breadcrumb: 'Parents'
                },
                children: [
                    {
                        path: '',
                        name: 'ParentsList',
                        component: ParentsList,
                        meta: {
                            breadcrumb: 'Parents'
                        },
                    },
                    {
                        path: 'details/:id',
                        name: 'ShowParent',
                        component: ShowParent,
                        meta: {
                            breadcrumb: 'Parent Profile'
                        },
                        props: true
                    }
                ]
            },
            {
                path: '/plans',
                component: PlansLayout,
                meta: {
                    breadcrumb: 'Plans'
                },
                children: [
                    {
                        path: '',
                        name: 'PlansList',
                        component: PlansList,
                        meta: {
                            breadcrumb: 'Plans'
                        },
                    },
                    {
                        path: 'details/:id',
                        name: 'ShowPlan',
                        component: ShowPlan,
                        meta: {
                            breadcrumb: 'Plan Details'
                        },
                        props: true
                    }
                ]
            },
            {
                path: '/permissions',
                name: 'PermissionsList',
                component: PermissionsList,
                meta: {
                    breadcrumb: 'Permissions'
                },
            },
            {
                path: '/profile',
                name: 'Profile',
                component: Profile
            },
            {
                path: '/audit-logs',
                component: AuditLogLayout,
                meta: {
                    breadcrumb: 'Audit Log'
                },
                children: [
                    {
                        path: '',
                        name: 'AuditLogList',
                        component: AuditLogList,
                        meta: {
                            breadcrumb: 'Audit Log'
                        },
                    },
                    {
                        path: 'details/:id',
                        name: 'ShowAuditLog',
                        component: ShowAuditLog,
                        meta: {
                            breadcrumb: 'Log Details'
                        },
                        props: true
                    }
                ]
            },
            {
                path: 'keywords',
                name: 'KeywordsList',
                component: KeywordsList,
                meta: {
                    breadcrumb: 'Keywords'
                },
            },
            {
                path: '/subscriptions',
                component: SubscriptionsLayout,
                meta: {
                    breadcrumb: 'Subscriptions'
                },
                children: [
                    {
                        path: '',
                        name: 'SubscriptionList',
                        component: SubscriptionList,
                        meta: {
                            breadcrumb: 'Subscriptions'
                        },
                    },
                    {
                        path: 'details/:id',
                        name: 'ShowSubscription',
                        component: ShowSubscription,
                        meta: {
                            breadcrumb: 'Subscription Details'
                        },
                        props: true
                    }
                ]
            },
            {
                path: '/transactions',
                component: TransactionsLayout,
                meta: {
                    breadcrumb: 'Transactions'
                },
                children: [
                    {
                        path: '',
                        name: 'TransactionsList',
                        component: TransactionsList,
                        meta: {
                            breadcrumb: 'Transactions'
                        },
                    },
                    {
                        path: 'details/:id',
                        name: 'ShowTransaction',
                        component: ShowTransaction,
                        meta: {
                            breadcrumb: 'Transaction Details'
                        },
                        props: true
                    }
                ]
            },
            {
                path: 'checklist',
                name: 'ChecklistList',
                component: ChecklistList,
                meta: {
                    breadcrumb: 'Checklist'
                },
            },
            {
                path: 'curriculums',
                name: 'CurriculumList',
                component: CurriculumList,
                meta: {
                    breadcrumb: 'Curriculum'
                },
            }
        ]
    },
    {
        path: '/auth',
        name: 'NoBgLayout',
        component: NoBgLayout,
        children: [
            {
                path: 'login',
                name: 'Login',
                component: Login
            },
            {
                path: 'password-setup',
                name: 'PasswordReset',
                component: PasswordReset,
                props: true
            },
            {
                path: 'request-password',
                name: 'PasswordRequest',
                component: PasswordEmail
            },
            {
                path: 'new-user/password-setup',
                name: 'NewUserPassword',
                component: NewUserPassword,
                props: true
            }
        ]
    },
    {
        path: '/school',
        name: 'Public Pages',
        component: NoBgLayout,
        children: [
            {
                path: ':id/parent/sign-up',
                name: 'ParentRegistration',
                component: ParentRegistration
            },
            {
                path: ':id/teacher/sign-up',
                name: 'ColearnerRegistration',
                component: ColearnerRegistration
            }
        ]
    },
    {
        path: '/school',
        name: 'HorizontalMenuLayout',
        component: HorizontalMenuLayout,
        beforeEnter: requireAuth,
        children: [
            {
                path: 'dashboard',
                name: 'SchoolDashboard',
                component: SchoolDashboard,
                meta: {
                    breadcrumb: 'School Home'
                },
                props: true
            },
            {
                path: 'parents',
                component: SchoolParentsLayout,
                meta: {
                    breadcrumb: 'Parents'
                },
                children: [
                    {
                        path: '',
                        name: 'SchoolParentList',
                        component: SchoolParentList,
                        meta: {
                            breadcrumb: 'Parents'
                        }
                    },
                    {
                        path: 'details/:id',
                        name: 'ShowSchoolParent',
                        component: ShowSchoolParent,
                        meta: {
                            breadcrumb: 'Parent Details'
                        },
                        props: true
                    }
                ]
            },
            {
                path: 'students',
                component: SchoolsStudentsLayout,
                meta: {
                    breadcrumb: 'Students'
                },
                children: [
                    {
                        path: '',
                        name: 'SchoolStudentsList',
                        component: SchoolStudentsList,
                        meta: {
                            breadcrumb: 'Students'
                        }
                    },
                    {
                        path: 'details/:id',
                        name: 'ShowSchoolStudent',
                        component: ShowSchoolStudent,
                        meta: {
                            breadcrumb: 'Student Details'
                        },
                        props: true
                    }
                ]
            },
            {
                path: 'colearners',
                component: SchoolColearnerLayout,
                meta: {
                    breadcrumb: 'Colearners'
                },
                children: [
                    {
                        path: '',
                        name: 'SchoolColearnersList',
                        component: SchoolColearnersList,
                        meta: {
                            breadcrumb: 'Colearners'
                        }
                    },
                    {
                        path: 'details/:id',
                        name: 'ShowSchoolColearner',
                        component: ShowSchoolColearners,
                        meta: {
                            breadcrumb: 'Colearner Details'
                        },
                        props: true
                    }
                ]
            },
            {
                path: 'admins',
                component: SchoolUsersLayout,
                meta: {
                    breadcrumb: 'Admin Users'
                },
                children: [
                    {
                        path: '',
                        name: 'SchoolUsersList',
                        component: SchoolUsersList,
                        meta: {
                            breadcrumb: 'Admin Users'
                        }
                    },
                    {
                        path: 'details/:id',
                        name: 'ShowSchoolUser',
                        component: ShowSchoolUser,
                        meta: {
                            breadcrumb: 'Admin User Details'
                        },
                        props: true
                    }
                ]
            },
            {
                path: 'learning-groups',
                component: LearningGroupLayout,
                beforeEnter: requireAuth,
                meta: {
                    breadcrumb: 'Learning Groups'
                },
                children: [
                    {
                        path: '',
                        name: 'LearningGroupList',
                        component: LearningGroupsList,
                        meta: {
                            breadcrumb: 'Learning Groups'
                        },
                    },
                    {
                        path: 'details/:id',
                        name: 'ShowLearningGroup',
                        component: ShowLearningGroup,
                        meta: {
                            breadcrumb: 'Learning Group Details'
                        },
                        props: true
                    }
                ]
            },
            {
                path: 'projects',
                component: ProjectsLayout,
                beforeEnter: requireAuth,
                meta: {
                    breadcrumb: 'Projects'
                },
                children: [
                    {
                        path: '',
                        name: 'ProjectsList',
                        component: ProjectsList,
                        meta: {
                            breadcrumb: 'Projects'
                        },
                    },
                    {
                        path: 'details/:id',
                        name: 'ShowProject',
                        component: ShowProject,
                        meta: {
                            breadcrumb: 'Project Details'
                        },
                        props: true
                    },
                    {
                        path: 'create',
                        name: 'SetupProject',
                        component: SetupProject,
                        meta: {
                            breadcrumb: 'Setup a Project'
                        },
                        props: true
                    },
                    {
                        path: 'createFromTemplate',
                        name: 'EditProjectFromTemplate',
                        component: EditProjectFromTemplate,
                        meta: {
                            breadcrumb: 'Setup a Project'
                        },
                        props: true
                    }
                ]
            },
            {
                path: 'roles',
                component: SchoolRolesLayout,
                beforeEnter: requireAuth,
                meta: {
                    breadcrumb: 'Roles'
                },
                children: [
                    {
                        path: '',
                        name: 'SchoolRolesList',
                        component: SchoolRolesList,
                        meta: {
                            breadcrumb: 'Roles'
                        },
                    },
                    {
                        path: 'details/:id',
                        name: 'ShowSchoolRoles',
                        component: ShowSchoolRoles,
                        meta: {
                            breadcrumb: 'Role Details'
                        },
                    }
                ]
            },
            {
                path: 'profile',
                name: 'SchoolProfile',
                component: SchoolProfile
            },
            {
                path: 'permissions',
                name: 'SchoolPermissionList',
                component: SchoolPermissionList,
                meta: {
                    breadcrumb: 'Permissions'
                },
            },
            {
                path: 'subscriptions',
                name: 'SubscriptionDetail',
                component: SubscriptionDetail,
                meta: {
                    breadcrumb: 'Subscription Details'
                },
            },
            {
                path: 'select-plans',
                name: 'SchoolPlanSelection',
                component: SchoolPlanSelection,
                meta: {
                    breadcrumb: 'Select Plan'
                },
            },
            {
                path: 'SMEs',
                name: 'SMEList',
                component: SMEList,
                meta: {
                    breadcrumb: 'SMEs'
                },
            },
            {
                path: 'academic-years',
                name: 'AcademicYearList',
                component: AcademicYearList,
                meta: {
                    breadcrumb: 'academic-years'
                },
            },
            {
                path: 'terms',
                name: 'TermsList',
                component: TermsList,
                meta: {
                    breadcrumb: 'terms'
                },
            },
            {
                path: 'messages',
                name: 'ListSchoolMessages',
                component: ListSchoolMessages,
                meta: {
                    breadcrumb: 'messages'
                },
            }
        ]
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
