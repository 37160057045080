<template>
    <!-- start page title -->
    <div class="row">
        <div class="col-12">
            <div class="page-title-box">
                <div class="page-title-right">
                    <RoundedButton buttonText="Add Curriculum" iconText="mdi-plus" @clicked="openFormModal"
                                   v-if="hasPermission('update_keywords')"/>
                </div>
                <h4 class="page-title">Manage Curriculums</h4>
            </div>
        </div>
    </div>

    <div class="row mainContent">
        <div class="col-sm-12">
            <div class="card-box">
                <TableView :data="formattedCurriculumsData" :headers="tableHeaders" :id="idForActions"
                           :detailsData="getDataForDetailsView"
                           :loading="curriculumLoader" :searchStringFromParent.sync="searchStringFromChild"
                           :showActionsColumn="true" :showSearchField="true"
                           :editAction="editAction" :deleteAction="openConfirmationDialog"
                           :searchFields="searchFields"
                           viewPermission="view_keywords"
                           editPermission="update_keywords" deletePermission="deactivate_keywords"/>
            </div>
        </div>
    </div>

    <InfoModal :isModalOpen="isConfirmationDialogOpen" @closeModal="closeConfirmationDialog"
               :successImageURL="require('../../../assets/images/icons/success_icon.svg')"
               :errorImageURL="require('../../../assets/images/icons/error_icon.svg')" header="Hold on!"
               paragraph="You are about to delete this curriculum. This action cannot be undone once completed. Are you sure you want to continue this action?."
               buttonTxt="Yes, Delete Curriculum" :buttonAction="performAction" buttonColor="error"
               routerLinkText="No, Cancel Action" buttonLink="/"
               :linkAction="cancelAction" :loading="buttonLoader">
    </InfoModal>

    <FormModal :isFormModalOpen="isFormModalOpen" @closeFormModal="closeFormModal" modalHeader="Create Curriculum">
        <CreateCurriculum @closeFormModal="closeFormModal" @message="showFlashMessage"/>
    </FormModal>

    <FormModal :isFormModalOpen="isEditFormModalOpen" @closeFormModal="closeEditFormModal" modalHeader="Edit Curriculum">
        <EditCurriculum @closeFormModal="closeEditFormModal" @message="showFlashMessage" :itemData="curriculumDataForEditing"/>
    </FormModal>


    <MessageSliderCopy :isVisible="displayMessage" :messageText="messageText" :messageType="messageType"/>
</template>

<script>
import {computed, onActivated, onMounted, ref, watch} from "vue";
import RoundedButton from "@/components/common/RoundedButton.vue";
import TableView from "@/components/common/TableView.vue";
import FormModal from "@/components/common/FormModal.vue";
import InfoModal from "@/components/common/InfoModals.vue";
import MessageSliderCopy from "@/components/common/MessageSliderCopy.vue";
import {flashMessage} from "@/utils/flashMessage.js";
import {API_BASE_URL} from "@/config.js";
import {checkAndRefreshToken} from "@/utils/auth";
import {getValidToken, hasPermission} from "@/utils/helpers";
import {useUserStore} from "@/stores/user";
import getCurriculum from "@/composables/getCurriculum";
import CreateCurriculum from "@/views/God_Admin/Curriculum/CreateCurriculum.vue";
import EditCurriculum from "@/views/God_Admin/Curriculum/EditCurriculum.vue";

export default {
    components: {
        EditCurriculum,
        CreateCurriculum,
        RoundedButton,
        TableView,
        FormModal,
        MessageSliderCopy,
        InfoModal,
    },
    emits: ['showLoader', 'show-loader', 'flashMessage', 'data-loaded'],
    setup(props, {emit}) {
        const isFormModalOpen = ref(false);
        const isEditFormModalOpen = ref(false);
        const displayMessage = ref(false);
        const buttonLoader = ref(false);
        const messageText = ref("");
        const messageType = ref("");
        const newMessage = ref("");
        const showDetailsOrIcons = ref(false)
        const accessToken = getValidToken();
        const isConfirmationDialogOpen = ref(false);
        const curriculumData = ref(null)
        const curriculumId = ref('null')
        const searchStringFromChild = ref("");
        const searchFields = ['name']
        const {curriculumsData, fetchCurriculums, curriculumLoader, formattedCurriculumsData} = getCurriculum()
        document.title = "Manage Project Curriculum"

        const openFormModal = () => {
            isFormModalOpen.value = true;
        };

        const closeFormModal = async (message) => {
            isFormModalOpen.value = false;
            await fetchCurriculums()
        };

        const openEditFormModal = () => {
            isEditFormModalOpen.value = true;
        };

        const closeEditFormModal = async (message) => {
            isEditFormModalOpen.value = false;
            await fetchCurriculums()
        };

        onMounted(async () => {
            emit('data-loaded')
            await fetchCurriculums();
        })

        onActivated(() => {
            fetchCurriculums();
        })

        const idForActions = ref([])
        watch(() => curriculumsData.value, (newData) => {
            idForActions.value = newData.map((item) => item.id)
        })

        const getDataForDetailsView = computed(() => curriculumsData.value)

        const curriculumDataForEditing = ref(null)
        const editAction = (item, item_id) => {
            curriculumData.value = item

            const itemForEditing = curriculumsData.value.find((r) => r.id === item_id);

            curriculumDataForEditing.value = {
                name: itemForEditing.name,
                id: item_id
            }

            openEditFormModal()
        }

        const deleteAction = async (id) => {
            const apiUrl = `${API_BASE_URL}/curriculums/${id}`
            try {
                await checkAndRefreshToken()

                buttonLoader.value = true
                const response = await fetch(apiUrl, {
                    method: 'DELETE',
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`
                    }
                })

                if (response.ok) {
                    const result = await response.json()

                    if (result.code === 200) {
                        closeConfirmationDialog()

                        const msg = {
                            apiResponse: result.message,
                            type: "success"
                        }
                        showFlashMessage(msg)
                    } else {
                        const msg = {
                            apiResponse: result.message,
                            type: "success"
                        }
                        showFlashMessage(msg)
                        console.log('error', result.message)
                    }
                } else {
                    const result = await response.json();
                    console.log("error", result.message);
                }
            } catch (error) {
                console.error("An error occured:", error);
            } finally {
                buttonLoader.value = false
            }
        }

        const messageFromChild = ref("");
        const handleMessageFromChild = (message) => {
            messageFromChild.value = message
        };

        const showFlashMessage = (msg) => {
            flashMessage(
                msg.apiResponse,
                msg.type,
                messageText,
                messageType,
                displayMessage
            );
        };

        const tableHeaders = computed(() => {
            return [
                {label: "Name", key: "name"},
                {label: "Created On", key: "date"},
                {label: "Time Stamp", key: "time"},
            ];
        });

        const openConfirmationDialog = (detail, id) => {
            curriculumId.value = id
            isConfirmationDialogOpen.value = true;
        };

        const closeConfirmationDialog = () => {
            isConfirmationDialogOpen.value = false;
            fetchCurriculums()
        };

        const performAction = () => {
            deleteAction(curriculumId.value)
        }

        const cancelAction = () => {
            closeConfirmationDialog()
        }

        const userStore = useUserStore();
        const user = ref(userStore.user)
        const userPermissions = ref(user.value.permissions)
        const checkPermission = (permission) => hasPermission(userPermissions, permission)

        return {
            tableHeaders,
            formattedCurriculumsData,
            idForActions,
            searchStringFromChild,
            isFormModalOpen,
            openFormModal,
            closeFormModal,
            handleMessageFromChild,
            showFlashMessage,
            displayMessage,
            messageText,
            messageType,
            newMessage,
            showDetailsOrIcons,
            editAction,
            deleteAction,
            InfoModal,
            isConfirmationDialogOpen,
            openConfirmationDialog,
            closeConfirmationDialog,
            performAction,
            cancelAction,
            buttonLoader,
            isEditFormModalOpen,
            openEditFormModal,
            closeEditFormModal,
            curriculumData,
            curriculumDataForEditing,
            curriculumLoader,
            getDataForDetailsView,
            searchFields,
            hasPermission: checkPermission
        };
    },
};
</script>

<style scoped>
.mainContent {
    margin-top: 20px;
}
</style>