import {API_BASE_URL} from "@/config.js";
import {capitalizeFirstLetter, formatDateTime, getSchoolId, getUserInitials, getValidToken} from "@/utils/helpers.js";
import {computed, ref} from "vue";
import {checkAndRefreshToken} from '@/utils/auth';

const loading = ref(false);

const studentsPayloadData = ref(null);
const studentsData = ref([])

const fetchStudentsData = async (page, perPage) => {
    const schoolId = getSchoolId()
    const apiUrl = `${API_BASE_URL}/school/${schoolId}/students`;


    try {
        await checkAndRefreshToken()

        const accessToken = getValidToken()
        if (!accessToken) {
            console.error("Access token not available.");
            return;
        }

        loading.value = true;
        const apiUrlWithParams = `${apiUrl}?page=${page}&per_page=${perPage}`

        const response = await fetch(apiUrlWithParams, {
            headers: {
                Authorization: `Bearer ${accessToken}`, // Include the access token in the headers
            },
        });

        if (response.ok) {
            const result = await response.json();

            if (result.code === 200) {
                studentsPayloadData.value = result.data
                studentsData.value = result.data.results.students

                // console.log('studentsData.value', studentsData.value)
            } else {
                console.log("error", result.message);
            }
        } else {
            console.log("response", response);
            console.error("Error fetching data from the API");
        }
    } catch (error) {
        console.error("An error occured:", error);
    } finally {
        loading.value = false;
    }
};


const formattedStudentsData = computed(() => {
    // Map and format the data
    return studentsData.value.map((student) => {

        // Format the created at
        const {date, time} = formatDateTime(student.created_at);

        const formattedStudent = {
            user: {
                name: capitalizeFirstLetter(`${student.first_name} ${student.last_name}`),
                initials: getUserInitials(student.first_name, student.last_name),
                avatarColor: 'danger',
                profileImage: student.profile_image
            },
            age: student.age,
            gender: student._gender,
            school: student.school || '-',
            createdAt: date,
            status: student.isDeactivated ? 'Deactivated' : 'Active',
        };

        return {
            ...formattedStudent,
        };
    });
});

const formattedStudentsWithoutSchoolData = computed(() => {
    // Map and format the data
    return studentsData.value.map((student) => {

        // Format the created at
        const {date, time} = formatDateTime(student.created_at);

        const formattedStudent = {
            user: {
                name: capitalizeFirstLetter(`${student.first_name} ${student.last_name}`),
                initials: getUserInitials(student.first_name, student.last_name),
                avatarColor: 'danger',
                profileImage: student.profile_image
            },
            age: student.age,
            gender: student._gender,
            createdAt: date,
            status: student.isDeactivated ? 'Deactivated' : 'Active',
        };

        return {
            ...formattedStudent,
        };
    });
});

const getSchoolStudents = () => {
    return {studentsPayloadData, fetchStudentsData, loading, formattedStudentsData, studentsData, formattedStudentsWithoutSchoolData}
}

export default getSchoolStudents