<template>
    <div class="page-container" v-if="isOpen">
        <div class="page-content">
            <!-- Content of the mini page -->

            <div class="close-icon-container">
                <img
                    src="../../assets/images/icons/close_icon.svg"
                    alt="close icon"
                    height="35"
                    width="35"
                    class="close-icon"
                    @click="closeMiniPage"
                />
            </div>
            <slot></slot>
        </div>
    </div>

</template>

<script>
export default {
    props: {
        isOpen: Boolean,
    },

    setup(props, { emit }) {
        const closeMiniPage = () => {
            emit("closeMiniPage");
        };

        return { closeMiniPage };
    },
};
</script>

<style scoped>
.page-container {
    position: fixed;
    top: 0;
    right: 0; /* Initial position, outside of the viewport */
    width: 420px;
    height: 100%;
    z-index: 10000;
    background-color: #fff;
    transition: right 0.3s ease-in-out; /* Animation duration and easing */
    border-radius: 10px 0 0 10px;
    overflow-y: auto;
}

.page-content {
    padding: 20px;
}

.close-icon-container {
    position: relative;
    z-index: 10000;
}
.close-icon {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
}
</style>