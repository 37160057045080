<template>
    <div class="form-content">
        <form @submit.prevent="saveNewRole">
            <TextInputFieldVue :config="{
                class: 'form-control-gray mb-30',
                placeholder: 'Enter name of role',
                inputFieldType: 'text',
            }" labelText="Role Name" errorType="nameError" v-model="name" />

            <TextAreaInputField :config="{
                class: 'form-control-gray mb-100',
                placeholder: 'Enter description here',
                inputFieldType: 'description',
            }" labelText="Description" errorType="descriptionError" v-model="description" />

            <RoundedButton buttonText="Add Role" class="custom-button" buttonType="submit" :loading="loading" />
        </form>
    </div>
</template>

<script>
import TextInputFieldVue from "@/components/common/TextInputField.vue";
import TextAreaInputField from "@/components/common/TextAreaInputField.vue";
import RoundedButton from "@/components/common/RoundedButton.vue";
import {ref} from "vue";
import {API_BASE_URL} from "@/config.js";
import {getValidToken} from "@/utils/helpers";

export default {
    components: {
        TextInputFieldVue,
        TextAreaInputField,
        RoundedButton,
    },
    setup(props, { emit }) {
        const loading = ref(false);
        const loadingSpinner = ref(false);
        const name = ref("");
        const description = ref("");
        const accessToken = getValidToken();
        const message = ref("");

        const showLoaders = () => {
            loading.value = true;
            loadingSpinner.value = true;
        };

        const hideLoaders = () => {
            loading.value = false;
            loadingSpinner.value = false;
        };

        const saveNewRole = async () => {
            try {
                showLoaders();
                const response = await fetch(
                    `${API_BASE_URL}/role-permission/role`,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${accessToken}`,
                        },
                        body: JSON.stringify({
                            name: name.value,
                            description: description.value,
                        }),
                    }
                );

                if (response.ok) {
                    const result = await response.json();
                    if (result.code === 200) {
                        message.value = result.message;

                        emit("closeFormModal");
                        emit("message", {
                            apiResponse: message.value,
                            type: "success",
                        });
                    } else {
                        console.log("code error", result.message);

                        emit("closeFormModal");
                        emit("message", {
                            apiResponse: result.message,
                            type: "error",
                        });
                    }
                } else {
                    const result = await response.json();
                    console.log("ok error", result.message);
                }
            } catch (error) {
                console.error("An error occured:", error);
            } finally {
                hideLoaders();
            }
        };

        return {
            loading,
            loadingSpinner,
            showLoaders,
            hideLoaders,
            name,
            description,
            saveNewRole,
        };
    },
};
</script>

<style>

</style>