<template>
    <div class="form-content">
        <form @submit.prevent="saveNewItem">
            <TextInputFieldVue :config="{
                class: 'form-control-gray mb-30',
                placeholder: 'Enter name of learning group',
                inputFieldType: 'text',
            }" labelText="Learning Group Name" errorType="nameError" v-model="name" :isRequired="true" />

            <TextAreaInputField :config="{
                class: 'form-control-gray mb-100',
                placeholder: 'Enter description here',
                inputFieldType: 'description',
            }" labelText="Description" errorType="descriptionError" v-model="description" :isRequired="true"/>

            <RoundedButton buttonText="Submit" class="custom-button" buttonType="submit" :loading="loading" />
        </form>
    </div>
</template>

<script>
import TextInputFieldVue from "@/components/common/TextInputField.vue";
import TextAreaInputField from "@/components/common/TextAreaInputField.vue";
import RoundedButton from "@/components/common/RoundedButton.vue";
import {ref} from "vue";
import {API_BASE_URL} from "@/config.js";
import {capitalizeFirstLetter, getSchoolId, getValidToken} from "@/utils/helpers";

export default {
    components: {
        TextInputFieldVue,
        TextAreaInputField,
        RoundedButton,
    },
    setup(props, { emit }) {
        const loading = ref(false);
        const loadingSpinner = ref(false);
        const name = ref("");
        const description = ref("");
        const accessToken = getValidToken();

        const showLoaders = () => {
            loading.value = true;
            loadingSpinner.value = true;
        };

        const hideLoaders = () => {
            loading.value = false;
            loadingSpinner.value = false;
        };

        const saveNewItem = async () => {
            try {
                const schoolId = getSchoolId()
                const apiUrl = `${API_BASE_URL}/school/${schoolId}/learning-groups`
                showLoaders();

                const response = await fetch(apiUrl, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${accessToken}`,
                        },
                        body: JSON.stringify({
                            name: name.value,
                            description: description.value,
                        }),
                    }
                );

                if (response.ok) {
                    const result = await response.json();
                    if (result.code === 200) {
                        const itemFullName = capitalizeFirstLetter(name.value)
                        emit("closeFormModal", itemFullName);
                    } else {
                        flashAlertMessage('error', result.message)
                        console.log("error", result.message);
                    }
                } else {
                    const result = await response.json();
                    flashAlertMessage('error', result.message)
                    console.log("error", result.message);
                }
            } catch (error) {
                flashAlertMessage('error', error)
                console.error("An error occured:", error);
            } finally {
                hideLoaders();
            }
        };

        const flashAlertMessage = (alertType, alertMessage) => {
                    emit('flash-message', {
                        alertType: alertType,
                        alertMessage: alertMessage
                    })
                }

        return {
            loading,
            loadingSpinner,
            showLoaders,
            hideLoaders,
            name,
            description,
            saveNewItem,
        };
    },
};
</script>

<style>

</style>