<template>
    <form>
        <div v-if="currentPage === 1">
            <div class="row mb-30">
                <div class="col-lg-6 first_name">
                    <TextInputField :config="{
                        class: 'form-control-gray',
                        placeholder: 'Enter First Name',
                        inputFieldType: 'text'
                    }" labelText="First Name" :errorMessage="firstNameError" v-model="firstName"/>
                </div>

                <div class="col-lg-6">
                    <TextInputField :config="{
                    class: 'form-control-gray',
                    placeholder: 'Enter Last Name',
                    inputFieldType: 'text'
                }" labelText="Last Name" :errorMessage="lastNameError" v-model="lastName"/>
                </div>
            </div>

            <div class="row mb-30">
                <div class="col-12">
                    <SimpleDropdown labelText="Gender" :options="genderSelectOptions" v-model="selectedGender"
                                    selectId="genderOptions" :selectedItem="selectedGender" :loading="genderLoader"/>
                </div>
            </div>

            <div class="row mb-30">
                <div class="col-lg-6 country">
                    <SimpleDropdown labelText="Country of Residence" :options="countrySelectOptions"
                                    v-model="selectedCountry"
                                    :loading="countryDropdownLoader" selectId="countryOptions"
                                    :selectedItem="selectedCountry" @change="handleCountrySelection"/>
                </div>

                <div class="col-lg-6">
                    <SimpleDropdown labelText="State of Residence" :options="stateSelectOptions" v-model="selectedState"
                                    :loading="statesDropdownLoader"
                                    selectId="stateOptions" :selectedItem="selectedState"/>
                </div>
            </div>

            <div class="row mb-50">
                <div class="col-12">
                    <TextAreaInputField :config="{
                        class: 'form-control-gray',
                        placeholder: 'Enter Address',
                        inputFieldType: 'address',
                    }" labelText="Residential Address" :errorMessage="addressError" v-model="address"/>
                </div>
            </div>
        </div>

        <div v-if="currentPage === 2">
            <div class="row mb-30">
                <div class="col-12">
                    <TextInputField :config="{
                        class: 'form-control-gray',
                        placeholder: 'Enter Email',
                        inputFieldType: 'email'}" labelText="Work Email" v-model="workEmail"
                                    :isRequired="true" :errorMessage="workEmailError"/>
                </div>
            </div>

            <div class="row mb-30">
                <div class="col-12">
                    <TextInputField :config="{
                        class: 'form-control-gray',
                        placeholder: 'Enter Contact Number',
                        inputFieldType: 'tel'}" labelText="Work Contact Number" v-model="workMsisdn"
                                    :isRequired="true" :errorMessage="workMsisdnError"/>
                </div>
            </div>

            <div class="row mb-50">
                <div class="col-12">
                    <TextAreaInputField :config="{
                        class: 'form-control-gray',
                        placeholder: 'Enter Address',
                        inputFieldType: 'text'}" labelText="Office Address" :errorMessage="workAddressError"
                                        v-model="workAddress"/>
                </div>
            </div>
        </div>

        <div v-if="currentPage === 3">
            <SingleSelectField
                class="mb-30"
                labelText="Father or Mother?"
                :options="parentOptions"
                v-model="selectedParent"
                selectId="parentOptions"
                :selectedItem="selectedParent"/>


            <TextInputField :config="{
                    class: 'form-control-gray mb-30',
                    inputFieldType: 'text',
                    placeholder: 'English, French...'
                        }" labelText="Child's First Language"
                            v-model="child_first_language"
                            isRequired/>

            <SingleSelectField
                class="mb-30"
                labelText="Has this parent sent the child's birth certificate?"
                :options="yesOrNoOptions"
                v-model="selectedConfirmationOption"
                selectId="yesOrNoOptions"
                :selectedItem="selectedConfirmationOption"/>

            <SingleSelectField
                class="mb-30"
                labelText="Has this parent agreed to terms and conditions?"
                :options="termsAndConditionsOptions"
                v-model="selectedTermsAndConditionsOption"
                selectId="termsAndConditionsOptions"
                :selectedItem="selectedTermsAndConditionsOption"/>
        </div>


        <div class="mt-20 table-pagination">
            <ul class="pagination pagination-rounded">
                <li v-for="page in pages" :key="page" :class="{ active: currentPage === page }" class="page-item">
                    <a href="#" class="page-link" @click="changePage(page)">{{ page }}</a>
                </li>
            </ul>

            <RoundedButton :buttonText="currentPage < pages ? 'Next' : 'Submit'" class="custom-button"
                           @click="nextPage" :loading="buttonLoading"/>
        </div>

    </form>
</template>
<script>
import TextInputField from "@/components/common/TextInputField.vue";
import SimpleDropdown from "@/components/common/SimpleDropdown.vue";
import TextAreaInputField from "@/components/common/TextAreaInputField.vue";
import RoundedButton from "@/components/common/RoundedButton.vue";
import {onMounted, ref, watch} from "vue";
import getCountries from "@/composables/getCountries";
import getStates from "@/composables/getStates";
import {capitalizeFirstLetter, getValidToken} from '@/utils/helpers'
import getGender from "@/composables/getGender";
import {checkAndRefreshToken} from "@/utils/auth";
import {API_BASE_URL} from "@/config";
import SingleSelectField from "@/components/common/SingleSelectField.vue";


export default {
    props: {
        itemData: {
            type: Object,
            required: true
        }
    },
    components: {
        SingleSelectField,
        TextInputField,
        SimpleDropdown,
        TextAreaInputField,
        RoundedButton,
    },
    setup(props, {emit}) {
        const buttonLoading = ref(false)
        const message = ref('')
        const {fetchCountriesData, countriesList, loading: countryDropdownLoader} = getCountries()
        const {fetchStatesData, statesList, loading: statesDropdownLoader} = getStates()
        const {fetchGender, genderList, loading: genderLoader} = getGender()
        const genderSelectOptions = ref([])
        const countrySelectOptions = ref([])
        const stateSelectOptions = ref([])
        const selectedGender = ref(props.itemData.gender)
        const selectedCountry = ref(props.itemData.country)
        const selectedState = ref(props.itemData.state)
        const firstName = ref(props.itemData.firstName)
        const lastName = ref(props.itemData.lastName)
        const address = ref(props.itemData.address)
        const workEmail = ref(props.itemData.workEmail)
        const workMsisdn = ref(props.itemData.workMsisdn)
        const workAddress = ref(props.itemData.workAddress)
        const countryIdMap = ref({});
        const pages = 3
        const currentPage = ref(1);
        const firstNameError = ref('')
        const lastNameError = ref('')
        const addressError = ref('')
        const workEmailError = ref('')
        const workMsisdnError = ref('')
        const workAddressError = ref('')
        const yesOrNoOptions = ref([])
        const selectedConfirmationOption = ref('')
        const selectedTermsAndConditionsOption = ref('')
        const termsAndConditionsOptions = ref([])
        const child_first_language = ref(props.itemData.childFirstLanguage)
        const parentOptions = ref([])
        const selectedParent = ref('')


        onMounted(() => {
            fetchGender()
            fetchCountriesData()
        })

        const validatePage1 = () => {
            let isValid = true

            firstNameError.value = ''
            lastNameError.value = ''
            addressError.value = ''

            if (firstName.value.length === 0) {
                firstNameError.value = 'Enter a first name'
                isValid = false
            }


            if (lastName.value.length === 0) {
                lastNameError.value = 'Enter a last name'
                isValid = false
            }


            return isValid
        }

        const validatePage2 = () => {
            let isValid = true

            workEmailError.value = ''
            workMsisdnError.value = ''
            workAddressError.value = ''

            if (workEmail.value.length === 0) {
                workEmailError.value = 'Enter a work email'
                isValid = false
            }

            if (workMsisdn.value.length === 0) {
                workMsisdnError.value = 'Enter a work contact number'
                isValid = false
            }


            return isValid
        }

        const nextPage = () => {
            if (currentPage.value === 1) {
                if (validatePage1() && currentPage.value < pages) {
                    currentPage.value++;
                } else {
                    fetchCountriesData()
                    fetchGender()
                }
            } else if (currentPage.value === 2) {
                if (validatePage2()) {
                    currentPage.value++
                }
            } else {
                updateItem()
            }
        };

        watch(currentPage, (newData) => {
            if (newData === 1) {
                fetchCountriesData()
                fetchGender()
            } else if (newData === 3) {
                setTimeout(() => {
                    yesOrNoOptions.value = [
                        {label: "Yes", value: 'yes'},
                        {label: "No", value: 'no'}
                    ]

                    termsAndConditionsOptions.value = [
                        {label: "Yes", value: 'yes'},
                        {label: "No", value: 'no'}
                    ]

                    parentOptions.value = [
                        {label: "Father", value: 'father'},
                        {label: "Mother", value: 'mother'}
                    ]
                }, 500)

                setTimeout(() => {
                    selectedConfirmationOption.value = props.itemData.hasEmailedChildKyc === true ? 'yes' : 'no'
                    selectedTermsAndConditionsOption.value = props.itemData.agreeWithTerms === true ? 'yes' : 'no'
                    selectedParent.value = props.itemData.relationshipToStudent
                }, 1000)
            }
        })

        const changePage = (page) => {
            if (currentPage.value === 1) {
                if (validatePage1()) {
                    currentPage.value = page;
                }
            } else if (currentPage.value === 2) {
                if (validatePage2()) {
                    currentPage.value = page;
                }
            } else {
                currentPage.value = page;
            }
        }

        const updateGenderOptions = (newData) => {
            genderSelectOptions.value = newData.map(item => ({
                label: capitalizeFirstLetter(item.label), value: item.value
            }))

            if (genderSelectOptions.value.length > 0) {
                selectedGender.value = props.itemData.gender
            }
        }

        watch(genderList, (newData) => {
            updateGenderOptions(newData)
        })

        // COUNTRIES
        const handleCountrySelection = () => {
            const selectedCountryId = countryIdMap.value[selectedCountry.value]
            fetchStatesData(selectedCountryId)
        }

        // Update the country dropdown when the modal is opened
        const updateCountryOptions = (newData) => {
            countrySelectOptions.value = newData.map(country => ({
                label: country.country_name,
                value: country.country_name
            }))

            countryIdMap.value = newData.reduce((map, country) => {
                map[country.country_name] = country.id;
                return map;
            }, {});

            if (countrySelectOptions.value.length > 0) {
                selectedCountry.value = props.itemData.country
                handleCountrySelection()
            }
        }

        watch(countriesList, (newData) => {
            updateCountryOptions(newData)
        })

        // STATES
        // Update the states options
        const updateStatesOptions = (newData) => {
            stateSelectOptions.value = newData.map(state => ({
                label: state.state_name,
                value: state.state_name
            }))

            if (stateSelectOptions.value.length > 0) {
                selectedState.value = selectedCountry.value ? props.itemData.state : stateSelectOptions.value[0].value
            }
        }

        watch(statesList, (newData) => {
            updateStatesOptions(newData)
        })

        // OTHERS
        const showLoaders = () => {
            buttonLoading.value = true;
        };

        const hideLoaders = () => {
            buttonLoading.value = false;
        };

        const apiUrl = `${API_BASE_URL}/parent/update-parent?user_id=${props.itemData.id}`
        const updateItem = async () => {
            const item = {
                first_name: firstName.value,
                last_name: lastName.value,
                gender: selectedGender.value,
                country: selectedCountry.value,
                state: selectedState.value,
                address: address.value,
                work_email: workEmail.value,
                work_msisdn: workMsisdn.value,
                work_address: workAddress.value,
                child_first_language: child_first_language.value,
                languages_spoken_at_home: child_first_language.value,
                has_emailed_child_kyc: selectedConfirmationOption.value === 'yes',
                agree_with_terms: selectedTermsAndConditionsOption.value === 'yes',
                relationship_to_student: selectedParent.value,
            }

            // console.log('item', item)

            try {
                showLoaders()
                await checkAndRefreshToken()
                const accessToken = getValidToken()

                const response = await fetch(apiUrl, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    },
                    body: JSON.stringify(item)
                })

                if (response.ok) {
                    const result = await response.json();

                    if (result.code === 200) {
                        message.value = result.message;

                        emit("closeFormModal");
                        emit("refreshPage");
                        emit("message", {
                            apiResponse: "Parent updated successfully",
                            type: "success",
                        });
                    } else {
                        console.log("error", result.message);
                    }
                } else {
                    const result = await response.json();
                    console.log("error", result.message);
                }
            } catch (error) {
                console.error("An error occured:", error);
            } finally {
                hideLoaders();
            }
        }

        return {
            buttonLoading,
            updateItem,
            firstName,
            lastName,
            genderSelectOptions,
            selectedGender,
            countrySelectOptions,
            selectedCountry,
            countryDropdownLoader,
            statesDropdownLoader,
            handleCountrySelection,
            selectedState,
            stateSelectOptions,
            address,
            genderLoader,
            workEmail,
            workMsisdn,
            workAddress,
            pages,
            nextPage,
            changePage,
            currentPage,
            firstNameError,
            lastNameError,
            addressError,
            workEmailError,
            workMsisdnError,
            workAddressError,
            yesOrNoOptions,
            selectedConfirmationOption,
            selectedTermsAndConditionsOption,
            termsAndConditionsOptions,
            child_first_language,
            selectedParent,
            parentOptions
        }
    }
}
</script>
<style scoped>
.table-pagination {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.page-item.active .page-link {
    border-color: #2f5bcf;
    z-index: 3;
    color: #fff;
}

.page-item:first-child .page-link {
    margin-left: 0;
}

.pagination-rounded .page-link {
    border-radius: 30px !important;
    margin: 0 3px;
    border: none;
}

.page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.9rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #323a46;
    background-color: #fff;
    border: 1px solid #dee2e6;
}

@media (max-width: 767px) {
    .first_name, .country {
        margin-bottom: 30px;
    }
}

@media (max-width:992px) {
    .first_name, .country {
        margin-bottom: 30px;
    }
}
</style>