<template>
    <div class="row">
        <div class="col-12">
            <div class="page-title-box">
                <h4 class="page-title">Manage Subscriptions</h4>
            </div>
        </div>
    </div>

    <div class="row">
        <StatCards statCardText="Active" :statsCardNumber="activePlans" columnClass="col-lg-3 col-sm-12"/>
        <StatCards statCardText="Paused" :statsCardNumber="pausedPlans" columnClass="col-lg-3 col-sm-12"/>
        <StatCards statCardText="Expired" :statsCardNumber="expiredPlans" columnClass="col-lg-3 col-sm-12"/>
        <StatCards statCardText="Canceled" :statsCardNumber="cancelledPlans" columnClass="col-lg-3 col-sm-12"/>
    </div>

    <div class="row mainContent">
        <div class="col-sm-12">
            <div class="card-box">
                <TableView :data="formattedSubscriptionData" :headers="tableHeaders"
                           :id="getIdForActions" :showSearchField="true"
                           :detailsData="getDataForDetailsView" :loading="loading"
                           :searchStringFromParent.sync="searchStringFromChild"
                           :showActionsColumn="false"
                           :searchFields="searchFieldsData" :totalPages="totalPages" :currentPage.sync="currentPage"
                           :changePage="changePage"
                           :changeItemsPerPage="changeItemsPerPage" :itemsPerPage="itemsPerPage"/>
            </div>
        </div>
    </div>
</template>
<script>
import StatCards from "@/components/common/StatCards.vue";
import RoundedButton from "@/components/common/RoundedButton.vue";
import {computed, onMounted, ref, watch} from "vue";
import getSubscriptionInfo from "@/composables/getSubscriptionInfo";
import getSubscriptions from "@/composables/getSubscriptions";
import TableView from "@/components/common/TableView.vue";

export default {
    props: {},
    components: {TableView, RoundedButton, StatCards},
    emits: ['showLoader', 'show-loader', 'flashMessage', 'data-loaded'],
    setup(props, {emit}) {
        document.title = 'Manage Subscriptions'
        const {fetchSubscriptionInfoData, loading: subInfoLoader, subscriptionInfoData} = getSubscriptionInfo()
        const {
            subscriptionsPayload,
            fetchSubscriptionData,
            loading,
            formattedSubscriptionData,
            subscriptionData
        } = getSubscriptions()

        const activePlans = ref(0)
        const cancelledPlans = ref(0)
        const expiredPlans = ref(0)
        const pausedPlans = ref(0)
        const currentPage = ref(1)
        const itemsPerPage = ref(10)
        const totalPages = ref(0)

        onMounted(() => {
            fetchSubscriptionInfoData()
            fetchSubscriptionData(currentPage.value, itemsPerPage.value)

            emit('data-loaded')
        })

        watch(subscriptionInfoData, (item) => {
            activePlans.value = item.active_plans
            cancelledPlans.value = item.cancelled_plans
            expiredPlans.value = item.expired_plans
            pausedPlans.value = item.paused_plans
        })

        watch(subscriptionData, () => {
            totalPages.value = subscriptionsPayload.value.total_pages
        })

        const searchStringFromChild = ref("");
        const searchFieldsData = ['user.name', 'createdAt', 'current_plan', 'status']

        const tableHeaders = computed(() => {
            return [
                {label: "School", key: "name"},
                {label: "Start Date", key: "createdAt"},
                {label: "Current Plan", key: "current_plan"},
                {label: "Amount", key: "amount"},
                {label: "Next Billing Date", key: "next_billing_date"},
                {label: "Status", key: "new_status"}
            ];
        });

        const getIdForActions = computed(() => {
            return subscriptionData.value.map((user) => user.user_id);
        });

        const getDataForDetailsView = computed(() => {
            return subscriptionData.value
        })

        const viewAction = (item, id) => {
            router.push(
                {
                    name: 'ShowColearner',
                    params: {id: id}
                })
        }

        const changePage = (page) => {
            if (page >= 1 && page <= totalPages.value) {
                currentPage.value = page;

                fetchSubscriptionData(currentPage.value, itemsPerPage.value)
            }
        };

        const changeItemsPerPage = (perPage) => {
            itemsPerPage.value = perPage;
            currentPage.value = 1;

            fetchSubscriptionData(currentPage.value, perPage);
        };

        return {
            activePlans,
            cancelledPlans,
            expiredPlans,
            pausedPlans,
            searchStringFromChild,
            searchFieldsData,
            tableHeaders,
            getIdForActions,
            getDataForDetailsView,
            viewAction,
            formattedSubscriptionData,
            loading,
            currentPage,
            itemsPerPage,
            totalPages,
            subscriptionData,
            changePage,
            changeItemsPerPage
        }
    }
}
</script>
<style></style>