import {API_BASE_URL} from "@/config.js";
import {capitalizeFirstLetter, formatDateTime, getSchoolId, getValidToken} from "@/utils/helpers.js";
import {computed, ref} from "vue";
import {checkAndRefreshToken} from '@/utils/auth';

const curriculumLoader = ref(false);
const curriculumsData = ref([]);

const fetchCurriculums = async (page, perPage) => {
    const schoolId = getSchoolId()
    const apiUrl = `${API_BASE_URL}/curriculums/all`;

    try {
        curriculumLoader.value = true;
        await checkAndRefreshToken()

        const accessToken = getValidToken()
        if (!accessToken) {
            console.error("Access token not available.");
            return;
        }

        const response = await fetch(apiUrl, {
            headers: {
                Authorization: `Bearer ${accessToken}`, // Include the access token in the headers
            },
        });

        if (response.ok) {
            const result = await response.json();

            if (result.code === 200) {
                curriculumsData.value = result.data

            } else {
                console.log("error", result.message);
            }
        } else {
            console.log("response", response);
            console.error("Error fetching data from the API");
        }
    } catch (error) {
        console.error("An error occurred:", error);
    } finally {
        curriculumLoader.value = false;
    }
};

const formattedCurriculumsData = computed(() => {
    // Map and format the data
    return curriculumsData.value.map((item) => {

        // Format the created at
        const {date, time} = formatDateTime(item.created_at);

        const formattedData = {
            name: capitalizeFirstLetter(item.name),
            date,
            time
        };

        return {
            ...formattedData,
        };
    });
});


const getCurriculums = () => {
    return {curriculumsData, fetchCurriculums, curriculumLoader, formattedCurriculumsData}
}

export default getCurriculums