<template>
    <form @submit.prevent="submitForm">
        <div>
            <div class="row mb-30">
                <div class="col-12">
                    <TextInputField :config="{
                        class: 'form-control-gray',
                        placeholder: 'Enter email address of parent',
                        inputFieldType: 'email',
                    }" labelText="Email Address" v-model="email" :isRequired="true"/>
                </div>
            </div>

            <RegularButton buttonText="Submit" class="custom-button" buttonType="submit" :loading="buttonLoading"/>

        </div>

    </form>
</template>
<script>
import RoundedButton from "@/components/common/RoundedButton.vue";
import TextInputField from "@/components/common/TextInputField.vue";
import {ref} from "vue";
import {checkAndRefreshToken} from "@/utils/auth";
import {getValidToken} from "@/utils/helpers";
import {API_BASE_URL} from "@/config";
import RegularButton from "@/components/common/RegularButton.vue";

export default {
    props: {
    },
    components: {RegularButton, TextInputField, RoundedButton},
    setup(props, {emit}) {
        const email = ref('')
        const buttonLoading = ref(false)

        const submitForm = async () => {

            try {
                buttonLoading.value = true
                await checkAndRefreshToken()
                const accessToken = getValidToken()
                const apiUrl = `${API_BASE_URL}/auth/invite-link`

                const response = await fetch(apiUrl, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    },
                    body: JSON.stringify({
                        email: email.value,
                        type: 'parent'
                    })
                })


                if (response.ok) {
                    const result = await response.json();

                    if (result.code === 200) {
                        const msg = {
                            'type': 'success',
                            'message': `Success: An invitation has been sent to ${email.value}`
                        }
                        emit("closeFormModal", msg);
                    } else {
                        console.log("code error", result.message);
                        const msg = {
                            'type': 'error',
                            'message': "An error occurred"
                        }
                        emit("closeFormModal", msg);
                    }
                } else {
                    const result = await response.json();
                    console.log("ok error", result.message);

                    const msg = {
                        'type': 'error',
                        'message': "An error occurred"
                    }
                    emit("closeFormModal", msg);
                }
            } catch (error) {
                console.error("An error occured:", error);

                const msg = {
                    'type': 'error',
                    'message': "An error occurred"
                }
                emit("closeFormModal", msg);
            } finally {
                buttonLoading.value = false
            }
        }

        return{
            email,
            buttonLoading,
            submitForm
        }
    }
}
</script>