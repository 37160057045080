<template>
    <div class="linear-loader" v-if="loading">
        <div class="train"></div>
    </div>
</template>

<script>
import {ref, watchEffect} from "vue";

export default {
    name: "LinearLoader",
    props: {
        loading: Boolean,
        height: {
            type: String,
            default: '7px'
        }
    },
    setup(props) {
        const loading = ref(props.loading);

        watchEffect(() => {
            loading.value = props.loading;
        });

        return { loading };
    },
};
</script>

<style scoped>
.linear-loader {
    height: 7px; /* Adjust the height as needed */
    background-color: #89a6f3; /* Adjust the color as needed */
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    /* animation: moveRight 1s linear infinite; Adjust the animation speed as needed */
    //z-index: 9999; /* Ensure it's on top of other elements */
}

.train {
    width: 900px; /* Adjust the train's width */
    height: 7px; /* Should match the height of the loader */
    background-color: #2f5bcf; /* Adjust the color as needed */
    position: absolute;
    animation: moveRight 1s linear infinite; /* Adjust the animation speed as needed */
    top: 0;
    left: 0;
}

@keyframes moveRight {
    0% {
        left: -50px; /* Start off the screen to the left */
    }
    100% {
        left: 100%; /* Move to the right of the screen */
    }
}
</style>