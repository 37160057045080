<template>
    <div class="row m-0">
        <div class="col-12 mb-20 pl-0">
            <div class="row">
                <div class="col-12">
                    <h4 class="mt-0 mb-3">Files ({{ learningGroupFiles.length ?? 0 }})</h4>
                </div>
            </div>

            <div class="row mb-2">
                <div class="col-lg-6 col-sm-12">
                    <div class="card mb-2 shadow-none border"
                         v-for="(file, index) in learningGroupFiles" :key="index">
                        <div class="p-2">
                            <div class="row align-items-center">
                                <div class="col-auto">
                                    <div class="avatar-sm">
                                        <span
                                            class="avatar-title badge-soft-primary text-primary rounded">
                                            {{ index + 1 }}
                                        </span>
                                    </div>
                                </div>
                                <div class="col pl-0">
                                    <p class="text-muted font-weight-bold mb-0 text-black-50">
                                        {{ capitalizeFirstLetter(file.file_name) }}
                                    </p>
                                    <p class="mb-0 font-11">
                                        Uploaded By: {{ getFileUploadUser(file.uploaded_by) }}
                                    </p>
                                    <p class="mb-0 font-11">Upload Date:
                                        {{ getTimeAndDate(file.created_at) }}</p>
                                </div>
                                <div class="col-auto">
                                    <!-- Button -->
                                    <a :href="file.file_url" :download="file.file_name" target="_blank"
                                       class="btn btn-link btn-lg text-muted">
                                        <i class="dripicons-download"></i>
                                    </a>

                                    <a href="javascript:void(0);" @click="deleteFile(file.id)"
                                       class="btn btn-link btn-lg text-muted">
                                        <i class="dripicons-document-delete"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group mb-3">
                <input type="file"
                       class="form-control-file mb-2"
                       @change="handleFileUpload"
                       ref="fileInput">

                <RegularButton buttonText="Upload Selected File" color="yellow"
                               class="custom-button" :loading="fileuploadLoader"
                               @clicked="uploadFile"/>
            </div>
        </div>
    </div>
</template>
<script>
import {onMounted, ref} from "vue";
import {checkAndRefreshToken} from "@/utils/auth";
import {capitalizeFirstLetter, formatDateTime, getValidToken} from "@/utils/helpers";
import RegularButton from "@/components/common/RegularButton.vue";

export default {
    props: {
        fileAddURL: String,
        fileFetchURL: String,
        fileDeleteURL: String,
    },
    components: {RegularButton},
    setup(props, {emit}) {
        const learningGroupFiles = ref([])
        const base64File = ref('')
        const fileuploadLoader = ref(false)
        const accessToken = getValidToken()
        const fileName = ref('')

        onMounted(() => {
            fetchFiles()
        })

        const fetchFiles = async () => {
            try {
                await checkAndRefreshToken()

                emit('loading', 'show-files-loader')
                const response = await fetch(props.fileAddURL, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    }
                })

                if (response.ok) {
                    const result = await response.json()
                    learningGroupFiles.value = result.data
                } else {
                    const feedbackResponse = {
                        type: 'error',
                        message: "An error occurred"
                    }

                    emit('feedback', feedbackResponse)
                    console.log("response", response);
                    console.error("Error fetching data from the API");
                }
            } catch (error) {
                console.error('An error occured: ', error)
            } finally {
                emit('loading', 'files-data-loaded')
            }
        }

        const uploadFile = async () => {
            if (!base64File.value) {
                console.error('No file selected');
                emit("error", "No file selected")
                return; // Exit the function if no file is selected
            }

            if (base64File.value) {
                try {
                    fileuploadLoader.value = true
                    await checkAndRefreshToken()
                    const accessToken = getValidToken()

                    const response = await fetch(props.fileAddURL, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${accessToken}`,
                        },
                        body: JSON.stringify({
                            'file': base64File.value,
                            'file_name': fileName.value
                        })
                    })

                    if (response.ok) {
                        const result = await response.json();

                        if (result.code === 200) {
                            const feedbackResponse = {
                                type: "success",
                                message: "File uploaded successfully"
                            }

                            emit('feedback', feedbackResponse)
                            console.log('File uploaded successfully')
                            await fetchFiles()
                        } else {
                            const feedbackResponse = {
                                type: 'error',
                                message: result.message
                            }

                            emit('feedback', feedbackResponse)
                            console.log("error", result.message);
                        }
                    } else {
                        const result = await response.json();
                        const feedbackResponse = {
                            type: 'error',
                            message: result.message
                        }

                        emit('feedback', feedbackResponse)
                        console.log("error", result.message);
                    }
                } catch (error) {
                    const feedbackResponse = {
                        type: 'error',
                        message: error
                    }

                    emit('feedback', feedbackResponse)
                    console.error("An error occured:", error);
                } finally {
                    fileuploadLoader.value = false;
                }
            }
        }

        const handleFileUpload = (event) => {
            const selectedFile = event.target.files[0];

            if (selectedFile) {
                fileName.value = selectedFile.name
                convertToBase64(selectedFile);
            }
        }

        const convertToBase64 = (file) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                base64File.value = e.target.result;
            };

            reader.readAsDataURL(file);
        };

        const deleteFile = async (fileId) => {
            try {
                await checkAndRefreshToken()

                emit('loading', 'show-loader')
                const response = await fetch(`${props.fileDeleteURL}/${fileId}`, {
                    method: 'DELETE',
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`
                    }
                })

                if (response.ok) {
                    const result = await response.json()

                    if (result.code === 200) {
                        const feedbackResponse = {
                            type: "success",
                            message: "File deleted successfully"
                        }

                        emit('feedback', feedbackResponse)
                        await fetchFiles()
                    } else {
                        const feedbackResponse = {
                            type: 'error',
                            message: result.message
                        }

                        emit('feedback', feedbackResponse)
                        console.log('error', result.message)
                    }
                } else {
                    const result = await response.json();
                    const feedbackResponse = {
                        type: 'error',
                        message: result.message
                    }

                    emit('feedback', feedbackResponse)
                    console.log("error", result.message);
                }
            } catch (error) {
                const feedbackResponse = {
                    type: 'error',
                    message: error
                }

                emit('feedback', feedbackResponse)
                console.error("An error occured:", error);
            } finally {
                emit('loading', 'data-loaded')
            }
        }

        const getFileUploadUser = (user) => {
            return capitalizeFirstLetter(user.first_name, user.last_name)
        }

        const getTimeAndDate = (created_at) => {
            const {date, time} = formatDateTime(created_at);
            return date
        }

        return {
            getFileUploadUser,
            getTimeAndDate,
            deleteFile,
            capitalizeFirstLetter,
            learningGroupFiles,
            uploadFile,
            fileuploadLoader,
            handleFileUpload
        }
    }
}
</script>
<style></style>