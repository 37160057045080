<template>
    <div class="row">
        <div class="col-12">
            <div class="card-box">
                <div class="row">
                    <div class="col-12">
                        <TableView :data="formattedActivityData" :headers="tableHeaders"
                                   :id="getIdForActions" :showSearchField="false"
                                   :detailsData="getDataForDetailsView" :loading="activityLoader"
                                   :searchStringFromParent.sync="searchStringFromChild"
                                   :viewAction="viewAction"
                                   :editAction="editAction" :deleteAction="openConfirmationDialog"
                                   :searchFields="searchFieldsData" :showActionsColumn="true"/>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <InfoModal :isModalOpen="isConfirmationDialogOpen" @closeModal="closeConfirmationDialog"
               :successImageURL="require('../../../../assets/images/icons/success_icon.svg')"
               :errorImageURL="require('../../../../assets/images/icons/error_icon.svg')" header="Hold on!"
               :paragraph="deactivateConfirmationParagraph"
               :buttonTxt="deactivateButtonText" :buttonAction="performAction" buttonColor="error"
               routerLinkText="No, Cancel Action" buttonLink="/"
               :linkAction="cancelAction" :loading="buttonLoader">
    </InfoModal>
</template>
<script>
import getProjectActivities from "@/composables/Schools/getProjectActivities";
import {computed, onActivated, onMounted, ref, watch} from "vue";
import {API_BASE_URL} from "@/config";
import {checkAndRefreshToken} from "@/utils/auth";
import {getSchoolId, getValidToken} from "@/utils/helpers";
import TableView from "@/components/common/TableView.vue";
import InfoModal from "@/components/common/InfoModals.vue";

export default {
    props: {
        projectId: Number
    },
    components: {InfoModal, TableView},
    setup(props, {emit}) {
        const {
            projectActivityData,
            fetchProjectActivities,
            loading: activityLoader,
            formattedActivityData
        } = getProjectActivities()

        const tableHeaders = computed(() => {
            return [
                {label: "Name", key: "name"},
                {label: "Description", key: "description"},
                {label: "Start Date", key: "start_date"},
                {label: "Finish Date", key: "finish_date"}
            ];
        });

        const searchStringFromChild = ref("");
        const searchFieldsData = ['user.name', 'description', 'status']
        const isConfirmationDialogOpen = ref(false);
        const buttonLoader = ref(false);
        const loading = ref(false);
        const isFormModalOpen = ref(false);
        const isEditFormModalOpen = ref(false);
        const isFlashModalOpen = ref(false);
        const itemData = ref(null)

        onMounted(() => {
            fetchProjectActivities(props.projectId)
        })

        watch(projectActivityData, () => {
            // dd(projectActivityData.value)
        })

        onActivated(() => {
            fetchProjectActivities(props.projectId)
        })

        const closeFormModal = (name) => {
            isFormModalOpen.value = false;

            fullName.value = name
            isFlashModalOpen.value = true
        };

        const closeEditFormModal = async (msg) => {
            isEditFormModalOpen.value = false;

            if (msg) {
                flashAlertMessage(msg.type, msg.apiResponse)
            }
            await fetchProjectActivities(props.projectId)
        };

        const closeFormModal1 = () => {
            isFormModalOpen.value = false;

            fetchProjectActivities(props.projectId)
        }

        const viewAction = (item, item_id) => {
            itemData.value = item

            // Find the item to edit
            const itemDetails = projectActivityData.value.find((r) => r.id === item_id);
            itemData.value = {
                name: itemDetails.name,
                description: itemDetails.description,
                start_date: itemDetails.start_date,
                finish_date: itemDetails.finish_date,
                learning_objectives: itemDetails.learning_objectives,
                resources: itemDetails.resources,
                supporting_weblinks: itemDetails.supporting_weblinks,
                ways_to_extend: itemDetails.ways_to_extend,
                projectId: itemDetails.project_id,
                id: item_id
            }

            emit('openDetailsModal', itemData.value)
        }

        const itemDataForEditing = ref(null)
        const editAction = (item, item_id) => {
            itemData.value = item

            // Find the item to edit
            const itemForEditing = projectActivityData.value.find((r) => r.id === item_id);
            itemDataForEditing.value = {
                name: itemForEditing.name,
                description: itemForEditing.description,
                start_date: itemForEditing.start_date,
                finish_date: itemForEditing.finish_date,
                learning_objectives: itemForEditing.learning_objectives,
                resources: itemForEditing.resources,
                supporting_weblinks: itemForEditing.supporting_weblinks,
                ways_to_extend: itemForEditing.ways_to_extend,
                projectId: itemForEditing.project_id,
                id: item_id
            }

            emit('openFormModal', itemDataForEditing.value)
        }

        const deleteAction = async (id) => {
            const schoolId = getSchoolId()
            const apiUrl = `${API_BASE_URL}/school/${schoolId}/activity/${id}`

            try {
                const accessToken = getValidToken()
                await checkAndRefreshToken()

                buttonLoader.value = true
                const response = await fetch(apiUrl, {
                    method: 'DELETE',
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`
                    }
                })

                if (response.ok) {
                    const result = await response.json()

                    if (result.code === 200) {
                        const msg = {
                            apiResponse: "Success, Activity Deleted",
                            type: "success",
                        }

                        emit('showFlashMessage', msg)
                        closeConfirmationDialog()
                    } else {
                        flashAlertMessage('error', result.message)
                        console.log('error', result.message)
                    }
                } else {
                    const result = await response.json();

                    flashAlertMessage('error', result.message)
                    console.log("error", result.message);
                }
            } catch (error) {
                flashAlertMessage('error', result.message)
                console.error("An error occured:", error);
            } finally {
                buttonLoader.value = false
            }
        }


        const itemId = ref('null')
        const itemStatus = ref('')
        const deactivateConfirmationParagraph = ref('')
        const deactivateButtonText = ref('')
        const openConfirmationDialog = (item, id) => {
            itemId.value = id

            deactivateConfirmationParagraph.value = `You are about to delete this activity. Are you sure you want to continue this action?.`
            deactivateButtonText.value = `Yes, Delete Activity`
            isConfirmationDialogOpen.value = true;
        };

        const closeConfirmationDialog = () => {
            isConfirmationDialogOpen.value = false;
            fetchProjectActivities(props.projectId)
        };

        const performAction = () => {
            deleteAction(itemId.value)
        }

        const cancelAction = () => {
            closeConfirmationDialog()
        }

        const getIdForActions = computed(() => {
            return projectActivityData.value.map((item) => item.id);
        });

        const getDataForDetailsView = computed(() => {
            return projectActivityData.value
        })

        const flashAlertMessage = (alertType, alertMessage) => {
                    emit('flash-message', {
                        alertType: alertType,
                        alertMessage: alertMessage
                    })
                }


        return {
            tableHeaders,
            searchStringFromChild,
            searchFieldsData,
            viewAction,
            editAction,
            deleteAction,
            openConfirmationDialog,
            performAction,
            cancelAction,
            getIdForActions,
            getDataForDetailsView,
            formattedActivityData,
            projectActivityData,
            loading,
            isFormModalOpen,
            isEditFormModalOpen,
            isConfirmationDialogOpen,
            closeFormModal1,
            closeEditFormModal,
            closeConfirmationDialog,
            closeFormModal,
            deactivateConfirmationParagraph,
            deactivateButtonText,
            buttonLoader,
            itemDataForEditing,
            activityLoader
        }

    }
}
</script>
<style></style>