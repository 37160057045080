<template>

    <div class="row" v-if="itemData">
        <div class="col-sm-12 col-md-3">
            <div class="card-box">
                <div class="card-content">
                    <div class="header-content text-center">
                        <img :src="itemData.profile_image"
                             alt="school logo"
                             class="profile-image rounded-circle img-thumbnail"
                             v-if="itemData.profile_image"
                             @error="handleImageError"
                        />

                        <AvatarForInitials :avatarText="getUserInitialsForName(itemName)" iconColor="primary"
                                           class="avatar-md-150 mb-30 m-auto" v-else/>

                        <h4 class="mb-0">{{ itemName }}</h4>
                        <p class="mb-0">{{ itemData.email }}</p>
                        <p class="mb-20">{{ itemData.work_msisdn }}</p>
                    </div>

                    <div class="middle-content mb-50">
                        <div class="details-content">
                            <img src="../../../assets/images/icons/calendar_icon.svg" alt="Mobile Icon"/>
                            <div class="details">
                                <p class="muted-text-sm">Source School</p>
                                <p class="result-text">{{ school }}</p>
                            </div>
                        </div>

                        <div class="details-content">
                            <img src="../../../assets/images/icons/calendar_icon.svg" alt="Mobile Icon"/>
                            <div class="details">
                                <p class="muted-text-sm">Added On</p>
                                <p class="result-text">{{ userCreatedAt }}</p>
                            </div>
                        </div>

                        <div class="details-content">
                            <img src="../../../assets/images/icons/folder_icon.svg" class="data-img" alt="Mobile Icon"/>

                            <div class="detail-content-group">
                                <p class="data-title">Children</p>
                                <p class="data-text">{{ itemData.students.length }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="buttons-content">
                        <RegularButtonBlock buttonText="Edit Profile" iconText="mdi-account-edit"
                                            class="custom-button mb-3" @clicked="editAction"/>

                        <a href="#" class="bold primary" @click.prevent="triggerFilePicker">
                            Update {{ itemName }}'s Profile Image
                        </a>

                        <input ref="fileInput" type="file" accept=".png, .jpg" @change="selectImage" style="display: none"/>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-sm-12 col-md-9">
            <div class="row">
                <div class="col-12">
                    <div class="page-title-box">
                        <BreadCrumbs/>
                    </div>
                </div>
            </div>

            <div class="card-box-no-pd">
                <div class="page-title-box mb-30">
                    <h4 class="page-title-gray">Parent Profile</h4>

                    <ul class="tab-container">
                        <li v-for="(tab, index) in tabs" :key="index" @click="selectTab(index)" class="tab"
                            :class="{ 'active-tab': activeTab === index }">
                            {{ tab }}
                        </li>
                    </ul>
                </div>

                <div v-show="activeTab === 0">
                    <div class="card-content border-bottom plr-50">
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="detail-group mb-30">
                                    <span class="muted-text-sm">Full Name</span>
                                    <p>{{ itemName }}</p>
                                </div>

                                <div class="detail-group mb-30">
                                    <span class="muted-text-sm">Contact Number</span>
                                    <p>{{ itemData.msisdn }}</p>
                                </div>

                                <div class="detail-group mb-30">
                                    <span class="muted-text-sm">Country of Residence</span>
                                    <p>{{ itemData.country }}</p>
                                </div>
                            </div>

                            <div class="col-sm-6">
                                <div class="detail-group mb-30">
                                    <span class="muted-text-sm">Gender</span>
                                    <p>{{ itemData._gender }}</p>
                                </div>

                                <div class="detail-group mb-30">
                                    <span class="muted-text-sm">Email Address</span>
                                    <p>{{ itemData.email }}</p>
                                </div>

                                <div class="detail-group mb-30">
                                    <span class="muted-text-sm">State of Residence</span>
                                    <p>{{ itemData.state }}</p>
                                </div>
                            </div>

                            <div class="col-sm-12">
                                <div class="detail-group mb-30">
                                    <span class="muted-text-sm">Residential Address</span>
                                    <p>{{ itemData.address }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div v-show="activeTab === 1">
                    <div class="card-content border-bottom plr-50">
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="detail-group mb-30">
                                    <span class="muted-text-sm">Work Contact Number</span>
                                    <p>{{ itemData.work_msisdn }}</p>
                                </div>
                            </div>


                            <div class="col-sm-6">
                                <div class="detail-group mb-30">
                                    <span class="muted-text-sm">Email Address</span>
                                    <p>{{ itemData.work_email }}</p>
                                </div>
                            </div>

                            <div class="col-sm-12">
                                <div class="detail-group mb-30">
                                    <span class="muted-text-sm">Work Address</span>
                                    <p>{{ itemData.work_address }}</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>


                <div v-show="activeTab === 2">
                    <div class="card-content border-bottom plr-50">
                        <div class="row m-0">

                            <p class="p-0" v-if="itemData.students.length === 0">No Children Yet</p>

                            <div class="col-lg-3 col-md-12 col-sm-12 col-xs 12 mb-20 pl-0" v-else v-for="student in itemData.students" :key="student">
                                <UserAvatarBox2 class="mr-2"
                                                :studentFirstName="student.first_name"
                                                :studentLastName="student.last_name"
                                                :viewAction="viewStudent" :id="student.user_id"
                                                :toggleStatusAction="toggleStudentStatus"
                                                studentSchool="Angus Memorial"/>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-show="activeTab === 3">
                    <div class="card-content border-bottom plr-50">
                        <div class="row">
                            <div class="col-lg-6 col-sm-12">
                                <div class="detail-group mb-30">
                                    <span class="muted-text-sm">Emailed child's birth certificate?</span>
                                    <p>{{ itemData.has_emailed_child_kyc === true ? 'Yes' : 'No' }}</p>
                                </div>
                            </div>


                            <div class="col-lg-6 col-sm-12">
                                <div class="detail-group mb-30">
                                    <span class="muted-text-sm">
                                        Agreed with terms and conditions
                                    </span>
                                    <p>{{ itemData.agree_with_terms ===  true ? 'Yes' : 'No' }}</p>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-lg-6 col-sm-12">
                                <div class="detail-group mb-30">
                                    <span class="muted-text-sm">
                                        Child's first language
                                    </span>
                                    <p>{{ capitalizeFirstLetter(itemData.child_first_language) }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="ptb-30 plr-50">
                    <p class="dark-p semibold mb-10">Turn on/off to activate or deactivate this account</p>
                    <Switch :switchText="switchTextLabel" :checked="checkedStatus"
                            :toggleStatus="handleSwitchToggle" class="mb-20"
                            :loading="loading"/>
                </div>


                <MessageSliderCopy :isVisible="displayMessage" :messageText="messageText" :messageType="messageType"/>
                <FormModal :isFormModalOpen="isEditFormModalOpen" @closeFormModal="closeEditFormModal"
                           modalHeader="Edit Parent">
                    <EditSchoolParent @closeFormModal="closeEditFormModal"
                                      :itemData="itemDataForEditing" @refreshPage="fetchItemData"/>
                </FormModal>
            </div>
        </div>
    </div>
</template>

<script>
import Switch from "@/components/common/Switch.vue";
import RegularButtonBlock from "@/components/common/RegularButtonBlock.vue";
import BreadCrumbs from "@/components/common/BreadCrumbs.vue";
import {onMounted, ref, watch} from "vue";
import {
    capitalizeFirstLetter,
    compressImage,
    dd,
    formatDateTime,
    getCurrentUser,
    getUserInitialsForName,
    getValidToken
} from '@/utils/helpers'
import {API_BASE_URL} from "@/config.js";
import MessageSliderCopy from "@/components/common/MessageSliderCopy.vue";
import FormModal from "@/components/common/FormModal.vue";
import EditParent from "@/components/layouts/GodAdmin/Parents/EditParent.vue";
import {useRoute, useRouter} from "vue-router";
import {checkAndRefreshToken} from "@/utils/auth";
import UserAvatarBox2 from "@/components/common/UserAvatarBox2.vue";
import EditSchoolParent from "@/views/School_Admin/Parents/EditSchoolParent.vue";
import AvatarForInitials from "@/components/common/AvatarForInitials.vue";


export default {
    components: {
        AvatarForInitials,
        EditSchoolParent,
        UserAvatarBox2,
        EditParent,
        FormModal,
        Switch,
        RegularButtonBlock,
        BreadCrumbs,
        MessageSliderCopy
    },
    props: {
        id: String
    },
    setup(props, {emit}) {
        const isEditFormModalOpen = ref(false);
        const itemData = ref(null)
        const itemName = ref('')
        const userCreatedAt = ref('')
        const loading = ref(null)
        const checkedStatus = ref(false)
        const switchTextLabel = ref('')
        const messageText = ref("");
        const messageType = ref("");
        const displayMessage = ref(false);
        const currentUser = getCurrentUser()
        const tabs = ref(['Basic Details', 'Work Details', 'Children', 'Other Information'])
        const activeTab = ref(0)
        const router = useRoute()
        const itemId = router.params.id
        const accessToken = getValidToken()
        const school = ref(null)
        const navRouter = useRouter()

        const fetchItemData = async () => {
            const apiUrl = `${API_BASE_URL}/parent/get-parent/${itemId}`;

            try {
                emit('show-loader')
                const response = await fetch(apiUrl, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    }
                })

                if (response.ok) {
                    const result = await response.json()
                    itemData.value = result.data

                    // console.log('result', result.data)
                } else {
                    console.log("response", response);
                    console.error("Error fetching data from the API");
                }
            } catch (error) {
                console.error('An error occured: ', error)
            }
        }


        onMounted(() => {
            fetchItemData()
        })

        watch(itemData, (item) => {
            itemName.value = capitalizeFirstLetter(item.first_name, item.last_name)
            const {date, time} = formatDateTime(item.created_at)
            userCreatedAt.value = `${date}, ${time}`

            checkedStatus.value = !item.isDeactivated
            switchTextLabel.value = item.isDeactivated ? 'Account Deactivated' : 'Account Active'

            school.value = item.schools.length !== 0 ? item.schools[0].name : 'Not attached to a school'

            if (item) {
                emit('data-loaded')
            }
        })

        const selectTab = (index) => {
            activeTab.value = index;
        };

        const openEditFormModal = () => {
            isEditFormModalOpen.value = true;
        };

        const closeEditFormModal = async (msg) => {
            isEditFormModalOpen.value = false;

            if (msg) {
                flashAlertMessage(msg.type, msg.apiResponse)
            }
            await fetchItemData()
        };


        const itemDataForEditing = ref(null)
        const editAction = () => {
            itemDataForEditing.value = {
                firstName: itemData.value.first_name,
                lastName: itemData.value.last_name,
                gender: itemData.value._gender,
                msisdn: itemData.value.msisdn,
                country: itemData.value.country,
                state: itemData.value.state,
                address: itemData.value.address,
                workEmail: itemData.value.work_email,
                workMsisdn: itemData.value.work_msisdn,
                workAddress: itemData.value.work_address,
                hasEmailedChildKyc: itemData.value.has_emailed_child_kyc,
                agreeWithTerms: itemData.value.agree_with_terms,
                childFirstLanguage: itemData.value.child_first_language,
                relationshipToStudent: itemData.value.relationship_to_student,
                id: props.id
            }

            openEditFormModal()
        }

        const handleSwitchToggle = () => {
            const userId = props.id
            toggleUserStatus(userId)
        }

        const toggleUserStatus = async (id) => {
            const apiUrl = `${API_BASE_URL}/parent/delete-parent`

            try {
                await checkAndRefreshToken()

                loading.value = true
                const response = await fetch(apiUrl, {
                    method: 'DELETE',
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`
                    },
                    body: JSON.stringify({
                        parent_id: id,
                        reason: ''
                    })
                })

                if (response.ok) {
                    const result = await response.json()

                    if (result.code === 200) {

                        flashAlertMessage("success", "Parent status updated")
                        await fetchItemData()
                    } else {

                        flashAlertMessage("error", result.message)
                        console.log('error', result.message)
                    }
                } else {
                    const result = await response.json();
                    flashAlertMessage("error", result.message)
                    console.log("error", result.message);
                }
            } catch (error) {
                flashAlertMessage("error", error)
                console.error("An error occured:", error);
            } finally {
                loading.value = false
            }
        }

        const flashAlertMessage = (alertType, alertMessage) => {
            emit('flash-message', {
                alertType: alertType,
                alertMessage: alertMessage
            })
        }

        const viewStudent = (id) => {
            navRouter.push({name: 'ShowSchoolStudent', id: id})
        }

        const toggleStudentStatus = (id) => {
            dd(id)
        }

        const handleImageError = () => {
            itemData.value.profile_image = null
        }

        const triggerFilePicker = () => {
            if (fileInput.value) {
                fileInput.value.click(); // This simulates a click on the file input, opening the file picker dialog
            }
        }

        const selectedImage = ref('')
        const fileInput = ref(null);
        const selectImage = async (event) => {
            const file = event.target.files[0];

            if (file && (file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/jpg')) {
                try {
                    // Set the compressed image as the selected image
                    selectedImage.value = await compressImage(file, 200, 200, 0.5);
                    await handleFileChange()
                } catch (error) {
                    console.error('Error compressing image: ', error)
                } finally {
                    // Reset the value to allow selecting the same file again
                    if (fileInput.value) {
                        fileInput.value.value = ''; // Reset the file input
                    }
                }
            } else {
                // Handle invalid file type
                flashAlertMessage('error', 'Invalid file type. Please select a PNG or JPEG image.')
                console.error('Invalid file type. Please select a PNG or JPEG image.');
            }
        };

        const handleFileChange = async () => {
            try {
                emit('show-loader')
                const apiUrl = `${API_BASE_URL}/parent/change-profile-image?user_id=${itemId}`;
                await checkAndRefreshToken()

                const profileImage = {
                    profile_image: selectedImage.value
                }

                const response = await fetch(apiUrl, {
                    method: 'PUT',
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(profileImage)
                })

                if (response.ok) {
                    const result = await response.json()

                    if (result.code === 200) {
                        flashAlertMessage('success', 'Profile image successfully updated')
                        await fetchItemData()
                        // console.log('result', result.data)
                    } else {
                        flashAlertMessage('error', 'An error occured')
                        console.log("code error", result);
                    }
                } else {
                    console.log("ok error", response);
                    console.error("Error sending data to the API");
                }
            } catch (error) {
                console.error('An error occured: ', error)
            } finally {
                emit('data-loaded')
            }
        }

        return {
            loading,
            itemData,
            itemName,
            userCreatedAt,
            checkedStatus,
            switchTextLabel,
            handleSwitchToggle,
            messageText,
            messageType,
            displayMessage,
            currentUser,
            editAction,
            closeEditFormModal,
            isEditFormModalOpen,
            itemDataForEditing,
            tabs,
            activeTab,
            selectTab,
            fetchItemData,
            school,
            toggleStudentStatus,
            viewStudent,
            capitalizeFirstLetter,
            getUserInitialsForName,
            selectImage,
            fileInput,
            selectedImage,
            triggerFilePicker,
            handleImageError,
        }
    }
};
</script>

<style scoped>

.detail-content-group > p {
    margin: 0;
}

.border-bottom {
    border-bottom: 2px solid #F5F6FA;
}

p {
    color: #9a9a9a;
    font-size: 16px;
}

.details-content {
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding: 30px 30px 0 30px;
}

.detail-content-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.details-content:last-child {
    padding-bottom: 30px;
}

.details-content:first-child {
    border-bottom: 1px solid #e9eaf3;
    padding-bottom: 30px;
}

.details-content > img {
    margin-right: 15px;
    margin-top: 5px;
}

.details-content > img {
    margin-right: 15px;
    margin-top: 5px;
}

.details-content .details > p {
    margin-bottom: 0;
}

.logo-text > img {
    margin-right: 15px;
    margin-top: 2px;
}

.logo-text > p {
    color: #333333;
}

.detail-group > p,
.result-text,
.data-title {
    color: #333;
}

.item > .count {
    color: #2f5bcf;
}

.buttons-content {
    padding: 0 15px 40px 15px;
    text-align: center;
}

.page-title-gray {
    background-color: #F8FAFC;
    font-size: 1.25rem;
    margin: 0;
    line-height: 75px;
    color: #323a46;
    padding: 20px 50px 0 50px;
}

.tab-container {
    display: flex;
    background-color: #F8FAFC;
    padding-left: 50px;
}

.tab {
    list-style-type: none;
    margin: 0 20px;
    text-align: center;
    cursor: pointer;
    padding: 10px;
}

.tab:first-child {
    margin: 0;
    padding-left: 0;
}

.active-tab {
    border-bottom: 2px solid #0d44b2;
}

.buttons-content a {
    color: #0d44b2;
}

.rounded-circle {
    height: 150px !important;
    width: 150px !important;
    object-fit: contain;
}
</style>