<template>
    <div :class="[messageClass, { 'slide-in': isVisible }]">
        {{ message }}
    </div>
</template>

<script>
import { ref, watch, onMounted } from "vue";

export default {
    props: {
        message: String,
        type: {
            type: String,
            default: "success", // Default type is success, you can change it as needed
        },
        duration: {
            type: Number,
            default: 3000, // Default duration in milliseconds before message disappears
        },
    },
    setup(props) {
        const isVisible = ref(false);
        const messageClass = ref("");

        const showMessage = () => {
            isVisible.value = true;
            messageClass.value = props.type === "success" ? "success" : "error";

            // Automatically hide the message after the specified duration
            setTimeout(() => {
                hideMessage();
            }, props.duration);
        };

        const hideMessage = () => {
            isVisible.value = false;
            messageClass.value = "";
        };

        // Automatically show the message when the component is mounted
        onMounted(() => {
            // Whenever the message prop changes, show the message
            showMessage();
        });

        // Watch for changes in the message prop
        watch(
            () => props.message,
            (newValue) => {
                // Whenever the message prop changes, show the message
                showMessage();
            }
        );

        return { isVisible, messageClass };
    },
};
</script>

<style scoped>
.slide-in {
    position: fixed;
    top: 20px; /* Adjust the top position as needed */
    right: 20px; /* Adjust the right position as needed */
    animation: slide-in 0.5s ease-in-out;
    transform: translateX(100%); /* Start off the screen to the right */
}
.success {
    /* Add your success message styles here */
    background-color: #4caf50;
    color: white;
}
.error {
    /* Add your error message styles here */
    background-color: #f44336;
    color: white;
}
@keyframes slide-in {
    0% {
        transform: translateX(100%); /* Start off the screen to the right */
    }
    100% {
        transform: translateX(0); /* Slide in to the left */
    }
}
</style>