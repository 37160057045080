<template>
    <div class="row">
        <div class="col-12">
            <h4 class="mt-0 mb-3">Comments ({{ comments.length === 0 ? 0 : comments.length }})</h4>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <div class="mt-2 mb-100">
                <p v-if="comments.length === 0">No updates have been added yet.</p>
                <div class="media mb-50" v-else v-for="comment in comments" :key="comment">
                    <img class="mr-2 avatar-sm rounded-circle"
                         :src="comment.commented_by.profile_image"
                         alt="Generic placeholder image"
                         v-if="comment.commented_by.profile_image"
                         @error="handleImageError(comment)"
                    >

                    <div class="avatar-md mr-10" v-else>
                        <span
                            :class="`avatar-title bg-soft-success text-success rounded-circle`">
                            {{ getUserInitialsForComments(comment) }}
                        </span>
                    </div>

                    <div class="media-body">
                        <h5 class="mt-0">
                            <a href="#" class="text-reset mr-1">
                            {{ getCommentUsername(comment) }}
                            </a>
                            <small class="text-muted">{{ timeAgo(comment.created_at) }}</small>
                        </h5>
                        <img :src="comment.file_url" alt="comment image" class="rounded comment-image mb-2" v-if="comment.file_url"/>
                        {{ comment.comment }}


                        <br/>
                        <a href="javascript: void(0);" class="text-muted font-13 d-inline-block mt-2 mr-2"
                           @click="editComment(comment)">
                            <i class="mdi mdi-comment-edit mr-1"/>Edit
                        </a>

                        <a href="javascript: void(0);" class="text-muted font-13 d-inline-block mt-2"
                           @click="deleteComment(comment.id)">
                            <i class="mdi mdi-delete mr-1"/>Delete
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row mb-50">
        <div class="col-12">

            <div v-if="imageSrc || currentImageUrl" class="image-preview-container">
                <img v-if="imageSrc" :src="imageSrc" class="preview-img rounded img-thumbnail" alt="Preview Image" />
                <img v-else-if="currentImageUrl" :src="currentImageUrl" class="preview-img rounded img-thumbnail" alt="Existing image">
                <i class="fas fa-times-circle remove-image-icon" @click="removeImage"></i>
            </div>

            <textarea class="form-control form-control-light mb-2" placeholder="Write message"
                      id="example-textarea" rows="3" v-model="comment"></textarea>

            <input type="file" @change="onFileChange" accept="image/*" class="mb-2">

            <div>
                <RegularButton :buttonText="isEditAction ? 'Update Comment' : 'Save Comment'" color="yellow"
                               class="custom-button" :loading="commentLoader"
                               @clicked="isEditAction ? updateComment() : addComment()"/>
            </div>
        </div>
    </div>
</template>
<script>
import RegularButtonBlock from "@/components/common/RegularButtonBlock.vue";
import {onMounted, ref} from "vue";
import {checkAndRefreshToken} from "@/utils/auth";
import {capitalizeFirstLetter, getInitialsForWord, getUserInitials, getValidToken, timeAgo} from "@/utils/helpers";
import RegularButton from "@/components/common/RegularButton.vue";

export default {
    props: {
        fetchCommentUrl: String,
        addCommentUrl: String,
        deleteCommentUrl: String,
        updateCommentUrl: String,
        canUploadFile: Boolean
    },
    components: {RegularButton, RegularButtonBlock},
    setup(props, {emit}) {
        const comment = ref('')
        const comments = ref([])
        const commentLoader = ref(false)
        const accessToken = getValidToken()
        const isEditAction = ref(false)
        const commentId = ref(0)
        const imageFile = ref(null)
        const imageSrc = ref('')
        const fileName = ref('')
        const currentImageUrl = ref(null);

        onMounted(() => {
            fetchComments()
        })

        const fetchComments = async () => {
            const apiUrl = props.fetchCommentUrl

            try {
                await checkAndRefreshToken()

                emit('commentsLoading', 'show-loader')
                const response = await fetch(apiUrl, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    }
                })

                if (response.ok) {
                    const result = await response.json()
                    comments.value = result.data

                    // console.log('result', result.message)
                } else {
                    console.log("response", response);
                    console.error("Error fetching data from the API");
                }
            } catch (error) {
                console.error('An error occured: ', error)
            } finally {
                emit('commentsLoading', 'data-loaded')
            }
        }

        const addComment = async () => {
            const apiUrl = props.addCommentUrl

            try {
                await checkAndRefreshToken()

                commentLoader.value = true
                const response = await fetch(apiUrl, {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`
                    },
                    body: JSON.stringify({
                        "comment": comment.value,
                        'file': imageSrc.value,
                        'file_name': fileName.value
                    })
                })

                if (response.ok) {
                    const result = await response.json()

                    if (result.code === 200) {
                        await fetchComments()
                        comment.value = ''
                        imageSrc.value = ''
                        currentImageUrl.value = ''
                        fileName.value = ''
                    } else {
                        console.log('error', result.message)
                    }
                } else {
                    const result = await response.json();
                    console.log("error", result.message);
                }
            } catch (error) {
                console.error("An error occured:", error);
            } finally {
                commentLoader.value = false
            }
        }

        const updateComment = async () => {
            const apiUrl = props.updateCommentUrl

            try {
                await checkAndRefreshToken()

                commentLoader.value = true
                const response = await fetch(`${apiUrl}/${commentId.value}`, {
                    method: 'PUT',
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`
                    },
                    body: JSON.stringify({
                        "comment": comment.value,
                        'file': currentImageUrl.value,
                        'file_name': fileName.value
                    })
                })

                if (response.ok) {
                    const result = await response.json()

                    if (result.code === 200) {
                        await fetchComments()
                        comment.value = ''
                        imageSrc.value = ''
                        currentImageUrl.value = ''
                        fileName.value = ''
                        isEditAction.value = false
                    } else {
                        console.log('error', result.message)
                    }
                } else {
                    const result = await response.json();
                    console.log("error", result.message);
                }
            } catch (error) {
                console.error("An error occured:", error);
            } finally {
                commentLoader.value = false
            }
        }

        const editComment = (previousComment) => {
            isEditAction.value = true
            commentId.value = previousComment.id
            comment.value = previousComment.comment
            currentImageUrl.value = previousComment.file_url

            // Reset new image data since editing starts without a new image selected
            imageFile.value = null;
            imageSrc.value = null;
        }


        const deleteComment = async (commentId) => {
            const apiUrl = `${props.deleteCommentUrl}/${commentId}`;

            try {
                await checkAndRefreshToken()

                emit('show-loader')
                const response = await fetch(apiUrl, {
                    method: 'DELETE',
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    }
                })

                if (response.ok) {
                    const result = await response.json()

                    if (result.code === 200) {
                        console.log('comment ok')
                        await fetchComments()
                    } else {
                        console.log('code error => ', result.message)
                    }
                } else {
                    console.log("response", response);
                    console.error("Error fetching data from the API");
                }
            } catch (error) {
                console.error('An error occured: ', error)
            } finally {
                emit('data-loaded')
            }
        }

        const getCommentUsername = (comment) => {
            if (comment.commented_by.first_name) {
                return capitalizeFirstLetter(comment.commented_by.first_name, comment.commented_by.last_name)
            } else {
                return capitalizeFirstLetter(comment.commented_by.name)
            }
        }

        const getUserInitialsForComments = (comment) => {
            if (comment.commented_by.first_name) {
                return getUserInitials(comment.commented_by.first_name, comment.commented_by.last_name)
            } else {
                return getInitialsForWord(comment.commented_by.name)
            }
        }

        const handleImageError = (comment) => {
            comment.commented_by.profile_image = null
        }

        const onFileChange = (e) => {
            const file = e.target.files[0];
            imageFile.value = file;
            fileName.value = file.name
            currentImageUrl.value = null;

            readAsBase64(file);
        }

        const removeImage = () => {
            imageSrc.value = null
            currentImageUrl.value = null
        }

        function readAsBase64(file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                if (isEditAction) {
                    currentImageUrl.value = e.target.result
                } else {
                    imageSrc.value = e.target.result;
                }
            };
            reader.readAsDataURL(file);
        }

        return {
            removeImage,
            onFileChange,
            handleImageError,
            imageSrc,
            comment,
            comments,
            commentLoader,
            timeAgo,
            capitalizeFirstLetter,
            addComment,
            deleteComment,
            getCommentUsername,
            getUserInitialsForComments,
            editComment,
            isEditAction,
            updateComment,
            currentImageUrl
        }
    }
}
</script>
<style scoped>
.avatar-md {
    height: 40px;
    width: 40px;
}

.comment-image {
    display: block;
    height: 300px;
}

.preview-img {
    width: 300px; /* or any appropriate width */
    height: auto;
    margin-top: 10px;
}

.image-preview-container {
    position: relative;
    display: inline-block;
    margin-top: 10px;
}

.remove-image-icon {
    position: absolute;
    top: 5px;
    right: -50px;
    cursor: pointer;
    padding: 10px;
    font-size: 30px;
}
</style>