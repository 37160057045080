<template>
    <div id="wrapper">
        <MessageSliderCopy :isVisible="displayMessage" :messageText="messageText" :messageType="messageType"/>

        <div class="auth-card-box">
            <InfiniteLinearLoader :loading="loading"/>
            <div class="image">
                <img src="../../assets/images/login_logo_new.jpeg" alt="login image"/>
            </div>

            <div class="form-content">
                <img src="../../assets/key-logo.png" alt="logo"/>
                <p>Reset your password</p>

                <TextInputFieldVue :config="{
                        class: 'form-control-gray',
                        placeholder: 'Enter Email',
                        inputFieldType: 'email'
                    }" labelText="Enter Email" :errorMessage="emailError" v-model="email"/>

                <RegularButtonVue buttonText="Proceed" class="custom-button mt-20 mb-3"
                                  :loading="loading" @click="handleFormSubmit"/>

                <p class="forgot-text">
                    Remember Password? <span><a href="#" @click="navigateToLogin">Click to login</a></span>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import TextInputFieldVue from "@/components/common/TextInputField.vue";
import PasswordInputFieldVue from "@/components/common/PasswordInputField.vue";
import CheckBoxVue from "@/components/common/CheckBox.vue";
import RegularButtonVue from "@/components/common/RegularButton.vue";
import InfiniteLinearLoader from "@/components/common/InfiniteLinearLoader.vue";
import MessageSliderCopy from "@/components/common/MessageSliderCopy.vue";
import {ref} from "vue";
import {API_BASE_URL} from "@/config.js";
import {useRouter} from "vue-router";
import {flashMessage} from "@/utils/flashMessage.js";

export default {
    components: {
        TextInputFieldVue,
        PasswordInputFieldVue,
        CheckBoxVue,
        RegularButtonVue,
        InfiniteLinearLoader,
        MessageSliderCopy,
    },
    setup() {
        const email = ref("");

        const loading = ref(false);
        const loadingSpinner = ref(false);
        const router = useRouter();

        const displayMessage = ref(false);
        const messageText = ref("");
        const messageType = ref("");
        const emailError = ref("");

        const showMessage = (text, type) => {
            flashMessage(text, type, messageText, messageType, displayMessage);
        };

        const showLoaders = () => {
            loading.value = true;
            loadingSpinner.value = true;
        };

        const hideLoaders = () => {
            loading.value = false;
            loadingSpinner.value = false;
        };

        const handleFormSubmit = async () => {
            if (email.value === '') {
                emailError.value = 'Enter an email address'
                return
            }

            const emailBody = {
                email: email.value
            }

            try {
                emailError.value = ''
                showLoaders();
                const apiURL = ref(`${API_BASE_URL}/auth/reset-password`);

                const response = await fetch(apiURL.value, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(emailBody),
                });

                if (response.ok) {
                    const result = await response.json();

                    if (result.code === 200) {
                        flashMessage(
                            "Password reset successful, an OTP has been sent to your mail",
                            "success",
                            messageText,
                            messageType,
                            displayMessage
                        );

                    } else {
                        console.log('code error => ', result.message)
                        flashMessage(
                            result.message,
                            "error",
                            messageText,
                            messageType,
                            displayMessage
                        );
                    }
                } else {
                    const result = await response.json();
                    console.log('ok error => ', result.message)
                    flashMessage(
                        result.message,
                        "error",
                        messageText,
                        messageType,
                        displayMessage
                    );
                }
            } catch (error) {
                console.error("An error occurred:", error);
                flashMessage(
                    "An error occurred while processing your request.",
                    "error",
                    messageText,
                    messageType,
                    displayMessage
                );
            } finally {
                hideLoaders();
            }
        };

        const navigateToLogin = () => {
            router.push({name: 'Login'})
        }

        return {
            navigateToLogin,
            email,
            handleFormSubmit,
            loading,
            showMessage,
            displayMessage,
            messageText,
            messageType,
            emailError
        };
    },
};
</script>

<style scoped>
.auth-card-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 550px;
    width: 850px;
    box-shadow: 0px 0px 15px 0px rgba(184, 188, 202, 0.5);
}

.image {
    height: 100%;
    width: 40%;
    display: inline-block;
}

.image > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.form-content {
    height: 100%;
    width: 60%;
    display: inline-block;
    position: inherit;
    top: 0;
    padding: 50px 45px;
}

.form-content > img {
    height: 35px;
    width: 60px;
    margin-bottom: 10px;
}

.form-content > p {
    margin-bottom: 40px;
}

.form-content > .forgot-text {
    font-size: 14px;
    color: #333;
}

.form-content > .forgot-text span {
    color: #f75549;
}

.checkboxVue {
    margin-bottom: 40px;
}
</style>