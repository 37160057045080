<template>
    <div class="container">
        <div class="linear-loader" v-if="loader">
            <div class="train"></div>
        </div>

        <div class="chats-section">
            <div class="chat-item"
                 :class="messageSelected === chat.user_id ? 'active' : ''"
                 v-for="chat in originalMessages" :key="chat"
                 @click="handleChatItemClick(chat)"
            >
                <div class="chat-item-group">
                    <div class="avatar-md mr-10">
                        <span
                            :class="`avatar-title bg-soft-success text-success font-20 rounded-circle`">
                            {{ getInitialsForWord(getNameOfSender(chat)) }}
                        </span>
                    </div>
                    <div>
                        <p class="m-0 semibold f-16">
                            {{ getNameOfSender(chat) }}
                        </p>
                        <span class="muted-text-sm f-14">Teacher</span>
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>
<script>
import {onMounted, ref, watch} from "vue";
import {capitalizeFirstLetter, getInitialsForWord, getValidToken, truncateText} from "@/utils/helpers";
import {checkAndRefreshToken} from "@/utils/auth";
import {API_BASE_URL} from "@/config";

export default {
    props: {
        searchTerm: String
    },
    setup(props, {emit}) {
        const chatMessages = ref([])
        const loader = ref(false)
        const messageSelected = ref(0)
        const originalMessages = ref([])

        const fetchChatMessages = async () => {
            try {
                loader.value = true
                await checkAndRefreshToken()

                const accessToken = getValidToken()
                if (!accessToken) {
                    console.error('Access token not available')
                }

                const apiURL = `${API_BASE_URL}/message/get-chats`

                const response = await fetch(apiURL, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                })

                if (response.ok) {
                    const result = await response.json()

                    chatMessages.value = result.data
                    originalMessages.value = result.data.slice()
                } else {
                    console.error('response', response)
                    console.error("Error fetching data from API")
                }

            } catch (error) {
                console.log('An error occurred', error)
            } finally {
                loader.value = false
            }
        }

        onMounted(() => {
            fetchChatMessages()
        })

        const handleChatItemClick = (chat) => {
            const chatDetails = {
                messageSelectedID: chat.user_id,
                userName: getNameOfSender(chat)
            }

            emit('chatDetails', chatDetails)
        }

        const getNameOfSender = (chat) => {
            if (chat.receiver === 'me') {
                return chat.sender
            } else {
                return capitalizeFirstLetter(chat.receiver.first_name, chat.receiver.last_name)
            }
        }

        watch(() => props.searchTerm, (word) => {
            filterResults(word)
        })

        const filterResults = (searchTerm) => {
            if (searchTerm) {
                originalMessages.value = chatMessages.value.filter(chat => {
                        const user = chat.receiver
                        const fullName = `${user.first_name} ${user.last_name}`.toLowerCase()
                        return fullName.includes(searchTerm.toLowerCase())
                    }
                );
            } else {
                originalMessages.value = chatMessages.value
            }
        };

        return {
            chatMessages,
            getInitialsForWord,
            loader,
            capitalizeFirstLetter,
            truncateText,
            handleChatItemClick,
            messageSelected,
            getNameOfSender,
            originalMessages
        }
    }
}
</script>
<style scoped>
.container {
    padding: 0 !important;
    position: relative;
}

.chats-section {
    overflow: auto;
}

.chat-item {
    padding: 20px;
    border-bottom: 1px solid #EBEDF3;
}

.chat-item-group {
    display: flex;
    align-items: center;
}

.chat-message {
    margin-top: 10px;
    margin-left: 65px;
}

.active {
    background-color: #f5f6fa;
}

.chat-item:hover {
    background-color: #f5f6fa;
}

.linear-loader {
    height: 2px; /* Adjust the height as needed */
    background-color: #89a6f3; /* Adjust the color as needed */
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    overflow: hidden;
}

.train {
    width: 900px; /* Adjust the train's width */
    height: 2px; /* Should match the height of the loader */
    background-color: #2f5bcf; /* Adjust the color as needed */
    position: absolute;
    animation: moveRight 1s linear infinite; /* Adjust the animation speed as needed */
    top: 0;
    left: 0;
}

@keyframes moveRight {
    0% {
        left: -50px; /* Start off the screen to the left */
    }
    100% {
        left: 100%; /* Move to the right of the screen */
    }
}
</style>