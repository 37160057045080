import {API_BASE_URL} from "@/config.js";
import {getValidToken} from "@/utils/helpers.js";
import {ref} from "vue";
import {checkAndRefreshToken} from '@/utils/auth';

const loading = ref(false);
const dashboardStats = ref([]);
const monthlyStatsLoader = ref(false)
const monthlyStatsData = ref([])
const sortedMonths = ref(null)
const monthlyRevenueData = ref(null)
const monthlyRevenueDataLoader = ref(null)
const recentActivitiesLoader = ref(null)
const recentActivities = ref(null)

const fetchDashboardStatistics = async (page, perPage) => {
    const apiUrl = `${API_BASE_URL}/dashboard/school-statistics`;

    try {
        await checkAndRefreshToken()

        const accessToken = getValidToken()
        if (!accessToken) {
            console.error("Access token not available.");
            return;
        }

        loading.value = true;

        const response = await fetch(apiUrl, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });

        if (response.ok) {
            const result = await response.json();

            if (result.code === 200) {
                dashboardStats.value = result.data

            } else {
                console.log("error", result.message);
            }
        } else {
            console.log("response", response);
            console.error("Error fetching data from the API");
        }
    } catch (error) {
        console.error("An error occurred:", error);
    } finally {
        loading.value = false;
    }
};

const fetchMonthlyStatistics = async (year) => {
    const apiUrl = `${API_BASE_URL}/dashboard/get-monthly-statistics?year=${year}`;

    try {
        await checkAndRefreshToken()

        const accessToken = getValidToken()
        if (!accessToken) {
            console.error("Access token not available.");
            return;
        }

        monthlyStatsLoader.value = true;

        const response = await fetch(apiUrl, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });

        if (response.ok) {
            const result = await response.json();

            if (result.code === 200) {
                monthlyStatsData.value = result.data

                // Convert the object to an array of key-value pairs
                const monthsArray = Object.entries(monthlyStatsData.value);

                // Sort the array based on the month names
                monthsArray.sort(([monthA], [monthB]) => {
                    const monthsOrder = [
                        'January',
                        'February',
                        'March',
                        'April',
                        'May',
                        'June',
                        'July',
                        'August',
                        'September',
                        'October',
                        'November',
                        'December',
                    ];

                    return monthsOrder.indexOf(monthA) - monthsOrder.indexOf(monthB);
                });

                // Convert the sorted array back to an object
                sortedMonths.value = Object.fromEntries(monthsArray);

            } else {
                console.log("error", result.message);
            }
        } else {
            console.log("response", response);
            console.error("Error fetching data from the API");
        }
    } catch (error) {
        console.error("An error occurred:", error);
    } finally {
        monthlyStatsLoader.value = false;
    }
}

const fetchTotalMonthlyRevenueStatistics = async (monthNumber, year) => {
    const apiUrl = `${API_BASE_URL}/dashboard/monthly-revenue?year=${year}&month=${monthNumber}`;

    try {
        await checkAndRefreshToken()

        const accessToken = getValidToken()
        if (!accessToken) {
            console.error("Access token not available.");
            return;
        }

        monthlyRevenueDataLoader.value = true;

        const response = await fetch(apiUrl, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });

        if (response.ok) {
            const result = await response.json();

            if (result.code === 200) {
                monthlyRevenueData.value = result.data

            } else {
                console.log("error", result.message);
            }
        } else {
            console.log("response", response);
            console.error("Error fetching data from the API");
        }
    } catch (error) {
        console.error("An error occurred:", error);
    } finally {
        monthlyRevenueDataLoader.value = false;
    }
}

const fetchRecentActivities = async () => {
    const apiUrl = `${API_BASE_URL}/dashboard/activity-feed`;

    try {
        await checkAndRefreshToken()

        const accessToken = getValidToken()
        if (!accessToken) {
            console.error("Access token not available.");
            return;
        }

        recentActivitiesLoader.value = true;

        const response = await fetch(apiUrl, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });

        if (response.ok) {
            const result = await response.json();

            if (result.code === 200) {
                recentActivities.value = result.data

            } else {
                console.log("error", result.message);
            }
        } else {
            console.log("response", response);
            console.error("Error fetching data from the API");
        }
    } catch (error) {
        console.error("An error occurred:", error);
    } finally {
        recentActivitiesLoader.value = false;
    }
}


const getDashboardStats = () => {
    return {dashboardStats, fetchDashboardStatistics, loading,
        sortedMonths, fetchMonthlyStatistics, monthlyStatsLoader,
        fetchTotalMonthlyRevenueStatistics, monthlyRevenueData, monthlyRevenueDataLoader,
        fetchRecentActivities, recentActivities, recentActivitiesLoader}
}

export default getDashboardStats