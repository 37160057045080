<template>
    <div class="row">
        <div class="col-12">
            <div class="row mb-30">
                <div class="col-lg-6">
                    <TextInputFieldVue :config="{
                            class: 'form-control-gray',
                            placeholder: 'Enter name of project',
                            inputFieldType: 'text',
                        }" labelText="Project Name" v-model="name" :isRequired="true"
                                       :errorMessage="nameError"/>
                </div>

                <div class="col-lg-6">
                    <SingleSelectField labelText="Academic Year"
                                       :options="academicYearSelectOptions"
                                       v-model="selectedAcademicYear"
                                       selectId="academicYearOptions"
                                       :loading="yearLoader"
                                       :isRequired="true" :errorMessage="academicYearError"
                                       :selectedItem="selectedAcademicYear"/>
                </div>
            </div>

            <div class="row mb-30">
                <div class="col-lg-6">
                    <SingleSelectField labelText="Term"
                                       :options="termsSelectOptions"
                                       v-model="selectedTerm"
                                       selectId="termOptions"
                                       :loading="termLoader"
                                       :isRequired="true" :errorMessage="termError"
                                       :selectedItem="selectedTerm"/>
                </div>

                <div class="col-lg-6">
                    <SingleSelectField labelText="Project Type"
                                       :options="projectTypeSelectOptions"
                                       v-model="selectedProjectType"
                                       selectId="projectTypeOptions"
                                       :isRequired="true" :errorMessage="projectTypeError"
                                       :selectedItem="selectedProjectType"/>
                </div>
            </div>

            <div class="row mb-30">
                <div class="col-lg-6">
                    <SingleSelectField labelText="Lead Co-Learner"
                                       :options="colearnerSelectOptions"
                                       v-model="selectedLeadColearner"
                                       :loading="leadColearnerLoader"
                                       selectId="colearnerSelectOptions"
                                       :isRequired="true"
                                       :errorMessage="leadColearnerError"
                                       :selectedItem="selectedLeadColearner"/>

                </div>

                <div class="col-lg-6">
                    <MultipleSelectDropdown labelText="Supporting Co-Learner(s)"
                                            :options="colearnerSelectOptions"
                                            v-model="selectedSupportingColearners"
                                            :loading="leadColearnerLoader"
                                            selectId="colearnerSelectOptionsM"
                                            :errorMessage="supportingColearnerError"
                                            :selectedItem="selectedSupportingColearners"/>
                </div>
            </div>

            <div class="row mb-30">
                <div class="col-lg-6">
                    <MultipleSelectDropdown labelText="Learning Group"
                                            :options="learningGroupSelectOptions"
                                            v-model="selectedLearningGroup"
                                            :loading="learningGroupDropdownLoader"
                                            selectId="learningGroupOptions"
                                            :errorMessage="supportingColearnerError"
                                            :selectedItem="selectedLearningGroup"/>
                </div>

                <div class="col-lg-6">
                    <MultipleSelectDropdown labelText="Subject Matter Expert"
                                            :options="smeSelectOptions"
                                            v-model="selectedSME"
                                            :loading="smeLoader" selectId="smeSelectOptions"
                                            :isRequired="true"
                                            :errorMessage="smeError"
                                            :selectedItem="selectedSME"/>
                </div>
            </div>

            <div class="row mb-30">
                <div class="col-lg-6">
                    <SingleSelectField
                        labelText="Project Duration"
                        :options="projectDurationSelectOptions"
                        v-model="selectedDuration"
                        selectId="projectDurationSelectOptions"
                        :isRequired="true" :errorMessage="projectDurationError"
                        :selectedItem="selectedDuration"/>
                </div>

                <div class="col-lg-6">
                    <MultipleSelectDropdown
                        labelText="Keywords"
                        :options="projectKeywordSelectOptions"
                        v-model="selectedKeywords"
                        :loading="keyWordsLoader"
                        selectId="projectKeywordSelectOptions"
                        :isRequired="true"
                        :errorMessage="keywordsError"
                        :selectedItem="selectedKeywords"/>
                </div>
            </div>

            <div class="row mb-50">
                <div class="col-lg-12">
                    <SingleSelectField
                        labelText="Project Curriculum"
                        :options="curriculumOptions"
                        v-model="selectedCurriculum"
                        selectId="curriculumOptions"
                        :loading="curriculumLoader"
                        :selectedItem="selectedCurriculum"/>
                </div>
            </div>

            <RoundedButton buttonText="Save Changes" class="custom-button" :loading="loading" @click="updateItems"/>
        </div>
    </div>
</template>
<script>
import TextInputFieldVue from "@/components/common/TextInputField.vue";
import SingleSelectField from "@/components/common/SingleSelectField.vue";
import MultipleSelectDropdown from "@/components/common/MultipleSelectDropdown.vue";
import {onMounted, ref, watch} from "vue";
import RoundedButton from "@/components/common/RoundedButton.vue";
import getLearningGroups from "@/composables/Schools/getLearningGroups";
import getSchoolColearners from "@/composables/Schools/getSchoolColearners";
import getKeywords from "@/composables/getKeywords";
import getSMEs from "@/composables/Schools/getSMEs";
import {capitalizeFirstLetter, getSchoolId, getValidToken} from "@/utils/helpers";
import {API_BASE_URL} from "@/config";
import {checkAndRefreshToken} from "@/utils/auth";
import getAcademicYear from "@/composables/Schools/getAcademicYear";
import getTerms from "@/composables/Schools/getTerms";
import getCurriculum from "@/composables/getCurriculum";

export default {
    props: {
        itemData: {
            type: Object,
            required: true
        }
    },
    components: {RoundedButton, MultipleSelectDropdown, SingleSelectField, TextInputFieldVue},
    setup(props, {emit}) {
        const {learningGroupsData, fetchLearningGroupsData, loading: learningGroupDropdownLoader} = getLearningGroups()
        const {
            fetchColearnersData,
            loading: leadColearnerLoader,
            colearnersUserData
        } = getSchoolColearners()
        const {fetchKeywordsData, keywordsData, loading: keyWordsLoader} = getKeywords()
        const {smeData, fetchSMEData, loading: smeLoader} = getSMEs()
        const {academicYearData, fetchAcademicYearData, loading: yearLoader} = getAcademicYear()
        const {termsData, fetchTerms, loading: termLoader} = getTerms()
        const {curriculumsData, fetchCurriculums, curriculumLoader} = getCurriculum()
        const name = ref(props.itemData.name);
        const term = ref(props.itemData.term)
        const selectedProjectType = ref(props.itemData.project_type)
        const selectedLeadColearner = ref('')
        const selectedSupportingColearners = ref([])
        const selectedLearningGroup = ref('')
        const selectedSME = ref('')
        const selectedDuration = ref(props.itemData.project_duration)
        const selectedKeywords = ref([])
        const loading = ref(false);
        const nameError = ref('')
        const academicYearError = ref('')
        const termError = ref('')
        const projectTypeError = ref('')
        const supportingColearnerError = ref('')
        const keywordsError = ref('')
        const leadColearnerError = ref('')
        const projectDurationError = ref('')
        const smeError = ref('')
        const colearnerSelectOptions = ref([])
        const smeSelectOptions = ref([])
        const learningGroupSelectOptions = ref([])
        const projectKeywordSelectOptions = ref([])
        const projectTypeSelectOptions = ref([])
        const projectDurationSelectOptions = ref([])
        const academicYearSelectOptions = ref(props.itemData.academic_year)
        const selectedAcademicYear = ref('')
        const termsSelectOptions = ref([])
        const selectedTerm = ref('')
        const selectedCurriculum = ref('')
        const curriculumOptions = ref([])

        setTimeout(() => {
            projectTypeSelectOptions.value = [
                {label: "Literacy", value: 'Literacy'},
                {label: "Numeracy", value: 'Numeracy'},
                {label: "Science", value: 'Science'},
                {label: "Multi-disciplinary", value: 'Multi-disciplinary'},
            ]

            projectDurationSelectOptions.value = [
                {label: "1 week", value: '1 week'},
                {label: "2 weeks", value: '2 weeks'},
                {label: "3 weeks", value: '3 weeks'},
                {label: "1 month", value: '1 month'},
                {label: "2 months", value: '2 months'},
                {label: "3 months", value: '3 months'},
            ]
        }, 500)

        onMounted(() => {
            fetchLearningGroupsData(1, 100)
            fetchColearnersData(1, 100)
            fetchSMEData(1, 100)
            fetchKeywordsData()
            fetchAcademicYearData()
            fetchTerms()
            fetchCurriculums()

            setTimeout(() => {
                selectedProjectType.value = props.itemData.project_type
                selectedDuration.value = props.itemData.project_duration
                selectedLeadColearner.value = props.itemData?.lead_teacher.user_id;
                selectedAcademicYear.value = props.itemData.academic_year
                selectedKeywords.value = props.itemData.keywords.map((item, index) => item)
                selectedLearningGroup.value = props.itemData.learning_groups.map((item) => item.id)
                selectedSupportingColearners.value = props.itemData.colearnerNames.map((item) => item.user_id)
                selectedSME.value = props.itemData.smes.map((item) => item.id)
                selectedCurriculum.value = props.itemData.curriculum
            }, 2000)
        })

        // Colearners
        const updateLeadColearnerOptions = (newData) => {
            colearnerSelectOptions.value = newData.map(item => ({
                label: capitalizeFirstLetter(item.first_name, item.last_name),
                value: item.user_id
            }))
        }

        watch(colearnersUserData, (newData) => {
            updateLeadColearnerOptions(newData)
        })

        // Curriculum
        const updateCurriculumOptions = (newData) => {
            curriculumOptions.value = newData.map(item => ({
                label: capitalizeFirstLetter(item.name),
                value: item.name
            }))

            if (curriculumOptions.value.length > 0) {
                selectedCurriculum.value = props.itemData.curriculum
            }
        }

        watch(curriculumsData, (newData) => {
            updateCurriculumOptions(newData)
        })

        // Academic Year
        const updateAcademiYearOptions = (newData) => {
            academicYearSelectOptions.value = newData.map(item => ({
                label: capitalizeFirstLetter(item.name),
                value: item.name
            }))
        }

        watch(academicYearData, (newData) => {
            updateAcademiYearOptions(newData)
        })


        // Terms
        const updateTermOptions = (newData) => {
            termsSelectOptions.value = newData.map(item => ({
                label: capitalizeFirstLetter(item.name),
                value: item.name
            }))

            if (termsSelectOptions.value.length > 0) {
                selectedTerm.value = props.itemData.term
            }
        }

        watch(termsData, (newData) => {
            updateTermOptions(newData)
        })


        // SMEs
        const updateSMEOptions = (newData) => {
            smeSelectOptions.value = newData.map(item => ({
                label: capitalizeFirstLetter(item.name, item.surname),
                value: item.id
            }))
        }

        watch(smeData, (newData) => {
            updateSMEOptions(newData)
        })

        // Keywords
        const updateKeywordOptions = (newData) => {
            projectKeywordSelectOptions.value = newData.map(item => ({
                label: capitalizeFirstLetter(item.name),
                value: item.name
            }))
        }

        watch(keywordsData, (newData) => {
            updateKeywordOptions(newData)
        })

        // Learning Groups
        const updateLearningGroupOptions = (newData) => {
            learningGroupSelectOptions.value = newData.map(item => ({
                label: capitalizeFirstLetter(item.name),
                value: item.id
            }))
        }

        watch(learningGroupsData, (newData) => {
            updateLearningGroupOptions(newData)
        })


        const validatePage = () => {
            let isValid = true

            nameError.value = ''
            academicYearError.value = ''
            termError.value = ''
            keywordsError.value = ''
            projectTypeError.value = ''
            leadColearnerError.value = ''
            projectDurationError.value = ''


            if (name.value.length === 0) {
                nameError.value = 'Enter a valid project name'
                isValid = false
            }

            if (selectedAcademicYear.value.length === 0) {
                academicYearError.value = 'Select at least one academic year'
                isValid = false
            }

            if (selectedTerm.value === null) {
                termError.value = 'Select at least one term'
                isValid = false
            }

            if (selectedLeadColearner.value === null) {
                leadColearnerError.value = 'Select at least one lead co-learner'
                isValid = false
            }

            if (selectedProjectType.value === null) {
                projectTypeError.value = 'Select at least one project type'
                isValid = false
            }

            if (selectedDuration.value === null) {
                projectDurationError.value = 'Select at least one duration'
                isValid = false
            }

            if (selectedSME.value === null) {
                keywordsError.value = 'Select at least one keyword'
                isValid = false
            }

            return isValid
        }

        const updateItems = async () => {
            if (validatePage()) {
                const registrationData = {
                    name: name.value,
                    academic_year: selectedAcademicYear.value,
                    term: selectedTerm.value,
                    project_type: selectedProjectType.value,
                    lead_teacher: selectedLeadColearner.value,
                    supporting_teachers: selectedSupportingColearners.value,
                    learning_groups: selectedLearningGroup.value,
                    group_id: selectedLearningGroup.value,
                    sme: selectedSME.value,
                    project_duration: selectedDuration.value,
                    key_words: selectedKeywords.value,
                    curriculum: selectedCurriculum.value,
                }

                try {
                    const schoolId = getSchoolId()
                    const apiUrl = `${API_BASE_URL}/school/${schoolId}/projects/${props.itemData.id}`
                    loading.value = true
                    const accessToken = getValidToken()

                    await checkAndRefreshToken()

                    const response = await fetch(apiUrl, {
                            method: "PUT",
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${accessToken}`,
                            },
                            body: JSON.stringify(registrationData)
                        }
                    );

                    if (response.ok) {
                        const result = await response.json();
                        if (result.code === 200) {
                            const msg = {
                                type: 'success',
                                message: 'Success! You have updated the registration details'
                            }
                            emit('closeFormModal', msg)
                        } else {
                            console.log("error", result.message);
                        }
                    } else {
                        const result = await response.json();
                        console.log("error", result.message);
                    }
                } catch (error) {
                    console.error("An error occured:", error);
                } finally {
                    loading.value = false
                }
            }
        }

        return {
            name,
            term,
            updateItems,
            nameError,
            academicYearError,
            termError,
            projectTypeError,
            keywordsError,
            supportingColearnerError,
            projectTypeSelectOptions,
            colearnerSelectOptions,
            learningGroupSelectOptions,
            projectDurationSelectOptions,
            projectKeywordSelectOptions,
            smeSelectOptions,
            selectedProjectType,
            selectedLeadColearner,
            selectedSupportingColearners,
            selectedLearningGroup,
            selectedSME,
            selectedDuration,
            selectedKeywords,
            leadColearnerLoader,
            learningGroupDropdownLoader,
            smeLoader,
            keyWordsLoader,
            loading,
            leadColearnerError,
            projectDurationError,
            smeError,
            yearLoader,
            selectedAcademicYear,
            academicYearSelectOptions,
            termsSelectOptions,
            selectedTerm,
            termLoader,
            selectedCurriculum,
            curriculumOptions,
            curriculumLoader
        }
    }
}
</script>