import {API_BASE_URL} from "@/config.js";
import {
    capitalizeFirstLetter,
    formatCurrency,
    formatDateTime,
    getUserInitialsForName,
    getValidToken
} from "@/utils/helpers.js";
import {computed, ref} from "vue";
import {checkAndRefreshToken} from '@/utils/auth';

const loading = ref(false);
const apiUrl = `${API_BASE_URL}/subscription/list-plans`;
const plansPayloadData = ref(null);
const plansData = ref([])

const fetchPlansData = async () => {
    try {
        await checkAndRefreshToken()

        const accessToken = getValidToken()
        if (!accessToken) {
            console.error("Access token not available.");
            return;
        }

        loading.value = true;
        const response = await fetch(apiUrl, {
            headers: {
                Authorization: `Bearer ${accessToken}`, // Include the access token in the headers
            },
        });

        if (response.ok) {
            const result = await response.json();

            if (result.code === 200) {
                plansPayloadData.value = result.data
                plansData.value = result.data
            } else {
                console.log("error", result.message);
            }
        } else {
            console.log("response", response);
            console.error("Error fetching data from the API");
        }
    } catch (error) {
        console.error("An error occured:", error);
    } finally {
        loading.value = false;
    }
};


const formattedPlansData = computed(() => {
    // Map and format the data
    return plansData.value.map((plan) => {

        // Format the created at
        const {date, time} = formatDateTime(plan.created_at);

        const formattedPlan = {
            itemName: {
                name: capitalizeFirstLetter(plan.name),
                initials: getUserInitialsForName(plan.name),
                avatarColor: 'dark'
            },
            billing_cycle: plan.bill_cycle,
            amount: `${formatCurrency(plan.amount)}`,
            createdAt: date,
            status: plan.isActive ? 'Active' : 'Deactivated',
        };

        return {
            ...formattedPlan,
        };
    });
});

const getPlans = () => {
    return {plansPayloadData, fetchPlansData, loading, formattedPlansData, plansData}
}

export default getPlans