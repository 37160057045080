<template>

    <div class="row" v-if="itemData">
        <div class="col-sm-12 col-md-3">
            <div class="card-box">
                <div class="card-content">
                    <div class="header-content text-center">
                        <div v-if="itemData.profile_image">
                            <img
                                class="mb-20 d-block mx-auto rounded-circle img-thumbnail"
                                :src="itemData.profile_image"
                                alt="Profile Image"
                                @error="handleImageError"
                            />
                        </div>

                        <AvatarForInitials :avatarText="getUserInitialsForName(itemName)" iconColor="primary"
                                           class="avatar-md-150 mb-30 m-auto" v-else/>

                        <h4 class="mb-0">{{ itemName }}</h4>
                        <p class="mb-0">{{ itemData.age }} Years Old</p>
                    </div>

                    <div class="middle-content mb-50">
                        <div class="details-content">
                            <img src="../../../assets/images/icons/calendar_icon.svg" alt="Mobile Icon"/>
                            <div class="details">
                                <p class="muted-text-sm">Added On</p>
                                <p class="result-text">{{ userCreatedAt }}</p>
                            </div>
                        </div>

                        <div class="details-content">
                            <img src="../../../assets/images/icons/folder_icon.svg" class="data-img" alt="Mobile Icon"/>

                            <div class="detail-content-group">
                                <p class="data-title">Projects</p>
                                <p class="data-text">{{ itemData.projects.length }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="buttons-content">
                        <RegularButtonBlock buttonText="Edit Profile" iconText="mdi-account-edit"
                                            class="custom-button mb-3" @clicked="editAction"/>

                        <a href="#" class="bold primary" @click.prevent="triggerFilePicker">
                            Update {{ itemName }}'s Profile Image
                        </a>

                        <input ref="fileInput" type="file" accept=".png, .jpg" @change="selectImage" style="display: none"/>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-sm-12 col-md-9">
            <div class="row">
                <div class="col-12">
                    <div class="page-title-box">
                        <BreadCrumbs/>
                    </div>
                </div>
            </div>

            <div class="card-box-no-pd">
                <div class="page-title-box mb-30">
                    <h4 class="page-title-gray">Student's Profile</h4>

                    <ul class="tab-container">
                        <li v-for="(tab, index) in tabs" :key="index" @click="selectTab(index)" class="tab"
                            :class="{ 'active-tab': activeTab === index }">
                            {{ tab }}
                        </li>
                    </ul>
                </div>

                <div v-show="activeTab === 0">
                    <div class="card-content border-bottom plr-50">
                        <div class="row">
                            <div class="col-lg-6 col-sm-12">
                                <div class="detail-group mb-30">
                                    <span class="muted-text-sm">Full Name</span>
                                    <p>{{ itemName }}</p>
                                </div>

                                <div class="detail-group mb-30">
                                    <span class="muted-text-sm">Date of Birth</span>
                                    <p>{{ itemData.dob }}</p>
                                </div>

                                <div class="detail-group mb-30" v-if="itemData.msisdn">
                                    <span class="muted-text-sm">Contact Number</span>
                                    <p>{{ itemData.msisdn }}</p>
                                </div>

                                <div class="detail-group mb-30">
                                    <span class="muted-text-sm">Country of Residence</span>
                                    <p>{{ itemData.country }}</p>
                                </div>
                            </div>

                            <div class="col-lg-6 col-sm-12">
                                <div class="detail-group mb-30">
                                    <span class="muted-text-sm">Gender</span>
                                    <p>{{ itemData._gender }}</p>
                                </div>

                                <div class="detail-group mb-30">
                                    <span class="muted-text-sm">Current Age</span>
                                    <p>{{ itemData.age }}</p>
                                </div>

                                <div class="detail-group mb-30" v-if="itemData.email">
                                    <span class="muted-text-sm">Email Address</span>
                                    <p>{{ itemData.email }}</p>
                                </div>

                                <div class="detail-group mb-30">
                                    <span class="muted-text-sm">State of Residence</span>
                                    <p>{{ itemData.state }}</p>
                                </div>
                            </div>

                            <div class="col-sm-12">
                                <div class="detail-group mb-30">
                                    <span class="muted-text-sm">Residential Address</span>
                                    <p>{{ itemData.address }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-show="activeTab === 1">
                    <div class="card-content border-bottom plr-50">
                        <div v-if="parents.length > 0">
                            <template v-for="parent in parents" :key="parent">
                                <div v-if="parent.relationship_to_student === 'father'"
                                     class="parent-card mb-30">
                                    <div class="parent-side-card"></div>
                                    <div class="row">
                                        <div class="col-12 mt-3">
                                            <h2>Father</h2>
                                        </div>
                                        <div class="col-lg-6 col-sm-12">
                                            <div class="detail-group mb-30">
                                                <span class="muted-text-sm">Full Name</span>
                                                <p>{{ getParentFullName(parent) }}</p>
                                            </div>

                                            <div class="detail-group mb-30">
                                                <span class="muted-text-sm">Contact Number</span>
                                                <p>{{ parent.work_msisdn }}</p>
                                            </div>

                                            <div class="detail-group mb-30">
                                                <span class="muted-text-sm">Country of Residence</span>
                                                <p>{{ parent.country }}</p>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-sm-12">
                                            <div class="detail-group mb-30">
                                                <span class="muted-text-sm">Email Address</span>
                                                <p>{{ parent.work_email }}</p>
                                            </div>
                                            <div class="detail-group mb-30">
                                                <span class="muted-text-sm">State of Residence</span>
                                                <p>{{ parent.state }}</p>
                                            </div>
                                        </div>

                                        <div class="col-lg-6 col-sm-12">
                                            <div class="detail-group mb-30">
                                                <span class="muted-text-sm">Residential Address</span>
                                                <p>{{ parent.work_address }}</p>
                                            </div>
                                        </div>

                                        <div class="col-12 mt-10">
                                            <RegularButton buttonText="Remove Father" class="custom-button mb-30"
                                                           @click="removeParentFromStudent(parent.user_id)"
                                                           :loading="removeParentLoader"/>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="parent.relationship_to_student === 'mother'"
                                     class="parent-card mb-50">
                                    <div class="parent-side-card"></div>
                                    <div class="row">
                                        <div class="col-12 mt-3">
                                            <h2>Mother</h2>
                                        </div>
                                        <div class="col-lg-6 col-sm-12">
                                            <div class="detail-group mb-30">
                                                <span class="muted-text-sm">Full Name</span>
                                                <p>{{ getParentFullName(parent) }}</p>
                                            </div>

                                            <div class="detail-group mb-30">
                                                <span class="muted-text-sm">Contact Number</span>
                                                <p>{{ parent.work_msisdn }}</p>
                                            </div>

                                            <div class="detail-group mb-30">
                                                <span class="muted-text-sm">Country of Residence</span>
                                                <p>{{ parent.country }}</p>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-sm-12">
                                            <div class="detail-group mb-30">
                                                <span class="muted-text-sm">Email Address</span>
                                                <p>{{ parent.work_email }}</p>
                                            </div>
                                            <div class="detail-group mb-30">
                                                <span class="muted-text-sm">State of Residence</span>
                                                <p>{{ parent.state }}</p>
                                            </div>
                                        </div>

                                        <div class="col-lg-6 col-sm-12">
                                            <div class="detail-group mb-30">
                                                <span class="muted-text-sm">Residential Address</span>
                                                <p>{{ parent.work_address }}</p>
                                            </div>
                                        </div>

                                        <div class="col-12 mt-10">
                                            <RegularButton buttonText="Remove Mother" class="custom-button mb-30"
                                                           @click="removeParentFromStudent(parent.user_id)"
                                                           :loading="removeParentLoader"/>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>

                        <div v-else class="row m-0">
                            <div class="col-12 mb-20 pl-0">
                                <p class="p-0 bold">This student is not linked to a parent yet.</p>
                            </div>

                            <div class="col-12 mb-30 pl-0">
                                <form @submit.prevent="searchForResult">
                                    <TextInputFieldVue :config="{
                                            class: 'form-control-gray mb-10',
                                            placeholder: 'Search Parent Email',
                                            inputFieldType: 'email',
                                        }" labelText="You can add a parent by entering the parent's email below"
                                                       v-model="searchTerm" :isRequired="true"/>

                                    <RegularButton buttonText="Search" class="custom-button"
                                                   buttonType="submit"
                                                   :loading="searchButtonLoading"/>
                                </form>
                            </div>

                            <div class="col-lg-6 col-sm-12" v-if="searchResults.length > 0">
                                <h3>Results</h3>
                                <div class="card card-project" v-for="parent in searchResults" :key="parent">
                                    <div class="card-body">
                                        <h5 class="card-title mb-1 bold">
                                            {{ capitalizeFirstLetter(parent.first_name, parent.last_name) }}</h5>

                                        <p class="card-text mb-3">
                                            {{ parent.email }}
                                        </p>

                                        <RegularButton buttonText="Link to Student" class="custom-button"
                                                       @click="addParentInfoToStudent(parent)"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-show="activeTab === 2">
                    <div class="card-content border-bottom plr-50">
                        <div class="row m-0">
                            <div class="col-12 mb-20 pl-0">
                                <p class="p-0" v-if="itemData.projects.length === 0">No Projects Yet</p>

                                <div class="row" v-else>
                                    <div class="col-lg-4 col-sm-12" v-for="project in itemData.projects">
                                        <div class="card card-project bg-light">
                                            <div class="card-body">
                                                <h5 class="card-title bold">
                                                    {{ capitalizeFirstLetter(project.name) }}
                                                </h5>
                                                <p class="card-text text-dark mb-3">
                                                    {{ truncateText(capitalizeFirstLetter(project.description), 30) }}
                                                </p>
                                                <router-link :to="{ name: 'ShowProject', params: {id: project.id} }"
                                                             class="btn btn-dark btn-sm waves-effect">
                                                    <span class="bold"> View </span>
                                                </router-link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-show="activeTab === 3">
                    <div class="card-content border-bottom plr-50">
                        <div class="row m-0">
                            <div class="col-12 mb-20 pl-0">
                                <Comments
                                    :deleteCommentUrl="deleteCommentUrl"
                                    :fetchCommentUrl="fetchCommentUrl"
                                    :addCommentUrl="addCommentUrl"/>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-show="activeTab === 4">
                    <div class="card-content border-bottom plr-50">
                        <div class="row m-0">
                            <div class="col-12 mb-20 pl-0">
                                <Files
                                    :fileAddURL="fileAddURL"
                                    :fileFetchURL="fileFetchURL"
                                    :fileDeleteURL="fileDeleteURL"
                                    @feedback="showAlertForFiles"
                                    @loading="showFilesLoader"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div v-show="activeTab === 5">
                    <div class="card-content border-bottom plr-50">
                        <div class="row mb-30">
                            <div class="col-lg-5 col-sm-12">
                                <div class="detail-group mb-30">
                                    <span class="muted-text-sm">
                                        Do you have any medical conditions?
                                    </span>
                                    <p>{{ itemData.any_medical_condition === true ? 'Yes' : 'No' }}</p>
                                </div>

                                <div class="detail-group mb-30">
                                    <span class="muted-text-sm">Do you have any Special Educational Needs?</span>
                                    <p>{{ itemData.any_educational_needs === true ? 'Yes' : 'No' }}</p>
                                </div>

                                <div class="detail-group mb-30">
                                    <span
                                        class="muted-text-sm">Do you have any learning differences and/or delays?</span>
                                    <p>{{ itemData.any_learning_delay === true ? 'Yes' : 'No' }}</p>
                                </div>

                                <div class="detail-group mb-30">
                                    <span
                                        class="muted-text-sm">Do you have any allergies?</span>
                                    <p>{{ itemData.any_allergies === true ? 'Yes' : 'No' }}</p>
                                </div>

                                <div class="detail-group mb-30">
                                    <span
                                        class="muted-text-sm">Do you have any special diet?</span>
                                    <p>{{ itemData.any_special_dietary === true ? 'Yes' : 'No' }}</p>
                                </div>
                            </div>

                            <div class="col-lg-7 col-sm-12">
                                <div class="detail-group mb-30">
                                    <span class="muted-text-sm">
                                        If yes, please provide more information
                                    </span>
                                    <p>{{ capitalizeFirstLetterInSentence(itemData.medical_condition) }}</p>
                                </div>

                                <div class="detail-group mb-30">
                                    <span class="muted-text-sm">If yes, please provide more information</span>
                                    <p>{{ capitalizeFirstLetterInSentence(itemData.educational_needs) }}</p>
                                </div>

                                <div class="detail-group mb-30">
                                    <span class="muted-text-sm">If yes, please provide more information</span>
                                    <p>{{ capitalizeFirstLetterInSentence(itemData.learning_delay) }}</p>
                                </div>

                                <div class="detail-group mb-30">
                                    <span class="muted-text-sm">If yes, please provide more information</span>
                                    <p>{{ capitalizeFirstLetterInSentence(itemData.allergies) }}</p>
                                </div>

                                <div class="detail-group mb-30">
                                    <span class="muted-text-sm">If yes, please provide more information</span>
                                    <p>{{ capitalizeFirstLetterInSentence(itemData.special_dietary) }}</p>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12 mb-30">
                                <RegularButton buttonText="Edit Medical Info" iconText="mdi-circle-edit-outline"
                                               color="yellow"
                                               class="custom-button"
                                               @clicked="editMedicalInfo"/>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-show="activeTab === 6">
                    <div class="card-content border-bottom plr-50">
                        <div class="row mb-30">
                            <div class="col-lg-5 col-sm-12">
                                <div class="detail-group mb-30">
                                    <span class="muted-text-sm">First Name</span>
                                    <p>{{ capitalizeFirstLetter(itemData.emergency_contact_first_name) }}</p>
                                </div>

                                <div class="detail-group mb-30">
                                    <span class="muted-text-sm">Contact Number</span>
                                    <p>{{ capitalizeFirstLetter(itemData.emergency_contact_msisdn) }}</p>
                                </div>

                            </div>

                            <div class="col-lg-7 col-sm-12">
                                <div class="detail-group mb-30">
                                    <span class="muted-text-sm">Last Name</span>
                                    <p>{{ capitalizeFirstLetter(itemData.emergency_contact_last_name) }}</p>
                                </div>

                                <div class="detail-group mb-30">
                                    <span class="muted-text-sm">Relationship to Student</span>
                                    <p>{{ capitalizeFirstLetter(itemData.emergency_contact_relationship) }}</p>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12 mb-30">
                                <RegularButton buttonText="Edit Emergency Info" iconText="mdi-circle-edit-outline"
                                               color="yellow"
                                               class="custom-button"
                                               @clicked="editEmergencyInfo"/>
                            </div>
                        </div>
                    </div>
                </div>

                <MessageSliderCopy :isVisible="displayMessage" :messageText="messageText" :messageType="messageType"/>
                <FormModal :isFormModalOpen="isEditFormModalOpen" @closeFormModal="closeEditFormModal"
                           modalHeader="Edit Student">
                    <EditSchoolStudent @closeFormModal="closeEditFormModal"
                                       :itemData="itemDataForEditing" @refreshPage="fetchItemData"/>
                </FormModal>

                <FormModal :isFormModalOpen="isEditMedicalConditionFormModalOpen"
                           @closeFormModal="closeEditMedicalConditionFormModal"
                           modalHeader="Edit Emergency Contact Info">
                    <EditStudentMedicalInfo @closeFormModal="closeEditMedicalConditionFormModal"
                                            :itemData="itemDataForEditing" @refreshPage="fetchItemData"/>
                </FormModal>

                <FormModal :isFormModalOpen="isEditEmergencyInfoFormModalOpen"
                           @closeFormModal="closeEditEmergencyInfoFormModal"
                           modalHeader="Edit Emergency Contact Info">
                    <EditEmergencyInfo @closeFormModal="closeEditEmergencyInfoFormModal"
                                       :itemData="itemDataForEditing" @refreshPage="fetchItemData"/>
                </FormModal>
            </div>
        </div>
    </div>
</template>

<script>
import Switch from "@/components/common/Switch.vue";
import RegularButtonBlock from "@/components/common/RegularButtonBlock.vue";
import BreadCrumbs from "@/components/common/BreadCrumbs.vue";
import {onMounted, ref, watch} from "vue";
import {
    capitalizeFirstLetter,
    capitalizeFirstLetterInSentence,
    compressImage,
    dd,
    formatDateTime,
    getCurrentUser,
    getUserInitialsForName,
    getValidToken,
    truncateText
} from '@/utils/helpers'
import {API_BASE_URL} from "@/config.js";
import MessageSliderCopy from "@/components/common/MessageSliderCopy.vue";
import FormModal from "@/components/common/FormModal.vue";
import EditParent from "@/components/layouts/GodAdmin/Parents/EditParent.vue";
import {useRoute} from "vue-router";
import {checkAndRefreshToken} from "@/utils/auth";
import UserAvatarBox2 from "@/components/common/UserAvatarBox2.vue";
import EditSchoolParent from "@/views/School_Admin/Parents/EditSchoolParent.vue";
import EditSchoolStudent from "@/views/School_Admin/Students/EditSchoolStudent.vue";
import TextInputFieldVue from "@/components/common/TextInputField.vue";
import RegularButton from "@/components/common/RegularButton.vue";
import Comments from "@/components/common/Comments.vue";
import EditEmergencyInfo from "@/views/God_Admin/Students/EditEmergencyInfo.vue";
import EditStudentMedicalInfo from "@/views/God_Admin/Students/EditStudentMedicalInfo.vue";
import Files from "@/components/common/Files.vue";
import AvatarForInitials from "@/components/common/AvatarForInitials.vue";


export default {
    components: {
        AvatarForInitials,
        Files,
        EditStudentMedicalInfo, EditEmergencyInfo,
        Comments,
        RegularButton, TextInputFieldVue,
        EditSchoolStudent,
        EditSchoolParent,
        UserAvatarBox2,
        EditParent,
        FormModal,
        Switch,
        RegularButtonBlock,
        BreadCrumbs,
        MessageSliderCopy
    },
    props: {
        id: String
    },
    setup(props, {emit}) {
        const isEditFormModalOpen = ref(false);
        const isEditMedicalConditionFormModalOpen = ref(false);
        const isEditEmergencyInfoFormModalOpen = ref(false);
        const itemData = ref(null)
        const itemName = ref('')
        const parents = ref([])
        const userCreatedAt = ref('')
        const loading = ref(null)
        const checkedStatus = ref(false)
        const switchTextLabel = ref('')
        const messageText = ref("");
        const messageType = ref("");
        const displayMessage = ref(false);
        const currentUser = getCurrentUser()
        const tabs = ref(['Basic Details', 'Parent\'s Details', 'Projects', 'Comments', 'Files', 'Medical Info', 'Emergency Info'])
        const activeTab = ref(0)
        const router = useRoute()
        const itemId = router.params.id
        const accessToken = getValidToken()
        const searchTerm = ref('')
        const searchResults = ref([])
        const fetchCommentUrl = `${API_BASE_URL}/student/${itemId}/comment`;
        const addCommentUrl = `${API_BASE_URL}/student/${itemId}/comment`;
        const deleteCommentUrl = `${API_BASE_URL}/student/${itemId}/comment`;
        const removeParentLoader = ref(false)
        const fileFetchURL = `${API_BASE_URL}/student/${itemId}/file`
        const fileAddURL = `${API_BASE_URL}/student/${itemId}/file`
        const fileDeleteURL = `${API_BASE_URL}/student/${itemId}/file`

        const fetchItemData = async () => {
            const apiUrl = `${API_BASE_URL}/student/get-student/${itemId}`;

            try {
                emit('show-loader')
                const response = await fetch(apiUrl, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    }
                })

                if (response.ok) {
                    const result = await response.json()
                    itemData.value = result.data

                    // console.log('result', result.data)
                } else {
                    console.log("response", response);
                    console.error("Error fetching data from the API");
                }
            } catch (error) {
                console.error('An error occured: ', error)
            }
        }


        onMounted(() => {
            fetchItemData()
        })

        watch(itemData, (item) => {
            itemName.value = capitalizeFirstLetter(item.first_name, item.last_name)
            const {date, time} = formatDateTime(item.created_at)
            userCreatedAt.value = `${date}, ${time}`
            checkedStatus.value = !item.isDeactivated
            switchTextLabel.value = item.isDeactivated ? 'Account Deactivated' : 'Account Active'
            parents.value = item.parent

            if (item) {
                emit('data-loaded')
            }
        })

        const selectTab = (index) => {
            activeTab.value = index;
        };

        const openEditFormModal = () => {
            isEditFormModalOpen.value = true;
        };

        const closeEditFormModal = async (msg) => {
            isEditFormModalOpen.value = false;

            if (msg) {
                flashAlertMessage(msg.type, msg.apiResponse)
            }

            await fetchItemData()
        };


        const itemDataForEditing = ref(null)
        const editAction = () => {
            itemDataForEditing.value = {
                firstName: itemData.value.first_name,
                lastName: itemData.value.last_name,
                gender: itemData.value._gender,
                msisdn: itemData.value.msisdn,
                country: itemData.value.country,
                state: itemData.value.state,
                address: itemData.value.address,
                dob: itemData.value.dob,
                age: itemData.value.age,
                workAddress: itemData.value.work_address,
                id: props.id
            }

            openEditFormModal()
        }

        const handleSwitchToggle = () => {
            const userId = props.id
            toggleUserStatus(userId)
        }

        const toggleUserStatus = async (id) => {
            const apiUrl = `${API_BASE_URL}/student/delete-student`

            try {
                await checkAndRefreshToken()

                loading.value = true
                const response = await fetch(apiUrl, {
                    method: 'DELETE',
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`
                    },
                    body: JSON.stringify({
                        student_id: id,
                        reason: ''
                    })
                })

                if (response.ok) {
                    const result = await response.json()

                    if (result.code === 200) {

                        flashAlertMessage("success", "Student status updated")
                        await fetchItemData()
                    } else {

                        flashAlertMessage("error", result.message)
                        console.log('error', result.message)
                    }
                } else {
                    const result = await response.json();
                    flashAlertMessage("error", result.message)
                    console.log("error", result.message);
                }
            } catch (error) {
                flashAlertMessage("error", error)
                console.error("An error occured:", error);
            } finally {
                loading.value = false
            }
        }

        const flashAlertMessage = (alertType, alertMessage) => {
            emit('flash-message', {
                alertType: alertType,
                alertMessage: alertMessage
            })
        }

        const viewStudent = (id) => {
            dd(id)
        }

        const toggleStudentStatus = (id) => {
            dd(id)
        }

        const addParentInfoToStudent = async (parent) => {
            try {
                await checkAndRefreshToken()

                const accessToken = getValidToken()
                if (!accessToken) {
                    console.error("Access token not available.");
                    return;
                }

                emit('show-loader')
                const data = {
                    parent_id: parent.user_id,
                    student_id: props.id,
                }

                const apiUrl = `${API_BASE_URL}/student/add-parent`
                // console.log('id', parent.user_id)
                const response = await fetch(apiUrl, {
                    method: 'PUT',
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(data)
                });

                if (response.ok) {
                    const result = await response.json();

                    if (result.code === 200) {
                        await fetchItemData()

                        const msg = {
                            apiResponse: 'Success! You have linked a parent to this student',
                            type: "success"
                        }

                        flashAlertMessage(msg.type, msg.apiResponse)
                    } else {
                        console.log("error", result.message);
                    }
                } else {
                    console.log("response", response);
                    console.error("Error fetching data from the API");
                }
            } catch (error) {
                console.error("An error occured:", error);
            } finally {
                emit('data-loaded')
            }
        }

        const searchButtonLoading = ref(false)
        const searchForResult = async () => {
            try {
                await checkAndRefreshToken()

                const accessToken = getValidToken()
                if (!accessToken) {
                    console.error("Access token not available.");
                    return;
                }

                searchButtonLoading.value = true;
                const apiUrl = `${API_BASE_URL}/parent/search-parent?query=${searchTerm.value}`
                const response = await fetch(apiUrl, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`, // Include the access token in the headers
                    },
                });

                if (response.ok) {
                    const result = await response.json();

                    if (result.code === 200) {
                        searchResults.value = result.data
                        console.log(result)
                    } else {
                        console.log("error", result.message);
                    }
                } else {
                    console.log("response", response);
                    console.error("Error fetching data from the API");
                }
            } catch (error) {
                console.error("An error occured:", error);
            } finally {
                searchButtonLoading.value = false;
            }
        };

        const removeParentFromStudent = async (parentId) => {
            try {
                await checkAndRefreshToken()

                const accessToken = getValidToken()
                if (!accessToken) {
                    console.error("Access token not available.");
                    return;
                }

                emit('show-loader')
                removeParentLoader.value = true
                const data = {
                    parent_id: parentId,
                    student_id: props.id,
                }

                const apiUrl = `${API_BASE_URL}/student/remove-parent`
                const response = await fetch(apiUrl, {
                    method: 'PUT',
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(data)
                });

                if (response.ok) {
                    const result = await response.json();

                    if (result.code === 200) {
                        await fetchItemData()

                        const msg = {
                            apiResponse: 'Success! You have removed the parent from this student',
                            type: "success"
                        }

                        flashAlertMessage("success", msg.apiResponse)
                    } else {
                        console.log("error", result.message);
                    }
                } else {
                    console.log("response", response);
                    console.error("Error fetching data from the API");
                }
            } catch (error) {
                console.error("An error occured:", error);
            } finally {
                emit('data-loaded')
                removeParentLoader.value = false
            }
        }

        const openEditMedicalConditionFormModal = () => {
            isEditMedicalConditionFormModalOpen.value = true;
        };

        const closeEditMedicalConditionFormModal = async (message) => {
            isEditMedicalConditionFormModalOpen.value = false;

            if (message) {
                flashAlertMessage(message.type, message.apiResponse)
            }
        };

        const openEditEmergencyInfoFormModal = () => {
            isEditEmergencyInfoFormModalOpen.value = true;
        };

        const closeEditEmergencyInfoFormModal = async (message) => {
            isEditEmergencyInfoFormModalOpen.value = false;

            if (message) {
                flashAlertMessage(message.type, message.apiResponse)
            }
        };

        const editMedicalInfo = () => {
            itemDataForEditing.value = {
                any_medical_condition: itemData.value.any_medical_condition,
                any_educational_needs: itemData.value.any_educational_needs,
                any_learning_delay: itemData.value.any_learning_delay,
                any_allergies: itemData.value.any_allergies,
                any_special_dietary: itemData.value.any_special_dietary,

                medical_condition: itemData.value.medical_condition,
                educational_needs: itemData.value.educational_needs,
                learning_delay: itemData.value.learning_delay,
                allergies: itemData.value.allergies,
                special_dietary: itemData.value.special_dietary,
                id: itemData.value.user_id
            }

            openEditMedicalConditionFormModal()
        }

        const editEmergencyInfo = () => {
            itemDataForEditing.value = {
                emergency_contact_first_name: itemData.value.emergency_contact_first_name,
                emergency_contact_last_name: itemData.value.emergency_contact_last_name,
                emergency_contact_msisdn: itemData.value.emergency_contact_msisdn,
                emergency_contact_relationship: itemData.value.emergency_contact_relationship,
                id: itemData.value.user_id
            }

            openEditEmergencyInfoFormModal()
        }

        const getParentFullName = (parent) => {
            return capitalizeFirstLetter(parent.first_name, parent.last_name)
        }

        const showFilesLoader = (loading) => {
            if (loading === 'show-files-loader') {
                emit('show-loader')
            }

            if (loading === 'files-data-loaded') {
                emit('data-loaded')
            }
        }

        const showAlertForFiles = (feedback) => {
            if (feedback.type === 'success') {
                flashAlertMessage('success', feedback.message)
            } else {
                flashAlertMessage('error', feedback.message)
            }
        }

        const handleImageError = () => {
            itemData.value.profile_image = null
        }

        const triggerFilePicker = () => {
            if (fileInput.value) {
                fileInput.value.click(); // This simulates a click on the file input, opening the file picker dialog
            }
        }

        const selectedImage = ref('')
        const fileInput = ref(null);
        const selectImage = async (event) => {
            const file = event.target.files[0];

            if (file && (file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/jpg')) {
                try {
                    // Set the compressed image as the selected image
                    selectedImage.value = await compressImage(file, 200, 200, 0.5);
                    await handleFileChange()
                } catch (error) {
                    console.error('Error compressing image: ', error)
                } finally {
                    // Reset the value to allow selecting the same file again
                    if (fileInput.value) {
                        fileInput.value.value = ''; // Reset the file input
                    }
                }
            } else {
                // Handle invalid file type
                flashAlertMessage('error', 'Invalid file type. Please select a PNG or JPEG image.')
                console.error('Invalid file type. Please select a PNG or JPEG image.');
            }
        };

        const handleFileChange = async () => {
            try {
                emit('show-loader')
                const apiUrl = `${API_BASE_URL}/student/change-profile-image?user_id=${itemId}`;
                await checkAndRefreshToken()

                const profileImage = {
                    profile_image: selectedImage.value
                }

                const response = await fetch(apiUrl, {
                    method: 'PUT',
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(profileImage)
                })

                if (response.ok) {
                    const result = await response.json()

                    if (result.code === 200) {
                        flashAlertMessage('success', 'Profile image successfully updated')
                        await fetchItemData()
                        // console.log('result', result.data)
                    } else {
                        flashAlertMessage('error', 'An error occured')
                        console.log("code error", result);
                    }
                } else {
                    console.log("ok error", response);
                    console.error("Error sending data to the API");
                }
            } catch (error) {
                console.error('An error occured: ', error)
            } finally {
                emit('data-loaded')
            }
        }

        return {
            selectImage,
            fileInput,
            selectedImage,
            triggerFilePicker,
            handleImageError,
            loading,
            showAlertForFiles,
            showFilesLoader,
            getParentFullName,
            openEditMedicalConditionFormModal,
            closeEditMedicalConditionFormModal,
            openEditEmergencyInfoFormModal,
            closeEditEmergencyInfoFormModal,
            editMedicalInfo,
            editEmergencyInfo,
            itemData,
            itemName,
            userCreatedAt,
            checkedStatus,
            switchTextLabel,
            handleSwitchToggle,
            messageText,
            messageType,
            displayMessage,
            currentUser,
            editAction,
            closeEditFormModal,
            isEditFormModalOpen,
            itemDataForEditing,
            tabs,
            activeTab,
            selectTab,
            fetchItemData,
            toggleStudentStatus,
            viewStudent,
            capitalizeFirstLetter,
            parents,
            addParentInfoToStudent,
            searchTerm,
            searchResults,
            searchForResult,
            searchButtonLoading,
            fetchCommentUrl,
            addCommentUrl,
            deleteCommentUrl,
            truncateText,
            removeParentFromStudent,
            removeParentLoader,
            capitalizeFirstLetterInSentence,
            isEditMedicalConditionFormModalOpen,
            isEditEmergencyInfoFormModalOpen,
            fileFetchURL,
            fileAddURL,
            fileDeleteURL,
            getUserInitialsForName
        }
    }
};
</script>

<style scoped>

.detail-content-group > p {
    margin: 0;
}

.border-bottom {
    border-bottom: 2px solid #F5F6FA;
}

p {
    color: #9a9a9a;
    font-size: 16px;
}

.details-content {
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding: 30px 30px 0 30px;
}

.detail-content-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.details-content:last-child {
    padding-bottom: 30px;
}

.details-content:first-child {
    border-bottom: 1px solid #e9eaf3;
    padding-bottom: 30px;
}

.details-content > img {
    margin-right: 15px;
    margin-top: 5px;
}

.details-content .details > p {
    margin-bottom: 0;
}

.logo-text > img {
    margin-right: 15px;
    margin-top: 2px;
}

.logo-text > p {
    color: #333333;
}

.detail-group > p,
.result-text,
.data-title {
    color: #333;
}

.item > .count {
    color: #2f5bcf;
}

.buttons-content {
    padding: 0 15px 40px 15px;
    text-align: center;
}

.page-title-gray {
    background-color: #F8FAFC;
    font-size: 1.25rem;
    margin: 0;
    line-height: 75px;
    color: #323a46;
    padding: 20px 50px 0 50px;
}

.tab-container {
    display: flex;
    background-color: #F8FAFC;
    padding-left: 50px;
}

.tab {
    list-style-type: none;
    margin: 0 20px;
    text-align: center;
    cursor: pointer;
    padding: 10px;
}

.tab:first-child {
    margin: 0;
    padding-left: 0;
}

.active-tab {
    border-bottom: 2px solid #0d44b2;
}

.card-project {
    border: 1px solid #B8BCCA !important;
    background-color: #fff !important;
}

.parent-card {
    background-color: #f3f4f8;
    position: relative;
    padding-left: 30px;
    border-radius: 12px;
}

.parent-side-card {
    position: absolute;
    left: 0;
    background-color: #0d44b2;
    width: 10px;
    height: 100%;
    border-bottom-left-radius: 12px;
    border-top-left-radius: 12px;
}

.rounded-circle {
    height: 150px !important;
    width: 150px !important;
    object-fit: contain;
}

.buttons-content a {
    color: #0d44b2;
}
</style>