<template>
    <form @submit.prevent="updateItem">
        <div class="row mb-30">
            <div class="col-lg-6 first_name">
                <TextInputField :config="{
                    class: 'form-control-gray',
                    placeholder: 'Enter First Name',
                    inputFieldType: 'text',
                }" labelText="First Name" v-model="firstName" :isRequired="true"/>
            </div>

            <div class="col-lg-6">
                <TextInputField :config="{
                    class: 'form-control-gray',
                    placeholder: 'Enter Last Name',
                    inputFieldType: 'text',
                }" labelText="Last Name" v-model="lastName" :isRequired="true"/>
            </div>
        </div>

        <div class="row mb-30">
            <div class="col-lg-6">
                <TextInputField :config="{
                    class: 'form-control-gray',
                    placeholder: 'Enter Phone Number',
                    inputFieldType: 'tel',
                }" labelText="Phone Number" v-model="msisdn" :isRequired="true"/>
            </div>

            <div class="col-lg-6">
                <SingleSelectField labelText="Relationship to Student" :options="relationshipOptions" v-model="selectedRelationship"
                                :isRequired="true"
                                selectId="relationshipOptions" :selectedItem="selectedRelationship" />
            </div>
        </div>

        <RoundedButton buttonText="Save Changes" class="custom-button" buttonType="submit" :loading="buttonLoading"/>

    </form>
</template>
<script>
import TextInputField from "@/components/common/TextInputField.vue";
import SimpleDropdown from "@/components/common/SimpleDropdown.vue";
import TextAreaInputField from "@/components/common/TextAreaInputField.vue";
import RoundedButton from "@/components/common/RoundedButton.vue";
import {onMounted, ref, watch} from "vue";
import {getValidToken} from '@/utils/helpers'
import {checkAndRefreshToken} from "@/utils/auth";
import {API_BASE_URL} from "@/config";
import SingleSelectField from "@/components/common/SingleSelectField.vue";


export default {
    props: {
        itemData: {
            type: Object,
            required: true
        }
    },
    components: {
        SingleSelectField,
        TextInputField,
        SimpleDropdown,
        TextAreaInputField,
        RoundedButton,
    },
    setup(props, {emit}) {
        const buttonLoading = ref(false)
        const message = ref('')
        const relationshipOptions = ref([])
        const selectedRelationship = ref(props.itemData.emergency_contact_relationship)
        const firstName = ref(props.itemData.emergency_contact_first_name)
        const lastName = ref(props.itemData.emergency_contact_last_name)
        const msisdn = ref(props.itemData.emergency_contact_msisdn)
        const relationshipUpdated = ref(false);

        onMounted(() => {
            fetchRelationshipOptions()

            setTimeout(() => {
                selectedRelationship.value = props.itemData.emergency_contact_relationship
            }, 1000)
        })

        const fetchRelationshipOptions = () => {
            relationshipOptions.value = [
                {label: "Uncle", value: 'Uncle'},
                {label: "Aunty", value: 'Aunty'},
                {label: "Nanny", value: 'Nanny'},
                {label: "Brother", value: 'Brother'},
                {label: "Sister", value: 'Sister'},
                {label: "Caretaker", value: 'Caretaker'},
                {label: "Sibling", value: 'Sibling'},
            ]
        }

        watch(relationshipOptions, (newData) => {
            if (!relationshipUpdated.value) {
                selectedRelationship.value = newData.length > 0 ? newData[0].value : '';
            }
            relationshipUpdated.value = false; // Reset flag after watch
        });

        // OTHERS
        const showLoaders = () => {
            buttonLoading.value = true;
        };

        const hideLoaders = () => {
            buttonLoading.value = false;
        };

        const apiUrl = `${API_BASE_URL}/student/update-student?user_id=${props.itemData.id}`
        const updateItem = async () => {
            const item = {
                emergency_contact_first_name: firstName.value,
                emergency_contact_last_name: lastName.value,
                emergency_contact_msisdn: msisdn.value,
                emergency_contact_relationship: selectedRelationship.value,
            }


            try {
                showLoaders()
                await checkAndRefreshToken()
                const accessToken = getValidToken()

                const response = await fetch(apiUrl, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    },
                    body: JSON.stringify(item)
                })

                if (response.ok) {
                    const result = await response.json();

                    if (result.code === 200) {
                        message.value = result.message;
                        const msg = {
                            apiResponse: "Emergency details updated successfully",
                            type: "success",
                        }

                        emit("closeFormModal", msg);
                        emit("refreshPage");
                    } else {
                        console.log("error", result.message);
                        emit("closeFormModal", {
                            apiResponse: result.message,
                            type: "success",
                        });
                    }
                } else {
                    const result = await response.json();
                    console.log("error", result.message);
                }
            } catch (error) {
                console.error("An error occured:", error);
            } finally {
                hideLoaders();
            }
        }

        return {
            buttonLoading,
            updateItem,
            firstName,
            lastName,
            msisdn,
            relationshipOptions,
            selectedRelationship
        }
    }
}
</script>
<style scoped>
@media (max-width: 767px) {
    .first_name, .country {
        margin-bottom: 30px;
    }
}

@media (max-width:992px) {
    .first_name, .country {
        margin-bottom: 30px;
    }
}
</style>