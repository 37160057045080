<template>
    <div class="form-group" :class="fieldClass">
        <div class="custom-select-wrapper">
            <label :for="selectId" class="label">
                {{ labelText }}
                <span class="required" v-if="isRequired">*</span>
            </label>

            <div class="multiple-select-input-wrapper">
                <select
                    :id="selectId" v-model="selectedValues" @change="handleMultipleSelectChange"
                    multiple="multiple"
                    data-plugin="multiselect"
                    :class="selectId" class="js-example-basic-multiple"
                    :disabled="isDisabled" :required="isRequired">

                    <option></option>
                    <option v-for="(option, index) in itemOptions" :key="index" :value="option.value">
                        {{ option.label }}
                    </option>
                </select>
            </div>

            <div v-if="loading" class="linear-loader">
                <div class="train"></div>
            </div>

            <div class="invalid-feedback" v-if="errorMessage">
                {{ errorMessage }}
            </div>
        </div>
    </div>
</template>
<script>
import {onMounted, ref, watch} from "vue";

export default {
    props: {
        labelText: {
            type: String,
            default: 'Select Label'
        },
        options: {
            type: [Array, String],
            required: true,
        },
        selectId: {
            type: String,
            default: 'example-select'
        },
        modelValue: [Array, Number, String],
        class: String,
        loading: Boolean,
        selectedItem: [Array, String],
        isDisabled: {
            type: Boolean,
            default: false
        },
        isRequired: {
            type: Boolean,
            default: false
        },
        maximumSelectionLength: {
            type: Number,
            default: 100
        },
        errorMessage: String,
    },
    setup(props, {emit}) {
        const selectPlaceholder = 'Select an option'
        const selectedValues = ref(0)
        const fieldClass = props.class || ''
        const itemOptions = ref([])

        const handleMultipleSelectChange = () => {
            emit('update:modelValue', selectedValues.value);
        };

        // Watch for changes in the selected item and update the selectedValues
        // watch(() => props.modelValue, (newVal) => {
        //     selectedValues.value = newVal;
        // });

        onMounted(() => {
            const selectField = $(`.${props.selectId}`)

            selectField.select2({
                placeholder: selectPlaceholder,
                maximumSelectionLength: props.maximumSelectionLength
            });

            // Watch for changes in the selected item and update the selectedValues
            watch(() => props.modelValue, (newVal) => {
                if (newVal !== selectedValues.value) {
                    selectedValues.value = newVal;

                    // Update the Select2 field value after a short delay
                    setTimeout(() => {
                        selectField.val(selectedValues.value).trigger('change');
                    }, 100);
                }
            });

            if (props.options.length > 0) {
                selectedValues.value = props.modelValue;

                setTimeout(() => {
                    selectField.val(selectedValues.value).trigger('change');
                }, 100)
            }

            // Call handleMultipleSelectChange once the component is mounted to emit the initial value
            selectField.on('change', function () {
                selectedValues.value = $(this).val();
                handleMultipleSelectChange();
            })

            // Watch for changes in the options and reset selectedValues
            watch(() => props.options, (newOptions) => {
                if (!selectField || selectField.length === 0) {
                    console.error(`Element with class '${props.selectId}' not found.`);
                    return;
                }

                // Check if there are options to update
                if (newOptions.length > 0) {
                    // Clear the Select2 data and unbind the change event
                    selectField.select2('destroy');

                    // Remove all options
                    selectField.empty();

                    // Add new options
                    for (const option of newOptions) {
                        const optionElement = document.createElement('option');
                        optionElement.value = option.value;
                        optionElement.text = option.label;
                        selectField.append(optionElement);
                    }

                    // Reinitialize Select2
                    selectField.select2({
                        placeholder: selectPlaceholder,
                        allowClear: true,
                        maximumSelectionLength: props.maximumSelectionLength,
                    });

                    // Set the selected values
                    selectField.val(selectedValues.value).trigger('change');
                }

            });
        })

        return {selectedValues, fieldClass, handleMultipleSelectChange, itemOptions}
    }
}

</script>


<style scoped>
.label {
    font-weight: 400;
    font-size: 14px;
}


/* Custom select */
.custom-select-wrapper {
    display: block;
    width: 100%; /* Adjust the width as needed */
}

.custom-select-wrapper select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 100%;
    height: 40px !important;
    padding: 10px;
    border-radius: 4px;
    background-color: #f8fafc;
    border: 1px solid #D0D5DD !important;
    color: #333333;
}

.invalid-feedback {
    display: block;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.75rem;
    color: #f1556c;
}

.multiple-select-input-wrapper::after {
    content: url("../../assets/images/icons/arrow_drop_down.svg");
    width: 15px;
    height: 15px;
    position: absolute;
    top: 60%;
    right: 30px;
    transform: translateY(-50%);
    pointer-events: none;
}

.custom-select-wrapper select option:checked {
    color: #b8bcca;
}

.linear-loader {
    height: 2px;
    background-color: #b8bcca;
    width: 100%;
    position: relative;
    top: -2.5px;
    left: 0;
    right: 0;
    overflow: hidden;
}

.train {
    width: 900px;
    height: 2px;
    background-color: #2f5bcf;
    position: absolute;
    animation: moveRight 1s linear infinite;
    top: 0;
    left: 0;
}

@keyframes moveRight {
    0% {
        left: -50px;
        /* Start off the screen to the left */
    }

    100% {
        left: 100%;
        /* Move to the right of the screen */
    }
}

.required {
    color: red;
    margin-left: 2px;
    font-weight: bolder;
}
</style>