<template>
    <div class="row">
        <div class="col-12">
            <div class="page-title-box">
                <BreadCrumbs/>
            </div>
        </div>
    </div>

    <div class="row" v-if="itemData">
        <div class="col-sm-4">
            <div class="card-box">
                <div class="card-content">
                    <div class="header-content text-center mb-20">
                        <img src="../../../assets/images/icons/college_logo.png" alt="school logo" height="150"
                             width="150"/>

                        <h4 class="mb-0">{{ userName }}</h4>
                        <p class="mb-0">{{ itemData.email }}</p>
                        <p class="mb-20">{{ itemData.msisdn }}</p>
                    </div>

                    <div class="middle-content mb-100">
                        <div class="details-content mb-30">
                            <img src="../../../assets/images/icons/calendar_icon.svg" alt="Mobile Icon"/>
                            <div class="details">
                                <p class="muted-text-sm">Added On</p>
                                <p class="result-text">{{ userCreatedAt }}</p>
                            </div>
                        </div>

                        <div class="details-content">
                            <img src="../../../assets/images/icons/shield_icon.svg" alt="Mobile Icon"/>
                            <div class="details">
                                <p class="muted-text-sm">Portal Role</p>
                                <p class="result-text">{{ userRole }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="buttons-content">
                        <RegularButtonBlock buttonText="Edit Profile" iconText="mdi-account-edit"
                                            class="custom-button mb-3" @clicked="editAction"/>

                        <RegularButtonBlock buttonText="Reset Admin Password" iconText="mdi-lock"
                                            class="custom-button error mb-3" @clicked="passwordUpdateAction"/>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-sm-8">
            <div class="card-box-no-pd">
                <div class="page-title-box mb-30">
                    <h4 class="page-title-gray">User Profile</h4>
                </div>

                <div class="card-content border-bottom plr-50">
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="detail-group mb-30">
                                <span class="muted-text-sm">Full Name</span>
                                <p>{{ userName }}</p>
                            </div>

                            <div class="detail-group mb-30">
                                <span class="muted-text-sm">Contact Number</span>
                                <p>{{ itemData.msisdn }}</p>
                            </div>

                            <div class="detail-group mb-30">
                                <span class="muted-text-sm">Country of Residence</span>
                                <p>{{ itemData.country }}</p>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="detail-group mb-30">
                                <span class="muted-text-sm">Gender</span>
                                <p>{{ itemData.gender }}</p>
                            </div>
                            <div class="detail-group mb-30">
                                <span class="muted-text-sm">Email Address</span>
                                <p>{{ itemData.email }}</p>
                            </div>
                            <div class="detail-group mb-30">
                                <span class="muted-text-sm">State of Residence</span>
                                <p>{{ itemData.state }}</p>
                            </div>
                        </div>

                        <div class="col-sm-12">
                            <div class="detail-group mb-30">
                                <span class="muted-text-sm">Residential Address</span>
                                <p>{{ itemData.residence }}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="ptb-30 plr-50" v-if="itemData.user_id !== currentUser.user_id">
                    <p class="dark-p mb-20">Turn on/off to activate or deactivate this account</p>
                    <Switch :switchText="switchTextLabel" :checked="checkedStatus" :toggleStatus="handleSwitchToggle"
                            :loading="loading"/>
                </div>

                <MessageSliderCopy :isVisible="displayMessage" :messageText="messageText" :messageType="messageType"/>

                <FormModal :isFormModalOpen="isEditFormModalOpen" @closeFormModal="closeEditFormModal"
                           modalHeader="Edit Admin">
                    <EditUser @closeFormModal="closeEditFormModal" @message="showFlashMessage"
                              :userData="itemDataForEditing" @refreshPage="fetchItemData"/>
                </FormModal>

                <FormModal :isFormModalOpen="isPasswordFormModalOpen" @closeFormModal="closePasswordFormModal"
                           modalHeader="Update Admin Password">
                    <ResetUserPassword @closeFormModal="closePasswordFormModal" @message="showFlashMessage"
                              :userData="adminEmailAddress"/>
                </FormModal>

            </div>
        </div>
    </div>
</template>

<script>
import Switch from "@/components/common/Switch.vue";
import RegularButtonBlock from "@/components/common/RegularButtonBlock.vue";
import BreadCrumbs from "@/components/common/BreadCrumbs.vue";
import {onMounted, ref, watch} from "vue";
import {capitalizeFirstLetter, formatDateTime, getCurrentUser, getValidToken} from '@/utils/helpers'
import {checkAndRefreshToken} from '@/utils/auth';
import {API_BASE_URL} from "@/config.js";
import {flashMessage} from "@/utils/flashMessage.js";
import MessageSliderCopy from "@/components/common/MessageSliderCopy.vue";
import {useRoute} from "vue-router";
import FormModal from "@/components/common/FormModal.vue";
import EditUser from "@/components/layouts/GodAdmin/Users/EditUser.vue";
import ResetUserPassword from "@/views/God_Admin/Users/ResetUserPassword.vue";


export default {
    components: {
        ResetUserPassword,
        EditUser, FormModal,
        Switch,
        RegularButtonBlock,
        BreadCrumbs,
        MessageSliderCopy
    },
    emits: ['showLoader', 'show-loader', 'flashMessage', 'data-loaded'],
    setup(props, {emit}) {
        const isEditFormModalOpen = ref(false);
        const isPasswordFormModalOpen = ref(false);
        const itemData = ref(null)
        const userName = ref('')
        const userRole = ref('')
        const userCreatedAt = ref('')
        const loading = ref(null)
        const checkedStatus = ref(false)
        const switchTextLabel = ref('')
        const messageText = ref("");
        const messageType = ref("");
        const displayMessage = ref(false);
        const currentUser = getCurrentUser()
        const router = useRoute()
        const itemId = router.params.id
        const accessToken = getValidToken();

        const fetchItemData = async () => {
            const apiUrl = `${API_BASE_URL}/admin/get-admin/${itemId}`;

            try {
                emit('show-loader')
                const response = await fetch(apiUrl, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    }
                })

                if (response.ok) {
                    const result = await response.json()
                    itemData.value = result.data

                    // console.log('result', result.data)
                } else {
                    console.log("response", response);
                    console.error("Error fetching data from the API");
                }
            } catch (error) {
                console.error('An error occured: ', error)
            }
        }


        onMounted(() => {
            fetchItemData()
        })

        watch(itemData, (item) => {
            userName.value = capitalizeFirstLetter(item.first_name, item.last_name)
            userRole.value = capitalizeFirstLetter(item.role_name)
            const {date, time} = formatDateTime(item.created_at)
            userCreatedAt.value = `${date}, ${time}`
            checkedStatus.value = !item.isDeactivated
            switchTextLabel.value = item.isDeactivated ? 'Account Deactivated' : 'Account Active'

            emit('data-loaded')
        })

        const openEditFormModal = () => {
            isEditFormModalOpen.value = true;
        };

        const closeEditFormModal = async (message) => {
            isEditFormModalOpen.value = false;
        };

        const openPasswordFormModal = () => {
            isPasswordFormModalOpen.value = true;
        };

        const closePasswordFormModal = async (message) => {
            isPasswordFormModalOpen.value = false;
        };

        const itemDataForEditing = ref(null)
        const editAction = () => {
            itemDataForEditing.value = {
                firstName: itemData.value.first_name,
                lastName: itemData.value.last_name,
                gender: itemData.value.gender,
                country: itemData.value.country,
                state: itemData.value.state,
                address: itemData.value.residence,
                roleId: itemData.value.role_id,
                id: props.id
            }

            openEditFormModal()
        }

        const adminEmailAddress = ref(null)
        const passwordUpdateAction = () => {
            adminEmailAddress.value = {
                email: itemData.value.email
            }

            openPasswordFormModal()
        }


        const handleSwitchToggle = () => {
            const userId = props.id
            toggleUserStatus(userId)
        }

        const toggleUserStatus = async (id) => {
            const apiUrl = `${API_BASE_URL}/admin/delete-admin`
            const accessToken = getValidToken()

            try {
                await checkAndRefreshToken()

                loading.value = true
                const response = await fetch(apiUrl, {
                    method: 'DELETE',
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`
                    },
                    body: JSON.stringify({
                        admin_id: id,
                        reason: ''
                    })
                })

                if (response.ok) {
                    const result = await response.json()

                    if (result.code === 200) {
                        const msg = {
                            apiResponse: "User status updated",
                            type: "success"
                        }

                        await fetchItemData()
                        showFlashMessage(msg)
                    } else {
                        const msg = {
                            apiResponse: result.message,
                            type: "success"
                        }
                        showFlashMessage(msg)
                        console.log('error', result.message)
                    }
                } else {
                    const result = await response.json();
                    console.log("error", result.message);
                }
            } catch (error) {
                console.error("An error occured:", error);
            } finally {
                loading.value = false
            }
        }

        const showFlashMessage = (msg) => {
            flashMessage(
                msg.apiResponse,
                msg.type,
                messageText,
                messageType,
                displayMessage
            );
        };

        return {
            loading,
            itemData,
            userName,
            userRole,
            userCreatedAt,
            checkedStatus,
            switchTextLabel,
            handleSwitchToggle,
            messageText,
            messageType,
            displayMessage,
            currentUser,
            fetchItemData,
            editAction,
            closeEditFormModal,
            isEditFormModalOpen,
            itemDataForEditing,
            showFlashMessage,
            isPasswordFormModalOpen,
            openPasswordFormModal,
            passwordUpdateAction,
            adminEmailAddress,
            closePasswordFormModal
        }
    }
};
</script>

<style scoped>
.border-bottom {
    border-bottom: 2px solid #F5F6FA;
}

.mb-80 {
    margin-bottom: 80px;
}

.header-content {
    padding: 30px 0 20px 0;
    position: relative;
    border-bottom: 1px solid #e9eaf3;
}

p {
    color: #9a9a9a;
    font-size: 16px;
}

.details-content {
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding: 15px 30px 0 30px;
}

.details-content:last-child {
    border-bottom: 1px solid #e9eaf3;
    padding-bottom: 20px;
}

.details-content img {
    vertical-align: top;
    margin-right: 15px;
    margin-top: 5px;
}

.details-content .details > .subHeader {
    font-weight: 500;
    color: #333333;
}

.details-content .details > p {
    margin-bottom: 0;
}

.logo-text {
    display: flex;
    align-content: center;
    align-items: flex-start;
}

.logo-text > img {
    margin-right: 15px;
    margin-top: 2px;
}

.logo-text > p {
    color: #333333;
}

.bottom-content {
    padding: 20px 30px 0 30px;
    margin-bottom: 50px;
}

.detail-group > p,
.result-text {
    color: #333;
}

.item {
    display: flex;
    justify-content: space-between;
}

.item > .count {
    color: #2f5bcf;
}

.buttons-content {
    padding: 0 15px 40px 15px;
}

.card-box {
    padding: 0 15px 0 15px
}
</style>