import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import {createPinia} from "pinia";
import SweetAlertPlugin from "@/plugins/sweetalert";

const app = createApp(App);

const pinia = createPinia()
app.use(pinia)
app.use(SweetAlertPlugin)

app.use(router).mount('#app');
