<template>
    <div class="form-content">
        <form @submit.prevent="updateitem">
            <div class="row">
                <div class="col-12">
                    <TextInputFieldVue :config="{
                class: 'form-control-gray mb-30',
                placeholder: 'Enter name of learning group',
                inputFieldType: 'text',
            }" labelText="Learning Group Name" errorType="nameError" v-model="name" />
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <TextAreaInputField :config="{
                        class: 'form-control-gray mb-100',
                        placeholder: 'Enter description here',
                        inputFieldType: 'description',
                    }" labelText="Description" errorType="descriptionError" v-model="description" />
                </div>
            </div>

            <RoundedButton buttonText="Update Group" class="custom-button" buttonType="submit" :loading="loading" />
        </form>
    </div>
</template>

<script>
import TextInputFieldVue from "@/components/common/TextInputField.vue";
import TextAreaInputField from "@/components/common/TextAreaInputField.vue";
import RoundedButton from "@/components/common/RoundedButton.vue";
import {ref} from "vue";
import {API_BASE_URL} from "@/config.js";
import {getSchoolId, getValidToken} from '@/utils/helpers'
import {checkAndRefreshToken} from '@/utils/auth';

export default {
    props: {
        itemData: {
            type: Object,
            required: true
        }
    },
    components: {
        TextInputFieldVue,
        TextAreaInputField,
        RoundedButton,
    },
    setup(props, { emit }) {
        const loading = ref(false);
        const loadingSpinner = ref(false);
        const name = ref(props.itemData.name);
        const description = ref(props.itemData.description);
        const accessToken = getValidToken()

        const showLoaders = () => {
            loading.value = true;
            loadingSpinner.value = true;
        };

        const hideLoaders = () => {
            loading.value = false;
            loadingSpinner.value = false;
        };


        const schoolId = getSchoolId()
        const apiUrl = `${API_BASE_URL}/school/${schoolId}/learning-groups/${props.itemData.id}`
        const updateitem = async () => {
            try {
                showLoaders()
                await checkAndRefreshToken()

                const response = await fetch(apiUrl, {
                    method: "PATCH",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    },
                    body: JSON.stringify({
                        name: name.value,
                        description: description.value,
                    }),
                })


                if (response.ok) {
                    const result = await response.json();

                    if (result.code === 200) {
                        const msg = {
                            apiResponse: "Group updated successfully",
                            type: "success"
                        }

                        emit("closeFormModal", msg);
                    } else {
                        console.log("error", result.message);
                    }
                } else {
                    const result = await response.json();
                    console.log("error", result.message);
                }

            } catch (error) {
                console.error("An error occured:", error)
            } finally {
                hideLoaders();
            }
        }

        return { loading, loadingSpinner, name, description, updateitem }
    }
}
</script>
<style scoped>
.textarea {
    margin-bottom: 100px;
}
</style>