<template>
    <div class="custom-control custom-checkbox">
        <input type="checkbox" class="custom-control-input" id="customCheck1" />
        <label class="custom-control-label" for="customCheck1">
            {{ checkboxText }}
        </label>
    </div>
</template>

<script>
export default {
    props: {
        checkboxText: {
            type: String,
            default: "CheckBoxText",
        },
    },
    setup() {},
};
</script>

<style>
</style>