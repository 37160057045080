<template>
    <div class="row p-20">
        <div class="col-lg-7 col-sm-12 mx-auto">
            <div class="text-center">
                <img src="../../assets/key-logo.png" alt="logo" width="200" class="mt-20"/>
                <h2 class="text-center bold mt-20 mb-50">Sign up as a colearner</h2>
            </div>

            <div class="row">
                <div class="col-lg-6 col-sm-12 mb-30">
                    <TextInputField :config="{
                    class: 'form-control-gray',
                    placeholder: 'Enter First Name',
                    inputFieldType: 'text'
                }" labelText="First Name" isRequired v-model="firstName"
                                    :errorMessage="firstNameError"/>
                </div>

                <div class="col-lg-6 col-sm-12 mb-30">
                    <TextInputField :config="{
                    class: 'form-control-gray',
                    placeholder: 'Enter Last Name',
                    inputFieldType: 'text'
                }" labelText="Last Name" isRequired v-model="lastName" :errorMessage="lastNameError"/>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-6 col-sm-12 mb-30">
                    <SingleSelectField labelText="Gender" :options="genderSelectOptions" v-model="selectedGender"
                                       isRequired :errorMessage="genderError"
                                       selectId="genderOptions" :selectedItem="selectedGender" :loading="genderLoader"/>
                </div>

                <div class="col-lg-6 col-sm-12 mb-30">
                    <TextInputField :config="{
                    class: 'form-control-gray',
                    placeholder: 'Enter Contact Number',
                    inputFieldType: 'tel'
                }" labelText="Contact Number" isRequired v-model="msisdn" :errorMessage="msisdnError"/>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-6 col-sm-12 mb-30">
                    <TextInputField :config="{
                        class: 'form-control-gray',
                        placeholder: 'Enter Email',
                        inputFieldType: 'email'
                    }" labelText="Email" isRequired v-model="email" :errorMessage="emailError"/>
                </div>

                <div class="col-lg-6 col-sm-12 mb-30">
                    <SingleSelectField
                        labelText="Bachelor's degree in an education related field?"
                        :options="bachelorDegreeOptions"
                        v-model="selectedBachelorDegreeOption"
                        selectId="bachelorDegreeOptions"
                        :selectedItem="selectedBachelorDegreeOption"/>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-6 mb-30">
                    <SingleSelectField labelText="Country of Residence" :options="countrySelectOptions"
                                       v-model="selectedCountry"
                                       :loading="countryDropdownLoader" selectId="countryOptions"
                                       isRequired
                                       :selectedItem="selectedCountry" @change="handleCountrySelection"/>
                </div>

                <div class="col-lg-6 mb-30">
                    <SingleSelectField labelText="State of Residence" :options="stateSelectOptions"
                                       v-model="selectedState"
                                       :loading="statesDropdownLoader" isRequired
                                       selectId="stateOptions" :selectedItem="selectedState"/>
                </div>
            </div>

            <div class="row mb-30">
                <div class="col-12">
                    <TextAreaInputField :config="{
                        class: 'form-control-gray',
                        placeholder: 'Enter Address',
                        inputFieldType: 'address'
                    }" labelText="Residential Address" v-model="address"/>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-6 col-sm-12 mb-30">
                    <TextInputField :config="{
                        class: 'form-control-gray',
                        placeholder: '',
                        inputFieldType: 'years_of_experience',
                    }" labelText="How many years experience do you have teaching early years / preschool aged children?"
                                    v-model="years_of_experience"/>
                </div>

                <div class="col-lg-6 col-sm-12 mb-30">
                    <SingleSelectField
                        labelText="Are you familiar with Early Years Education / EYFS / Pre-school education?"
                        :options="earlyYearsEducationOptions" v-model="selectedEarlyYearEducation"
                        isRequired :errorMessage="earlyYearError"
                        selectId="earlyYearsEducationOptions" :selectedItem="selectedEarlyYearEducation"/>

                </div>
            </div>

            <div class="row">
                <div class="col-lg-6 col-sm-12 mb-30">
                    <TextInputField :config="{
                        class: 'form-control-gray',
                        placeholder: 'Enter LinkedIn Link',
                        inputFieldType: 'text',
                    }" labelText="LinkedIn URL" v-model="linkedin"/>
                </div>

                <div class="col-lg-6 col-sm-12 mb-30">
                    <SingleSelectField
                        labelText="How did you hear about the KEY app?"
                        isRequired :errorMessage="hearAboutUsError"
                        :options="heardAboutUsOptions" v-model="selectedHeardAboutUsEducation"
                        selectId="heardAboutUsOptions" :selectedItem="selectedHeardAboutUsEducation"/>

                </div>
            </div>

            <div class="row mb-50">
                <div class="col-12">
                    <TextAreaInputField :config="{
                        class: 'form-control-gray',
                        placeholder: 'Enter purpose',
                        inputFieldType: 'text',
                    }" labelText="What is your purpose / objective for using the KEY app?" isRequired
                                        :errorMessage="purposeError" v-model="purpose_using_the_app"/>
                </div>
            </div>


            <RoundedButton buttonText="Submit" class="custom-button mb-100"
                           @click.prevent="submit" :loading="buttonLoading"/>

        </div>
    </div>
</template>

<script>
import TextInputField from "@/components/common/TextInputField.vue";
import SingleSelectField from "@/components/common/SingleSelectField.vue";
import TextAreaInputField from "@/components/common/TextAreaInputField.vue";
import RoundedButton from "@/components/common/RoundedButton.vue";
import {onMounted, ref, watch} from "vue";
import getCountries from "@/composables/getCountries";
import getStates from "@/composables/getStates";
import getGender from "@/composables/getGender";
import {capitalizeFirstLetter} from "@/utils/helpers";
import {useRoute} from "vue-router";
import {API_BASE_URL} from "@/config";
import Swal from 'sweetalert2';


export default {
    props: {},
    components: {RoundedButton, TextAreaInputField, SingleSelectField, TextInputField},
    setup(props, {emit}) {
        const buttonLoading = ref(false)
        const {fetchCountriesData, countriesList, loading: countryDropdownLoader} = getCountries()
        const {fetchStatesData, statesList, loading: statesDropdownLoader} = getStates()
        const {fetchGender, genderList, loading: genderLoader} = getGender()
        const schoolSelectOptions = ref([])
        const genderSelectOptions = ref([])
        const countrySelectOptions = ref([])
        const stateSelectOptions = ref([])
        const selectedSchool = ref('')
        const selectedGender = ref('')
        const selectedCountry = ref('')
        const selectedState = ref('')
        const firstName = ref('')
        const firstNameError = ref('')
        const lastNameError = ref('')
        const genderError = ref('')
        const msisdnError = ref('')
        const emailError = ref('')
        const bachelorFieldError = ref('')
        const countryError = ref('')
        const statesError = ref('')
        const lastName = ref('')
        const msisdn = ref('')
        const email = ref('')
        const address = ref('')
        const purpose_using_the_app = ref('')
        const countryIdMap = ref({});
        const selectedEarlyYearEducation = ref('')
        const selectedHeardAboutUsEducation = ref('')
        const earlyYearsEducationOptions = ref([])
        const heardAboutUsOptions = ref([])
        const linkedin = ref('')
        const bachelorDegreeOptions = ref([])
        const years_of_experience = ref('')
        const selectedBachelorDegreeOption = ref('')
        const earlyYearError = ref('')
        const hearAboutUsError = ref('')
        const purposeError = ref('')
        const router = useRoute()
        const schoolId = router.params.id

        // Flag to prevent recursive updates
        const earlyYearsEducationUpdated = ref(false);
        const heardAboutUsUpdated = ref(false);

        onMounted(() => {
            fetchEarlyYearsEducationOptions()
            fetchBachelorDegreeOptions()
            fetchHeardAboutUsOptions()
            fetchGender();
            fetchCountriesData();

        })

        // Early Years Education
        const fetchEarlyYearsEducationOptions = () => {
            setTimeout(() => {
                earlyYearsEducationOptions.value = [
                    {label: "Yes", value: 'Yes'},
                    {label: "No", value: 'No'},
                ]
            }, 200)
        }

        watch(earlyYearsEducationOptions, (newData) => {
            if (!earlyYearsEducationUpdated.value) {
                selectedEarlyYearEducation.value = newData.length > 0 ? newData[0].value : '';
            }
            earlyYearsEducationUpdated.value = false; // Reset flag after watch
        });


        // Bachelors Degree
        const fetchBachelorDegreeOptions = () => {
            setTimeout(() => {
                bachelorDegreeOptions.value = [
                    {label: "Yes", value: 'Yes'},
                    {label: "No", value: 'No'},
                ]
            }, 200)
        }


        // Heard about us
        const fetchHeardAboutUsOptions = () => {
            setTimeout(() => {
                heardAboutUsOptions.value = [
                    {label: "Google Search", value: 'Google Search'},
                    {label: "Website/Blog", value: 'Website/Blog'},
                    {label: "Email Newsletter", value: 'Email Newsletter'},
                    {label: "Advertisement", value: 'Advertisement'},
                    {label: "Instagram", value: 'Instagram'},
                    {label: "X", value: 'X'},
                    {label: "Tiktok", value: 'Tiktok'},
                    {label: "Referral", value: 'Referral'},
                    {label: "Facebook", value: 'Facebook'},
                    {label: "Event", value: 'Event'},
                ]
            })
        }

        watch(heardAboutUsOptions, (newData) => {
            if (!heardAboutUsUpdated.value) {
                selectedHeardAboutUsEducation.value = newData.length > 0 ? newData[0].value : '';
            }
            heardAboutUsUpdated.value = false; // Reset flag after watch
        });


        // Gender
        const updateGenderOptions = (newData) => {
            genderSelectOptions.value = newData.map(item => ({
                label: capitalizeFirstLetter(item.label), value: item.value
            }))

            if (genderSelectOptions.value.length > 0) {
                selectedGender.value = genderSelectOptions.value[0].value;
            }
        }

        watch(genderList, (newData) => {
            updateGenderOptions(newData)
        })

        // COUNTRIES
        const handleCountrySelection = () => {
            const selectedCountryId = countryIdMap.value[selectedCountry.value]
            fetchStatesData(selectedCountryId)
        }

        // Update the country dropdown when the modal is opened
        const updateCountryOptions = (newData) => {
            countrySelectOptions.value = newData.map(country => ({
                label: country.country_name, value: country.country_name
            }))

            countryIdMap.value = newData.reduce((map, country) => {
                map[country.country_name] = country.id;
                return map;
            }, {});

            if (countrySelectOptions.value.length > 0) {
                selectedCountry.value = 'Nigeria'
                handleCountrySelection()
            }
        }

        watch(countriesList, (newData) => {
            updateCountryOptions(newData)
        })

        // STATES
        // Update the states options
        const updateStatesOptions = (newData) => {
            stateSelectOptions.value = newData.map(state => ({
                label: state.state_name, value: state.state_name
            }))

            if (stateSelectOptions.value.length > 0) {
                selectedState.value = stateSelectOptions.value[0].value;
            }
        }

        watch(statesList, (newData) => {
            updateStatesOptions(newData)
        })

        const validateForm = () => {
            let isValid = true

            firstNameError.value = ''
            lastNameError.value = ''
            emailError.value = ''
            genderError.value = ''
            msisdnError.value = ''
            bachelorFieldError.value = ''
            countryError.value = ''
            statesError.value = ''
            earlyYearError.value = ''
            hearAboutUsError.value = ''
            purposeError.value = ''

            if (firstName.value.length === 0) {
                firstNameError.value = 'Enter a first name'
                isValid = false
            }

            if (lastName.value.length === 0) {
                lastNameError.value = 'Enter a last name'
                isValid = false
            }

            if (email.value.length === 0) {
                emailError.value = 'Enter an email address'
                isValid = false
            }

            if (msisdn.value.length === 0) {
                msisdnError.value = 'Enter a phone number'
                isValid = false
            }

            if (selectedGender.value === null) {
                genderError.value = 'Select at least one option'
                isValid = false
            }

            if (selectedBachelorDegreeOption.value === null) {
                console.log('got here')
                bachelorFieldError.value = 'Select at least one option'
                isValid = false
            }

            if (selectedCountry.value === null) {
                countryError.value = 'Select at least one option'
                isValid = false
            }

            if (selectedState.value === null) {
                statesError.value = 'Select at least one option'
                isValid = false
            }

            if (selectedEarlyYearEducation.value === null) {
                earlyYearError.value = 'Select at least one option'
                isValid = false
            }

            if (selectedHeardAboutUsEducation.value === null) {
                hearAboutUsError.value = 'Select at least one option'
                isValid = false
            }

            if (purpose_using_the_app.value.length === 0) {
                purposeError.value = 'Enter a purpose'
                isValid = false
            }

            return isValid
        }

        const flashAlertMessage = (alertType, alertMessage) => {
            emit('flash-message', {
                alertType: alertType,
                alertMessage: alertMessage
            })
        }

        const submit = async () => {
            buttonLoading.value = true

            if (validateForm()) {
                const newItem = {
                    school_id: schoolId,
                    first_name: firstName.value,
                    last_name: lastName.value,
                    gender: selectedGender.value,
                    email: email.value,
                    msisdn: msisdn.value,
                    country: selectedCountry.value,
                    state: selectedState.value,
                    address: address.value,
                    purpose_using_the_app: purpose_using_the_app.value,
                    linkedin: linkedin.value,
                    years_of_experience: years_of_experience.value,
                    early_years_education: selectedEarlyYearEducation.value,
                    how_you_heard_about_us: selectedHeardAboutUsEducation.value,
                    has_bachelors_degree: selectedBachelorDegreeOption.value === 'yes',
                    token: 'keyhub_invite'
                }

                try {
                    const apiUrl = `${API_BASE_URL}/teacher/add-teacher`

                    const response = await fetch(apiUrl, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(newItem)
                    })


                    if (response.ok) {
                        const result = await response.json();

                        if (result.code === 200) {
                            await Swal.fire({
                                title: 'Success',
                                text: "You have signed up on Key Academy, please check your mail to complete set up for your account",
                                icon: 'success',
                            }).then(() => {
                                window.location.reload();
                            });
                        } else {
                            console.log("code error", result.message);
                            await Swal.fire({
                                text: 'An error occurred',
                                icon: 'error',
                            })
                        }
                    } else {
                        const result = await response.json();
                        console.log("ok error", result.message);
                        await Swal.fire({
                            text: 'An error occurred',
                            icon: 'error',
                        })
                    }
                } catch (error) {
                    console.error("An error occured:", error);
                    await Swal.fire({
                        text: 'An error occurred',
                        icon: 'error',
                    })
                } finally {
                    buttonLoading.value = false
                }
            }
        }

        return {
            buttonLoading,
            schoolSelectOptions,
            selectedSchool,
            firstName,
            lastName,
            genderSelectOptions,
            selectedGender,
            msisdn,
            email,
            countrySelectOptions,
            selectedCountry,
            countryDropdownLoader,
            statesDropdownLoader,
            handleCountrySelection,
            selectedState,
            stateSelectOptions,
            address,
            genderLoader,
            selectedEarlyYearEducation,
            selectedHeardAboutUsEducation,
            earlyYearsEducationOptions,
            heardAboutUsOptions,
            purpose_using_the_app,
            linkedin,
            bachelorDegreeOptions,
            selectedBachelorDegreeOption,
            years_of_experience,
            firstNameError,
            lastNameError,
            genderError,
            msisdnError,
            emailError,
            bachelorFieldError,
            countryError,
            statesError,
            purposeError,
            hearAboutUsError,
            earlyYearError,
            capitalizeFirstLetter,
            submit
        }
    }
}
</script>
