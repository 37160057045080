<template>

    <div class="row">
        <div class="col-12">
            <div class="card-box">
                <div class="row">
                    <div class="col-12">
                        <div class="page-header mb-30">
                            <h4 class="bold">Manage Academic Years</h4>
                            <RoundedButton buttonText="Add Academic Year" class="custom-button" v-if="hasPermission('add_sme')"
                                           iconText="mdi-plus" @clicked="openFormModal"/>
                        </div>

                        <TableView :data="formattedAcademicYearData" :headers="tableHeaders"
                                   :id="getIdForActions"
                                   :detailsData="getDataForDetailsView" :loading="loading"
                                   :searchStringFromParent.sync="searchStringFromChild"
                                   :editAction="editAction" :showActionsColumn="true"
                                   :deleteAction="openConfirmationDialog"
                                   editPermission="update_sme" deletePermission="delete_sme"
                                   :searchFields="searchFieldsData"/>
                    </div>
                </div>
            </div>
        </div>


    </div>


    <FormModal :isFormModalOpen="isFormModalOpen" @closeFormModal="closeFormModal1" modalHeader="Add Academic Year">
        <CreateAcademicYear @closeFormModal="closeFormModal"/>
    </FormModal>

    <FormModal :isFormModalOpen="isEditFormModalOpen" @closeFormModal="closeEditFormModal"
               modalHeader="Edit Academic Year">
        <EditAcademicYear @closeFormModal="closeEditFormModal"
                 :itemData="itemDataForEditing"/>
    </FormModal>

    <InfoModal :isModalOpen="isConfirmationDialogOpen" @closeModal="closeConfirmationDialog"
               :successImageURL="require('../../../assets/images/icons/success_icon.svg')"
               :errorImageURL="require('../../../assets/images/icons/error_icon.svg')" header="Hold on!"
               :paragraph="actionConfirmationParagraph"
               :buttonTxt="actionButtonText" :buttonAction="performAction" buttonColor="error"
               routerLinkText="No, Cancel Action" buttonLink="/"
               :linkAction="cancelAction" :loading="buttonLoader">
    </InfoModal>

    <InfoModal :isModalOpen="isFlashModalOpen" @closeModal="closeFlashModal" :isSuccess="true" header="Academic Year Added"
               :paragraph="`You have successfully added '${fullName}'. You can view and manage this profile under 'Academic Years'.`"
               buttonTxt="Add Another Academic Year" routerLinkText="Manage Academic Years"
               :linkAction="createItemFlashLinkAction"
               :buttonAction="buttonAction">
    </InfoModal>

</template>

<script>
import RegularButtonBlock from "@/components/common/RegularButtonBlock.vue";
import {computed, onActivated, onMounted, ref} from "vue";
import {getSchoolId, getUserInitialsForName, getValidToken, hasPermission} from '@/utils/helpers'
import {checkAndRefreshToken} from '@/utils/auth';
import {API_BASE_URL} from "@/config.js";
import FormModal from "@/components/common/FormModal.vue";
import TableView from "@/components/common/TableView.vue";
import RoundedButton from "@/components/common/RoundedButton.vue";
import InfoModal from "@/components/common/InfoModals.vue";
import AvatarForInitials from "@/components/common/AvatarForInitials.vue";
import getAcademicYear from "@/composables/Schools/getAcademicYear";
import RegularButton from "@/components/common/RegularButton.vue";
import CreateAcademicYear from "@/views/School_Admin/AcademicYears/CreateAcademicYear.vue";
import EditAcademicYear from "@/views/School_Admin/AcademicYears/EditAcademicYear.vue";
import {useUserStore} from "@/stores/user";


export default {
    methods: {getUserInitialsForName},
    components: {
        EditAcademicYear,
        CreateAcademicYear,
        RegularButton,
        AvatarForInitials,
        RoundedButton,
        TableView,
        FormModal,
        InfoModal,
        RegularButtonBlock
    },
    props: {
        id: String
    },
    emits: ['showLoader', 'show-loader', 'flashMessage', 'data-loaded', 'flash-message'],
    setup(props, {emit}) {
        document.title = "Manage Academic Years"
        const {academicYearData, loading, fetchAcademicYearData, formattedAcademicYearData} = getAcademicYear()
        const isFormModalOpen = ref(false);
        const isEditFormModalOpen = ref(false);
        const displayMessage = ref(false);
        const buttonLoader = ref(false);
        const messageText = ref("");
        const messageType = ref("");
        const newMessage = ref("");
        const showDetailsOrIcons = ref(false)
        const accessToken = getValidToken();
        const isConfirmationDialogOpen = ref(false);
        const isFlashModalOpen = ref(false);
        const fullName = ref('')
        const itemData = ref(null)
        const schoolName = ref('')
        const schoolEmail = ref('')


        const buttonAction = () => {
            isFlashModalOpen.value = false;
            openFormModal()
        }

        const openFlashModal = () => {
            isFlashModalOpen.value = true;
        };

        const closeFlashModal = async () => {
            isFlashModalOpen.value = false;
            await fetchAcademicYearData()
        };

        const createItemFlashLinkAction = () => {
            closeFlashModal()
        }

        const openFormModal = () => {
            isFormModalOpen.value = true;
        };

        const closeFormModal = (name) => {
            isFormModalOpen.value = false;

            fullName.value = name
            isFlashModalOpen.value = true
        };

        const closeFormModal1 = () => {
            isFormModalOpen.value = false;

            fetchAcademicYearData()
        }

        const openEditFormModal = () => {
            isEditFormModalOpen.value = true;
        };

        const closeEditFormModal = async (msg) => {
            isEditFormModalOpen.value = false;

            if (msg) {
                flashAlertMessage(msg.type, msg.apiResponse)
            }
            await fetchAcademicYearData()
        };

        const searchStringFromChild = ref("");
        const searchFieldsData = ['name', 'date']

        onMounted(() => {
            fetchAcademicYearData();
            emit('data-loaded')

            const schoolDetails = JSON.parse(localStorage.getItem('schoolDetails'))
            schoolName.value = schoolDetails.name
            schoolEmail.value = schoolDetails.email
        })

        onActivated(() => {
            fetchAcademicYearData();
        })

        const getIdForActions = computed(() => {
            return academicYearData.value.map((item) => item.id);
        });

        const getDataForDetailsView = computed(() => {
            return academicYearData.value
        })

        const itemDataForEditing = ref(null)
        const editAction = (item, item_id) => {
            itemData.value = item

            // Find the item to edit
            const itemForEditing = academicYearData.value.find((r) => r.id === item_id);
            itemDataForEditing.value = {
                name: itemForEditing.name,
                id: item_id
            }

            openEditFormModal()
        }

        const deleteAction = async (id) => {
            const schoolId = getSchoolId()
            const apiUrl = `${API_BASE_URL}/school/${schoolId}/academic/${id}`

            try {
                await checkAndRefreshToken()

                buttonLoader.value = true
                const response = await fetch(apiUrl, {
                    method: 'DELETE',
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${accessToken}`
                    }
                })

                if (response.ok) {
                    const result = await response.json()

                    if (result.code === 200) {
                        closeConfirmationDialog()

                        flashAlertMessage('success', "Success! You have deleted the academic year")
                    } else {
                        flashAlertMessage('error', result.message)
                        console.log('error', result.message)
                    }
                } else {
                    const result = await response.json();

                    flashAlertMessage('error', result.message)
                    console.log("error", result.message);
                }
            } catch (error) {
                const result = await response.json();

                flashAlertMessage('error', result.message)
                console.error("An error occured:", error);
            } finally {
                closeConfirmationDialog()
                buttonLoader.value = false
            }
        }

        const messageFromChild = ref("");
        const handleMessageFromChild = (message) => {
            messageFromChild.value = message
        };

        const tableHeaders = computed(() => {
            return [
                {label: "Name", key: "name"},
                {label: "Date Added", key: "date"},
            ];
        });

        const itemId = ref('null')
        const actionConfirmationParagraph = ref('')
        const actionButtonText = ref('')

        const openConfirmationDialog = (item, id) => {
            itemId.value = id

            actionConfirmationParagraph.value = `You are about to delete this Academic Year. Are you sure you want to continue this action?.`
            actionButtonText.value = `Yes, Delete Academic Year`
            isConfirmationDialogOpen.value = true;
        };

        const closeConfirmationDialog = () => {
            isConfirmationDialogOpen.value = false;
            fetchAcademicYearData()
        };

        const performAction = () => {
            deleteAction(itemId.value)
        }

        const cancelAction = () => {
            closeConfirmationDialog()
        }

        const flashAlertMessage = (alertType, alertMessage) => {
            emit('flash-message', {
                alertType: alertType,
                alertMessage: alertMessage
            })
        }

        const userStore = useUserStore();
        const user = ref(userStore.user)
        const userPermissions = ref(user.value.permissions)
        const checkPermission = (permission) => hasPermission(userPermissions, permission)


        return {
            tableHeaders,
            academicYearData,
            formattedAcademicYearData,
            getIdForActions,
            loading,
            searchStringFromChild,
            searchFieldsData,
            isFormModalOpen,
            openFormModal,
            closeFormModal,
            closeFormModal1,
            handleMessageFromChild,
            displayMessage,
            itemDataForEditing,
            messageText,
            messageType,
            newMessage,
            showDetailsOrIcons,
            editAction,
            deleteAction,
            InfoModal,
            isConfirmationDialogOpen,
            openConfirmationDialog,
            closeConfirmationDialog,
            performAction,
            cancelAction,
            buttonLoader,
            isEditFormModalOpen,
            openEditFormModal,
            closeEditFormModal,
            itemData,
            isFlashModalOpen,
            openFlashModal,
            closeFlashModal,
            buttonAction,
            fullName,
            createItemFlashLinkAction,
            actionConfirmationParagraph,
            actionButtonText,
            getDataForDetailsView,
            schoolName,
            schoolEmail,
            hasPermission: checkPermission
        };

    }

}
</script>

<style scoped>
.page-header {
    display: flex;
    align-content: center;
    justify-content: space-between;
}

.detail-content-group > p {
    margin: 0;
}

p {
    color: #9a9a9a;
    font-size: 16px;
}

.details-content > img {
    margin-right: 15px;
    margin-top: 5px;
}

.details-content .details > p {
    margin-bottom: 0;
}

.logo-text > img {
    margin-right: 15px;
    margin-top: 2px;
}

.logo-text > p {
    color: #333333;
}

.detail-group > p,
.data-title {
    color: #333;
}

</style>