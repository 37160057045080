<template>
    <form @submit.prevent="updateItem">
        <div class="row mb-30">
            <div class="col-12">
                <TextInputField :config="{
                    class: 'form-control-gray',
                    placeholder: 'Enter Plan Name',
                    inputFieldType: 'text',
                }" labelText="Plan Name" errorType="nameError" v-model="name" :isRequired="true"/>
            </div>
        </div>

        <div class="row mb-30">
            <div class="col-12">
                <TextAreaInputField :config="{
                    class: 'form-control-gray',
                    placeholder: 'Enter Description',
                    inputFieldType: 'text',
                }" labelText="Description" errorType="descriptionError" v-model="description" :isRequired="true"/>
            </div>
        </div>

        <div class="row mb-30">
            <div class="col-lg-6 cycle">
                <SimpleDropdown labelText="Billing Cycle (Interval)" :options="planCycleOptions"
                                v-model="selectedBillingCycle"
                                selectId="billingOptions"
                                :selectedItem="selectedBillingCycle" :isRequired="true"/>
            </div>

            <div class="col-lg-6">
                <TextInputField :config="{
                    class: 'form-control-gray',
                    placeholder: 'Enter Amount',
                    inputFieldType: 'text',
                }" labelText="Plan Amount" errorType="amountError" v-model="amount" :isRequired="true"/>
            </div>
        </div>

        <RoundedButton buttonText="Save Changes" class="custom-button" buttonType="submit" :loading="buttonLoading"/>

    </form>
</template>
<script>
import TextInputField from "@/components/common/TextInputField.vue";
import SimpleDropdown from "@/components/common/SimpleDropdown.vue";
import TextAreaInputField from "@/components/common/TextAreaInputField.vue";
import RoundedButton from "@/components/common/RoundedButton.vue";
import {onMounted, ref} from "vue";
import {getValidToken} from '@/utils/helpers'
import {checkAndRefreshToken} from "@/utils/auth";
import {API_BASE_URL} from "@/config";


export default {
    props: {
        itemData: {
            type: Object,
            required: true
        }
    },
    components: {
        TextInputField,
        SimpleDropdown,
        TextAreaInputField,
        RoundedButton,
    },
    setup(props, {emit}) {
        const buttonLoading = ref(false)
        const message = ref('')
        const name = ref(props.itemData.name)
        const amount = ref(props.itemData.amount)
        const description = ref(props.itemData.description)
        const selectedBillingCycle = ref(props.itemData.bill_cycle)
        const features = ref(props.itemData.features)

        const planCycleOptions = [
            {label: "Select Option", value: ''},
            {label: "1", value: '1'},
            {label: "2", value: '2'},
            {label: "3", value: '3'},
            {label: "4", value: '4'},
            {label: "5", value: '5'},
            {label: "6", value: '6'},
            {label: "7", value: '7'},
            {label: "8", value: '8'},
            {label: "9", value: '9'},
            {label: "10", value: '10'},
            {label: "11", value: '11'},
            {label: "12", value: '12'},
        ]

        onMounted(() => {
            setTimeout(() => {
                selectedBillingCycle.value = props.itemData.bill_cycle;
            }, 500);
        })

        // OTHERS
        const showLoaders = () => {
            buttonLoading.value = true;
        };

        const hideLoaders = () => {
            buttonLoading.value = false;
        };

        const apiUrl = `${API_BASE_URL}/subscription/update-plan/${props.itemData.id}`
        const updateItem = async () => {
            const item = {
                name: name.value,
                amount: amount.value,
                description: description.value,
                bill_cycle: selectedBillingCycle.value,
                features: features.value,
            }

            // console.log('item', item)

            try {
                showLoaders()
                await checkAndRefreshToken()
                const accessToken = getValidToken()

                const response = await fetch(apiUrl, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    },
                    body: JSON.stringify(item)
                })


                if (response.ok) {
                    const result = await response.json();

                    if (result.code === 200) {
                        message.value = result.message;

                        emit("closeFormModal");
                        emit("refreshPage");
                        emit("message", {
                            apiResponse: "Plan details updated successfully",
                            type: "success",
                        });
                    } else {
                        console.log("error", result.message);
                    }
                } else {
                    const result = await response.json();
                    console.log("error", result.message);
                }
            } catch (error) {
                console.error("An error occured:", error);
            } finally {
                hideLoaders();
            }
        }

        return {
            buttonLoading,
            planCycleOptions,
            selectedBillingCycle,
            name,
            description,
            amount,
            features,
            updateItem
        }
    }
}
</script>
<style scoped>
@media (max-width: 767px) {
    .cycle {
        margin-bottom: 30px;
    }
}

@media (max-width:992px) {
    .cycle {
        margin-bottom: 30px;
    }
}
</style>