import {API_BASE_URL} from "@/config.js";
import {capitalizeFirstLetter, formatDateTime, getSchoolId, getUserInitials, getValidToken} from "@/utils/helpers.js";
import {computed, ref} from "vue";
import {checkAndRefreshToken} from '@/utils/auth';

const loading = ref(false);

const parentsPayloadData = ref(null);
const parentsData = ref([])

const fetchParentsData = async (page, perPage) => {
    const schoolId = getSchoolId()
    const apiUrl = `${API_BASE_URL}/school/${schoolId}/parents`;

    try {
        await checkAndRefreshToken()

        const accessToken = getValidToken()
        if (!accessToken) {
            console.error("Access token not available.");
            return;
        }

        loading.value = true;
        const apiUrlWithParams = `${apiUrl}?page=${page}&per_page=${perPage}`

        const response = await fetch(apiUrlWithParams, {
            headers: {
                Authorization: `Bearer ${accessToken}`, // Include the access token in the headers
            },
        });

        if (response.ok) {
            const result = await response.json();

            if (result.code === 200) {
                parentsPayloadData.value = result.data
                parentsData.value = result.data.results.parents
            } else {
                console.log("error", result.message);
            }
        } else {
            console.log("response", response);
            console.error("Error fetching data from the API");
        }
    } catch (error) {
        console.error("An error occured:", error);
    } finally {
        loading.value = false;
    }
};


const formattedParentsData = computed(() => {
    // Map and format the data
    return parentsData.value.map((parent) => {

        // Format the created at
        const {date, time} = formatDateTime(parent.created_at);

        const formattedParent = {
            user: {
                name: capitalizeFirstLetter(`${parent.first_name} ${parent.last_name}`),
                email: parent.email,
                initials: getUserInitials(parent.first_name, parent.last_name),
                avatarColor: 'primary',
                profileImage: parent.profile_image
            },
            contact: parent.msisdn,
            gender: parent._gender,
            createdAt: date,
            status: parent.isDeactivated ? 'Deactivated' : 'Active',
        };

        return {
            ...formattedParent,
        };
    });
});

const getSchoolParents = () => {
    return {parentsPayloadData, fetchParentsData, loading, formattedParentsData, parentsData}
}

export default getSchoolParents