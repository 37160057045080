import {API_BASE_URL} from "@/config.js";
import {formatDateTime, capitalizeFirstLetter, getValidToken, getUserInitialsForName} from "@/utils/helpers.js";
import {ref, computed} from "vue";
import {checkAndRefreshToken} from '@/utils/auth';


const loading = ref(false);
const apiUrl = `${API_BASE_URL}/role-permission/roles`;
const rolesData = ref([]);

const fetchRolesData = async () => {
    try {
        await checkAndRefreshToken()

        const accessToken = getValidToken()
        if (!accessToken) {
            console.error("Access token not available.");
            return;
        }

        loading.value = true;
        const response = await fetch(apiUrl, {
            headers: {
                Authorization: `Bearer ${accessToken}`, // Include the access token in the headers
            },
        });

        if (response.ok) {
            const result = await response.json();

            if (result.code === 200) {
                rolesData.value = result.data.results

                // console.log("result", result.data);
            } else {
                console.log("error", result.message);
            }
        } else {
            console.log("response", response);
            console.error("Error fetching data from the API");
        }
    } catch (error) {
        console.error("An error occured:", error);
    } finally {
        loading.value = false;
    }
};

const formattedRolesData = computed(() => {
    // Map and format the data
    return rolesData.value.map((role) => {
        // Format the created at
        const {date, time} = formatDateTime(role.created_at);

        // Replace admin with null
        const createdBy = capitalizeFirstLetter(role.creator_name) || "-";

        const formattedRole = {
            itemName: {
                name: capitalizeFirstLetter(role.name),
                initials: getUserInitialsForName(role.name),
                avatarColor: 'secondary'
            },
            date,
            time,
            createdBy: createdBy,
        };

        return {
            ...formattedRole,
        };
    });
});


const getRoles = () => {
    return {fetchRolesData, rolesData, formattedRolesData, loading}
}

export default getRoles