<template>
    <div id="wrapper">
        <MessageSliderCopy :isVisible="displayMessage" :messageText="messageText" :messageType="messageType"/>

        <div class="row">
            <div class="col-lg-8 col-md-8 col-sm-12 mx-auto">

                <div class="login-container d-flex align-items-center justify-content-center">
                    <div class="row auth-card-box">
                        <InfiniteLinearLoader :loading="loading"/>

                        <div class="image col-lg-7 col-md-5 col-sm-12 p-0">
                            <img src="../../assets/images/login_logo_new.jpeg" alt="login"/>
                        </div>

                        <div class="form-content col-lg-5 col-md-7 col-sm-12">
                            <img src="../../assets/key-logo.png" alt="logo"/>
                            <p>Welcome back! Log in to your account.</p>

                            <form>
                                <div class="row mb-30">
                                    <div class="col-12">
                                        <TextInputFieldVue :config="{
                                            class: 'form-control-gray',
                                            placeholder: 'Enter your email address',
                                            inputFieldType: 'email'
                                        }" labelText="Email" :errorMessage="emailError" v-model="email"/>
                                    </div>
                                </div>

                                <div class="row mb-30">
                                    <div class="col-12">
                                        <TextInputFieldVue :config="{
                                            class: 'form-control-gray',
                                            placeholder: 'Enter your Password',
                                            inputFieldType: 'password'
                                        }" labelText="Password" :errorMessage="passwordError" v-model="password"/>
                                    </div>
                                </div>

                                <CheckBoxVue checkboxText="Remember me" class="checkboxVue" v-model="rememberMe"/>

                                <p class="error bold font-italic" v-if="errorMessage">
                                    {{ errorMessage }}
                                </p>
                                <RegularButtonVue buttonText="Login" class="custom-button mb-3"
                                                  @click="handleFormSubmit"
                                                  :loading="loading"/>
                            </form>

                            <p class="forgot-text">
                                Forgot Password? <span><a href="#" @click="navigateToPasswordEmailRequest">Click to reset your password</a></span>
                            </p>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>
</template>

<script>
import TextInputFieldVue from "@/components/common/TextInputField.vue";
import PasswordInputFieldVue from "@/components/common/PasswordInputField.vue";
import CheckBoxVue from "@/components/common/CheckBox.vue";
import RegularButtonVue from "@/components/common/RegularButton.vue";
import InfiniteLinearLoader from "@/components/common/InfiniteLinearLoader.vue";
import MessageSliderCopy from "@/components/common/MessageSliderCopy.vue";
import {ref, watch} from "vue";
import {useRouter} from "vue-router";
import {flashMessage} from "@/utils/flashMessage.js";
import {useUserStore} from "@/stores/user";
import {getValidRefreshToken, getValidToken} from "@/utils/helpers";
import {API_BASE_URL} from "@/config";

export default {
    components: {
        TextInputFieldVue,
        PasswordInputFieldVue,
        CheckBoxVue,
        RegularButtonVue,
        InfiniteLinearLoader,
        MessageSliderCopy,
    },
    setup() {
        const email = ref("");
        const password = ref("");
        const rememberMe = ref(false);

        const loading = ref(false);
        const loadingSpinner = ref(false);
        const router = useRouter();

        const displayMessage = ref(false);
        const messageText = ref("");
        const messageType = ref("");

        const accessToken = ref(getValidToken());
        const refreshToken = ref(getValidRefreshToken());
        const currentUserData = ref(null)

        const emailError = ref('')
        const passwordError = ref('')
        const errorMessage = ref('')

        const showMessage = (text, type) => {
            flashMessage(text, type, messageText, messageType, displayMessage);
        };

        const showLoaders = () => {
            loading.value = true;
            loadingSpinner.value = true;
        };

        const hideLoaders = () => {
            loading.value = false;
            loadingSpinner.value = false;
        };

        const validateForm = () => {
            let isValid = true

            emailError.value = ''
            passwordError.value = ''

            if (email.value.length === 0) {
                emailError.value = 'Enter an email'
                isValid = false
            }

            if (password.value.length === 0) {
                passwordError.value = 'Enter a password'
                isValid = false
            }

            return isValid
        }

        const handleFormSubmit = async () => {
            errorMessage.value = ''

            if (validateForm()) {
                try {
                    showLoaders();
                    const apiURL = `${API_BASE_URL}/auth/login`

                    const response = await fetch(apiURL, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            email: email.value,
                            password: password.value,
                        }),
                    });

                    if (response.ok) {
                        localStorage.clear()
                        const result = await response.json();

                        // Check the error code
                        if (result.code === 200) {
                            flashMessage(
                                "Login Successful!!!",
                                "success",
                                messageText,
                                messageType,
                                displayMessage
                            );

                            // Store the user data in cookies
                            document.cookie = `uid=${result.data.access_token}; path=/`
                            document.cookie = `refresh_token=${result.data.refresh_token}; path=/`

                            if (result.data.school_id) {
                                document.cookie = `schoolId=${result.data.school_id}; path=/`
                            }

                            // Set items in local storage and store
                            currentUserData.value = {
                                role_name: result.data.role_name,
                                user_id: result.data.user_id,
                                first_name: result.data.first_name,
                                last_name: result.data.last_name,
                                country: result.data.country,
                                state: result.data.state,
                                residence: result.data.residence,
                                gender: result.data.gender,
                                school_id: result.data.school_id,
                                name: result.data.name,
                                permissions: result.data.permissions,
                                userType: result.data.user_type
                            }

                            localStorage.setItem(
                                'current_user',
                                JSON.stringify(currentUserData.value)
                            )
                            const userStore = useUserStore()
                            userStore.setUserDetails(currentUserData.value)


                            accessToken.value = result.data.access_token;
                            refreshToken.value = result.data.refresh_token;

                            // Navigate to dashboard page for god admin or school admin
                            if (result.data.school_id) {
                                await router.push({name: "SchoolDashboard"})
                            } else {
                                await router.push({name: "Dashboard"});
                            }

                        } else {
                            const result = await response.json();
                            console.log('Code error message => ' . result.message)
                            errorMessage.value = 'An error occurred. Please try again'
                        }
                    } else {
                        const result = await response.json();
                        console.log('Ok error message => ' . result.message)
                        errorMessage.value = 'An error occurred. Please try again'
                    }
                } catch (e) {
                    console.error("Catch error occurred:", e);
                    errorMessage.value = 'An error occurred. Please try again'
                } finally {
                    hideLoaders();
                }
            }
        };

        // Watch the accessToken for changes and check for token expiry
        watch(accessToken, (newAccessToken) => {
            if (newAccessToken) {
                // Assuming your tokens are JWT tokens with an 'exp' claim
                const jwtToken = parseJwt(newAccessToken);
                const expirationTimestamp = jwtToken.exp * 1000; // Convert to milliseconds

                if (Date.now() >= expirationTimestamp) {
                    // Access token has expired, Navigate to dashboard page
                    router.push({name: "Dashboard"});
                }
            }
        });

        const parseJwt = (token) => {
            try {
                return JSON.parse(atob(token.split(".")[1]));
            } catch (e) {
                return {};
            }
        };

        const navigateToPasswordEmailRequest = () => {
            router.push({name: "PasswordRequest"})
        }

        return {
            navigateToPasswordEmailRequest,
            email,
            password,
            rememberMe,
            handleFormSubmit,
            loading,
            showMessage,
            displayMessage,
            messageText,
            messageType,
            emailError,
            passwordError,
            errorMessage
        };
    },
};
</script>

<style scoped>


@media (min-width: 1200px) {
    .auth-card-box {
        height: 550px;
        box-shadow: 0 0 15px 0 rgba(184, 188, 202, 0.5);
        position: relative;
    }

    .login-container {
        height: 100vh
    }
}

@media (min-width: 768px) and (max-width: 1199px) {
    .auth-card-box {
        height: 550px;
        box-shadow: 0 0 15px 0 rgba(184, 188, 202, 0.5);
        position: relative;
    }

    .login-container {
        height: 100vh
    }
}

.image {
    height: 100%;
}

.image > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.form-content {
    height: 100%;
    width: 60%;
    display: inline-block;
    position: inherit;
    top: 0;
    padding: 50px 45px;
}

.form-content > img {
    height: 50px;
    width: 80px;
    margin-bottom: 10px;
}

.form-content > p {
    margin-bottom: 40px;
}

.form-content > .forgot-text {
    font-size: 14px;
    color: #333;
}

.form-content > .forgot-text span, .error {
    color: #f75549;
}

.checkboxVue {
    margin-bottom: 20px;
}


</style>