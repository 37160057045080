<template>
    <form @submit.prevent="saveNewItem">
        <div>
            <div class="row mb-30">
                <div class="col-12">
                    <TextInputField :config="{
                    class: 'form-control-gray',
                    placeholder: 'Enter Name',
                    inputFieldType: 'text',
                }" labelText="Name" v-model="name" :isRequired="true"/>
                </div>
            </div>

            <RoundedButton buttonText="Save Changes" class="custom-button" buttonType="submit" :loading="buttonLoading"/>

        </div>

    </form>
</template>
<script>
import TextInputField from "@/components/common/TextInputField.vue";
import RoundedButton from "@/components/common/RoundedButton.vue";
import {ref} from "vue";
import {getSchoolId, getValidToken} from '@/utils/helpers'
import {API_BASE_URL} from "@/config";
import {checkAndRefreshToken} from "@/utils/auth";


export default {
    props: {
        itemData: {
            type: Object,
            required: true
        }
    },
    components: {
        TextInputField,
        RoundedButton,
    },
    setup(props, {emit}) {
        const buttonLoading = ref(false)
        const name = ref(props.itemData.name)

        // OTHERS
        const showLoaders = () => {
            buttonLoading.value = true;
        };

        const hideLoaders = () => {
            buttonLoading.value = false;
        };

        const schoolId = getSchoolId()
        const apiUrl = `${API_BASE_URL}/school/${schoolId}/term/${props.itemData.id}`

        const saveNewItem = async () => {
            const newItem = {
                name: name.value,
            }

            // console.log('newItem', newItem)

            try {
                showLoaders()
                await checkAndRefreshToken()
                const accessToken = getValidToken()

                const response = await fetch(apiUrl, {
                    method: "PUT" +
                        "",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    },
                    body: JSON.stringify(newItem)
                })


                if (response.ok) {
                    const result = await response.json();

                    if (result.code === 200) {
                        const msg = {
                            apiResponse: "Success! You have updated the details of the term",
                            type: "success"
                        }

                        emit("closeFormModal", msg);
                    } else {
                        console.log("error", result.message);
                    }
                } else {
                    const result = await response.json();
                    console.log("error", result.message);
                }
            } catch (error) {
                console.error("An error occured:", error);
            } finally {
                hideLoaders();
            }
        }

        return {
            buttonLoading,
            saveNewItem,
            name
        }
    }
}
</script>
<style scoped>

</style>