<template>
    <form>
        <div v-if="currentPage === 1">

            <div class="row mb-30">
                <div class="col-lg-6 col-sm-12">
                    <TextInputField :config="{
                    class: 'form-control-gray',
                    placeholder: 'Enter First Name',
                    inputFieldType: 'text',
                }" labelText="First Name" :errorMessage="firstNameError" v-model="firstName" :isRequired="true"/>
                </div>

                <div class="col-lg-6 col-sm-12">
                    <TextInputField :config="{
                    class: 'form-control-gray',
                    placeholder: 'Enter Last Name',
                    inputFieldType: 'text',
                }" labelText="Last Name" :errorMessage="lastNameError" v-model="lastName" :isRequired="true"/>
                </div>
            </div>

            <div class="row mb-30">
                <div class="col-lg-6 col-sm-12">
                    <SimpleDropdown labelText="Gender" :options="genderSelectOptions" v-model="selectedGender"
                                    selectId="genderOptions" :selectedItem="selectedGender" :loading="genderLoader"
                                    :isRequired="true"/>
                </div>
                <div class="col-lg-6 col-sm-12">
                    <TextInputField :config="{
                    class: 'form-control-gray',
                    placeholder: 'Enter Contact Number',
                    inputFieldType: 'tel',
                }" labelText="Contact Number" v-model="msisdn" :isRequired="true" :errorMessage="msisdnError"/>
                </div>
            </div>

            <div class="row mb-30">
                <div class="col-lg-6 col-sm-12">
                    <TextInputField :config="{
                        class: 'form-control-gray',
                        placeholder: 'Enter Email Address',
                        inputFieldType: 'email'}"
                                    labelText="Contact Email Address" v-model="email" :isRequired="true"
                                    :errorMessage="emailError"/>

                </div>

                <div class="col-lg-6 col-sm-12">
                    <TextInputField :config="{
                        class: 'form-control-gray',
                        placeholder: 'Enter Age',
                        inputFieldType: 'number'}"
                                    labelText="Age" v-model="age" :isRequired="true"
                                    :errorMessage="ageError"/>

                </div>
            </div>

            <div class="row mb-30">
                <div class="col-6">
                    <SimpleDropdown labelText="Country of Residence" :options="countrySelectOptions"
                                    v-model="selectedCountry"
                                    :loading="countryDropdownLoader" selectId="countryOptions" :isRequired="true"
                                    :selectedItem="selectedCountry" @change="handleCountrySelection"/>
                </div>

                <div class="col-6">
                    <SimpleDropdown labelText="State of Residence" :options="stateSelectOptions" v-model="selectedState"
                                    :loading="statesDropdownLoader" :isRequired="true"
                                    selectId="stateOptions" :selectedItem="selectedState"/>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <TextAreaInputField :config="{
                    class: 'form-control-gray mb-30',
                    placeholder: 'Enter Address',
                    inputFieldType: 'address',}" labelText="Residential Address" errorType="addressError"
                                        v-model="address"/>
                </div>
            </div>

        </div>

        <div v-if="currentPage === 2">
            <div class="row mb-30">
                <div class="col-lg-12 col-sm-12">
                    <TextInputField :config="{
                        class: 'form-control-gray',
                        placeholder: 'Enter Email Address',
                        inputFieldType: 'email'}"
                                    labelText="Work Email" v-model="workEmail" :isRequired="true"
                                    :errorMessage="workEmailError"/>
                </div>
            </div>

            <div class="row mb-30">
                <div class="col-lg-12 col-sm-12">
                    <TextInputField :config="{
                    class: 'form-control-gray',
                    placeholder: 'Enter Contact Number',
                    inputFieldType: 'tel',
                }" labelText="Work Number" v-model="workMsisdn" :isRequired="true" :errorMessage="workMsisdnError"/>
                </div>
            </div>

            <div class="row mb-30">
                <div class="col-6">
                    <SimpleDropdown labelText="Country of Work" :options="countrySelectOptions"
                                    v-model="selectedWorkCountry"
                                    :loading="countryDropdownLoader" selectId="workCountryOptions" :isRequired="true"
                                    :selectedItem="selectedWorkCountry" @change="handleWorkCountrySelection"/>
                </div>

                <div class="col-6">
                    <SimpleDropdown labelText="State of Work" :options="stateSelectOptions" v-model="selectedWorkState"
                                    :loading="statesDropdownLoader" :isRequired="true"
                                    selectId="workStateOptions" :selectedItem="selectedWorkState"/>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <TextAreaInputField :config="{
                    class: 'form-control-gray mb-30',
                    placeholder: 'Enter Address',
                    inputFieldType: 'address',}" labelText="Office Address" :errorType="workAddressError"
                                        v-model="workAddress"/>
                </div>
            </div>
        </div>

        <div v-if="currentPage === 3">
            <SingleSelectField
                class="mb-30"
                labelText="Father or Mother?"
                :options="parentOptions"
                v-model="selectedParent"
                selectId="parentOptions"
                :selectedItem="selectedParent"/>


            <TextInputField :config="{
                    class: 'form-control-gray mb-30',
                    inputFieldType: 'text',
                    placeholder: 'English, French...'
                        }" labelText="Child's First Language"
                            v-model="child_first_language"
                            isRequired/>

            <SingleSelectField
                class="mb-30"
                labelText="Has this parent sent the child's birth certificate?"
                :options="yesOrNoOptions"
                v-model="selectedConfirmationOption"
                selectId="yesOrNoOptions"
                :selectedItem="selectedConfirmationOption"/>

            <SingleSelectField
                class="mb-30"
                labelText="Has this parent agreed to terms and conditions?"
                :options="termsAndConditionsOptions"
                v-model="selectedTermsAndConditionsOption"
                selectId="termsAndConditionsOptions"
                :selectedItem="selectedTermsAndConditionsOption"/>
        </div>

        <div class="mt-20 table-pagination">
            <ul class="pagination pagination-rounded">
                <li v-for="page in pages" :key="page" :class="{ active: currentPage === page }" class="page-item">
                    <a href="#" class="page-link" @click="changePage(page)">{{ page }}</a>
                </li>
            </ul>

            <RoundedButton :buttonText="currentPage < pages ? 'Next' : 'Submit'" class="custom-button"
                           @click="nextPage" :loading="buttonLoading"/>
        </div>

    </form>
</template>
<script>
import TextInputField from "@/components/common/TextInputField.vue";
import SimpleDropdown from "@/components/common/SimpleDropdown.vue";
import TextAreaInputField from "@/components/common/TextAreaInputField.vue";
import RoundedButton from "@/components/common/RoundedButton.vue";
import {onMounted, ref, watch} from "vue";
import getCountries from "@/composables/getCountries";
import getStates from "@/composables/getStates";
import {capitalizeFirstLetter, getSchoolId, getValidToken} from '@/utils/helpers'
import getGender from "@/composables/getGender";
import {API_BASE_URL} from "@/config";
import ImageUploadInput from "@/components/common/ImageUploadInput.vue";
import {checkAndRefreshToken} from "@/utils/auth";
import MultipleSelectDropdown from "@/components/common/MultipleSelectDropdown.vue";
import SingleSelectField from "@/components/common/SingleSelectField.vue";

export default {
    components: {
        SingleSelectField,
        MultipleSelectDropdown,
        TextInputField,
        SimpleDropdown,
        TextAreaInputField,
        RoundedButton,
        ImageUploadInput
    },
    setup(props, {emit}) {
        const buttonLoading = ref(false)
        const {fetchCountriesData, countriesList, loading: countryDropdownLoader} = getCountries()
        const {fetchStatesData, statesList, loading: statesDropdownLoader} = getStates()
        const {fetchGender, genderList, loading: genderLoader} = getGender()
        const genderSelectOptions = ref([])
        const countrySelectOptions = ref([])
        const stateSelectOptions = ref([])
        const workCountrySelectOptions = ref([])
        const workStateSelectOptions = ref([])
        const selectedGender = ref('')
        const selectedCountry = ref('')
        const selectedState = ref('')
        const selectedWorkCountry = ref('')
        const selectedWorkState = ref('')
        const pages = 3
        const currentPage = ref(1);
        const firstName = ref('')
        const lastName = ref('')
        const msisdn = ref('')
        const email = ref('')
        const address = ref('')
        const age = ref('')
        const countryIdMap = ref({});
        const firstNameError = ref('')
        const lastNameError = ref('')
        const emailError = ref('')
        const msisdnError = ref('')
        const addressError = ref('')
        const workEmail = ref('')
        const workMsisdn = ref('')
        const workAddress = ref('')
        const workEmailError = ref('')
        const workMsisdnError = ref('')
        const workAddressError = ref('')
        const ageError = ref('')
        const yesOrNoOptions = ref([])
        const selectedConfirmationOption = ref('')
        const selectedTermsAndConditionsOption = ref('')
        const termsAndConditionsOptions = ref([])
        const child_first_language = ref('')
        const parentOptions = ref([])
        const selectedParent = ref('')

        onMounted(() => {
            fetchGender()
            fetchCountriesData()
        })

        const updateGenderOptions = (newData) => {
            genderSelectOptions.value = newData.map(item => ({
                label: capitalizeFirstLetter(item.label), value: item.value
            }))

            if (genderSelectOptions.value.length > 0) {
                selectedGender.value = genderSelectOptions.value[0].value;
            }
        }

        watch(genderList, (newData) => {
            updateGenderOptions(newData)
        })

        const validatePage1 = () => {
            let isValid = true

            firstNameError.value = ''
            lastNameError.value = ''
            msisdnError.value = ''
            emailError.value = ''

            if (firstName.value.length === 0) {
                firstNameError.value = 'Enter a valid first name'
                isValid = false
            }


            if (lastName.value.length === 0) {
                lastNameError.value = 'Enter a valid last name'
                isValid = false
            }

            if (msisdn.value.length === 0) {
                msisdnError.value = 'Enter a valid phone number'
                isValid = false
            }

            if (email.value.length === 0) {
                emailError.value = 'Enter a valid email address'
                isValid = false
            }

            if (age.value.length === 0) {
                ageError.value = 'Enter a valid email age'
                isValid = false
            }


            return isValid
        }

        const validatePage2 = () => {
            let isValid = true

            workEmailError.value = ''
            workMsisdnError.value = ''

            if (workEmail.value.length === 0) {
                workEmailError.value = 'Enter a valid email address'
                isValid = false
            }

            if (workMsisdn.value.length === 0) {
                workMsisdnError.value = 'Enter a valid phone number'
                isValid = false
            }

            return isValid
        }


        const nextPage = () => {
            if (currentPage.value < pages) {
                if (currentPage.value === 1 && !validatePage1()) {
                    return;
                }
                if (currentPage.value === 2 && !validatePage2()) {
                    return;
                }
                currentPage.value++;
            } else {
                saveNewItem();
            }
        };

        watch(currentPage, (newData) => {
            if (newData === 1) {
                fetchGender()
                fetchCountriesData()
            } else if (newData === 2) {
                fetchCountriesData()
            } else {
                setTimeout(() => {
                    yesOrNoOptions.value = [
                        {label: "Yes", value: 'yes'},
                        {label: "No", value: 'no'}
                    ]

                    termsAndConditionsOptions.value = [
                        {label: "Yes", value: 'yes'},
                        {label: "No", value: 'no'}
                    ]

                    parentOptions.value = [
                        {label: "Father", value: 'father'},
                        {label: "Mother", value: 'mother'}
                    ]
                }, 500)
            }
        })


        const changePage = (page) => {
            if (currentPage.value === 1 && page === 2) {
                if (validatePage1()) {
                    currentPage.value = page;
                }
            } else if (currentPage.value === 2) {
                if ((page === 3 && validatePage2()) || page === 1) {
                    currentPage.value = page;
                }
            } else if (currentPage.value === 1 && page === 3) {
                if (validatePage1() && validatePage2()) {
                    currentPage.value = page;
                } else if (validatePage2()) {
                    currentPage.value = page;
                } else if (validatePage1()) {
                    currentPage.value = 2;
                }
            } else {
                currentPage.value = page;
            }
        };

        // COUNTRIES
        const handleCountrySelection = () => {
            const selectedCountryId = countryIdMap.value[selectedCountry.value]
            fetchStatesData(selectedCountryId)
        }

        const handleWorkCountrySelection = () => {
            const selectedCountryId = countryIdMap.value[selectedWorkCountry.value]
            fetchStatesData(selectedCountryId)
        }

        // Update the country dropdown when the modal is opened
        const updateCountryOptions = (newData) => {
            countrySelectOptions.value = newData.map(country => ({
                label: country.country_name, value: country.country_name
            }))

            countryIdMap.value = newData.reduce((map, country) => {
                map[country.country_name] = country.id;
                return map;
            }, {});

            if (countrySelectOptions.value.length > 0 && currentPage.value === 1) {
                selectedCountry.value = 'Nigeria'
                handleCountrySelection()
            } else {
                selectedWorkCountry.value = 'Nigeria'
                handleWorkCountrySelection()
            }
        }

        watch(countriesList, (newData) => {
            updateCountryOptions(newData)
        })

        // STATES
        // Update the states options
        const updateStatesOptions = (newData) => {
            stateSelectOptions.value = newData.map(state => ({
                label: state.state_name, value: state.state_name
            }))

            if (stateSelectOptions.value.length > 0) {
                if (currentPage.value === 1) {
                    selectedState.value = stateSelectOptions.value[0].value || '';
                } else {
                    selectedWorkState.value = stateSelectOptions.value[0].value || '';
                }
            }
        }

        watch(statesList, (newData) => {
            updateStatesOptions(newData)
        })

        // OTHERS
        const showLoaders = () => {
            buttonLoading.value = true;
        };

        const hideLoaders = () => {
            buttonLoading.value = false;
        };

        const apiUrl = `${API_BASE_URL}/parent/add-parent`
        const schoolId = getSchoolId()

        const saveNewItem = async () => {
            const newItem = {
                school_id: schoolId,
                first_name: firstName.value,
                last_name: lastName.value,
                gender: selectedGender.value,
                email: email.value,
                msisdn: msisdn.value,
                country: selectedCountry.value,
                state: selectedState.value,
                address: address.value,
                work_email: workEmail.value,
                work_msisdn: workMsisdn.value,
                work_address: workAddress.value,
                work_country: selectedWorkCountry.value,
                work_state: selectedWorkState.value,
                age: age.value,
                child_first_language: child_first_language.value,
                languages_spoken_at_home: child_first_language.value,
                has_emailed_child_kyc: selectedConfirmationOption.value === 'yes',
                agree_with_terms: selectedTermsAndConditionsOption.value === 'yes',
                date_to_join: 'N/A',
                relationship_to_student: selectedParent.value,
                token: 'keyhub_invite'
            }

            // console.log('newItem', newItem)

            try {
                showLoaders()
                await checkAndRefreshToken()
                const accessToken = getValidToken()

                const response = await fetch(apiUrl, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    },
                    body: JSON.stringify(newItem)
                })


                if (response.ok) {
                    const result = await response.json();

                    if (result.code === 200) {

                        const itemFullName = capitalizeFirstLetter(`${newItem.first_name} ${newItem.last_name}`)
                        emit("closeFormModal", itemFullName);
                    } else {
                        flashAlertMessage('error', result.message)
                        console.log("error", result.message);
                    }
                } else {
                    const result = await response.json();
                    flashAlertMessage('error', result.message)
                    console.log("error", result.message);
                }
            } catch (error) {
                flashAlertMessage('error', error)
                console.error("An error occured:", error);
            } finally {
                hideLoaders();
            }
        }

        const flashAlertMessage = (alertType, alertMessage) => {
            emit('flash-message', {
                alertType: alertType,
                alertMessage: alertMessage
            })
        }

        return {
            buttonLoading,
            saveNewItem,
            currentPage,
            nextPage,
            pages,
            changePage,
            firstName,
            lastName,
            genderSelectOptions,
            selectedGender,
            msisdn,
            email,
            countrySelectOptions,
            selectedCountry,
            countryDropdownLoader,
            statesDropdownLoader,
            handleCountrySelection,
            selectedState,
            stateSelectOptions,
            address,
            genderLoader,
            firstNameError,
            lastNameError,
            emailError,
            msisdnError,
            addressError,
            workEmail,
            workMsisdn,
            workAddress,
            workEmailError,
            workMsisdnError,
            workAddressError,
            workCountrySelectOptions,
            workStateSelectOptions,
            selectedWorkCountry,
            selectedWorkState,
            handleWorkCountrySelection,
            ageError,
            age,
            yesOrNoOptions,
            selectedConfirmationOption,
            selectedTermsAndConditionsOption,
            termsAndConditionsOptions,
            child_first_language,
            selectedParent,
            parentOptions
        }
    }
}
</script>
<style scoped>
.table-pagination {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.page-item.active .page-link {
    border-color: #2f5bcf;
    z-index: 3;
    color: #fff;
}

.page-item:first-child .page-link {
    margin-left: 0;
}

.pagination-rounded .page-link {
    border-radius: 30px !important;
    margin: 0 3px;
    border: none;
}

.page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.9rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #323a46;
    background-color: #fff;
    border: 1px solid #dee2e6;
}
</style>