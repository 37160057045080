import {API_BASE_URL} from "@/config.js";
import {formatDateTime, getValidToken} from "@/utils/helpers.js";
import {computed, ref} from "vue";
import {checkAndRefreshToken} from '@/utils/auth';


const loading = ref(false);
const apiUrl = `${API_BASE_URL}/keywords/all`;
const keywordsData = ref([]);

const fetchKeywordsData = async () => {
    try {
        await checkAndRefreshToken()

        const accessToken = getValidToken()
        if (!accessToken) {
            console.error("Access token not available.");
            return;
        }

        loading.value = true;
        const response = await fetch(apiUrl, {
            headers: {
                Authorization: `Bearer ${accessToken}`, // Include the access token in the headers
            },
        });

        if (response.ok) {
            const result = await response.json();

            if (result.code === 200) {
                keywordsData.value = result.data

                // console.log("result", result.data);
            } else {
                console.log("error", result.message);
            }
        } else {
            console.log("response", response);
            console.error("Error fetching data from the API");
        }
    } catch (error) {
        console.error("An error occured:", error);
    } finally {
        loading.value = false;
    }
};

const formattedKeywordsData = computed(() => {
    // Map and format the data
    return keywordsData.value.map((item) => {
        // Format the created at
        const {date, time} = formatDateTime(item.created_at);

        const formattedKeyword = {
            name: item.name,
            date: date,
            time: time
        };

        return {
            ...formattedKeyword,
        };
    });
});


const getKeywords = () => {
    return {fetchKeywordsData, keywordsData, formattedKeywordsData, loading}
}

export default getKeywords