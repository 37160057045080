<template>
    <div class="form-group mb-3">
        <label for="password" class="label">{{ labelText }}</label>
        <input
            type="password"
            id="password"
            :class="fieldClass"
            :placeholder="config.placeholder"
            :value="localValue"
            @input="updateValue($event.target.value)"
            required
        />

        <div class="invalid-feedback" v-if="error">
            {{ error.message }}
        </div>
    </div>
</template>

<script>
import {ref, watch} from "vue";

export default {
    props: {
        config: {
            type: Object,
            required: true,
        },
        errorType: String,
        labelText: String,
        modelValue: String,
    },
    setup(props, { emit }) {
        const error = ref(null); // Dynamic error message

        const localValue = ref(props.modelValue);

        const updateValue = (newValue) => {
            localValue.value = newValue;
            emit("update:modelValue", newValue); // Emit the updated value
        };

        // Update the error message based on the errorType prop
        watch(
            () => props.errorType,
            (newErrorType) => {
                if (newErrorType === "passwordError") {
                    error.value = { message: "Invalid password format" };
                } else if (newErrorType === "dateError") {
                    error.value = { message: "Invalid date format" };
                } else if (newErrorType === "nameError") {
                    error.value = { message: "Invalid name format" };
                } else {
                    error.value = null; // Reset error
                }
            }
        );

        return { localValue, error, updateValue };
    },
    computed: {
        placeholderText() {
            return this.config.placeholder || "Enter Text";
        },
        fieldClass() {
            return this.config.class
                ? `form-control ${this.config.class}`
                : "form-control";
        },
    },
};
</script>

<style scoped>
.label {
    font-weight: 400;
    font-size: 14px;
}

.form-control {
    background-color: #f8fafc !important;
    border: 1px solid #b8bcca !important;
    color: #333333 !important;
    padding: 10px;
}

.form-control-gray {
    border: 1px solid #D0D5DD !important;
}

.invalid-feedback {
    display: block;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.75rem;
    color: #f1556c;
}
</style>