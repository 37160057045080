<template>
    <div id="wrapper">
        <MessageSliderCopy :isVisible="displayMessage" :messageText="messageText" :messageType="messageType"/>

        <div class="auth-card-box">
            <InfiniteLinearLoader :loading="loading"/>
            <div class="image">
                <img src="../../assets/images/login_logo_new.jpeg" alt="login image"/>
            </div>

            <div class="form-content">
                <img src="../../assets/key-logo.png" alt="logo"/>
                <p>Set your password</p>

                <div class="row">
                    <div class="col-12 mb-20">
                        <TextInputFieldVue :config="{
                            class: 'form-control-gray',
                            placeholder: 'Enter OTP',
                            inputFieldType: 'number'
                        }" labelText="Enter OTP" :errorMessage="otpError" v-model="otp"/>
                    </div>

                    <div class="col-12 mb-20">
                        <TextInputFieldVue :config="{
                            class: 'form-control-gray',
                            placeholder: '********',
                            inputFieldType: 'password'
                        }" labelText="Enter Password" :errorMessage="passwordError" v-model="password"/>
                    </div>

                    <div class="col-12 mb-20">
                        <TextInputFieldVue :config="{
                            class: 'form-control-gray ',
                            placeholder: '********',
                            inputFieldType: 'password'
                        }" labelText="Confirm Password" :errorMessage="passwordConfirmError" v-model="passwordConfirm"/>
                    </div>
                </div>

                <RegularButtonVue buttonText="Set Password" class="custom-button mb-3"
                                  :loading="loading" @click="handleFormSubmit"/>

                <p class="forgot-text">
                    <a href="#" @click.prevent="navigateToLogin">Click to Login</a>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import TextInputFieldVue from "@/components/common/TextInputField.vue";
import PasswordInputFieldVue from "@/components/common/PasswordInputField.vue";
import CheckBoxVue from "@/components/common/CheckBox.vue";
import RegularButtonVue from "@/components/common/RegularButton.vue";
import InfiniteLinearLoader from "@/components/common/InfiniteLinearLoader.vue";
import MessageSliderCopy from "@/components/common/MessageSliderCopy.vue";
import {ref} from "vue";
import {API_BASE_URL} from "@/config.js";
import {useRoute, useRouter} from "vue-router";
import {flashMessage} from "@/utils/flashMessage.js";

export default {
    components: {
        TextInputFieldVue,
        PasswordInputFieldVue,
        CheckBoxVue,
        RegularButtonVue,
        InfiniteLinearLoader,
        MessageSliderCopy,
    },
    setup() {
        const password = ref("");
        const passwordConfirm = ref("");
        const passwordError = ref("");
        const otpError = ref("");
        const otp = ref("");
        const passwordConfirmError = ref("");
        const loading = ref(false);
        const loadingSpinner = ref(false);
        const router = useRoute();
        const navigationRouter = useRouter();
        const email = router.query.email;
        const displayMessage = ref(false);
        const messageText = ref("");
        const messageType = ref("");

        const showMessage = (text, type) => {
            flashMessage(text, type, messageText, messageType, displayMessage);
        };

        const showLoaders = () => {
            loading.value = true;
            loadingSpinner.value = true;
        };

        const hideLoaders = () => {
            loading.value = false;
            loadingSpinner.value = false;
        };

        const validateform = () => {
            let isValid = true

            otpError.value = ''
            passwordError.value = ''
            passwordConfirmError.value = ''

            if (otp.value.length === 0) {
                otpError.value = 'Enter the OTP from your email inbox'
                isValid = false
            }

            if (password.value.length === 0) {
                passwordError.value = 'Enter a password'
                isValid = false
            }

            if (password.value !== passwordConfirm.value) {
                passwordConfirmError.value = 'Passwords do not match'
                isValid = false
            }

            return isValid
        }

        const handleFormSubmit = async () => {
            if (validateform()) {
                try {
                    showLoaders();
                    const data = {
                        otp: otp.value,
                        email: email,
                        password: password.value,
                    }

                    const response = await fetch(`${API_BASE_URL}/auth/update-password`, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(data),
                    });

                    if (response.ok) {
                        const result = await response.json();
                        // Assuming the response structure is similar to what you provided
                        if (result.code === 200) {
                            flashMessage(
                                "Password reset successful, you can now login",
                                "success",
                                messageText,
                                messageType,
                                displayMessage
                            );
                        } else {
                            console.log('code error => ', result)
                            flashMessage(
                                result.message,
                                "error",
                                messageText,
                                messageType,
                                displayMessage
                            );
                        }
                    } else {
                        const result = await response.json();
                        console.log('ok error => ', result.message)
                        flashMessage(
                            'An error occurred',
                            "error",
                            messageText,
                            messageType,
                            displayMessage
                        );
                    }
                } catch (error) {
                    console.error("An error occurred:", error);
                    flashMessage(
                        "An error occurred while processing your request.",
                        "error",
                        messageText,
                        messageType,
                        displayMessage
                    );
                } finally {
                    hideLoaders();
                }
            }
        };

        const navigateToLogin = () => {
            navigationRouter.push({name: 'Login'})
        }

        return {
            password,
            handleFormSubmit,
            loading,
            showMessage,
            displayMessage,
            messageText,
            messageType,
            passwordConfirm,
            passwordError,
            passwordConfirmError,
            otpError,
            otp,
            navigateToLogin,
        };
    },
};
</script>

<style scoped>
.auth-card-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 550px;
    width: 850px;
    box-shadow: 0px 0px 15px 0px rgba(184, 188, 202, 0.5);
}

.image {
    height: 100%;
    width: 40%;
    display: inline-block;
}

.image > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.form-content {
    height: 100%;
    width: 60%;
    display: inline-block;
    position: inherit;
    top: 0;
    padding: 50px 45px;
}

.form-content > img {
    height: 35px;
    width: 60px;
    margin-bottom: 10px;
}

.form-content > p {
    margin-bottom: 40px;
}

.form-content > .forgot-text {
    font-size: 12px;
    color: #333;
}

.form-content > .forgot-text span {
    color: #f75549;
}

.checkboxVue {
    margin-bottom: 40px;
}
</style>