<template>
    <div class="row">
        <div class="col-12">
            <div class="page-title-box">
                <h4 class="page-title">Manage Transactions</h4>
            </div>
        </div>
    </div>

    <div class="row">
        <StatCards statCardText="Total" :statsCardNumber="totalTransactions"/>
        <StatCards statCardText="Pending" :statsCardNumber="pendingTransactions"/>
        <StatCards statCardText="Completed" :statsCardNumber="completedTransactions"/>
    </div>

    <div class="row mainContent">
        <div class="col-sm-12">
            <div class="card-box">
                <TableView :data="formattedTransactionData" :headers="tableHeaders"
                           :id="getIdForActions" :showSearchField="true"
                           :detailsData="getDataForDetailsView" :loading="loading"
                           :searchStringFromParent.sync="searchStringFromChild"
                           :showActionsColumn="true" :viewAction="viewAction"
                           :searchFields="searchFieldsData" :totalPages="totalPages" :currentPage.sync="currentPage"
                           :changePage="changePage"
                           :changeItemsPerPage="changeItemsPerPage" :itemsPerPage="itemsPerPage"/>
            </div>
        </div>
    </div>
</template>
<script>
import StatCards from "@/components/common/StatCards.vue";
import RoundedButton from "@/components/common/RoundedButton.vue";
import {computed, onMounted, ref, watch} from "vue";
import TableView from "@/components/common/TableView.vue";
import getTransactions from "@/composables/getTransactions";
import {useRouter} from "vue-router";

export default {
    props: {},
    components: {TableView, RoundedButton, StatCards},
    emits: ['showLoader', 'show-loader', 'flashMessage', 'data-loaded'],
    setup(props, {emit}) {
        document.title = 'Manage Transactions'
        const {transactionsPayload, fetchTransactionData, loading, formattedTransactionData, transactionsData} = getTransactions()

        const totalTransactions = ref(0)
        const pendingTransactions = ref(0)
        const completedTransactions = ref(0)
        const currentPage = ref(1)
        const itemsPerPage = ref(10)
        const totalPages = ref(0)
        const router = useRouter()

        onMounted(() => {
            fetchTransactionData(currentPage.value, itemsPerPage.value)

            emit('data-loaded')
        })

        watch(transactionsData, () => {
            totalPages.value = transactionsPayload.value.total_pages

            const stats = transactionsPayload.value.results
            totalTransactions.value = stats.num_of_transactions
            pendingTransactions.value = stats.num_of_pending_transactions
            completedTransactions.value = stats.num_of_completed_transactions
        })

        const searchStringFromChild = ref("");
        const searchFieldsData = ['transaction_reference', 'createdAt', 'amount', 'payer', 'status']

        const tableHeaders = computed(() => {
            return [
                // {label: "Transaction ID", key: "transaction_reference"},
                {label: "Date", key: "createdAt"},
                {label: "Amount", key: "amount"},
                {label: "Payer", key: "payer"},
                {label: "Status", key: "new_status"}
            ];
        });

        const getIdForActions = computed(() => {
            return transactionsData.value.map((item) => item.id);
        });

        const getDataForDetailsView = computed(() => {
            return transactionsData.value
        })

        const viewAction = (item, id) => {
            router.push(
                {
                    name: 'ShowTransaction',
                    params: {id: id}
                })
        }

        const changePage = (page) => {
            if (page >= 1 && page <= totalPages.value) {
                currentPage.value = page;

                fetchTransactionData(currentPage.value, itemsPerPage.value)
            }
        };

        const changeItemsPerPage = (perPage) => {
            itemsPerPage.value = perPage;
            currentPage.value = 1;

            fetchTransactionData(currentPage.value, perPage);
        };

        return {
            totalTransactions,
            pendingTransactions,
            completedTransactions,
            searchStringFromChild,
            searchFieldsData,
            tableHeaders,
            getIdForActions,
            getDataForDetailsView,
            viewAction,
            formattedTransactionData,
            loading,
            currentPage,
            itemsPerPage,
            totalPages,
            transactionsData,
            changePage,
            changeItemsPerPage
        }
    }
}
</script>
<style></style>