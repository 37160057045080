<template>
    <div class="message-item" v-if="messageType === 'sender'">
        <div class="sender-chat-bubble">
            <div class="message">
                <p class="m-0">
                    {{ capitalizeFirstLetterInSentence(messageContent) }}
                </p>
            </div>
            <p class="timeSince">{{ timeStamp }}</p>
        </div>
    </div>

    <div class="message-item" v-if="messageType === 'receiver'">
        <div class="receiver-chat-bubble">
            <div class="message">
                <p class="m-0">
                    {{ capitalizeFirstLetterInSentence(messageContent) }}
                </p>
            </div>
            <p class="timeSince">{{ timeStamp }}</p>
        </div>
    </div>

</template>
<script>
import {ref} from "vue";
import {capitalizeFirstLetterInSentence, timeAgo} from "@/utils/helpers";

export default {
    props: {
        message: {
            type: String,
            required: true
        },
        timestamp: {
            type: Number,
            required: true
        },
        messageType: {
            type: String,
            required: true
        }
    },
    components: {},
    setup(props) {
        const messageType = ref(props.messageType)
        const messageContent = ref(props.message)
        const timeStamp = ref(timeAgo(props.timestamp))

        return {
            messageType,
            messageContent,
            timeStamp,
            capitalizeFirstLetterInSentence
        }
    }
}
</script>
<style scoped>
.sender-chat-bubble {
    width: 60%;
    float: right;
}

.receiver-chat-bubble {
    width: 60%;
}

.sender-chat-bubble > .message {
    border: 1px solid #B8BCCA;
    border-radius: 8px 8px 0 8px;
    padding: 15px;
    margin-bottom: 8px;
}

.receiver-chat-bubble > .message {
    background-color: #F5F6FA;
    border-radius: 8px 8px 8px 0;
    padding: 15px;
    margin-bottom: 8px;
}

.timeSince {
    float: right;
    color: #9A9A9A;
    font-size: 10px;
}
</style>