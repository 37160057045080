<template>
    <!-- start page title -->
    <div class="row">
        <div class="col-12">
            <div class="page-title-box">
                <div class="page-title-right">
                    <RoundedButton buttonText="Add Student" iconText="mdi-plus" @clicked="openFormModal"
                    v-if="hasPermission('add_students')"/>
                </div>
                <h4 class="page-title">Manage Students</h4>
            </div>
        </div>
    </div>

    <div class="row">
        <StatCards statCardText="Total Students" :statsCardNumber="numOfStudents"/>
        <StatCards statCardText="Active Students" :statsCardNumber="activeStudents"/>
        <StatCards statCardText="Deactivated Students" :statsCardNumber="inActiveStudents"/>
    </div>

    <div class="row mainContent" v-if="hasPermission('view_students')">
        <div class="col-sm-12">
            <div class="card-box">
                <TableView :data="studentsList" :headers="tableHeaders"
                           :id="getIdForActions" :showSearchField="true" :isAPISearchNeeded="true"
                           :detailsData="getDataForDetailsView" :loading="loading"
                           :searchStringFromParent.sync="searchStringFromChild"
                           :viewAction="viewAction" :editAction="editAction"
                           :showActionsColumn="true"
                           :searchFields="searchFieldsData" :totalPages="totalPages" :currentPage.sync="currentPage"
                           :changePage="changePage" @searchWord="searchForItem"
                           :changeItemsPerPage="changeItemsPerPage" :itemsPerPage="itemsPerPage"
                           viewPermission="view_students"
                           editPermission="modify_students" deactivatePermission="deactivate_students"/>
            </div>
        </div>
    </div>

    <InfoModal :isModalOpen="isConfirmationDialogOpen" @closeModal="closeConfirmationDialog"
               :successImageURL="require('../../../assets/images/icons/success_icon.svg')"
               :errorImageURL="require('../../../assets/images/icons/error_icon.svg')" header="Hold on!"
               :paragraph="deactivateConfirmationParagraph"
               :buttonTxt="deactivateButtonText" :buttonAction="performAction" buttonColor="error"
               routerLinkText="No, Cancel Action" buttonLink="/"
               :linkAction="cancelAction" :loading="buttonLoader">
    </InfoModal>

    <FormModal :isFormModalOpen="isFormModalOpen" @closeFormModal="closeFormModal1" modalHeader="Add Student">
        <CreateStudent @closeFormModal="closeFormModal" @message="showFlashMessage"/>
    </FormModal>

    <FormModal :isFormModalOpen="isEditFormModalOpen" @closeFormModal="closeEditFormModal"
               modalHeader="Edit Student">
        <EditStudent @closeFormModal="closeEditFormModal" @message="showFlashMessage" :itemData="itemDataForEditing"
        />
    </FormModal>


    <MessageSliderCopy :isVisible="displayMessage" :messageText="messageText" :messageType="messageType"/>

    <InfoModal :isModalOpen="isFlashModalOpen" @closeModal="closeFlashModal" :isSuccess="true" header="Student Added"
               :paragraph="`You have successfully added '${fullName}'. You can view and manage this student's profile under 'Manage Students'.`"
               buttonTxt="Add Another Student" routerLinkText="Manage Students"
               :linkAction="createItemFlashLinkAction"
               :buttonAction="buttonAction">
    </InfoModal>
</template>
<script>
import {computed, onActivated, onMounted, ref, watch, watchEffect} from "vue";
import RoundedButton from "@/components/common/RoundedButton.vue";
import TableView from "@/components/common/TableView.vue";
import FormModal from "@/components/common/FormModal.vue";
import InfoModal from "@/components/common/InfoModals.vue";
import MessageSliderCopy from "@/components/common/MessageSliderCopy.vue";
import CreateStudent from "@/components/layouts/GodAdmin/Students/CreateStudent.vue"
import EditStudent from "@/components/layouts/GodAdmin/Students/EditStudent.vue";
import {flashMessage} from "@/utils/flashMessage.js";
import {useRouter} from 'vue-router';
import {API_BASE_URL} from "@/config.js";
import {capitalizeFirstLetter, formatDateTime, getUserInitials, getValidToken, hasPermission} from '@/utils/helpers'
import {checkAndRefreshToken} from '@/utils/auth';
import StatCards from "@/components/common/StatCards.vue";
import getStudents from "@/composables/getStudents";
import {useUserStore} from "@/stores/user";

export default {
    components: {
        RoundedButton,
        TableView,
        FormModal,
        MessageSliderCopy,
        CreateStudent,
        EditStudent,
        InfoModal,
        StatCards
    },
    emits: ['showLoader', 'show-loader', 'flashMessage', 'data-loaded'],
    setup(props, {emit}) {
        document.title = 'Manage Students'
        const {studentsPayloadData, fetchStudentsData, loading, formattedStudentsData, studentsData} = getStudents()
        const isFormModalOpen = ref(false);
        const isEditFormModalOpen = ref(false);
        const displayMessage = ref(false);
        const buttonLoader = ref(false);
        const messageText = ref("");
        const messageType = ref("");
        const newMessage = ref("");
        const showDetailsOrIcons = ref(false)
        const router = useRouter()
        const accessToken = getValidToken();
        const isConfirmationDialogOpen = ref(false);
        const isFlashModalOpen = ref(false);
        const fullName = ref('')
        const currentPage = ref(1);
        const itemsPerPage = ref(10); // Default items per page
        const totalPages = ref(0)
        const userData = ref(null)
        const numOfStudents = ref(0)
        const activeStudents = ref(0)
        const inActiveStudents = ref(0)
        const itemData = ref(null)
        const studentsList = ref([])


        const buttonAction = () => {
            isFlashModalOpen.value = false;
            openFormModal()
        }

        const openFlashModal = () => {
            isFlashModalOpen.value = true;
        };

        const closeFlashModal = async () => {
            isFlashModalOpen.value = false;
            await fetchStudentsData(currentPage.value, itemsPerPage.value)
        };

        const createItemFlashLinkAction = () => {
            closeFlashModal()
        }

        const openFormModal = () => {
            isFormModalOpen.value = true;
        };

        const closeFormModal = (itemFullName) => {
            isFormModalOpen.value = false;

            fullName.value = itemFullName
            isFlashModalOpen.value = true
        };

        const closeFormModal1 = () => {
            isFormModalOpen.value = false;
        }

        const openEditFormModal = () => {
            isEditFormModalOpen.value = true;
        };

        const closeEditFormModal = async (message) => {
            isEditFormModalOpen.value = false;
            await fetchStudentsData(currentPage.value, itemsPerPage.value)
        };

        const searchStringFromChild = ref("");
        const searchFieldsData = ['user.name', 'age', 'gender', 'school', 'status']

        watch(studentsData, () => {
            studentsList.value = formattedStudentsData.value
            totalPages.value = studentsPayloadData.value.total_pages

            const stats = studentsPayloadData.value.results
            // console.log('stats', stats)
            numOfStudents.value = stats.num_of_students
            activeStudents.value = stats.num_of_active_students
            inActiveStudents.value = stats.num_of_deactivated_students
        })

        onMounted(() => {
            watchEffect(() => {
                user.value = userStore.user;
                userPermissions.value = user.value ? user.value.permissions : [];
            });

            fetchStudentsData(currentPage.value, itemsPerPage.value);
            emit('data-loaded')
        })

        onActivated(() => {
            fetchStudentsData(currentPage.value, itemsPerPage.value);
        })

        const getIdForActions = computed(() => {
            return studentsList.value.map((item) => item.user.id);
        });

        const getDataForDetailsView = computed(() => {
            return studentsData.value
        })

        const viewAction = (item, id) => {
            router.push(
                {
                    name: 'ShowStudent',
                    params: {id: id}
                })
        }

        const itemDataForEditing = ref(null)
        const editAction = (item, item_id) => {
            // Find the user to edit
            const itemForEditing = studentsData.value.find((r) => r.user_id === item_id);

            itemDataForEditing.value = {
                firstName: itemForEditing.first_name,
                lastName: itemForEditing.last_name,
                age: itemForEditing.age,
                dob: itemForEditing.dob,
                project: itemForEditing.project,
                gender: itemForEditing._gender,
                msisdn: itemForEditing.msisdn,
                email: itemForEditing.email,
                country: itemForEditing.country,
                state: itemForEditing.state,
                address: itemForEditing.address,
                parent: itemForEditing.parent,
                id: item_id
            }

            openEditFormModal()
        }

        const deactivateAction = async (id) => {
            // console.log('item id', id)
            const apiUrl = `${API_BASE_URL}/student/delete-student`
            try {
                await checkAndRefreshToken()

                buttonLoader.value = true
                const response = await fetch(apiUrl, {
                    method: 'DELETE',
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`
                    },
                    body: JSON.stringify({
                        student_id: id,
                        reason: ''
                    })
                })

                if (response.ok) {
                    const result = await response.json()

                    if (result.code === 200) {
                        closeConfirmationDialog()

                        const msg = {
                            apiResponse: "Student status updated",
                            type: "success"
                        }
                        showFlashMessage(msg)
                    } else {
                        const msg = {
                            apiResponse: result.message,
                            type: "success"
                        }
                        showFlashMessage(msg)
                        console.log('error', result.message)
                    }
                } else {
                    const result = await response.json();
                    console.log("error", result.message);
                }
            } catch (error) {
                console.error("An error occured:", error);
            } finally {
                buttonLoader.value = false
            }
        }

        const messageFromChild = ref("");
        const handleMessageFromChild = (message) => {
            messageFromChild.value = message
        };

        const showFlashMessage = (msg) => {
            flashMessage(
                msg.apiResponse,
                msg.type,
                messageText,
                messageType,
                displayMessage
            );
        };

        const tableHeaders = computed(() => {
            return [
                {label: "Name", key: "name"},
                {label: "Age", key: "age"},
                {label: "Gender", key: "gender"},
                {label: "Source School", key: "school"},
                {label: "Added On", key: "createdAt"},
                {label: "Acct Status", key: "status"}
            ];
        });

        const userId = ref('null')
        const userStatus = ref('')
        const deactivateConfirmationParagraph = ref('')
        const deactivateButtonText = ref('')

        const openConfirmationDialog = (item, id) => {
            userId.value = id

            userStatus.value = item.isDeactivated === false ? 'deactivate' : 'activate'
            deactivateConfirmationParagraph.value = `You are about to ${userStatus.value} this students's account. Are you sure you want to continue this action?.`
            deactivateButtonText.value = `Yes, ${capitalizeFirstLetter(userStatus.value)} student`
            isConfirmationDialogOpen.value = true;
        };

        const closeConfirmationDialog = () => {
            isConfirmationDialogOpen.value = false;
            fetchStudentsData(currentPage.value, itemsPerPage.value)
        };

        const performAction = () => {
            deactivateAction(userId.value)
        }

        const cancelAction = () => {
            closeConfirmationDialog()
        }

        const changePage = (page) => {
            if (page >= 1 && page <= totalPages.value) {
                currentPage.value = page;

                fetchStudentsData(currentPage.value, itemsPerPage.value)
            }
        };

        const changeItemsPerPage = (perPage) => {
            itemsPerPage.value = perPage;
            currentPage.value = 1; // Reset to the first page when changing items per page

            fetchStudentsData(currentPage.value, perPage);
        };

        const searchForItem = async (word) => {
            const apiUrl = `${API_BASE_URL}/student/search-student?query=${word}`
            try {
                loading.value = true
                await checkAndRefreshToken()

                const response = await fetch(apiUrl, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                })

                if (response.ok) {
                    const result = await response.json()

                    if (result.code === 200) {
                        studentsList.value = result.data.map((student) => {

                            // Format the created at
                            const {date, time} = formatDateTime(student.created_at);

                            const formattedStudent = {
                                user: {
                                    name: capitalizeFirstLetter(`${student.first_name} ${student.last_name}`),
                                    initials: getUserInitials(student.first_name, student.last_name),
                                    avatarColor: 'danger',
                                    id: student.user_id
                                },
                                age: student.age,
                                gender: student._gender,
                                school: student.school || '-',
                                createdAt: date,
                                status: student.isDeactivated ? 'Deactivated' : 'Active',
                            };

                            return {
                                ...formattedStudent,
                            };
                        })

                        totalPages.value = studentsList.value.length
                    } else {
                        const msg = {
                            apiResponse: result.message,
                            type: "success"
                        }
                        showFlashMessage(msg)
                        console.log('error', result.message)
                    }
                } else {
                    const result = await response.json();
                    console.log("error", result.message);
                }
            } catch (error) {
                console.error("An error occured:", error);
            } finally {
                loading.value = false
            }
        }

        const userStore = useUserStore();
        const user = ref(userStore.user)
        const userPermissions = ref(user.value.permissions)
        const checkPermission = (permission) => hasPermission(userPermissions, permission)


        return {
            tableHeaders,
            studentsData,
            totalPages,
            studentsList,
            getIdForActions,
            loading,
            searchStringFromChild,
            searchFieldsData,
            isFormModalOpen,
            openFormModal,
            closeFormModal,
            closeFormModal1,
            handleMessageFromChild,
            showFlashMessage,
            displayMessage,
            messageText,
            messageType,
            newMessage,
            showDetailsOrIcons,
            viewAction,
            editAction,
            deactivateAction,
            InfoModal,
            isConfirmationDialogOpen,
            openConfirmationDialog,
            closeConfirmationDialog,
            performAction,
            cancelAction,
            buttonLoader,
            isEditFormModalOpen,
            openEditFormModal,
            closeEditFormModal,
            userData,
            itemDataForEditing,
            isFlashModalOpen,
            openFlashModal,
            closeFlashModal,
            buttonAction,
            fullName,
            createItemFlashLinkAction,
            deactivateConfirmationParagraph,
            deactivateButtonText,
            getDataForDetailsView,
            changePage,
            changeItemsPerPage,
            currentPage,
            itemsPerPage,
            numOfStudents,
            activeStudents,
            inActiveStudents,
            itemData,
            searchForItem,
            hasPermission: checkPermission
        };
    }
}
</script>