<template>
    <div class="row">
        <div class="col-12">
            <div class="detail-group mb-30">
                <span class="muted-text-sm bold">Name</span>
                <p>{{ itemDetails.name }}</p>
            </div>

            <div class="detail-group mb-30">
                <span class="muted-text-sm bold">Description</span>
                <p>{{ itemDetails.description }}</p>
            </div>

            <div class="detail-group mb-30">
                <span class="muted-text-sm bold">Start Date</span>
                <p>{{ itemDetails.start_date }}</p>
            </div>

            <div class="detail-group mb-30">
                <span class="muted-text-sm bold">End Date</span>
                <p>{{ itemDetails.finish_date }}</p>
            </div>

            <div class="detail-group">
                <span class="muted-text-sm bold">Learning Objectives</span>
                <p>{{ itemDetails.learning_objectives }}</p>
            </div>

            <div class="detail-group">
                <span class="muted-text-sm bold">Supporting Weblinks</span>
                <p>{{ itemDetails.supporting_weblinks }}</p>
            </div>

            <div class="detail-group">
                <span class="muted-text-sm bold">Resources</span>
                <p>{{ itemDetails.resources }}</p>
            </div>

            <div class="detail-group">
                <span class="muted-text-sm bold">Ways to Extend/Expand</span>
                <p>{{ itemDetails.ways_to_extend }}</p>
            </div>

            <div class="detail-group mb-30">
                <span class="muted-text-sm bold">Media</span>
                <p></p>
            </div>

            <RoundedButton buttonText="Edit Activity" @click="openEditActivityForm"/>
        </div>
    </div>
</template>
<script>
import RoundedButton from "@/components/common/RoundedButton.vue";
import {onMounted, ref} from "vue";
import {dd} from "@/utils/helpers";

export default {
    props: {
        itemDetails: {
            type: Object,
            required: true
        }
    },
    components: {RoundedButton},
    setup(props, {emit}) {
        const itemDataForEditing = ref(null)

        onMounted(() => {
            dd('mounted')
        })

        const openEditActivityForm = () => {


            // Find the item to edit
            itemDataForEditing.value = {
                name: props.itemDetails.name,
                description: props.itemDetails.description,
                start_date: props.itemDetails.start_date,
                finish_date: props.itemDetails.finish_date,
                learning_objectives: props.itemDetails.learning_objectives,
                resources: props.itemDetails.resources,
                supporting_weblinks: props.itemDetails.supporting_weblinks,
                ways_to_extend: props.itemDetails.ways_to_extend,
                projectId: props.itemDetails.project_id,
                id: props.itemDetails.id
            }

            emit('openFormModal', itemDataForEditing.value)
            emit('closeFormModal')
        }

        return {
            openEditActivityForm
        }
    }
}
</script>
<style></style>