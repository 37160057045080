export function flashMessage(text, type, messageText, messageType, displayMessage) {
    messageText.value = text;
    messageType.value = type;
    displayMessage.value = true;

    // Automatically hide the message after a short duration (e.g., 3000 milliseconds)
    setTimeout(() => {
        displayMessage.value = false;
    }, 4000);
}
