<template>
    <div class="form-content">
        <form @submit.prevent="saveItem">
            <div class="row">
                <div class="col-12">
                    <TextInputFieldVue :config="{
                        class: 'form-control-gray mb-30',
                        placeholder: 'Enter keyword',
                        inputFieldType: 'text',
                    }" labelText="Keyword" errorType="nameError" v-model="name" />
                </div>
            </div>

            <RegularButton buttonText="Save Keyword" class="custom-button" buttonType="submit" :loading="loading" />
        </form>
    </div>
</template>

<script>
import TextInputFieldVue from "@/components/common/TextInputField.vue";
import TextAreaInputField from "@/components/common/TextAreaInputField.vue";
import RoundedButton from "@/components/common/RoundedButton.vue";
import { ref } from "vue";
import { API_BASE_URL } from "@/config.js";
import {getValidToken} from "@/utils/helpers";
import RegularButton from "@/components/common/RegularButton.vue";

export default {
    components: {
        RegularButton,
        TextInputFieldVue,
        TextAreaInputField,
        RoundedButton,
    },
    setup(props, { emit }) {
        const loading = ref(false);
        const loadingSpinner = ref(false);
        const name = ref("");
        const accessToken = getValidToken()
        const message = ref("");

        const showLoaders = () => {
            loading.value = true;
            loadingSpinner.value = true;
        };

        const hideLoaders = () => {
            loading.value = false;
            loadingSpinner.value = false;
        };

        const saveItem = async () => {
            try {
                showLoaders();
                const response = await fetch(
                    `${API_BASE_URL}/keywords/add`,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${accessToken}`,
                        },
                        body: JSON.stringify({
                            name: name.value,
                        }),
                    }
                );

                if (response.ok) {
                    const result = await response.json();
                    if (result.code === 201) {
                        message.value = result.message;

                        emit("closeFormModal");
                        emit("message", {
                            apiResponse: message.value,
                            type: "success",
                        });
                    } else {
                        console.log("error", result.message);
                    }
                } else {
                    const result = await response.json();
                    console.log("error", result.message);
                }
            } catch (error) {
                console.error("An error occured:", error);
            } finally {
                hideLoaders();
            }
        };

        return {
            loading,
            loadingSpinner,
            showLoaders,
            hideLoaders,
            name,
            saveItem,
        };
    },
};
</script>

<style>

</style>