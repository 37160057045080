<template>
    <nav class="breadcrumb mtb-30 p-0">
        <router-link to="/">
            <img src="../../assets/images/icons/home_icon.svg" alt="Home" class="home-icon">
        </router-link>
        <span class="mr-2"> > </span>

        <ul class="m-0">
            <li v-for="(crumb, index) in breadcrumbs" :key="index">
                <router-link :to="crumb.to" class="mr-1">{{ crumb.text }}</router-link>
                <span v-if="index < breadcrumbs.length - 1"> > </span>
            </li>
        </ul>
    </nav>
</template>

<script>
import { computed } from 'vue';
import { useRoute } from 'vue-router';

export default {
    setup() {
        const route = useRoute();

        const breadcrumbs = computed(() => {
            const crumbs = [];
            for (const segment of route.matched) {
                if (segment.meta.breadcrumb) {
                    crumbs.push({
                        text: segment.meta.breadcrumb,
                        to: segment.path,
                    });
                }
            }
            return crumbs;
        });
        return {
            breadcrumbs,
        };
    }
}
</script>

<style scoped>
.breadcrumb {
    margin-top: 30px;
    margin-bottom: 30px;
}

.breadcrumb ul {
    list-style-type: none;
    padding: 0;
    display: flex;
    align-items: center;
}

.breadcrumb li {
    margin-right: 10px;
}

.breadcrumb li a {
    color: #333333;
}

.home-icon {
    margin-right: 10px;
}

span {
    color: #B8BCCA;
    font-size: 16px
}

.router-link-exact-active {
    color: #B8BCCA !important;
}
</style>