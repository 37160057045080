<template>
    <div class="col-md-6 col-xl-3">
        <div class="widget-rounded-circle card-box">

            <div class="row align-items-center">

                <div class="col-auto">
                    <div class="avatar-lg">
                        <img src="../../assets/images/users/user-3.jpg" class="img-fluid rounded-circle"
                             alt="user-img"/>
                    </div>
                </div>

                <div class="col">
                    <h5 class="mb-1 mt-2">Thelma Fridley</h5>
                    <p class="mb-2 text-muted">Admin User</p>
                </div>

            </div>

        </div>
    </div>
</template>

<script>
export default {
    props: {},
    components: {},
    setup() {

    }
}
</script>

<style></style>