import { API_BASE_URL } from "@/config.js";
import { formatDateTime, capitalizeFirstLetter, getValidToken } from "@/utils/helpers.js";
import { checkAndRefreshToken } from '@/utils/auth';
import { ref } from "vue";


const loading = ref(false);
const apiUrl = `${API_BASE_URL}/helper/countries`;
const statesList = ref([]);

const fetchStatesData = async (country_id) => {
    try {
        await checkAndRefreshToken()
        
        const accessToken = getValidToken()
        if (!accessToken) {
            console.error("Access token not available.");
            return;
        }

        loading.value = true;
        const newApiUrl = `${apiUrl}/${country_id}`
        const response = await fetch(newApiUrl, {
            headers: {
                Authorization: `Bearer ${accessToken}`, // Include the access token in the headers
            },
        });

        if (response.ok) {
            const result = await response.json();

            if (result.code === 200) {
                statesList.value = result.data.sort((a, b) => {
                    return a.state_name.localeCompare(b.state_name);
                });

                if(result.data === []) {
                    result.data = ['']
                }

                // console.log("result", result.data);
            } else {
                console.log("error", result.message);
            }
        } else {
            console.log("response", response);
            console.error("Error fetching data from the API");
        }

    } catch (error) {
        console.error("An error occured:", error);
    } finally {
        loading.value = false;
    }
}

const getStates = () => {
    return { fetchStatesData, statesList, loading }
}

export default getStates