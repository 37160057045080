<template>
    <div class="row">
        <div class="col-12">
            <div class="row mb-30">
                <div class="col-12">
                    <TextAreaInputField :config="{
                                class: 'form-control-gray',
                                placeholder: 'Enter Aim',
                                inputFieldType: 'text',
                            }" labelText="Project Aim" :errorMessage="aimError" v-model="aim"
                                        :isRequired="true"/>
                </div>
            </div>

            <div class="row mb-30">
                <div class="col-12">
                    <TextAreaInputField :config="{
                                class: 'form-control-gray',
                                placeholder: 'Enter Description',
                                inputFieldType: 'text',
                            }" labelText="Project Description" :errorMessage="descriptionError"
                                        v-model="description"
                                        :isRequired="true"/>
                </div>
            </div>

            <div class="row mb-30">
                <div class="col-12">
                    <TextInputFieldVue :config="{
                                class: 'form-control-gray',
                                placeholder: 'Enter links',
                                inputFieldType: 'text',
                            }" labelText="Web Links" v-model="webLinks"/>
                </div>
            </div>

            <div class="row mb-30">
                <div class="col-12">
                    <TextAreaInputField :config="{
                                class: 'form-control-gray',
                                placeholder: 'Enter milestones',
                                inputFieldType: 'text',
                            }" labelText="Key Milestones" :errorMessage="milestoneError"
                                        v-model="milestones"
                                        :isRequired="true"/>
                </div>
            </div>

            <div class="row mb-30">
                <div class="col-12">
                    <SingleSelectField labelText="Final Product"
                                       :options="finalProductSelectOptions"
                                       v-model="selectedFinalProduct"
                                       selectId="finalProductSelectOptions"
                                       :isRequired="true"
                                       :errorMessage="finalProductSelectionError"
                                       :selectedItem="selectedFinalProduct"/>
                </div>
            </div>

            <div class="row mb-30">
                <div class="col-12">
                    <TextAreaInputField :config="{
                                    class: 'form-control-gray',
                                    placeholder: 'Enter product details',
                                    inputFieldType: 'text',
                                }" labelText="Product Details" v-model="finalProduct" :isRequired="true"
                                        :errorMessage="finalProductError"/>
                </div>
            </div>

            <div class="row mb-50">
                <div class="col-12">
                    <div class="form-group">
                        <label class="label">
                            Guiding Questions
                            <span class="required">*</span>
                        </label>

                        <div class="input-group">
                            <input class="form-control form-control-gray"
                                   placeholder="Question/Answer" type="text"
                                   v-model="newQuestion"/>

                            <div class="input-group-append">
                                <button class="btn btn-dark waves-effect waves-light"
                                        @click="addQuestion">
                                    Click to Add
                                </button>
                            </div>
                        </div>

                        <div class="invalid-feedback" v-if="questionsError">
                            {{ questionsError }}
                        </div>
                    </div>


                    <table class="table table-borderless mt-20"
                           v-if="questions.length !== 0">
                        <thead>
                        <tr>
                            <th class="text-center">S/N</th>
                            <th>Question</th>
                            <th class="text-center">Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(question, index) in questions" :key="index">
                            <td class="text-center">{{ index + 1 }}</td>
                            <td>{{ question.text }}</td>
                            <td class="text-center">
                                <a href="#" @click.prevent="deleteQuestion(index)">
                                    <img class="mr-2"
                                         src="@/assets/images/icons/trash_icon.svg"
                                         alt="Delete Icon"/>
                                </a>
                            </td>
                        </tr>
                        </tbody>
                    </table>

                </div>
            </div>

            <RoundedButton buttonText="Save Changes" class="custom-button" :loading="loading" @click="updateItems"/>
        </div>
    </div>
</template>
<script>
import TextAreaInputField from "@/components/common/TextAreaInputField.vue";
import TextInputFieldVue from "@/components/common/TextInputField.vue";
import SingleSelectField from "@/components/common/SingleSelectField.vue";
import {onMounted, ref} from "vue";
import RoundedButton from "@/components/common/RoundedButton.vue";
import {API_BASE_URL} from "@/config";
import {getSchoolId, getValidToken} from "@/utils/helpers";
import {checkAndRefreshToken} from "@/utils/auth";

export default {
    props: {
        itemData: {
            type: Object,
            required: true
        }
    },
    components: {RoundedButton, SingleSelectField, TextInputFieldVue, TextAreaInputField},
    setup(props, {emit}) {
        const aim = ref(props.itemData.aim)
        const description = ref(props.itemData.description);
        const webLinks = ref(props.itemData.webLinks)
        const milestones = ref(props.itemData.milestones)
        const selectedFinalProduct = ref('')
        const finalProduct = ref(props.itemData.finalProduct)
        const finalProductSelectOptions = ref([])
        const aimError = ref('')
        const descriptionError = ref('')
        const milestoneError = ref('')
        const finalProductError = ref('')
        const questionsError = ref('')
        const finalProductSelectionError = ref('')
        const questions = ref(props.itemData.questions)
        const newQuestion = ref('')
        const loading = ref(false)

        setTimeout(() => {
            finalProductSelectOptions.value = [
                {label: "Presentation", value: 'Presentation'},
                {label: "Offering", value: 'Offering'},
                {label: "Product", value: 'Product'},
                {label: "Public Display", value: 'Public Display'},
            ]
        }, 500)

        onMounted(() => {
            setTimeout(() => {
                selectedFinalProduct.value = props.itemData.selectedFinalProduct
            }, 1000)
        })

        const addQuestion = () => {
            if (newQuestion.value.trim() !== '') {
                const newIndex = questions.value.length + 1; // Get the current length of the array as the new index

                questions.value.push({
                    text: newQuestion.value,
                    [`problem_${newIndex}`]: newQuestion.value,
                    'answer': ''
                });

                newQuestion.value = ''
            }
        }

        const deleteQuestion = (index) => {
            questions.value.splice(index, 1)
        }

        const validateIdeaPage = () => {
            let isValid = true

            aimError.value = ''
            descriptionError.value = ''
            milestoneError.value = ''
            finalProductSelectionError.value = ''
            finalProductError.value = ''
            questionsError.value = ''

            if (aim.value.length === 0) {
                aimError.value = 'Enter the project aim'
                isValid = false
            }

            if (description.value.length === 0) {
                descriptionError.value = 'Enter the project description'
                isValid = false
            }

            if (milestones.value.length === 0) {
                milestoneError.value = 'Enter the project milestone'
                isValid = false
            }

            if (selectedFinalProduct.value === null) {
                finalProductSelectionError.value = 'Select an option in final product'
                isValid = false
            }

            if (finalProduct.value.length === 0) {
                finalProductError.value = 'Enter the details of the final product'
                isValid = false
            }

            if (questions.value.length === 0) {
                questionsError.value = 'You need to add at least 1 question'
                isValid = false
            }

            return isValid
        }

        const updateItems = async () => {
            const registrationData = {
                project_aim: aim.value,
                description: description.value,
                weblinks: webLinks.value,
                milestones: milestones.value,
                final_product: {
                    key: selectedFinalProduct.value,
                    details: finalProduct.value
                },
                problem_to_solve: questions.value,
            }

            if (validateIdeaPage()) {
                try {
                    const schoolId = getSchoolId()
                    const apiUrl = `${API_BASE_URL}/school/${schoolId}/projects/${props.itemData.id}`
                    loading.value = true
                    const accessToken = getValidToken()

                    await checkAndRefreshToken()

                    const response = await fetch(apiUrl, {
                            method: "PUT",
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${accessToken}`,
                            },
                            body: JSON.stringify(registrationData)
                        }
                    );

                    if (response.ok) {
                        const result = await response.json();
                        if (result.code === 200) {
                            const msg = {
                                type: 'success',
                                message: 'Success! You have updated the idea details'
                            }
                            emit('closeFormModal', msg)
                        } else {
                            console.log("error", result.message);
                        }
                    } else {
                        const result = await response.json();
                        console.log("error", result.message);
                    }
                } catch (error) {
                    console.error("An error occured:", error);
                } finally {
                    loading.value = false
                }
            }
        }

        return {
            aim,
            description,
            webLinks,
            milestones,
            selectedFinalProduct,
            finalProduct,
            finalProductSelectOptions,
            aimError,
            descriptionError,
            milestoneError,
            finalProductSelectionError,
            finalProductError,
            questions,
            newQuestion,
            addQuestion,
            deleteQuestion,
            questionsError,
            loading,
            updateItems
        }
    }
}
</script>
<style scoped>

.table td {
    padding: 10px
}

.table-borderless thead,
.table-borderless td {
    border-bottom: 1px solid #f1f1f1;
}

.table-borderless thead {
    color: #9a9a9a;
}

.table-borderless thead {
    background-color: #f5f6fa;
}

.table-borderless th:first-child {
    border-top-left-radius: 10px;
}

.table-borderless th:last-child {
    border-top-right-radius: 10px;
}

.tableEmail {
    font-size: 11px;
    color: #9a9a9a;
}

td {
    vertical-align: middle !important;
}
</style>