<template>
    <!-- start page title -->
    <div class="row">
        <div class="col-12">
            <div class="page-title-box">
                <div class="page-title-right">
                    <RoundedButton buttonText="Add User" iconText="mdi-plus" @clicked="openFormModal"
                                   v-if="hasPermission('add_system_admin')"/>
                </div>
                <h4 class="page-title">Manage Users</h4>
            </div>
        </div>
    </div>

    <div class="row">
        <StatCards statCardText="Total Users" :statsCardNumber="numOfAdmins"/>
        <StatCards statCardText="Active Users" :statsCardNumber="numOfActiveAdmins"/>
        <StatCards statCardText="Deactivated Users" :statsCardNumber="numOfInactiveAdmins"/>
    </div>

    <div class="row mainContent">
        <div class="col-sm-12">
            <div class="card-box">
                <TableView :data="formattedUsersData" :headers="tableHeaders" :id="getIdForActions"
                           :detailsData="getDataForDetailsView" :loading="loading"
                           :searchStringFromParent.sync="searchStringFromChild" :showSearchField="true"
                           :viewAction="viewAction" :editAction="editAction"
                           :deactivateAction="openConfirmationDialog" :showActionsColumn="true"
                           :searchFields="searchFieldsData" :totalPages="totalPages" :currentPage.sync="currentPage"
                           :changePage="changePage"
                           :changeItemsPerPage="changeItemsPerPage" :itemsPerPage="itemsPerPage"
                           viewPermission="view_system_admin"
                           editPermission="update_system_admin" deactivatePermission="deactivate_system_admin"/>
            </div>
        </div>
    </div>

    <InfoModal :isModalOpen="isConfirmationDialogOpen" @closeModal="closeConfirmationDialog"
               :successImageURL="require('../../../assets/images/icons/success_icon.svg')"
               :errorImageURL="require('../../../assets/images/icons/error_icon.svg')" header="Hold on!"
               :paragraph="deactivateConfirmationParagraph"
               :buttonTxt="deactivateButtonText" :buttonAction="performAction" buttonColor="error"
               routerLinkText="No, Cancel Action" buttonLink="/"
               :linkAction="cancelAction" :loading="buttonLoader">
    </InfoModal>

    <FormModal :isFormModalOpen="isFormModalOpen" @closeFormModal="closeFormModal1" modalHeader="Add Admin">
        <CreateUser @closeFormModal="closeFormModal" @message="showFlashMessage"/>
    </FormModal>

    <FormModal :isFormModalOpen="isEditFormModalOpen" @closeFormModal="closeEditFormModal" modalHeader="Edit Admin">
        <EditUser @closeFormModal="closeEditFormModal" @message="showFlashMessage" :userData="userDataForEditing"/>
    </FormModal>


    <MessageSliderCopy :isVisible="displayMessage" :messageText="messageText" :messageType="messageType"/>

    <InfoModal :isModalOpen="isFlashModalOpen" @closeModal="closeFlashModal" :isSuccess="true" header="Admin Added"
               :paragraph="`You have successfully added '${adminFullName}'. You can view and manage this user's profile under 'Admin Users'.`"
               buttonTxt="Add Another Admin" routerLinkText="Manage Admin Users" :linkAction="createUserFlashLinkAction"
               :buttonAction="buttonAction">
    </InfoModal>
</template>
<script>
import {computed, onActivated, onMounted, ref, watch} from "vue";
import RoundedButton from "@/components/common/RoundedButton.vue";
import TableView from "@/components/common/TableView.vue";
import FormModal from "@/components/common/FormModal.vue";
import InfoModal from "@/components/common/InfoModals.vue";
import MessageSliderCopy from "@/components/common/MessageSliderCopy.vue";
import CreateUser from "@/components/layouts/GodAdmin/Users/CreateUser.vue";
import EditUser from "@/components/layouts/GodAdmin/Users/EditUser.vue";
import {flashMessage} from "@/utils/flashMessage.js";
import getUsers from '@/composables/getUsers'
import {useRouter} from 'vue-router';
import {API_BASE_URL} from "@/config.js";
import {capitalizeFirstLetter, getValidToken, hasPermission} from '@/utils/helpers'
import {checkAndRefreshToken} from '@/utils/auth';
import StatCards from "@/components/common/StatCards.vue";
import {useUserStore} from "@/stores/user";

export default {
    components: {
        RoundedButton,
        TableView,
        FormModal,
        MessageSliderCopy,
        CreateUser,
        EditUser,
        InfoModal,
        StatCards
    },
    emits: ['showLoader', 'show-loader', 'flashMessage', 'data-loaded'],
    setup(props, {emit}) {
        document.title = 'Manage Users'
        const {usersData, fetchUsersData, loading, formattedUsersData, usersPayloadData} = getUsers()
        const isFormModalOpen = ref(false);
        const isEditFormModalOpen = ref(false);
        const displayMessage = ref(false);
        const buttonLoader = ref(false);
        const messageText = ref("");
        const messageType = ref("");
        const newMessage = ref("");
        const showDetailsOrIcons = ref(false)
        const router = useRouter()
        const accessToken = getValidToken();
        const isConfirmationDialogOpen = ref(false);
        const userData = ref(null)
        const isFlashModalOpen = ref(false);
        const adminFullName = ref('')
        const currentPage = ref(1);
        const itemsPerPage = ref(10); // Default items per page
        const totalPages = ref(0)
        const numOfAdmins = ref(0)
        const numOfActiveAdmins = ref(0)
        const numOfInactiveAdmins = ref(0)


        const buttonAction = () => {
            isFlashModalOpen.value = false;
            openFormModal()
        }

        const openFlashModal = () => {
            isFlashModalOpen.value = true;
        };

        const closeFlashModal = async () => {
            isFlashModalOpen.value = false;
            await fetchUsersData(currentPage.value, itemsPerPage.value)
        };

        const createUserFlashLinkAction = () => {
            closeFlashModal()
        }

        const openFormModal = () => {
            isFormModalOpen.value = true;
        };

        const closeFormModal = (fullName) => {
            isFormModalOpen.value = false;

            adminFullName.value = fullName
            isFlashModalOpen.value = true
        };

        const closeFormModal1 = () => {
            isFormModalOpen.value = false;
            fetchUsersData(currentPage.value, itemsPerPage.value)
        }

        const openEditFormModal = () => {
            isEditFormModalOpen.value = true;
        };

        const closeEditFormModal = async (message) => {
            isEditFormModalOpen.value = false;
            await fetchUsersData(currentPage.value, itemsPerPage.value)
        };

        const searchStringFromChild = ref("");
        const searchFieldsData = ['user.name', 'user.email', 'contact', 'status', 'role']

        watch(usersData, () => {
            totalPages.value = usersPayloadData.value.total_pages

            const stats = usersPayloadData.value.results
            numOfAdmins.value = stats.num_of_admins
            numOfActiveAdmins.value = stats.num_of_active_admins
            numOfInactiveAdmins.value = stats.num_of_deactivated_admins
        })

        onMounted(() => {
            fetchUsersData(currentPage.value, itemsPerPage.value);
            emit('data-loaded')
        })

        onActivated(() => {
            fetchUsersData(currentPage.value, itemsPerPage.value);
        })

        const getIdForActions = computed(() => {
            return usersData.value.map((user) => user.user_id);
        });

        const getDataForDetailsView = computed(() => {
            return usersData.value
        })

        const viewAction = (item, id) => {
            localStorage.removeItem('detailsData')
            localStorage.setItem('detailsData', JSON.stringify(item))
            router.push(
                {
                    name: 'ShowUser',
                    params: {id: id}
                })
        }

        const userDataForEditing = ref(null)
        const editAction = (user, user_id) => {
            userData.value = user

            // Find the user to edit
            const userForEditing = usersData.value.find((r) => r.user_id === user_id);
            userDataForEditing.value = {
                firstName: userForEditing.first_name,
                lastName: userForEditing.last_name,
                gender: userForEditing.gender,
                msisdn: userForEditing.msisdn,
                email: userForEditing.email,
                country: userForEditing.country,
                state: userForEditing.state,
                address: userForEditing.residence,
                roleId: userForEditing.role_id,
                id: user_id
            }

            openEditFormModal()
        }

        const deactivateAction = async (id) => {
            // console.log('item id', id)
            const apiUrl = `${API_BASE_URL}/admin/delete-admin`
            try {
                await checkAndRefreshToken()

                buttonLoader.value = true
                const response = await fetch(apiUrl, {
                    method: 'DELETE',
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`
                    },
                    body: JSON.stringify({
                        admin_id: id,
                        reason: ''
                    })
                })

                if (response.ok) {
                    const result = await response.json()

                    if (result.code === 200) {
                        closeConfirmationDialog()

                        const msg = {
                            apiResponse: "User status updated",
                            type: "success"
                        }
                        showFlashMessage(msg)
                    } else {
                        const msg = {
                            apiResponse: result.message,
                            type: "success"
                        }
                        showFlashMessage(msg)
                        console.log('error', result.message)
                    }
                } else {
                    const result = await response.json();
                    console.log("error", result.message);
                }
            } catch (error) {
                console.error("An error occured:", error);
            } finally {
                buttonLoader.value = false
            }
        }

        const messageFromChild = ref("");
        const handleMessageFromChild = (message) => {
            messageFromChild.value = message
        };

        const showFlashMessage = (msg) => {
            flashMessage(
                msg.apiResponse,
                msg.type,
                messageText,
                messageType,
                displayMessage
            );
        };

        const tableHeaders = computed(() => {
            return [
                {label: "Name", key: "name"},
                {label: "Contact No", key: "contact"},
                {label: "Role", key: "role"},
                {label: "Added On", key: "date"},
                {label: "Acct Status", key: "status"}
            ];
        });

        const userId = ref('null')
        const userStatus = ref('')
        const deactivateConfirmationParagraph = ref('')
        const deactivateButtonText = ref('')

        const openConfirmationDialog = (item, id) => {
            userId.value = id

            userStatus.value = item.isDeactivated === false ? 'deactivate' : 'activate'
            deactivateConfirmationParagraph.value = `You are about to ${userStatus.value} this user's account. Are you sure you want to continue this action?.`
            deactivateButtonText.value = `Yes, ${capitalizeFirstLetter(userStatus.value)} User`
            isConfirmationDialogOpen.value = true;
        };

        const closeConfirmationDialog = () => {
            isConfirmationDialogOpen.value = false;
            fetchUsersData(currentPage.value, itemsPerPage.value)
        };

        const performAction = () => {
            deactivateAction(userId.value)
        }

        const cancelAction = () => {
            closeConfirmationDialog()
        }

        const changePage = (page) => {
            if (page >= 1 && page <= totalPages.value) {
                currentPage.value = page;

                fetchUsersData(currentPage.value, itemsPerPage.value)
            }
        };

        const changeItemsPerPage = (perPage) => {
            itemsPerPage.value = perPage;
            currentPage.value = 1; // Reset to the first page when changing items per page

            fetchUsersData(currentPage.value, perPage);
        };

        const userStore = useUserStore();
        const user = ref(userStore.user)
        const userPermissions = ref(user.value.permissions)
        const checkPermission = (permission) => hasPermission(userPermissions, permission)

        return {
            tableHeaders,
            usersData,
            totalPages,
            formattedUsersData,
            getIdForActions,
            loading,
            searchStringFromChild,
            searchFieldsData,
            isFormModalOpen,
            openFormModal,
            closeFormModal,
            closeFormModal1,
            handleMessageFromChild,
            showFlashMessage,
            displayMessage,
            messageText,
            messageType,
            newMessage,
            showDetailsOrIcons,
            viewAction,
            editAction,
            deactivateAction,
            InfoModal,
            isConfirmationDialogOpen,
            openConfirmationDialog,
            closeConfirmationDialog,
            performAction,
            cancelAction,
            buttonLoader,
            isEditFormModalOpen,
            openEditFormModal,
            closeEditFormModal,
            userData,
            userDataForEditing,
            isFlashModalOpen,
            openFlashModal,
            closeFlashModal,
            buttonAction,
            adminFullName,
            createUserFlashLinkAction,
            deactivateConfirmationParagraph,
            deactivateButtonText,
            getDataForDetailsView,
            changePage,
            changeItemsPerPage,
            currentPage,
            itemsPerPage,
            numOfAdmins,
            numOfActiveAdmins,
            numOfInactiveAdmins,
            hasPermission: checkPermission
        };
    },
}

</script>
<style></style>
