<template>
    <!-- start page title -->
    <div class="row">
        <div class="col-12">
            <div class="page-title-box">
                <h4 class="page-title">All Components</h4>
            </div>
        </div>
    </div>

    <p>Table</p>
    <div class="row">
        <div class="col-sm-12">
            <div class="card-box">
                <div class="row">
                    <div class="col-lg-6">
                        <PhoneNumberFieldInput :config="{
                            class: 'mb-20',
                        }" labelText="Phone Number" v-model="msisdn"/>

                        <TextInputField :config="{
                            class: 'mb-20',
                            placeholder: 'Enter First Name',
                            inputFieldType: 'text',
                        }" labelText="First Name" errorType="nameError" v-model="first_name"/>

                        <SimpleDropdown labelText="Gender" :options="genderSelectOptions" v-model="selectedGender"
                                        class='mb-20'/>

                        <TextAreaInputField :config="{
                            class: 'mb-30',
                            placeholder: 'Enter Address',
                            inputFieldType: 'address',
                        }" labelText="Residential Address" errorType="addressError" v-model="address"/>

                        <SelectDropdown labelText="Select State" :options="stateSelectOptions" v-model="selectedState"
                                        class='mb-20'/>

                    </div>
                </div>
            </div>
        </div>
    </div>

    <p>Buttons</p>
    <div class="row">
        <div class="col-sm-12">
            <div class="card-box buttons">
                <RegularButton buttonText="Edit Profile" iconText="mdi-cards-heart" class="custom-button mr-2"
                               @clicked="buttonClicked"/>
                <RoundedButton buttonText="Save" iconText="mdi-content-save" class="custom-button mr-2"
                               @clicked="buttonClicked"/>

                <div>
                    <RegularButtonBlock buttonText="Click this wonderful button" class="custom-button mr-2"
                                        @clicked="buttonClicked"/>
                </div>
            </div>
        </div>
    </div>

    <p>Form Fields</p>
    <div class="row">
        <div class="col-sm-12">
            <div class="card-box">
                <ImageUploadInput/>
                <FormFields/>
            </div>
        </div>
    </div>

    <p>Switch</p>
    <div class="row">
        <div class="col-sm-12">
            <div class="card-box">
                <Switch switchText="Account Active"/>
            </div>
        </div>
    </div>

    <p>Stat Cards</p>
    <div class="row">
        <div class="col-sm-12">
            <div class="card-box">
                <StatCards/>
            </div>
        </div>
    </div>

    <p>Right Side Page</p>
    <div class="row">
        <div class="col-sm-12">
            <div class="card-box">
                <RegularButton buttonText="Open Mini Page" class="custom-button" @clicked="openMiniPage"/>

                <Overlay :isOpen="isOpen" @closeOverlay="closeMiniPage">
                    <RightSideMiniPage :isOpen="isOpen" @closeMiniPage="closeMiniPage">
                        <SchoolDetail/>
                    </RightSideMiniPage>
                </Overlay>
            </div>
        </div>
    </div>

    <p>Modal</p>
    <div class="row">
        <div class="col-sm-12">
            <div class="card-box">
                <RegularButton buttonText="Open Modal" class="custom-button mr-2" @clicked="openModal"/>

                <RegularButton buttonText="Open Form Modal" class="custom-button mr-2" @clicked="openFormModal"/>

                <RegularButton buttonText="Open Confirmation Dialog" class="custom-button"
                               @clicked="openConfirmationDialog"/>

                <FormModal :isFormModalOpen="isFormModalOpen" @closeFormModal="closeFormModal"/>

                <InfoModal :isModalOpen="isModalOpen" @closeModal="closeModal" :isSuccess="true"
                           :successImageURL="require('../../assets/images/icons/success_icon.svg')"
                           :errorImageURL="require('../../assets/images/icons/error_icon.svg')" header="Admin Added"
                           paragraph="You have successfully added 'Irikefe Anthony'. You can view and manage this user's profile under 'School Admin'."
                           buttonTxt="Add Another Admin" routerLinkText="Manage Admin Users" buttonLink="/">
                </InfoModal>

                <InfoModal :isModalOpen="isConfirmationDialogOpen" @closeModal="closeConfirmationDialog"
                           :successImageURL="require('../../assets/images/icons/success_icon.svg')"
                           :errorImageURL="require('../../assets/images/icons/error_icon.svg')" header="Hold on!"
                           paragraph="You are about to reset this user password. This action cannot be undone once completed. Are you sure you want to continue this action?."
                           buttonTxt="Yes, Reset Password" routerLinkText="No, Cancel Action" buttonLink="/">
                </InfoModal>
            </div>
        </div>
    </div>

    <p>Circular Loader</p>
    <div class="row">
        <div class="col-sm-12">
            <div class="card-box">
                <RegularButton buttonText="Open Loader" class="custom-button" @clicked="openLoaderModal"/>
                <SingleLoader :isLoaderModalOpen="isLoaderModalOpen" @closeLoaderModal="closeLoaderModal"/>
            </div>
        </div>
    </div>

    <p>Linear Loader</p>
    <div class="row">
        <div class="col-sm-12">
            <div class="card-box loader-container">
                <RegularButton buttonText="Start Loader" class="custom-button mr-3" @clicked="startLoading"/>

                <RegularButton buttonText="Stop Loader" class="custom-button" @clicked="stopLoading"/>
                <InfiniteLinearLoader v-if="loading"/>
            </div>
        </div>
    </div>

    <p>Success and Error Messages</p>
    <div class="row">
        <div class="col-sm-12">
            <div class="card-box loader-container">
                <RegularButton buttonText="Show Success Message" class="custom-button mr-3" @clicked="
                    showMessage('This is a success message.', 'success')
                    "/>

                <RegularButton buttonText="Show Error Message" class="custom-button mr-3"
                               @clicked="showMessage('You\'ve got an error.', 'error')"/>

                <MessageSliderCopy :isVisible="displayMessage" :messageText="messageText" :messageType="messageType"/>
            </div>
        </div>
    </div>

    <p>User Avatar Box</p>
    <div class="row">
        <div class="col-sm-12">
            <div class="card-box loader-container">
                <UserAvatarBox />

                <UserAvatarBox2 />
            </div>
        </div>
    </div>
</template>

<script>
// import TableView from "@/components/common/TableView.vue";
import RegularButton from "@/components/common/RegularButton.vue";
import RegularButtonBlock from "@/components/common/RegularButtonBlock.vue";
import RoundedButton from "@/components/common/RoundedButton.vue";
import FormFields from "@/components/common/FormFields.vue";
import ImageUploadInput from "@/components/common/ImageUploadInput.vue";
import Switch from "@/components/common/Switch.vue";
import StatCards from "@/components/common/StatCards.vue";
import RightSideMiniPage from "@/components/common/RightSideMiniPage.vue";
import Overlay from "@/components/common/Overlay.vue";
import InfoModal from "@/components/common/InfoModals.vue";
import SingleLoader from "@/components/common/SingleLoader.vue";
import InfiniteLinearLoader from "@/components/common/InfiniteLinearLoader.vue";
import MessageSlider from "@/components/common/MessageSlider.vue";
import MessageSliderCopy from "@/components/common/MessageSliderCopy.vue";
import PhoneNumberFieldInput from "@/components/common/PhoneNumberFieldInput.vue";
import TextInputField from "@/components/common/TextInputField.vue";
import SimpleDropdown from "@/components/common/SimpleDropdown.vue";
import TextAreaInputField from "@/components/common/TextAreaInputField.vue";
import SelectDropdown from "@/components/common/SelectDropdown.vue";
import FormModal from "@/components/common/FormModal.vue";
import {ref} from "vue";
import SchoolDetail from "@/components/layouts/GodAdmin/Schools/SchoolDetail.vue";
import {flashMessage} from "@/utils/flashMessage.js";
import UserAvatarBox from "@/components/common/UserAvatarBox.vue";
import UserAvatarBox2 from "@/components/common/UserAvatarBox2.vue";

export default {
    components: {
        UserAvatarBox2,
        UserAvatarBox,
        // TableView,
        RegularButton,
        RoundedButton,
        RegularButtonBlock,
        FormFields,
        Switch,
        StatCards,
        RightSideMiniPage,
        Overlay,
        SchoolDetail,
        InfoModal,
        SingleLoader,
        InfiniteLinearLoader,
        MessageSlider,
        MessageSliderCopy,
        FormModal,
        ImageUploadInput,
        PhoneNumberFieldInput,
        TextInputField,
        SimpleDropdown,
        TextAreaInputField,
        SelectDropdown
    },
    setup() {
        const isOpen = ref(false);
        const isLoaderOpen = ref(false);
        const isModalOpen = ref(false);
        const isLoaderModalOpen = ref(false);
        const isFormModalOpen = ref(false);
        const isConfirmationDialogOpen = ref(false);
        const msisdn = ref('')
        const address = ref('')
        const first_name = ref('')
        const selectedGender = ref('')
        const selectedState = ref('')
        const loading = ref(false);

        const stateSelectOptions = [
            {label: "Alabama", value: 'Alabama'},
            {label: "Ohio", value: 'Ohio'},
            {label: "Oklahoma", value: 'Oklahoma'},
            {label: "Oregon", value: 'Oregon'},
            {label: "Pennsylvania", value: 'Pennsylvania'},
            {label: "Tennessee", value: 'Tennessee'},
            {label: "Texas", value: 'Texas'},
            {label: "Vermont", value: 'Vermont'},
            {label: "Wyoming", value: 'Wyoming'},
        ]

        const startLoading = () => {
            loading.value = true;
        };

        const stopLoading = () => {
            loading.value = false;
        };

        const buttonClicked = () => {
            console.log("button has been Clicked");
        };

        const openMiniPage = () => {
            isOpen.value = true;
        };

        const closeMiniPage = () => {
            isOpen.value = false;
        };

        const openModal = () => {
            isModalOpen.value = true;
        };

        const closeModal = () => {
            isModalOpen.value = false;
        };

        const openLoaderModal = () => {
            isLoaderModalOpen.value = true;
        };

        const closeLoaderModal = () => {
            isLoaderModalOpen.value = false;
        };

        const openFormModal = () => {
            isFormModalOpen.value = true;
        };

        const closeFormModal = () => {
            isFormModalOpen.value = false;
        };

        const openConfirmationDialog = () => {
            isConfirmationDialogOpen.value = true;
        };

        const closeConfirmationDialog = () => {
            isConfirmationDialogOpen.value = false;
        };

        // New Flash Message Code
        // ================================================
        // ================================================
        // ================================================
        // ================================================
        const displayMessage = ref(false);
        const messageText = ref("");
        const messageType = ref("");

        const showMessage = (text, type) => {
            flashMessage(text, type, messageText, messageType, displayMessage);
        };

        const genderSelectOptions = [
            {label: "Select Gender", value: ''},
            {label: "Male", value: 'Male'},
            {label: "Female", value: 'Female'}
        ]

        return {
            buttonClicked,
            isOpen,
            openMiniPage,
            closeMiniPage,
            isModalOpen,
            openModal,
            closeModal,
            isLoaderOpen,
            isLoaderModalOpen,
            openLoaderModal,
            closeLoaderModal,
            loading,
            startLoading,
            stopLoading,
            showMessage,
            displayMessage,
            messageText,
            messageType,
            isFormModalOpen,
            openFormModal,
            closeFormModal,
            isConfirmationDialogOpen,
            openConfirmationDialog,
            closeConfirmationDialog,
            genderSelectOptions,
            msisdn,
            address,
            first_name,
            selectedGender,
            stateSelectOptions,
            selectedState
        };
    },
};
</script>

<style scoped>
p {
    font-weight: bolder;
}

.buttons {
    display: flex;
    align-content: space-between;
}

.loader-container {
    position: relative;
}
</style>