<template>
    <form @submit.prevent="saveNewItem">
        <div>
            <div class="row mb-30">
                <div class="col-lg-6">
                    <TextInputField :config="{
                    class: 'form-control-gray',
                    placeholder: 'Enter Name',
                    inputFieldType: 'text',
                }" labelText="Name" v-model="name" :isRequired="true"/>
                </div>

                <div class="col-lg-6">
                    <TextInputField :config="{
                    class: 'form-control-gray',
                    placeholder: 'Enter Surname',
                    inputFieldType: 'text',
                }" labelText="Surame" v-model="surname" :isRequired="true"/>
                </div>
            </div>

            <div class="row mb-30">
                <div class="col-lg-6">
                    <TextInputField :config="{
                        class: 'form-control-gray',
                        placeholder: 'Enter Email',
                        inputFieldType: 'email',
                    }" labelText="Email" v-model="email" :isRequired="true"/>
                </div>

                <div class="col-lg-6">
                    <TextInputField :config="{
                    class: 'form-control-gray',
                    placeholder: 'Enter Contact Number',
                    inputFieldType: 'tel',
                }" labelText="Contact Number" v-model="contact_telephone" :isRequired="true"/>
                </div>
            </div>

            <div class="row mb-30">
                <div class="col-12">
                    <TextAreaInputField :config="{
                        class: 'form-control-gray',
                        placeholder: 'Enter Address',
                        inputFieldType: 'text',
                    }" labelText="Registered Address" v-model="registered_address" :isRequired="true"/>
                </div>
            </div>

            <div class="row mb-30">
                <div class="col-lg-6">
                    <TextInputField :config="{
                        class: 'form-control-gray',
                        placeholder: 'Enter Area of Expertise',
                        inputFieldType: 'text',
                    }" labelText="Area of Expertise" v-model="area_of_expertise" :isRequired="true"/>
                </div>

                <div class="col-lg-6">
                    <TextInputField :config="{
                        class: 'form-control-gray',
                        placeholder: 'Enter Website',
                        inputFieldType: 'text',
                    }" labelText="Website" v-model="website" :isRequired="true"/>
                </div>
            </div>

            <div class="row mb-50">
                <div class="col-12">
                    <TextInputField :config="{
                        class: 'form-control-gray',
                        placeholder: 'Enter Company Name',
                        inputFieldType: 'text',
                    }" labelText="Company Name" v-model="company_name" :isRequired="true"/>
                </div>
            </div>

            <RoundedButton buttonText="Submit" class="custom-button" buttonType="submit" :loading="buttonLoading"/>

        </div>

    </form>
</template>
<script>
import TextInputField from "@/components/common/TextInputField.vue";
import SimpleDropdown from "@/components/common/SimpleDropdown.vue";
import TextAreaInputField from "@/components/common/TextAreaInputField.vue";
import RoundedButton from "@/components/common/RoundedButton.vue";
import {ref} from "vue";
import {capitalizeFirstLetter, getSchoolId, getValidToken} from '@/utils/helpers'
import {API_BASE_URL} from "@/config";
import {checkAndRefreshToken} from "@/utils/auth";


export default {
    components: {
        TextInputField,
        SimpleDropdown,
        TextAreaInputField,
        RoundedButton,
    },
    setup(props, {emit}) {
        const buttonLoading = ref(false)
        const name = ref('')
        const surname = ref('')
        const contact_telephone = ref('')
        const email = ref('')
        const website = ref('')
        const registered_address = ref('')
        const area_of_expertise = ref('')
        const company_name = ref('')

        // OTHERS
        const showLoaders = () => {
            buttonLoading.value = true;
        };

        const hideLoaders = () => {
            buttonLoading.value = false;
        };

        const schoolId = getSchoolId()
        const apiUrl = `${API_BASE_URL}/sme/${schoolId}`

        const saveNewItem = async () => {
            const newItem = {
                name: name.value,
                surname: surname.value,
                email: email.value,
                contact_telephone: contact_telephone.value,
                registered_address: registered_address.value,
                area_of_expertise: area_of_expertise.value,
                company_name: company_name.value,
                website: website.value,
                nin_certificate: true
            }

            // console.log('newItem', newItem)

            try {
                showLoaders()
                await checkAndRefreshToken()
                const accessToken = getValidToken()

                const response = await fetch(apiUrl, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    },
                    body: JSON.stringify(newItem)
                })


                if (response.ok) {
                    const result = await response.json();

                    if (result.code === 201) {
                        const itemFullName = capitalizeFirstLetter(newItem.name, newItem.surname)
                        emit("closeFormModal", itemFullName);
                    } else {
                        console.log("error", result.message);
                    }
                } else {
                    const result = await response.json();
                    console.log("error", result.message);
                }
            } catch (error) {
                console.error("An error occured:", error);
            } finally {
                hideLoaders();
            }
        }

        return {
            buttonLoading,
            saveNewItem,
            name,
            contact_telephone,
            email,
            website,
            registered_address,
            surname,
            area_of_expertise,
            company_name,
        }
    }
}
</script>
<style scoped>

</style>