<template>
    <div class="row">
        <div class="col-lg-3 col-md-3 col-sm-12">
            <div class="card-box-small">
                <div class="card-content">
                    <div class="header-content text-center">
                        <div v-if="schoolDetails">
                            <img
                                class="mb-20 d-block mx-auto rounded-circle img-thumbnail"
                                :src="schoolDetails.logo"
                                alt="school logo"
                                v-if="schoolDetails.logo"
                                @error="handleSchoolImageError"
                            />

                            <AvatarForInitials :avatarText="getUserInitialsForName(schoolName)" iconColor="info" class="avatar-md-150 mb-30 m-auto" v-else/>
                        </div>

                        <h4 class="mb-0">{{ schoolName }}</h4>
                        <p class="mb-0">{{ schoolEmail }}</p>
                    </div>

                    <div class="middle-content mb-100">
                        <div class="details-content">
                            <img src="../../../assets/images/icons/user_icon.svg" class="data-img" alt="Mobile Icon"/>

                            <div class="detail-content-group">
                                <p class="data-title">Projects</p>
                                <p class="data-text">{{ numOfItems }}</p>
                            </div>
                        </div>

                        <div class="details-content">
                            <img src="../../../assets/images/icons/activate_icon.svg" class="data-img"
                                 alt="Mobile Icon"/>

                            <div class="detail-content-group">
                                <p class="data-title">Active</p>
                                <p class="data-text">{{ numOfActiveItems }}</p>
                            </div>
                        </div>

                        <div class="details-content">
                            <img src="../../../assets/images/icons/deactivate_icon2.svg" class="data-img"
                                 alt="Mobile Icon"/>

                            <div class="detail-content-group">
                                <p class="data-title">Deactivated</p>
                                <p class="data-text">{{ numOfInactiveItems }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="buttons-content" v-if="hasPermission('add_projects')">
                        <RegularButtonBlock buttonText="Add Project" iconText="mdi-plus"
                                            class="custom-button mb-3" @clicked="navigateToCreatePage"/>

                        <RegularButtonBlock buttonText="Add project from template" iconText="mdi-plus"
                                            class="custom-button gray" @clicked="openSearchModal"/>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-lg-9 col-md-3 col-sm-12">
            <div class="card-box">
                <div class="row">
                    <div class="col-12">
                        <h4 class="bold mb-30">Projects</h4>

                        <TableView :data="formattedProjectsData" :headers="tableHeaders"
                                   :id="getIdForActions" :showSearchField="true"
                                   :detailsData="getDataForDetailsView" :loading="loading"
                                   :searchStringFromParent.sync="searchStringFromChild"
                                   :viewAction="viewAction" :isAPISearchNeeded="false"
                                   :deactivateAction="openConfirmationDialog"
                                   :searchFields="searchFieldsData" :totalPages="totalPages"
                                   :currentPage.sync="currentPage" @searchWord="searchForItem"
                                   :changePage="changePage" :showActionsColumn="true"
                                   :changeItemsPerPage="changeItemsPerPage" :itemsPerPage="itemsPerPage"
                                   viewPermission="view_projects" deactivatePermission="deactivate_projects"/>
                    </div>
                </div>
            </div>
        </div>


    </div>


    <InfoModal :isModalOpen="isConfirmationDialogOpen" @closeModal="closeConfirmationDialog"
               :successImageURL="require('../../../assets/images/icons/success_icon.svg')"
               :errorImageURL="require('../../../assets/images/icons/error_icon.svg')" header="Hold on!"
               :paragraph="deactivateConfirmationParagraph"
               :buttonTxt="deactivateButtonText" :buttonAction="performAction" buttonColor="error"
               routerLinkText="No, Cancel Action" buttonLink="/"
               :linkAction="cancelAction" :loading="buttonLoader">
    </InfoModal>

    <InfoModal :isModalOpen="isFlashModalOpen" @closeModal="closeFlashModal" :isSuccess="true" header="Project Added"
               :paragraph="`You have successfully added '${fullName}'. You can view and manage this project's details under 'Projects'.`"
               buttonTxt="Add Another Project" routerLinkText="Manage Projects" :linkAction="createItemFlashLinkAction"
               :buttonAction="buttonAction">
    </InfoModal>

    <FormModal :isFormModalOpen="isSearchModalOpen" @closeFormModal="closeSearchModal" modalHeader="Search for Project">
        <SearchProject @closeFormModal="closeSearchModal" :itemData="itemDataForEditing"/>
    </FormModal>

</template>

<script>
import RegularButtonBlock from "@/components/common/RegularButtonBlock.vue";
import {computed, onActivated, onMounted, ref, watch} from "vue";
import {
    capitalizeFirstLetter,
    capitalizeFirstLetterInSentence,
    formatDateTime,
    getSchoolId,
    getUserInitialsForName,
    getValidToken,
    hasPermission,
    truncateText
} from '@/utils/helpers'
import {checkAndRefreshToken} from '@/utils/auth';
import {API_BASE_URL} from "@/config.js";
import FormModal from "@/components/common/FormModal.vue";
import {useRouter} from "vue-router";
import TableView from "@/components/common/TableView.vue";
import RoundedButton from "@/components/common/RoundedButton.vue";
import InfoModal from "@/components/common/InfoModals.vue";
import getProjects from "@/composables/Schools/getProjects";
import AvatarForInitials from "@/components/common/AvatarForInitials.vue";
import SearchProject from "@/views/School_Admin/Projects/SearchProject.vue";
import {useUserStore} from "@/stores/user";


export default {
    components: {
        SearchProject,
        AvatarForInitials,
        RoundedButton,
        TableView,
        FormModal,
        InfoModal,
        RegularButtonBlock
    },
    props: {
        id: String
    },
    emits: ['showLoader', 'show-loader', 'flashMessage', 'data-loaded'],
    setup(props, {emit}) {
        document.title = "Manage Projects"
        const {
            projectsPayloadData,
            fetchProjectsData,
            loading,
            formattedProjectsData,
            projectsData
        } = getProjects()
        const isFormModalOpen = ref(false);
        const isEditFormModalOpen = ref(false);
        const isSearchModalOpen = ref(false);
        const displayMessage = ref(false);
        const buttonLoader = ref(false);
        const messageText = ref("");
        const messageType = ref("");
        const newMessage = ref("");
        const showDetailsOrIcons = ref(false)
        const router = useRouter()
        const accessToken = getValidToken();
        const isConfirmationDialogOpen = ref(false);
        const isFlashModalOpen = ref(false);
        const fullName = ref('')
        const currentPage = ref(1);
        const itemsPerPage = ref(10); // Default items per page
        const totalPages = ref(0)
        const itemData = ref(null)
        const numOfItems = ref(0)
        const numOfActiveItems = ref(0)
        const numOfInactiveItems = ref(0)
        const schoolName = ref('')
        const schoolEmail = ref('')
        const projectList = ref([])
        const schoolId = getSchoolId()


        const openSearchModal = () => {
            isSearchModalOpen.value = true
        }

        const closeSearchModal = () => {
            isSearchModalOpen.value = false
        }

        const buttonAction = () => {
            isFlashModalOpen.value = false;
            openFormModal()
        }

        const openFlashModal = () => {
            isFlashModalOpen.value = true;
        };

        const closeFlashModal = async () => {
            isFlashModalOpen.value = false;
            await fetchProjectsData(currentPage.value, itemsPerPage.value)
        };

        const createItemFlashLinkAction = () => {
            closeFlashModal()
        }

        const openFormModal = () => {
            isFormModalOpen.value = true;
        };

        const closeFormModal = (name) => {
            isFormModalOpen.value = false;

            fullName.value = name
            isFlashModalOpen.value = true
        };

        const closeFormModal1 = () => {
            isFormModalOpen.value = false;

            fetchProjectsData(currentPage.value, itemsPerPage.value)
        }

        const navigateToCreatePage =() => {
            router.push({name: 'SetupProject'})
        }

        const openEditFormModal = () => {
            isEditFormModalOpen.value = true;
        };

        const closeEditFormModal = async (msg) => {
            isEditFormModalOpen.value = false;

            if (msg) {
                flashAlertMessage(msg.type, msg.apiResponse)
            }
            await fetchProjectsData(currentPage.value, itemsPerPage.value)
        };

        const searchStringFromChild = ref("");
        const searchFieldsData = ['user.name', 'description', 'status']

        watch(projectsData, () => {
            totalPages.value = projectsPayloadData.value.total_pages

            const stats = projectsPayloadData.value.results
            numOfItems.value = stats.num_of_projects
            numOfActiveItems.value = stats.num_of_active_projects
            numOfInactiveItems.value = stats.num_of_deactivated_projects
        })


        const schoolDetails = ref(null)
        onMounted(() => {
            fetchProjectsData(currentPage.value, itemsPerPage.value);
            emit('data-loaded')

            schoolDetails.value = JSON.parse(localStorage.getItem('schoolDetails'))
            schoolName.value = schoolDetails.value.name
            schoolEmail.value = schoolDetails.value.email
        })

        onActivated(() => {
            fetchProjectsData(currentPage.value, itemsPerPage.value);
        })

        const getIdForActions = computed(() => {
            return projectsData.value.map((item) => item.id);
        });

        const getDataForDetailsView = computed(() => {
            return projectsData.value
        })

        const viewAction = (item, id) => {
            router.push(
                {
                    name: 'ShowProject',
                    params: {id: id}
                })
        }

        const itemDataForEditing = ref(null)
        const editAction = (item, item_id) => {
            itemData.value = item

            // Find the item to edit
            const itemForEditing = projectsData.value.find((r) => r.id === item_id);
            itemDataForEditing.value = {
                name: itemForEditing.name,
                description: itemForEditing.description,
                id: item_id
            }

            openEditFormModal()
        }

        const deactivateAction = async (id) => {
            const schoolId = getSchoolId()
            const apiUrl = `${API_BASE_URL}/school/${schoolId}/projects/${id}/deactivate`

            try {
                await checkAndRefreshToken()

                buttonLoader.value = true
                const response = await fetch(apiUrl, {
                    method: 'PUT',
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`
                    },
                    body: JSON.stringify({
                        reason: ''
                    })
                })

                if (response.ok) {
                    const result = await response.json()

                    if (result.code === 200) {
                        closeConfirmationDialog()

                        flashAlertMessage('success', "Project status updated")
                    } else {
                        flashAlertMessage('error', result.message)
                        console.log('error', result.message)
                    }
                } else {
                    const result = await response.json();

                    flashAlertMessage('error', result.message)
                    console.log("error", result.message);
                }
            } catch (error) {
                flashAlertMessage('error', result.message)
                console.error("An error occured:", error);
            } finally {
                buttonLoader.value = false
            }
        }

        const messageFromChild = ref("");
        const handleMessageFromChild = (message) => {
            messageFromChild.value = message
        };

        const tableHeaders = computed(() => {
            return [
                {label: "Name", key: "name"},
                {label: "Description", key: "description"},
                {label: "Added On", key: "date"},
                {label: "Visibility", key: "visibility"},
                {label: "Status", key: "status"}
            ];
        });

        const itemId = ref('null')
        const itemStatus = ref('')
        const deactivateConfirmationParagraph = ref('')
        const deactivateButtonText = ref('')

        const openConfirmationDialog = (item, id) => {
            itemId.value = id

            itemStatus.value = item.isDeactivated === false ? 'deactivate' : 'activate'
            deactivateConfirmationParagraph.value = `You are about to ${itemStatus.value} this Project. Are you sure you want to continue this action?.`
            deactivateButtonText.value = `Yes, ${capitalizeFirstLetter(itemStatus.value)} Project`
            isConfirmationDialogOpen.value = true;
        };

        const closeConfirmationDialog = () => {
            isConfirmationDialogOpen.value = false;
            fetchProjectsData(currentPage.value, itemsPerPage.value)
        };

        const performAction = () => {
            deactivateAction(itemId.value)
        }

        const cancelAction = () => {
            closeConfirmationDialog()
        }

        const changePage = (page) => {
            if (page >= 1 && page <= totalPages.value) {
                currentPage.value = page;

                fetchProjectsData(currentPage.value, itemsPerPage.value)
            }
        };

        const changeItemsPerPage = (perPage) => {
            itemsPerPage.value = perPage;
            currentPage.value = 1; // Reset to the first page when changing items per page

            fetchProjectsData(currentPage.value, perPage);
        };

        const flashAlertMessage = (alertType, alertMessage) => {
            emit('flash-message', {
                alertType: alertType,
                alertMessage: alertMessage
            })
        }

        const deleteProject = async (item, item_id) => {
            const apiUrl = `${API_BASE_URL}/school/${schoolId}/projects/${item_id}`

            try {
                await checkAndRefreshToken()

                buttonLoader.value = true
                const response = await fetch(apiUrl, {
                    method: 'DELETE',
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`
                    },
                    body: JSON.stringify({
                        reason: ''
                    })
                })

                if (response.ok) {
                    const result = await response.json()

                    if (result.code === 200) {
                        flashAlertMessage('success', "Project Deleted")
                        await fetchProjectsData(currentPage.value, itemsPerPage.value);
                    } else {
                        flashAlertMessage('error', result.message)
                        console.log('error', result.message)
                    }
                } else {
                    const result = await response.json();

                    flashAlertMessage('error', result.message)
                    console.log("error", result.message);
                }
            } catch (error) {
                flashAlertMessage('error', error)
                console.error("An error occured:", error);
            } finally {
                buttonLoader.value = false
            }
        }

        const searchForItem = async (word) => {
            const apiUrl = `${API_BASE_URL}/school/${schoolId}/projects/search?query=${word}`
            try {
                loading.value = true
                await checkAndRefreshToken()

                const response = await fetch(apiUrl, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                })

                if (response.ok) {
                    const result = await response.json()

                    if (result.code === 200) {
                        projectList.value = result.data.map((project) => {

                            // Format the created at
                            const {date, time} = formatDateTime(project.created_at);

                            const formattedProject = {
                                user: {
                                    name: capitalizeFirstLetter(project.name),
                                    initials: getUserInitialsForName(project.name),
                                    avatarColor: 'primary'
                                },
                                description: truncateText(capitalizeFirstLetterInSentence(project.description), 40),
                                date,
                                visibility: project.is_private ? 'Private' : 'Public',
                                status: project.isDeactivated ? 'Deactivated' : 'Active',
                            };

                            return {
                                ...formattedProject,
                            };
                        });

                        totalPages.value = projectList.value.length
                    } else {
                        const msg = {
                            apiResponse: result.message,
                            type: "success"
                        }
                        showFlashMessage(msg)
                        console.log('error', result.message)
                    }
                } else {
                    const result = await response.json();
                    console.log("error", result.message);
                }
            } catch (error) {
                console.error("An error occured:", error);
            } finally {
                loading.value = false
            }
        }


        const userStore = useUserStore();
        const user = ref(userStore.user)
        const userPermissions = ref(user.value.permissions)
        const checkPermission = (permission) => hasPermission(userPermissions, permission)

        const handleSchoolImageError = () => {
            schoolDetails.value.logo = null
        }

        return {
            schoolDetails,
            handleSchoolImageError,
            tableHeaders,
            projectsData,
            navigateToCreatePage,
            totalPages,
            formattedProjectsData,
            getIdForActions,
            loading,
            searchStringFromChild,
            searchFieldsData,
            isFormModalOpen,
            openFormModal,
            closeFormModal,
            closeFormModal1,
            handleMessageFromChild,
            displayMessage,
            itemDataForEditing,
            messageText,
            messageType,
            newMessage,
            showDetailsOrIcons,
            viewAction,
            editAction,
            deactivateAction,
            InfoModal,
            isConfirmationDialogOpen,
            openConfirmationDialog,
            closeConfirmationDialog,
            performAction,
            cancelAction,
            buttonLoader,
            isEditFormModalOpen,
            openEditFormModal,
            closeEditFormModal,
            itemData,
            isFlashModalOpen,
            openFlashModal,
            closeFlashModal,
            buttonAction,
            fullName,
            createItemFlashLinkAction,
            deactivateConfirmationParagraph,
            deactivateButtonText,
            getDataForDetailsView,
            changePage,
            changeItemsPerPage,
            currentPage,
            itemsPerPage,
            numOfItems,
            numOfActiveItems,
            numOfInactiveItems,
            schoolName,
            schoolEmail,
            getUserInitialsForName,
            deleteProject,
            openSearchModal,
            closeSearchModal,
            isSearchModalOpen,
            projectList,
            searchForItem,
            hasPermission: checkPermission
        };

    }

}
</script>

<style scoped>

.data-text {
    color: #0D44B2;
}

.detail-content-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.detail-content-group > p {
    margin: 0;
}

.data-img {
    margin-top: 2px !important;
}

.header-content {
    padding: 30px 0 20px 0;
    position: relative;
    border-bottom: 1px solid #e9eaf3;
}

p {
    color: #9a9a9a;
    font-size: 16px;
}

.details-content {
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding: 30px 30px 0 30px;
}

.details-content:last-child {
    padding-bottom: 30px;
    border-bottom: 1px solid #e9eaf3;
}

.details-content > img {
    margin-right: 15px;
    margin-top: 5px;
}

.details-content .details > p {
    margin-bottom: 0;
}

.logo-text > img {
    margin-right: 15px;
    margin-top: 2px;
}

.logo-text > p {
    color: #333333;
}

.detail-group > p,
.data-title {
    color: #333;
}


.buttons-content {
    padding: 0 15px 40px 15px;
}

.card-box-small {
    padding: 0 15px 0 15px;
    background-color: #fff;
    box-shadow: 0 0.75rem 6rem rgba(56, 65, 74, .03);
    margin-bottom: 24px;
    border-radius: 12px !important;
}

.rounded-circle {
    height: 150px !important;
    width: 150px !important;
    object-fit: contain;
}
</style>