<template>
    <!-- start page title -->
    <div class="row mb-20">
        <div class="col-12">
            <div class="page-title-box">
                <div class="page-title-right">
                    <RoundedButton buttonText="Add Role" iconText="mdi-plus" @clicked="openFormModal"/>
                </div>
                <h4 class="page-title">Manage Permissions</h4>

            </div>
        </div>
    </div>

    <div class="row mainContent">
        <div class="col-12">
            <div class="card-box">
                <div class="table-responsive">
                    <table class="table table-borderless">
                        <thead>
                        <tr>
                            <th>
                                Permissions
                                <div class="spinner-border text-success m-0" v-if="permissionUpdateLoader" role="status"></div>
                            </th>
                            <th v-for="role in tableHeaders" :key="role.key" class="text-center">
                                {{ role.label }}
                            </th>
                        </tr>
                        </thead>

                        <tbody>
                        <tr v-if="loading" class="loader-row">
                            <td :colspan="tableHeadersLength + 2">
                                <div class="linear-loader">
                                    <div class="train"></div>
                                </div>
                            </td>
                        </tr>

                        <template v-for="groupName in permissionGroups" :key="groupName">
                            <tr class="bg-blue text-white">
                                <td>{{ capitalizeFirstLetter(groupName) }}</td>
                                <td v-for="role in tableHeaders" :key="role.key"></td>
                            </tr>


                            <tr v-for="permission in permissionsData[groupName]" :key="permission.name">
                                <td>{{ capitalizeFirstLetter(permission.name) }}</td>

                                <td v-for="role in tableHeaders" :key="role.key" class="text-center">
                                    <img :src="checkPermissionInRole(role, permission.id)" alt="Checked"
                                         @click="toggleCheckedStatus(role, permission.id)" height="20" width="20"
                                         class="permission-image">
                                </td>
                            </tr>
                        </template>
                        </tbody>
                    </table>

                    <div class="spinner-border text-success m-0" v-if="permissionUpdateLoader" role="status"></div>
                </div>
            </div>
        </div>
    </div>

    <FormModal :isFormModalOpen="isFormModalOpen" @closeFormModal="closeFormModal" modalHeader="Create Role">
        <CreateRole @closeFormModal="closeFormModal" @message="showFlashMessage"/>
    </FormModal>


    <MessageSliderCopy :isVisible="displayMessage" :messageText="messageText" :messageType="messageType"/>
</template>
<script>
import {computed, onMounted, ref, watch} from "vue";
import RoundedButton from "@/components/common/RoundedButton.vue";
import TableView from "@/components/common/TableView.vue";
import FormModal from "@/components/common/FormModal.vue";
import MessageSliderCopy from "@/components/common/MessageSliderCopy.vue";
import CreateRole from "@/components/layouts/GodAdmin/Roles/CreateRole.vue";
import {flashMessage} from "@/utils/flashMessage.js";
import {API_BASE_URL} from "@/config.js";
import {capitalizeFirstLetter, getValidToken} from '@/utils/helpers'
import {checkAndRefreshToken} from '@/utils/auth';
import getRoles from '@/composables/getRoles'

export default {
    components: {
        RoundedButton,
        TableView,
        FormModal,
        MessageSliderCopy,
        CreateRole,
    },
    emits: ['showLoader', 'show-loader', 'flashMessage', 'data-loaded'],
    setup(props, {emit}) {
        document.title = 'Manage Permissions'
        const {fetchRolesData, rolesData, loading} = getRoles()
        const isFormModalOpen = ref(false);
        const displayMessage = ref(false);
        const buttonLoader = ref(false);
        const messageText = ref("");
        const messageType = ref("");
        const newMessage = ref("");
        const accessToken = getValidToken();
        const permissionsData = ref([])
        const tableHeaders = ref(null)
        const tableHeadersLength = ref(0)
        const permissionGroups = ref([])
        const permissionsInGroup = ref(null)
        const permissionUpdateLoader = ref(false)


        onMounted(() => {
            fetchRolesData()
            fetchPermissionsData()

            emit('data-loaded')
        })

        watch(rolesData, () => {
            tableHeaders.value = rolesData.value.map(role => ({
                label: capitalizeFirstLetter(role.name),
                key: role.id,
                permissions: role.permissions
            }))

            tableHeadersLength.value = tableHeaders.value.length

        })

        watch(permissionsData, () => {
            permissionGroups.value = Object.keys(permissionsData.value)
        })

        const getPermissionsInGroup = (groupName) => {
            permissionsInGroup.value = permissionsData.value[groupName].map((permission) => permission.name);
        }

        const closeFormModal1 = () => {
            isFormModalOpen.value = false;
        }

        const openFormModal = () => {
            isFormModalOpen.value = true;
        };

        const closeFormModal = () => {
            isFormModalOpen.value = false;

            fetchRolesData()
            fetchPermissionsData()
        };

        const fetchPermissionsData = async () => {
            try {
                await checkAndRefreshToken()

                if (!accessToken) {
                    console.error("Access token not available.");
                    return;
                }

                permissionUpdateLoader.value = true;
                const apiUrlWithParams = `${API_BASE_URL}/role-permission/permissions`

                const response = await fetch(apiUrlWithParams, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`, // Include the access token in the headers
                    },
                });

                if (response.ok) {
                    const result = await response.json();

                    if (result.code === 200) {
                        permissionsData.value = result.data

                    } else {
                        console.log("error", result.message);
                    }
                } else {
                    console.log("response", response);
                    console.error("Error fetching data from the API");
                }
            } catch (error) {
                console.error("An error occured:", error);
            } finally {
                permissionUpdateLoader.value = false;
            }
        };

        const searchStringFromChild = ref("");
        const searchFieldsData = ['name',]

        const getIdForActions = computed(() => {
            return schoolsData.value.map((item) => item.user_id);
        });

        const messageFromChild = ref("");
        const handleMessageFromChild = (message) => {
            messageFromChild.value = message
        };

        const showFlashMessage = (msg) => {
            flashMessage(
                msg.apiResponse,
                msg.type,
                messageText,
                messageType,
                displayMessage
            );
        };

        const toggleCheckedStatus = async (role, permissionId) => {
            const apiUrl = `${API_BASE_URL}/role-permission/permission?role_id=${role.key}&permission_id=${permissionId}`

            try {
                await checkAndRefreshToken()

                permissionUpdateLoader.value = true
                const response = await fetch(apiUrl, {
                    method: role.permissions.find(p => p.id === permissionId) ? 'DELETE' : 'PUT',
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`
                    }
                })

                if (response.ok) {
                    const result = await response.json()

                    if (result.code === 200) {
                        const msg = {
                            apiResponse: `Permission for ${role.label} status updated`,
                            type: "success"
                        }

                        showFlashMessage(msg)
                        await fetchRolesData()
                        await fetchPermissionsData()
                    } else {
                        const msg = {
                            apiResponse: result.message,
                            type: "error"
                        }
                        showFlashMessage(msg)
                        console.log('error', result.message)
                    }
                } else {
                    const result = await response.json();
                    console.log("error", result.message);
                }
            } catch (error) {
                console.error("An error occured:", error);
            } finally {
                permissionUpdateLoader.value = false
            }
        }

        const checkedImage = require('../../../assets/images/icons/checked_icon.svg')
        const unCheckedImage = require('../../../assets/images/icons/uncheck_icon.svg')
        const checkPermissionInRole = (role, permissionId) => {
            // Find the permission by ID in the permissions array
            const permission = role.permissions.find(p => p.id === permissionId);

            // Check if the permission is in the role
            if (permission /* && your condition for checking if the permission is in the role */) {
                return checkedImage; // Display your image for checked status
            } else {
                return unCheckedImage; // Display your image for not checked status
            }
        }

        return {
            tableHeaders,
            permissionsData,
            getIdForActions,
            searchStringFromChild,
            searchFieldsData,
            isFormModalOpen,
            openFormModal,
            closeFormModal,
            handleMessageFromChild,
            showFlashMessage,
            displayMessage,
            messageText,
            messageType,
            newMessage,
            buttonLoader,
            closeFormModal1,
            loading,
            tableHeadersLength,
            permissionGroups,
            capitalizeFirstLetter,
            permissionsInGroup,
            getPermissionsInGroup,
            toggleCheckedStatus,
            checkPermissionInRole,
            permissionUpdateLoader
        };
    }
}
</script>
<style scoped>
.mr-16 {
    margin-right: 16px;
}

h4 {
    font-weight: 700;
    color: #333;
    margin-bottom: 20px;
}

.table_search {
    border-radius: 10px;
    padding: 25px 25px 25px 0;
    background-color: #f5f6fa !important;
    border: none !important;
}

.table_search::placeholder {
    color: #333;
    font-weight: 700;
    font-size: 16px;
}

.cardbox-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.input-group {
    width: 40%;
    min-width: 250px;
}

.input-group-text {
    background-color: #e9ecef;
}

.input-group > .input-group-append > .input-group-text {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border: none;
    background-color: #f5f6fa;
}

.input-group > .input-group-prepend > .input-group-text {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border: none;
    background-color: #f5f6fa;
}

#search {
    height: 20px;
    width: 20px;
    color: #333333;
    margin-right: 5px;
}

#menu_icon {
    height: 25px;
    width: 25px;
    color: #333333;
}

.table {
    width: 100%;
    white-space: nowrap;
    color: #333333 !important;
}

.table td {
    padding: 10px
}

.table-borderless thead,
.table-borderless td {
    border-bottom: 1px solid #f1f1f1;
}

.table-borderless thead {
    color: #9a9a9a;
}

.table-borderless thead {
    background-color: #f5f6fa;
}

.table-borderless th:first-child {
    border-top-left-radius: 10px;
}

.table-borderless th:last-child {
    border-top-right-radius: 10px;
}

.tableEmail {
    font-size: 11px;
    color: #9a9a9a;
}

td {
    vertical-align: middle !important;
}

.active {
    color: #38ca89;
}

.inactive {
    color: #f24e1e;
}

.avatar-md {
    width: 40px;
    height: 40px;
}

.avatar-title {
    font-size: 16px !important;
}

.detailsBtn {
    padding: 0 12px;
    color: #2f5bcf !important;
    background-color: #fff !important;
    height: 32px;
    min-width: 57px;
    border: 1.5px solid #dfe1e5 !important;
    align-items: center;
    border-radius: 4px;
    display: inline-flex;
    flex: 0 0 auto;
    font-weight: 600;
    letter-spacing: 0;
    justify-content: center;
    outline: 0;
    position: relative;
    text-decoration: none;
    text-indent: 0;
    text-transform: none;
    transition-duration: .28s;
    transition-property: box-shadow, transform, opacity;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
}

/* Custom select */
.custom-select-wrapper {
    position: relative;
    display: block;
    width: max-content;
}

.custom-select-wrapper select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 110px;
    padding: 10px;
    border-radius: 12px;
    background-color: #fff;
    border: 1px solid #f1f1f1;
    color: #333333;
}

.linear-loader {
    height: 3px;
    /* Adjust the height as needed */
    background-color: #89a6f3;
    /* Adjust the color as needed */
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    /* animation: moveRight 1s linear infinite; Adjust the animation speed as needed */
    //z-index: 9999;
    /* Ensure it's on top of other elements */
}

.train {
    width: 900px;
    /* Adjust the train's width */
    height: 7px;
    /* Should match the height of the loader */
    background-color: #2f5bcf;
    /* Adjust the color as needed */
    position: absolute;
    animation: moveRight 1s linear infinite;
    /* Adjust the animation speed as needed */
    top: 0;
    left: 0;
}

.bg-blue {
    background-color: #2f5bcf !important;
    font-weight: bolder;
}

@keyframes moveRight {
    0% {
        left: -50px;
        /* Start off the screen to the left */
    }

    100% {
        left: 100%;
        /* Move to the right of the screen */
    }
}

tr.loader-row td {
    padding: 0 !important;
}

.actions > a {
    cursor: pointer;
}

.no-data {
    text-align: center;
    font-weight: 900;
}

.name-container {
    display: flex;
    align-items: center;
}

.spinner-border {
    width: 15px;
    height: 15px;
}

.permission-image {
    cursor: pointer;
}
</style>