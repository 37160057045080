<template>
    <div class="col-12">
        <div class="card">
            <div class="card-body">

                <h4 class="header-title mb-3">This is the Project Creation Wizard, complete each step to create a
                    project</h4>

                <div id="progressbarwizard">
                    <ul class="nav nav-pills bg-light nav-justified form-wizard-header mb-3">
                        <li class="nav-item" v-for="(tab, index) in tabs" :key="index">
                            <a href="#" @click="selectTab(index)" class="nav-link rounded-0 pt-2 pb-2"
                               :class="{ 'active': activeTab === index }">
                                    <span class="d-none d-sm-inline bold">
                                        {{ tab }}
                                    </span>
                            </a>
                        </li>
                    </ul>

                    <div class="tab-content b-0 mb-0 pt-0">

                        <div id="bar" class="progress mb-3" style="height: 7px;">
                            <div
                                class="bar progress-bar progress-bar-striped progress-bar-animated bg-success"
                                :style="{ width: progressBarWidth }"></div>
                        </div>

                        <div class="row">
                            <div class="col-lg-7 col-md-9 col-sm-10 col-sm-12 mx-auto">
                                <div class="alert alert-success alert-dismissible fade show mb-30 bold"
                                     role="alert">
                                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                    Fill in the basic information about the project, required fields are marked with
                                    asterisk (*), you cannot proceed without filling them!
                                </div>
                            </div>
                        </div>

                        <div v-show="activeTab === 0">
                            <div class="row">
                                <div class="col-lg-7 col-md-7 col-sm-12 mx-auto">
                                    <div class="row mb-30">
                                        <div class="col-lg-6 project_name">
                                            <TextInputFieldVue :config="{
                                                    class: 'form-control-gray',
                                                    placeholder: 'Enter name of project',
                                                    inputFieldType: 'text',
                                                }" labelText="Project Name" v-model="name" :isRequired="true"
                                                               :errorMessage="nameError"/>
                                        </div>

                                        <div class="col-lg-6">
                                            <SingleSelectField labelText="Academic Year"
                                                               :options="academicYearSelectOptions"
                                                               v-model="selectedAcademicYear"
                                                               selectId="academicYearOptions"
                                                               :loading="yearLoader"
                                                               :isRequired="true" :errorMessage="academicYearError"
                                                               :selectedItem="selectedAcademicYear"/>
                                        </div>
                                    </div>

                                    <div class="row mb-30">
                                        <div class="col-lg-6 project_term">
                                            <SingleSelectField labelText="Term"
                                                               :options="termsSelectOptions"
                                                               v-model="selectedTerm"
                                                               selectId="termOptions"
                                                               :loading="termLoader"
                                                               :isRequired="true" :errorMessage="termError"
                                                               :selectedItem="selectedTerm"/>
                                        </div>

                                        <div class="col-lg-6">
                                            <SingleSelectField labelText="Project Type"
                                                               :options="projectTypeSelectOptions"
                                                               v-model="selectedProjectType"
                                                               selectId="projectTypeOptions"
                                                               :isRequired="true" :errorMessage="projectTypeError"
                                                               :selectedItem="selectedProjectType"/>
                                        </div>
                                    </div>

                                    <div class="row mb-30">
                                        <div class="col-lg-6 lead-colearner">
                                            <SingleSelectField labelText="Lead Co-Learner"
                                                               :options="colearnerSelectOptions"
                                                               v-model="selectedLeadColearner"
                                                               :loading="leadColearnerLoader"
                                                               selectId="colearnerSelectOptions"
                                                               :isRequired="true"
                                                               :errorMessage="leadColearnerError"
                                                               :selectedItem="selectedLeadColearner"/>
                                        </div>

                                        <div class="col-lg-6">
                                            <MultipleSelectDropdown labelText="Supporting Co-Learner(s)"
                                                                    :options="colearnerSelectOptions"
                                                                    v-model="selectedSupportingColearners"
                                                                    :loading="leadColearnerLoader"
                                                                    selectId="colearnerSelectOptionsM"
                                                                    :errorMessage="supportingColearnerError"
                                                                    :selectedItem="selectedSupportingColearners"/>
                                        </div>
                                    </div>

                                    <div class="row mb-30">
                                        <div class="col-lg-6 learning-group">
                                            <MultipleSelectDropdown labelText="Learning Group"
                                                                    :options="learningGroupSelectOptions"
                                                                    v-model="selectedLearningGroup"
                                                                    :loading="learningGroupDropdownLoader"
                                                                    selectId="learningGroupOptions"
                                                                    :errorMessage="supportingColearnerError"
                                                                    :selectedItem="selectedLearningGroup"/>
                                        </div>

                                        <div class="col-lg-6">
                                            <MultipleSelectDropdown labelText="Subject Matter Expert"
                                                                    :options="smeSelectOptions"
                                                                    v-model="selectedSME"
                                                                    :loading="smeLoader" selectId="smeSelectOptions"
                                                                    :selectedItem="selectedSME"/>
                                        </div>
                                    </div>

                                    <div class="row mb-30">
                                        <div class="col-lg-6 project-duration">
                                            <SingleSelectField
                                                labelText="Project Duration"
                                                :options="projectDurationSelectOptions"
                                                v-model="selectedDuration"
                                                selectId="projectDurationSelectOptions"
                                                :isRequired="true" :errorMessage="durationError"
                                                :selectedItem="selectedDuration"/>
                                        </div>

                                        <div class="col-lg-6">
                                            <MultipleSelectDropdown
                                                labelText="Keywords"
                                                :options="projectKeywordSelectOptions"
                                                v-model="selectedKeywords"
                                                :loading="keyWordsLoader"
                                                selectId="projectKeywordSelectOptions"
                                                :isRequired="true"
                                                :errorMessage="keywordsError"
                                                :selectedItem="selectedKeywords"/>
                                        </div>
                                    </div>

                                    <div class="row mb-30">
                                        <div class="col-lg-6 students">
                                            <MultipleSelectDropdown
                                                labelText="Add Students"
                                                :options="studentsSelectOptions"
                                                v-model="selectedStudents"
                                                :loading="studentsLoader"
                                                :errorMessage="studentsError"
                                                selectId="studentsSelectOptions"
                                                :isRequired="true"
                                                :selectedItem="selectedStudents"/>
                                        </div>

                                        <div class="col-lg-6">
                                            <SingleSelectField
                                                labelText="Project Curriculum"
                                                :options="curriculumOptions"
                                                v-model="selectedCurriculum"
                                                selectId="curriculumOptions"
                                                :loading="curriculumLoader"
                                                :selectedItem="selectedCurriculum"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-show="activeTab === 1">
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-sm-12 mx-auto">
                                    <div class="row mb-30">
                                        <div class="col-12">
                                            <TextAreaInputField :config="{
                                                    class: 'form-control-gray',
                                                    placeholder: 'Enter Aim',
                                                    inputFieldType: 'text',
                                                }" labelText="Project Aim" :errorMessage="aimError" v-model="aim"
                                                                :isRequired="true"/>
                                        </div>
                                    </div>

                                    <div class="row mb-30">
                                        <div class="col-12">
                                            <TextAreaInputField :config="{
                                                    class: 'form-control-gray',
                                                    placeholder: 'Enter Description',
                                                    inputFieldType: 'text',
                                                }" labelText="Project Description" :errorMessage="descriptionError"
                                                                v-model="description"
                                                                :isRequired="true"/>
                                        </div>
                                    </div>

                                    <div class="row mb-30">
                                        <div class="col-12">
                                            <TextInputFieldVue :config="{
                                                    class: 'form-control-gray',
                                                    placeholder: 'Enter links',
                                                    inputFieldType: 'text',
                                                }" labelText="Web Links" v-model="webLinks"/>
                                        </div>
                                    </div>

                                    <div class="row mb-30">
                                        <div class="col-12">
                                            <TextAreaInputField :config="{
                                                    class: 'form-control-gray',
                                                    placeholder: 'Enter milestones',
                                                    inputFieldType: 'text',
                                                }" labelText="Key Milestones" :errorMessage="milestoneError"
                                                                v-model="milestones"
                                                                :isRequired="true"/>
                                        </div>
                                    </div>

                                    <div class="row mb-30">
                                        <div class="col-12">
                                            <SingleSelectField labelText="Final Product"
                                                               :options="finalProductSelectOptions"
                                                               v-model="selectedFinalProduct"
                                                               selectId="finalProductSelectOptions"
                                                               :isRequired="true"
                                                               :selectedItem="selectedFinalProduct"/>
                                        </div>
                                    </div>

                                    <div class="row mb-30">
                                        <div class="col-12">
                                            <TextAreaInputField :config="{
                                                    class: 'form-control-gray',
                                                    placeholder: 'Enter product details',
                                                    inputFieldType: 'text',
                                                }" labelText="Product Details" v-model="finalProduct" :isRequired="true"
                                                                :errorMessage="finalProductError"/>
                                        </div>
                                    </div>

                                    <div class="row mb-30">
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label class="label">
                                                    Guiding Questions
                                                    <span class="required">*</span>
                                                </label>

                                                <div class="input-group">
                                                    <input class="form-control form-control-gray"
                                                           placeholder="Question/Answer" type="text"
                                                           v-model="newQuestion"/>

                                                    <div class="input-group-append">
                                                        <button class="btn btn-dark waves-effect waves-light"
                                                                @click="addQuestion">
                                                            Click to Add
                                                        </button>
                                                    </div>
                                                </div>

                                                <div class="invalid-feedback" v-if="activityCountError">
                                                    {{ activityCountError }}
                                                </div>
                                            </div>


                                            <table class="table table-borderless mt-20"
                                                   v-if="questions.length !== 0">
                                                <thead>
                                                <tr>
                                                    <th class="text-center">S/N</th>
                                                    <th>Question</th>
                                                    <th class="text-center">Action</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr v-for="(question, index) in questions" :key="index">
                                                    <td class="text-center">{{ index + 1 }}</td>
                                                    <td>{{ question.text }}</td>
                                                    <td class="text-center">
                                                        <a href="#" @click.prevent="deleteQuestion(index)">
                                                            <img class="mr-2"
                                                                 src="@/assets/images/icons/trash_icon.svg"
                                                                 alt="Delete Icon"/>
                                                        </a>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-show="activeTab === 2">
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-sm-12 mx-auto">
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <SingleSelectField labelText="Select Goal Category"
                                                               :options="goalCategorySelectOptions"
                                                               v-model="selectedGoalCategory"
                                                               class="mb-3"
                                                               selectId="goalCategorySelectOptionsId"
                                                               :isRequired="true"
                                                               :selectedItem="selectedGoalCategory"/>

                                            <RegularButton buttonText="Add Learning Goal"
                                                           class="btn btn-sm btn-outline-dark"
                                                           color="yellow"
                                                           @click="addLearningGoal"/>
                                        </div>

                                        <div class="col-lg-6">
                                            <MultipleSelectDropdown labelText="Learning Goal(s)"
                                                                    :options="filteredLearningGoals"
                                                                    v-model="selectedLearningGoals"
                                                                    selectId="learningGoal"
                                                                    :isRequired="true"
                                                                    :errorMessage="learningGoalError"
                                                                    :selectedItem="selectedLearningGoals"/>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-12">
                                            <table class="table table-borderless mt-20"
                                                   v-if="learningGoals.length !== 0">
                                                <thead>
                                                <tr>
                                                    <th class="text-center">S/N</th>
                                                    <th>Goal Category</th>
                                                    <th>Learning Goals</th>
                                                    <th class="text-center">Action</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr v-for="(item, index) in learningGoals" :key="index">
                                                    <td class="text-center">{{ index + 1 }}</td>
                                                    <td>{{ Object.keys(item)[0] }}</td>

                                                    <td>
                                                            <span v-for="(goal) in item[Object.keys(item)[0]]"
                                                                  :key="goal"
                                                                  class="badge badge-blue mr-1">{{ goal }}</span>
                                                    </td>

                                                    <td class="text-center">
                                                        <a href="#" @click.prevent="deleteLearningGoal(index)">
                                                            <img class="mr-2"
                                                                 src="@/assets/images/icons/trash_icon.svg"
                                                                 alt="Delete Icon"/>
                                                        </a>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-show="activeTab === 3">
                            <div class="row" v-if="activityCountError">
                                <div class="col-lg-7 col-md-7 col-sm-12 mx-auto">
                                    <div class="alert alert-danger alert-dismissible fade show mb-2 bold"
                                         role="alert">
                                        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                        {{ activityCountError }}
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-7 col-md-7 col-sm-12 mx-auto">
                                    <div class="row mb-30">
                                        <div class="col-12">
                                            <TextInputFieldVue :config="{
                                                    class: 'form-control-gray',
                                                    placeholder: 'Enter Activity Name',
                                                    inputFieldType: 'text',
                                                }" labelText="Name" v-model="activity_name" :isRequired="true"
                                                               :errorMessage="activityNameError"/>
                                        </div>
                                    </div>

                                    <div class="row mb-30">
                                        <div class="col-lg-6">
                                            <TextInputFieldVue :config="{
                                                    class: 'form-control-gray',
                                                    placeholder: 'Select Start Date',
                                                    inputFieldType: 'date',
                                                }" labelText="Start Date" v-model="start_date" :isRequired="true"
                                                               :errorMessage="activityStartDateError"/>
                                        </div>

                                        <div class="col-lg-6">
                                            <TextInputFieldVue :config="{
                                                    class: 'form-control-gray',
                                                    placeholder: 'Select End Date',
                                                    inputFieldType: 'date',
                                                }" labelText="End Date" v-model="end_date" :isRequired="true"
                                                               :errorMessage="activityEndDateError"/>
                                        </div>
                                    </div>

                                    <div class="row mb-30">
                                        <div class="col-12">
                                            <TextAreaInputField :config="{
                                                    class: 'form-control-gray',
                                                    placeholder: 'Enter Description',
                                                    inputFieldType: 'text',
                                                }" labelText="Description" :errorMessage="activityDescriptionError"
                                                                v-model="activity_description"
                                                                :isRequired="true"/>
                                        </div>
                                    </div>

                                    <div class="row mb-30">
                                        <div class="col-12">
                                            <TextAreaInputField :config="{
                                                    class: 'form-control-gray',
                                                    placeholder: 'Enter Learning Objectives',
                                                    inputFieldType: 'text',
                                                }" labelText="Learning Objectives" :errorMessage="objectivesError"
                                                                v-model="learning_objectives"
                                                                :isRequired="true"/>
                                        </div>
                                    </div>

                                    <div class="row mb-30">
                                        <div class="col-12">
                                            <TextAreaInputField :config="{
                                                    class: 'form-control-gray',
                                                    placeholder: 'Enter Resources',
                                                    inputFieldType: 'text',
                                                }" labelText="Resources"
                                                                v-model="resources"/>
                                        </div>
                                    </div>

                                    <div class="row mb-30">
                                        <div class="col-12">
                                            <TextInputFieldVue :config="{
                                                    class: 'form-control-gray',
                                                    placeholder: 'Weblinks',
                                                    inputFieldType: 'text',
                                                }" labelText="Supporting Weblinks" v-model="weblink"/>
                                        </div>
                                    </div>

                                    <div class="row mb-30">
                                        <div class="col-12">
                                            <TextAreaInputField :config="{
                                                    class: 'form-control-gray',
                                                    placeholder: 'Enter Text',
                                                    inputFieldType: 'text',
                                                }" labelText="Ways to Extend/Expand"
                                                                v-model="ways_to_expand"/>
                                        </div>
                                    </div>


                                    <RegularButtonBlock buttonText="Add Activity"
                                                        class="custom-button"
                                                        @click="addActivity"/>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-7 col-md-7 col-sm-12 mx-auto">
                                    <div class="table-responsive">
                                        <table class="table table-borderless mt-20" v-if="activities.length !== 0">
                                            <thead>
                                            <tr>
                                                <th class="text-center">S/N</th>
                                                <th>Name</th>
                                                <th>Start Date</th>
                                                <th>End Date</th>
                                                <th>Description</th>
                                                <th>Learning Objectives</th>
                                                <th>Resources</th>
                                                <th>Weblinks</th>
                                                <th>Media</th>
                                                <th>Ways to Expand</th>
                                                <th class="text-center">Action</th>
                                            </tr>
                                            </thead>

                                            <tbody>
                                            <tr v-for="(activity, index) in activities" :key="index">
                                                <td class="text-center">{{ index + 1 }}</td>
                                                <td class="wrap-text">{{ activity.name }}</td>
                                                <td>{{ activity.start_date }}</td>
                                                <td>{{ activity.finish_date }}</td>
                                                <td class="wrap-text">{{ activity.description }}</td>
                                                <td class="wrap-text">{{ activity.learning_objectives }}</td>
                                                <td class="wrap-text">{{ activity.resources }}</td>
                                                <td class="wrap-text">{{ activity.supporting_weblinks }}</td>
                                                <td class="wrap-text">{{ activity.supporting_media }}</td>
                                                <td class="wrap-text">{{ activity.ways_to_extend }}</td>
                                                <td class="text-center">
                                                    <a href="#" @click.prevent="deleteActivity(index)">
                                                        <img class="mr-2" src="@/assets/images/icons/trash_icon.svg"
                                                             alt="Delete Icon"/>
                                                    </a>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div v-show="activeTab === 4">
                            <div class="row mb-30">
                                <div class="col-lg-6 col-md-6 col-sm-12 mx-auto">

                                    <form @submit.prevent="submitForm">
                                        <div class="row" v-if="checklistData.length === 0">
                                            <div class="col-12 text-center">
                                                <h4 class="bold">There seems to be an error while loading the
                                                    questions.</h4>
                                            </div>
                                        </div>

                                        <div class="table-responsive">
                                            <table class="table table-hover table-borderless">
                                                <thead>
                                                <tr>
                                                    <th>S/N</th>
                                                    <th>Question</th>
                                                    <th>Options</th>
                                                </tr>
                                                </thead>

                                                <tbody>
                                                <tr v-if="checklistLoader" class="loader-row">
                                                    <td :colspan="3">
                                                        <div class="linear-loader">
                                                            <div class="train"></div>
                                                        </div>
                                                    </td>
                                                </tr>

                                                <tr v-for="(question, index) in checklistData" :key="index">
                                                    <td class="text-center">{{ index + 1 }}</td>
                                                    <td class="text-wrap">
                                                        {{ capitalizeFirstLetterInSentence(question.question) }}
                                                    </td>
                                                    <td>
                                                        <input type="radio"
                                                               value="correct"
                                                               :id="`input_yes_${index}`"
                                                               :name="`question_yes_${index}`"
                                                               v-model="question.answer"
                                                               class="mr-1">
                                                        <label :for="`input_yes_${index}`"
                                                               class="mr-2 mb-0">Correct</label>

                                                        <input type="radio"
                                                               v-model="question.answer"
                                                               value="incorrect"
                                                               :id="`input_no_${index}`"
                                                               :name="`question_no_${index}`"
                                                               class="mr-1">
                                                        <label :for="`input_no_${index}`"
                                                               class="mr-2 mb-0">Incorrect</label>

                                                        <input type="radio"
                                                               v-model="question.answer"
                                                               value="not_sure"
                                                               :id="`input_not_sure_${index}`"
                                                               :name="`question_not_sure_${index}`"
                                                               class="mr-1">
                                                        <label :for="`input_not_sure_${index}`">Not Sure</label>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>

                        <!-- Buttons-->
                        <ul class="list-inline mb-0 wizard mt-30">
                            <li class="previous list-inline-item">
                                <RegularButton buttonText="Previous Page" class="custom-button"
                                               @click="prevPage" :disabled="activeTab === 0"
                                               :class="activeTab === 0 ? 'disabled' : 'blue'"/>
                            </li>

                            <li class="next list-inline-item float-right">
                                <RegularButton :buttonText="activeTab === pages - 1 ? 'Submit' : 'Next'"
                                               class="custom-button"
                                               @click="nextPage" :loading="submitButtonLoading"/>
                            </li>

                            <li class="next list-inline-item float-right mr-2">
                                <RegularButton buttonText="Save Draft"
                                               class="custom-button error"
                                               @click="saveDraft" :loading="submitButtonLoading"/>
                            </li>
                        </ul>

                    </div> <!-- tab-content -->
                </div> <!-- end #progressbarwizard-->

            </div> <!-- end card-body -->
        </div> <!-- end card-->
    </div> <!-- end col -->
</template>
<script>
import {onMounted, ref, watch} from "vue";
import TextInputFieldVue from "@/components/common/TextInputField.vue";
import SimpleDropdown from "@/components/common/SimpleDropdown.vue";
import TextAreaInputField from "@/components/common/TextAreaInputField.vue";
import MultipleSelectDropdown from "@/components/common/MultipleSelectDropdown.vue";
import getLearningGroups from "@/composables/Schools/getLearningGroups";
import getSchoolColearners from "@/composables/Schools/getSchoolColearners";
import getSMEs from "@/composables/Schools/getSMEs";
import {capitalizeFirstLetter, capitalizeFirstLetterInSentence, getSchoolId, getValidToken} from "@/utils/helpers";
import RoundedButton from "@/components/common/RoundedButton.vue";
import RegularButton from "@/components/common/RegularButton.vue";
import RegularButtonBlock from "@/components/common/RegularButtonBlock.vue";
import getKeywords from "@/composables/getKeywords";
import getSchoolStudents from "@/composables/Schools/getSchoolStudents";
import {API_BASE_URL} from "@/config";
import {checkAndRefreshToken} from "@/utils/auth";
import {useRouter} from "vue-router";
import SingleSelectField from "@/components/common/SingleSelectField.vue";
import getAcademicYear from "@/composables/Schools/getAcademicYear";
import getTerms from "@/composables/Schools/getTerms";
import getChecklist from "@/composables/getProjectChecklist";
import getCurriculum from "@/composables/getCurriculum";

export default {
    methods: {capitalizeFirstLetterInSentence},
    props: {},
    components: {
        SingleSelectField,
        RegularButtonBlock,
        RegularButton,
        RoundedButton,
        TextInputFieldVue,
        SimpleDropdown,
        TextAreaInputField,
        MultipleSelectDropdown,
    },
    setup: function (props, {emit}) {
        const tabs = ref([
            'Step 1: Registration',
            'Step 2: Idea',
            'Step 3: Learning Goals',
            'Step 4: Calendar',
            'Step 5: Checklist'
        ])

        const router = useRouter()
        const pages = tabs.value.length
        const activeTab = ref(0)
        const {learningGroupsData, fetchLearningGroupsData, loading: learningGroupDropdownLoader} = getLearningGroups()
        const {
            fetchColearnersData,
            loading: leadColearnerLoader,
            colearnersUserData
        } = getSchoolColearners()
        const {fetchKeywordsData, keywordsData, loading: keyWordsLoader} = getKeywords()
        const {smeData, fetchSMEData, loading: smeLoader} = getSMEs()
        const {fetchStudentsData, loading: studentsLoader, studentsData} = getSchoolStudents()
        const {academicYearData, fetchAcademicYearData, loading: yearLoader} = getAcademicYear()
        const {termsData, fetchTerms, loading: termLoader} = getTerms()
        const {fetchChecklistData, checklistData, loading: checklistLoader} = getChecklist()
        const {curriculumsData, fetchCurriculums, curriculumLoader} = getCurriculum()
        const loading = ref(false);
        const buttonLoading = ref(false);
        const submitButtonLoading = ref(false);
        const loadingSpinner = ref(false);
        const name = ref("");
        const description = ref("");
        const learningGroupSelectOptions = ref([])
        const selectedLearningGroup = ref([])
        const academic_year = ref('')
        const term = ref('')
        const aim = ref('')
        const webLinks = ref('')
        const milestones = ref('')
        const colearnerSelectOptions = ref([])
        const selectedLeadColearner = ref('')
        const selectedSupportingColearners = ref([])
        const selectedProjectType = ref('')
        const selectedSME = ref([])
        const smeSelectOptions = ref([])
        const projectKeywordSelectOptions = ref([])
        const studentsSelectOptions = ref([])
        const selectedDuration = ref('')
        const selectedKeywords = ref([])
        const selectedStudents = ref([])
        const selectedFinalProduct = ref('')
        const newQuestion = ref('')
        const selectedGoalCategory = ref(null)
        const selectedLearningGoals = ref([])
        const questions = ref([])
        const activities = ref([])
        const activity_name = ref('')
        const start_date = ref('')
        const end_date = ref('')
        const activity_description = ref('')
        const learning_objectives = ref('')
        const resources = ref('')
        const weblink = ref('')
        const ways_to_expand = ref('')
        const nameError = ref('')
        const studentsError = ref('')
        const academicYearError = ref('')
        const termError = ref('')
        const projectTypeError = ref('')
        const supportingColearnerError = ref('')
        const durationError = ref('')
        const keywordsError = ref('')
        const aimError = ref('')
        const descriptionError = ref('')
        const milestoneError = ref('')
        const finalProductError = ref('')
        const activityNameError = ref('')
        const activityStartDateError = ref('')
        const activityEndDateError = ref('')
        const activityDescriptionError = ref('')
        const objectivesError = ref('')
        const activityCountError = ref('')
        const finalProduct = ref('')
        const learningGoalError = ref(null);
        const progressBarWidth = ref('0%');
        const projectId = ref(0)
        const learningGoals = ref([])
        const goalCategorySelectOptionsId = ref('')
        const checklist = ref([])
        const newChecklistQuestion = ref('')
        const checklistError = ref('')
        const yesOrNoOptions = ref([])
        const selectedYesOrNoOption = ref('')
        const academicYearSelectOptions = ref([])
        const selectedAcademicYear = ref('')
        const termsSelectOptions = ref([])
        const selectedTerm = ref('')
        const leadColearnerError = ref('')
        const learningGroupError = ref('')
        const SMEError = ref('')
        const question = ref(null)
        const selectedCurriculum = ref('')
        const curriculumOptions = ref([])


        const updateProgressBar = (progress) => {
            progressBarWidth.value = `${progress}%`;
        };

        const projectTypeSelectOptions = ref([])
        const finalProductSelectOptions = ref([])
        const projectDurationSelectOptions = ref([])
        const goalCategorySelectOptions = ref([])

        setTimeout(() => {
            projectTypeSelectOptions.value = [
                {label: "Literacy", value: 'Literacy'},
                {label: "Numeracy", value: 'Numeracy'},
                {label: "Science", value: 'Science'},
                {label: "Multi-disciplinary", value: 'Multi-disciplinary'},
            ]

            finalProductSelectOptions.value = [
                {label: "Presentation", value: 'Presentation'},
                {label: "Offering", value: 'Offering'},
                {label: "Product", value: 'Product'},
                {label: "Public Display", value: 'Public Display'},
            ]

            projectDurationSelectOptions.value = [
                {label: "1 week", value: '1 week'},
                {label: "2 weeks", value: '2 weeks'},
                {label: "3 weeks", value: '3 weeks'},
                {label: "1 month", value: '1 month'},
                {label: "2 months", value: '2 months'},
                {label: "3 months", value: '3 months'},
            ]

            goalCategorySelectOptions.value = [
                {label: "Content", value: 'Content'},
                {label: "Wellbeing", value: 'Wellbeing'},
                {label: "Skills", value: 'Skills'},
                {label: "Mindset", value: 'Mindset'},
            ]

            yesOrNoOptions.value = [
                {label: "Yes", value: 'Yes'},
                {label: "No", value: 'No'},
            ]
        }, 500)

        // Colearners
        const updateLeadColearnerOptions = (newData) => {
            colearnerSelectOptions.value = newData.map(item => ({
                label: capitalizeFirstLetter(item.first_name, item.last_name),
                value: item.user_id
            }))
        }

        watch(colearnersUserData, (newData) => {
            updateLeadColearnerOptions(newData)
        })

        // Academic Year
        const updateAcademiYearOptions = (newData) => {
            academicYearSelectOptions.value = newData.map(item => ({
                label: capitalizeFirstLetter(item.name),
                value: item.name
            }))
        }

        watch(academicYearData, (newData) => {
            updateAcademiYearOptions(newData)
        })

        // Curriculum
        const updateCurriculumOptions = (newData) => {
            curriculumOptions.value = newData.map(item => ({
                label: capitalizeFirstLetter(item.name),
                value: item.name
            }))
        }

        watch(curriculumsData, (newData) => {
            updateCurriculumOptions(newData)
        })

        onMounted(() => {
            fetchLearningGroupsData(1, 100)
            fetchColearnersData(1, 100)
            fetchSMEData(1, 100)
            fetchKeywordsData()
            fetchStudentsData(1, 200)
            fetchAcademicYearData()
            fetchTerms()
            fetchChecklistData()
            fetchCurriculums()

            emit('data-loaded')
        })

        // SMEs
        const updateSMEOptions = (newData) => {
            smeSelectOptions.value = newData.map(item => ({
                label: capitalizeFirstLetter(item.name, item.surname),
                value: item.id
            }))
        }

        watch(smeData, (newData) => {
            updateSMEOptions(newData)
        })

        // Terms
        const updateTermOptions = (newData) => {
            termsSelectOptions.value = newData.map(item => ({
                label: capitalizeFirstLetter(item.name),
                value: item.name
            }))
        }

        watch(termsData, (newData) => {
            updateTermOptions(newData)
        })

        // Keywords
        const updateKeywordOptions = (newData) => {
            projectKeywordSelectOptions.value = newData.map(item => ({
                label: capitalizeFirstLetter(item.name),
                value: item.name
            }))
        }

        watch(keywordsData, (newData) => {
            updateKeywordOptions(newData)
        })

        // Students
        const updateStudentOptions = (newData) => {
            studentsSelectOptions.value = newData.map(item => ({
                label: capitalizeFirstLetter(item.first_name, item.last_name),
                value: item.user_id
            }))
        }

        watch(studentsData, (newData) => {
            updateStudentOptions(newData)
        })


        const learningGoalSelectOptions = ref([
            {label: "Literacy", value: 'Literacy', category: "Content"},
            {label: "Numeracy", value: 'Numeracy', category: "Content"},
            {label: "Science", value: 'Science', category: "Content"},
            {label: "Arts & culture", value: 'Arts & culture', category: "Content"},
            {label: "Technology", value: 'Technology', category: "Content"},
            {label: "Makerspace", value: 'Makerspace', category: "Content"},
            {label: "Body & movement", value: 'Body & movement', category: "Wellbeing"},
            {label: "Relationships", value: 'Relationships', category: "Wellbeing"},
            {label: "Self Expression", value: 'Self Expression', category: "Wellbeing"},
            {label: "Self Awareness", value: 'Self Awareness', category: "Wellbeing"},
            {label: "Self Care", value: 'Self Care', category: "Wellbeing"},
            {label: "Fulfilment", value: 'Fulfilment', category: "Wellbeing"},
            {label: "Critical thinking", value: 'Critical thinking', category: "Skills"},
            {label: "Problem Solving", value: 'Problem Solving', category: "Skills"},
            {label: "Collaboration", value: 'Collaboration', category: "Skills"},
            {label: "Self Direction", value: 'Self Direction', category: "Skills"},
            {label: "Risk Taking", value: 'Risk Taking', category: "Skills"},
            {label: "Financial Literacy", value: 'Financial Literacy', category: "Skills"},
            {label: "Curiosity", value: 'Curiosity', category: "Mindset"},
            {label: "Imagination", value: 'Imagination', category: "Mindset"},
            {label: "Adaptation", value: 'Adaptation', category: "Mindset"},
            {label: "Empathy and Care", value: 'Empathy and Care', category: "Mindset"},
            {label: "Persistence/Resilience", value: 'Persistence/Resilience', category: "Mindset"},
            {label: "Growth Mindset", value: 'Growth Mindset', category: "Mindset"},
        ])

        const filteredLearningGoals = ref([])

        // Watch for changes in selectedGoalCategory and update filteredLearningGoals accordingly
        watch(selectedGoalCategory, (newCategory) => {
            if (newCategory) {
                filteredLearningGoals.value = learningGoalSelectOptions.value.filter(
                    (goal) => goal.category === newCategory
                );

                selectedLearningGoals.value = null
            } else {
                filteredLearningGoals.value = [];
            }
        });

        const showLoaders = () => {
            loading.value = true;
            buttonLoading.value = true;
            loadingSpinner.value = true;
        };

        const hideLoaders = () => {
            loading.value = false;
            buttonLoading.value = false;
            loadingSpinner.value = false;
        };

        // Learning Groups
        const updateLearningGroupOptions = (newData) => {
            learningGroupSelectOptions.value = newData.map(item => ({
                label: capitalizeFirstLetter(item.name), value: item.id
            }))
        }

        watch(learningGroupsData, (newData) => {
            updateLearningGroupOptions(newData)
        })

        const flashAlertMessage = (alertType, alertMessage) => {
            emit('flash-message', {
                alertType: alertType,
                alertMessage: alertMessage
            })
        }

        const nextPage = () => {
            let validationFunction = null;

            switch (activeTab.value) {
                case 0:
                    validationFunction = validateRegistrationPage;
                    break;
                case 1:
                    validationFunction = validateIdeaPage;
                    break;
                case 2:
                    validationFunction = validateLearningGoalsPage;
                    break;
                case 3:
                    validationFunction = validateActivityPage;
                    break;
                case 4:
                    saveNewProject();
                    return; // Exit early after saving
            }

            scrollToTop()
            if (!validationFunction || validationFunction()) {
                activeTab.value++;
            }
        };

        const scrollToTop = () => {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        }

        const selectTab = (index) => {
            let canNavigate = true;
            let firstInvalidTab = -1;

            for (let i = 0; i < index; i++) {
                if (i === 0 && !validateRegistrationPage()) {
                    canNavigate = false;
                    firstInvalidTab = 0;
                    break;
                }
                if (i === 1 && !validateIdeaPage()) {
                    canNavigate = false;
                    firstInvalidTab = 1;
                    break;
                }
                if (i === 2 && !validateLearningGoalsPage()) {
                    canNavigate = false;
                    firstInvalidTab = 2;
                    break;
                }
                if (i === 3 && !validateActivityPage()) {
                    canNavigate = false;
                    firstInvalidTab = 3;
                    break;
                }
            }

            if (canNavigate) {
                activeTab.value = index;
            } else {
                activeTab.value = firstInvalidTab;
            }
        };


        const prevPage = () => {
            if (activeTab.value > 0) {
                activeTab.value--
            }
        }

        const addQuestion = () => {
            if (newQuestion.value.trim() !== '') {
                const newIndex = questions.value.length + 1; // Get the current length of the array as the new index

                questions.value.push({
                    text: newQuestion.value,
                    [`problem_${newIndex}`]: newQuestion.value,
                    'answer': ''
                });

                newQuestion.value = ''
            }
        }

        const deleteQuestion = (index) => {
            questions.value.splice(index, 1)
        }

        const submitForm = () => {
            checklist.value = checklistData.value.map((question, index) => {
                return {
                    question: question.question,
                    answer: question.answer
                }
            })
        }

        const addActivity = () => {
            if (validateActivityCreation()) {
                activities.value.push({
                    name: activity_name.value,
                    start_date: start_date.value,
                    finish_date: end_date.value,
                    description: activity_description.value,
                    learning_objectives: learning_objectives.value,
                    resources: resources.value,
                    supporting_weblinks: weblink.value,
                    supporting_media: '',
                    ways_to_extend: ways_to_expand.value
                })

                // Clear the input fields
                setTimeout(() => {
                    activity_name.value = ''
                    start_date.value = ''
                    end_date.value = ''
                    activity_description.value = ''
                    learning_objectives.value = ''
                    resources.value = ''
                    weblink.value = ''
                    ways_to_expand.value = ''
                }, 500)
            }
        }

        const addLearningGoal = () => {
            learningGoals.value.push({
                [selectedGoalCategory.value]: selectedLearningGoals.value
            })
        }

        const deleteLearningGoal = (index) => {
            learningGoals.value.splice(index, 1)
        }

        const deleteActivity = (index) => {
            activities.value.splice(index, 1)
        }

        const saveDraft = () => {
            if (validateRegistrationPage()) {
                saveNewProject()
            }
        }

        const validateRegistrationPage = () => {
            let isValid = true

            nameError.value = ''
            academicYearError.value = ''
            termError.value = ''
            keywordsError.value = ''
            studentsError.value = ''
            leadColearnerError.value = ''
            learningGroupError.value = ''
            durationError.value = ''
            projectTypeError.value = ''


            if (name.value.length === 0) {
                nameError.value = 'Enter a valid project name'
                isValid = false
            }

            if (selectedAcademicYear.value === null) {
                academicYearError.value = 'Select at least one academic year'
                isValid = false
            }

            if (selectedProjectType.value === null) {
                projectTypeError.value = 'Select a project type'
                isValid = false
            }

            if (selectedTerm.value === null) {
                termError.value = 'Select a term'
                isValid = false
            }

            if (selectedLeadColearner.value === null) {
                leadColearnerError.value = 'Select a lead co-learner'
                isValid = false
            }

            if (selectedLearningGroup.value.length === 0) {
                learningGroupError.value = 'Select at least one learning group'
                isValid = false
            }

            if (selectedDuration.value === null) {
                durationError.value = 'Select a project duration'
                isValid = false
            }

            if (selectedKeywords.value.length === 0) {
                keywordsError.value = 'Select at least one keyword'
                isValid = false
            }

            if (selectedStudents.value.length === 0) {
                studentsError.value = 'Select at least one student'
                isValid = false
            }

            if (isValid) {
                updateProgressBar(20)
            }

            return isValid
        }

        const validateIdeaPage = () => {
            let isValid = true

            aimError.value = ''
            descriptionError.value = ''
            milestoneError.value = ''
            finalProductError.value = ''

            if (aim.value.length === 0) {
                aimError.value = 'Enter the project aim'
                isValid = false
            }

            if (description.value.length === 0) {
                descriptionError.value = 'Enter the project description'
                isValid = false
            }

            if (milestones.value.length === 0) {
                milestoneError.value = 'Enter the project milestone'
                isValid = false
            }

            if (finalProduct.value.length === 0) {
                finalProductError.value = 'Enter the details of the final product'
                isValid = false
            }

            validateActivityPage()

            if (isValid) {
                updateProgressBar(40)
            }

            return isValid
        }

        const validateLearningGoalsPage = () => {
            let isValid = true

            learningGoalError.value = ''

            if (learningGoals.value.length === 0) {
                learningGoalError.value = 'Select at least one learning goal'
                isValid = false
            }

            if (isValid) {
                updateProgressBar(60)
            }

            return isValid
        }

        const validateActivityCreation = () => {
            let isValid = true

            activityNameError.value = ''
            activityStartDateError.value = ''
            activityEndDateError.value = ''
            activityDescriptionError.value = ''
            objectivesError.value = ''

            if (activity_name.value.length === 0) {
                activityNameError.value = 'Enter the activity name'
                isValid = false
            }

            if (start_date.value.length === 0) {
                activityStartDateError.value = 'Enter a valid start date'
                isValid = false
            }

            if (end_date.value.length === 0) {
                activityEndDateError.value = 'Enter a valid end date'
                isValid = false
            }

            if (activity_description.value.length === 0) {
                activityDescriptionError.value = 'Enter the activity description'
                isValid = false
            }

            if (learning_objectives.value.length === 0) {
                objectivesError.value = 'Enter the learning objectives for this activity'
                isValid = false
            }

            return isValid
        }

        const validateActivityPage = () => {
            let isValid = true

            activityCountError.value = ''

            // if (calculateWorkingDays(selectedDuration.value) !== activities.value.length) {
            //     activityCountError.value = `You selected ${selectedDuration.value} as the duration, you need to create ${calculateWorkingDays(selectedDuration.value)} activities`
            //     isValid = false
            // }

            if (isValid) {
                updateProgressBar(80)
            }

            return isValid
        }

        const saveNewProject = async () => {
            updateProgressBar(100)
            submitForm()

            const project = {
                name: name.value,
                academic_year: selectedAcademicYear.value,
                term: selectedTerm.value,
                project_type: selectedProjectType.value,
                lead_teacher: selectedLeadColearner.value,
                supporting_teachers: selectedSupportingColearners.value,
                group_id: selectedLearningGroup.value,
                subject_matter: selectedSME.value,
                project_duration: selectedDuration.value,
                key_words: selectedKeywords.value,
                student_id: selectedStudents.value,
                project_aim: aim.value,
                description: description.value,
                weblinks: webLinks.value,
                milestones: milestones.value,
                final_product: {
                    key: selectedFinalProduct.value,
                    details: finalProduct.value
                },
                problem_to_solve: questions.value,
                learning_goals: learningGoals.value,
                project_checklist: checklist.value,
                curriculum: selectedCurriculum.value
            }

            // console.log('project', project)

            try {
                const schoolId = getSchoolId()
                const apiUrl = `${API_BASE_URL}/school/${schoolId}/projects`
                submitButtonLoading.value = true
                const accessToken = getValidToken()

                await checkAndRefreshToken()

                const response = await fetch(apiUrl, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${accessToken}`,
                        },
                        body: JSON.stringify(project)
                    }
                );

                if (response.ok) {
                    const result = await response.json();
                    if (result.code === 200) {
                        projectId.value = result.message.user_id
                        flashAlertMessage('success', "Success! You have created the project")
                        await router.push({name: 'ProjectsList'})
                    } else {
                        flashAlertMessage('error', 'An error occured')
                        console.log("error", result.message);
                    }
                } else {
                    const result = await response.json();
                    console.log("error", result.message);
                }
            } catch (error) {
                console.error("An error occured:", error);
            } finally {
                submitButtonLoading.value = false
            }
        }

        watch(projectId, () => {
            if (activities.value.length !== 0) {
                saveActivities(projectId.value)
            }
        })

        const saveActivities = async (projectId) => {
            for (const [index, activity] of activities.value.entries()) {
                const newActivity = {
                    project_id: projectId,
                    name: activity.name,
                    start_date: activity.start_date,
                    finish_date: activity.finish_date,
                    description: activity.description,
                    learning_objectives: activity.learning_objectives,
                    resources: activity.resources,
                    supporting_weblinks: activity.supporting_weblinks,
                    supporting_media: activity.supporting_media,
                    ways_to_extend: activity.ways_to_extend,
                }

                try {
                    const schoolId = getSchoolId()
                    const apiUrl = `${API_BASE_URL}/school/${schoolId}/activity`
                    const accessToken = getValidToken()
                    emit('show-loader')

                    await checkAndRefreshToken()

                    const response = await fetch(apiUrl, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${accessToken}`,
                        },
                        body: JSON.stringify(newActivity)
                    });

                    if (response.ok) {
                        const result = await response.json();
                        if (result.code === 201) {
                            console.log('Activity Creation Success', `Activity at index ${index} created`)
                        } else {
                            console.log('Activity Creation Error', result.message)
                        }
                    } else {
                        const result = await response.json();
                        console.log("error", result.message);
                    }
                } catch (error) {
                    console.error("An error occurred:", error);
                } finally {
                    emit('data-loaded')
                }
            }
        }


        return {
            tabs,
            selectTab,
            saveDraft,
            activeTab,
            addActivity,
            learningGoalError,
            deleteActivity,
            loading,
            loadingSpinner,
            showLoaders,
            hideLoaders,
            name,
            description,
            learningGroupSelectOptions,
            selectedLearningGroup,
            learningGroupDropdownLoader,
            filteredLearningGoals,
            colearnerSelectOptions,
            selectedLeadColearner,
            selectedSupportingColearners,
            projectTypeSelectOptions,
            selectedProjectType,
            addQuestion,
            deleteQuestion,
            leadColearnerLoader,
            smeSelectOptions,
            studentsError,
            smeLoader,
            selectedSME,
            selectedDuration,
            projectDurationSelectOptions,
            pages,
            nextPage,
            prevPage,
            selectedKeywords,
            projectKeywordSelectOptions,
            studentsSelectOptions,
            finalProduct,
            selectedStudents,
            selectedFinalProduct,
            buttonLoading,
            finalProductSelectOptions,
            questions,
            newQuestion,
            selectedLearningGoals,
            selectedGoalCategory,
            goalCategorySelectOptions,
            learningGoalSelectOptions,
            goalCategorySelectOptionsId,
            activities,
            activity_name,
            start_date,
            studentsLoader,
            end_date,
            activity_description,
            learning_objectives,
            resources,
            weblink,
            ways_to_expand,
            academic_year,
            term,
            aim,
            keyWordsLoader,
            webLinks,
            milestones,
            nameError,
            academicYearError,
            termError,
            activityCountError,
            projectTypeError,
            supportingColearnerError,
            durationError,
            keywordsError,
            finalProductError,
            milestoneError,
            descriptionError,
            aimError,
            activityNameError,
            activityStartDateError,
            activityEndDateError,
            activityDescriptionError,
            objectivesError,
            progressBarWidth,
            submitButtonLoading,
            addLearningGoal,
            deleteLearningGoal,
            learningGoals,
            checklist,
            checklistError,
            newChecklistQuestion,
            yesOrNoOptions,
            selectedYesOrNoOption,
            selectedAcademicYear,
            academicYearSelectOptions,
            yearLoader,
            termsSelectOptions,
            selectedTerm,
            termLoader,
            leadColearnerError,
            learningGroupError,
            SMEError,
            checklistData,
            checklistLoader,
            submitForm,
            question,
            selectedCurriculum,
            curriculumOptions,
            curriculumLoader
        }
    }
}
</script>

<style scoped>
.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    color: #fff !important;
    background-color: #2f5bcf;
}

.wrap-text {
    white-space: normal;
}

.table {
    width: 100%;
    white-space: nowrap;
    color: #333333 !important;
}

.invalid-feedback {
    display: block;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.75rem;
    color: #f1556c;
}

.table td {
    padding: 10px
}

.table-borderless thead,
.table-borderless td {
    border-bottom: 1px solid #f1f1f1;
}

.table-borderless thead {
    color: #9a9a9a;
}

.table-borderless thead {
    background-color: #f5f6fa;
}

.table-borderless th:first-child {
    border-top-left-radius: 10px;
}

.table-borderless th:last-child {
    border-top-right-radius: 10px;
}

.tableEmail {
    font-size: 11px;
    color: #9a9a9a;
}

td {
    vertical-align: middle !important;
}

.required {
    color: red; /* or any other color you prefer */
    margin-left: 2px; /* Adjust spacing as needed */
    font-weight: bolder;
}

.linear-loader {
    height: 3px;
    /* Adjust the height as needed */
    background-color: #89a6f3;
    /* Adjust the color as needed */
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    /* animation: moveRight 1s linear infinite; Adjust the animation speed as needed */
    //z-index: 9999;
    /* Ensure it's on top of other elements */
}

.train {
    width: 900px;
    /* Adjust the train's width */
    height: 7px;
    /* Should match the height of the loader */
    background-color: #2f5bcf;
    /* Adjust the color as needed */
    position: absolute;
    animation: moveRight 1s linear infinite;
    /* Adjust the animation speed as needed */
    top: 0;
    left: 0;
}

@keyframes moveRight {
    0% {
        left: -50px;
        /* Start off the screen to the left */
    }

    100% {
        left: 100%;
        /* Move to the right of the screen */
    }
}

tr.loader-row td {
    padding: 0 !important;
}

@media (max-width: 767px) {
    .project_name, .project_term, .country, .lead-colearner, .learning-group, .project-duration, .students {
        margin-bottom: 30px;
    }
}

@media (max-width: 992px) {
    .project_name, .project_term, .country, .lead-colearner, .learning-group, .project-duration, .students {
        margin-bottom: 30px;
    }
}
</style>