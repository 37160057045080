<template>
    <form @submit.prevent="updateItem">

        <div class="row mb-30">
            <div class="col-lg-6 col-sm-12 first_name">
                <TextInputField :config="{
                    class: 'form-control-gray',
                    placeholder: 'Enter First Name',
                    inputFieldType: 'text',
                }" labelText="First Name" v-model="firstName" :isRequired="true"/>
            </div>

            <div class="col-lg-6 col-sm-12">
                <TextInputField :config="{
                    class: 'form-control-gray',
                    placeholder: 'Enter Last Name',
                    inputFieldType: 'text',
                }" labelText="Last Name" v-model="lastName" :isRequired="true"/>
            </div>
        </div>

        <div class="row mb-30">
            <div class="col-lg-6 col-sm-12">
                <SingleSelectField labelText="Gender" :options="genderSelectOptions" v-model="selectedGender"
                                   :isRequired="true"
                                   selectId="genderOptions" :selectedItem="selectedGender" :loading="genderLoader"/>
            </div>

            <div class="col-lg-6 col-sm-12">
                <TextInputField :config="{
                    class: 'form-control-gray',
                    inputFieldType: 'text',
                }" labelText="Nick Name" v-model="nick_name"/>
            </div>
        </div>

        <div class="row mb-30">
            <div class="col-lg-6 col-sm-12">
                <TextInputField :config="{
                    class: 'form-control-gray',
                    placeholder: 'DD - MM - YYYY',
                    inputFieldType: 'date',
                }" labelText="Date of Birth" v-model="dob" :isRequired="true"/>
            </div>

            <div class="col-lg-6 col-sm-12">
                <TextInputField :config="{
                    class: 'form-control-gray',
                    placeholder: 'Enter Age',
                    inputFieldType: 'text',
                }" labelText="Current Age" v-model="age" :isRequired="true"/>
            </div>
        </div>


        <div class="row mb-30">
            <div class="col-lg-6 col-sm-12 country">
                <SimpleDropdown labelText="Country of Residence" :options="countrySelectOptions"
                                v-model="selectedCountry" :isRequired="true"
                                :loading="countryDropdownLoader" selectId="countryOptions"
                                :selectedItem="selectedCountry" @change="handleCountrySelection"/>
            </div>

            <div class="col-lg-6 col-sm-12">
                <SimpleDropdown labelText="State of Residence" :options="stateSelectOptions" v-model="selectedState"
                                :loading="statesDropdownLoader" :isRequired="true"
                                selectId="stateOptions" :selectedItem="selectedState"/>
            </div>
        </div>

        <TextAreaInputField :config="{
            class: 'form-control-gray mb-30',
            placeholder: 'Enter Address',
            inputFieldType: 'address',
        }" labelText="Residential Address" errorType="addressError" v-model="address"/>

        <RoundedButton buttonText="Save Changes" class="custom-button" buttonType="submit" :loading="buttonLoading"/>

    </form>
</template>
<script>
import TextInputField from "@/components/common/TextInputField.vue";
import SimpleDropdown from "@/components/common/SimpleDropdown.vue";
import TextAreaInputField from "@/components/common/TextAreaInputField.vue";
import RoundedButton from "@/components/common/RoundedButton.vue";
import {onMounted, ref, watch} from "vue";
import getCountries from "@/composables/getCountries";
import getStates from "@/composables/getStates";
import {capitalizeFirstLetter, getValidToken} from '@/utils/helpers'
import getGender from "@/composables/getGender";
import {checkAndRefreshToken} from "@/utils/auth";
import {API_BASE_URL} from "@/config";
import SingleSelectField from "@/components/common/SingleSelectField.vue";
import ImageUploadInput from "@/components/common/ImageUploadInput.vue";


export default {
    props: {
        itemData: {
            type: Object,
            required: true
        }
    },
    components: {
        ImageUploadInput,
        SingleSelectField,
        TextInputField,
        SimpleDropdown,
        TextAreaInputField,
        RoundedButton,
    },
    setup(props, {emit}) {
        const buttonLoading = ref(false)
        const message = ref('')
        const {fetchCountriesData, countriesList, loading: countryDropdownLoader} = getCountries()
        const {fetchStatesData, statesList, loading: statesDropdownLoader} = getStates()
        const {fetchGender, genderList, loading: genderLoader} = getGender()
        const genderSelectOptions = ref([])
        const countrySelectOptions = ref([])
        const stateSelectOptions = ref([])
        const selectedGender = ref(props.itemData.gender)
        const selectedCountry = ref(props.itemData.country)
        const selectedState = ref(props.itemData.state)
        const firstName = ref(props.itemData.firstName)
        const lastName = ref(props.itemData.lastName)
        const address = ref(props.itemData.address)
        const dob = ref(props.itemData.dob)
        const age = ref(props.itemData.age)
        const nick_name = ref(props.itemData.nick_name)
        const countryIdMap = ref({});
        const imageFile = ref('')

        onMounted(() => {
            fetchGender()
            fetchCountriesData()
        })

        const updateGenderOptions = (newData) => {
            genderSelectOptions.value = newData.map(item => ({
                label: capitalizeFirstLetter(item.label), value: item.value
            }))

            if (genderSelectOptions.value.length > 0) {
                setTimeout(() => {
                    selectedGender.value = props.itemData.gender
                }, 200)
            }
        }

        watch(genderList, (newData) => {
            updateGenderOptions(newData)
        })

        // COUNTRIES
        const handleCountrySelection = () => {
            const selectedCountryId = countryIdMap.value[selectedCountry.value]
            fetchStatesData(selectedCountryId)
        }

        // Update the country dropdown when the modal is opened
        const updateCountryOptions = (newData) => {
            countrySelectOptions.value = newData.map(country => ({
                label: country.country_name,
                value: country.country_name
            }))

            countryIdMap.value = newData.reduce((map, country) => {
                map[country.country_name] = country.id;
                return map;
            }, {});

            if (countrySelectOptions.value.length > 0) {
                selectedCountry.value = props.itemData.country
                handleCountrySelection()
            }
        }

        watch(countriesList, (newData) => {
            updateCountryOptions(newData)
        })

        // STATES
        // Update the states options
        const updateStatesOptions = (newData) => {
            stateSelectOptions.value = newData.map(state => ({
                label: state.state_name,
                value: state.state_name
            }))

            if (stateSelectOptions.value.length > 0) {
                selectedState.value = selectedCountry.value === props.itemData.country ? props.itemData.state : stateSelectOptions.value[0].value
            }
        }

        watch(statesList, (newData) => {
            updateStatesOptions(newData)
        })

        // OTHERS
        const showLoaders = () => {
            buttonLoading.value = true;
        };

        const hideLoaders = () => {
            buttonLoading.value = false;
        };

        const apiUrl = `${API_BASE_URL}/student/update-student?user_id=${props.itemData.id}`
        const updateItem = async () => {
            const item = {
                first_name: firstName.value,
                last_name: lastName.value,
                gender: selectedGender.value,
                country: selectedCountry.value,
                state: selectedState.value,
                address: address.value,
                dob: dob.value,
                age: age.value,
                nick_name: nick_name.value,
                profile_image: imageFile.value,
            }


            try {
                showLoaders()
                await checkAndRefreshToken()
                const accessToken = getValidToken()

                const response = await fetch(apiUrl, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    },
                    body: JSON.stringify(item)
                })

                if (response.ok) {
                    const result = await response.json();

                    if (result.code === 200) {
                        message.value = result.message;

                        emit("closeFormModal");
                        emit("refreshPage");
                        emit("message", {
                            apiResponse: "Student updated successfully",
                            type: "success",
                        });
                    } else {
                        console.log("error", result.message);
                    }
                } else {
                    const result = await response.json();
                    console.log("error", result.message);
                }
            } catch (error) {
                console.error("An error occured:", error);
            } finally {
                hideLoaders();
            }
        }

        const handleImageFileUpdate = (newVal) => {
            imageFile.value = newVal;
        }

        return {
            handleImageFileUpdate,
            buttonLoading,
            updateItem,
            firstName,
            lastName,
            genderSelectOptions,
            selectedGender,
            countrySelectOptions,
            selectedCountry,
            countryDropdownLoader,
            statesDropdownLoader,
            handleCountrySelection,
            selectedState,
            stateSelectOptions,
            address,
            genderLoader,
            dob,
            age,
            nick_name,
            imageFile
        }
    }
}
</script>
<style scoped>
@media (max-width: 767px) {
    .first_name, .country {
        margin-bottom: 30px;
    }
}

@media (max-width: 992px) {
    .first_name, .country {
        margin-bottom: 30px;
    }
}
</style>