<template>
    <router-view />
</template>

<style>
#app {
    font-family: "Quicksand", sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
}
</style>
