<template>
    <div v-if="currentPage === 1">
        <div class="row mb-30">
            <div class="col-lg-6 col-sm-12">
                <TextInputField :config="{
                    class: 'form-control-gray',
                    placeholder: 'Enter First Name',
                    inputFieldType: 'text'
                }" labelText="First Name" :isRequired=true v-model="firstName"
                                :errorMessage="firstNameError"/>
            </div>

            <div class="col-lg-6 col-sm-12">
                <TextInputField :config="{
                    class: 'form-control-gray',
                    placeholder: 'Enter Last Name',
                    inputFieldType: 'text'
                }" labelText="Last Name" :isRequired=true v-model="lastName" :errorMessage="lastNameError"/>
            </div>
        </div>

        <div class="row mb-30">
            <div class="col-lg-6 col-sm-12">
                <SimpleDropdown labelText="Gender" :options="genderSelectOptions" v-model="selectedGender"
                                :isRequired=true :errorMessage="genderError"
                                selectId="genderOptions" :selectedItem="selectedGender" :loading="genderLoader"/>
            </div>

            <div class="col-lg-6 col-sm-12">
                <SingleSelectField
                    labelText="Bachelor's degree in an education related field?"
                    :options="bachelorDegreeOptions" v-model="selectedBachelorDegreeOption"
                    :isRequired=true
                    selectId="bachelorDegreeOptions" :selectedItem="selectedBachelorDegreeOption"
                    :errorMessage="bachelorFieldError"/>
            </div>
        </div>

        <div class="row mb-30">
            <div class="col-lg-6 col-sm-12">
                <SingleSelectField labelText="Country of Residence" :options="countrySelectOptions"
                                   v-model="selectedCountry"
                                   :loading="countryDropdownLoader" selectId="countryOptions"
                                   :isRequired=true
                                   :selectedItem="selectedCountry" @change="handleCountrySelection"/>
            </div>

            <div class="col-lg-6 col-sm-12">
                <SingleSelectField labelText="State of Residence" :options="stateSelectOptions"
                                   v-model="selectedState"
                                   :loading="statesDropdownLoader"
                                   :isRequired=true
                                   selectId="stateOptions" :selectedItem="selectedState"/>
            </div>
        </div>

        <div class="row mb-50">
            <div class="col-12">
                <TextAreaInputField :config="{
                        class: 'form-control-gray',
                        placeholder: 'Enter Address',
                        inputFieldType: 'address'
                    }" labelText="Residential Address" v-model="address"/>
            </div>
        </div>

    </div>

    <div v-if="currentPage === 2">
        <div class="row mb-30">
            <div class="col-lg-6 col-sm-12">
                <TextInputField :config="{
                        class: 'form-control-gray',
                        placeholder: '',
                        inputFieldType: 'years_of_experience',
                    }" labelText="How many years experience do you have teaching early years / preschool aged children?"
                                v-model="years_of_experience"/>
            </div>

            <div class="col-lg-6 col-sm-12">
                <SingleSelectField
                    labelText="Are you familiar with Early Years Education / EYFS / Pre-school education?"
                    :options="earlyYearsEducationOptions" v-model="selectedEarlyYearEducation"
                    :isRequired=true :errorMessage="earlyYearError"
                    selectId="earlyYearsEducationOptions" :selectedItem="selectedEarlyYearEducation"/>
            </div>
        </div>

        <div class="row mb-30">
            <div class="col-lg-6 col-sm-12">
                <TextInputField :config="{
                        class: 'form-control-gray',
                        placeholder: 'Enter LinkedIn Link',
                        inputFieldType: 'text',
                    }" labelText="LinkedIn URL" v-model="linkedin"/>
            </div>

            <div class="col-lg-6 col-sm-12">
                <SingleSelectField
                    labelText="How did you hear about the KEY app?"
                    :isRequired=true :errorMessage="hearAboutUsError"
                    :options="heardAboutUsOptions" v-model="selectedHeardAboutUsEducation"
                    selectId="heardAboutUsOptions" :selectedItem="selectedHeardAboutUsEducation"/>
            </div>
        </div>

        <div class="row mb-50">
            <div class="col-12">
                <TextAreaInputField :config="{
                        class: 'form-control-gray',
                        placeholder: 'Enter purpose',
                        inputFieldType: 'text',
                    }" labelText="What is your purpose / objective for using the KEY app?" :isRequired=true
                                    :errorMessage="purposeError" v-model="purpose_using_the_app"/>
            </div>
        </div>
    </div>


    <div class="mt-20 table-pagination">
        <ul class="pagination pagination-rounded">
            <li v-for="page in pages" :key="page" :class="{ active: currentPage === page }" class="page-item">
                <a href="#" class="page-link" @click="changePage(page)">{{ page }}</a>
            </li>
        </ul>

        <RoundedButton :buttonText="currentPage < pages ? 'Next' : 'Submit'" class="custom-button"
                       @click="nextPage" :loading="buttonLoading"/>

    </div>
</template>
<script>
import TextInputField from "@/components/common/TextInputField.vue";
import SimpleDropdown from "@/components/common/SimpleDropdown.vue";
import TextAreaInputField from "@/components/common/TextAreaInputField.vue";
import RoundedButton from "@/components/common/RoundedButton.vue";
import {onMounted, ref, watch} from "vue";
import getCountries from "@/composables/getCountries";
import getStates from "@/composables/getStates";
import {capitalizeFirstLetter, getValidToken} from '@/utils/helpers'
import getGender from "@/composables/getGender";
import {checkAndRefreshToken} from "@/utils/auth";
import {API_BASE_URL} from "@/config";
import SingleSelectField from "@/components/common/SingleSelectField.vue";


export default {
    emits: [
        'showLoader',
        'show-loader',
        'flashMessage',
        'data-loaded',
        'closeFormModal',
        'message',
        'refreshPage'
    ],
    props: {
        itemData: {
            type: Object,
            required: true
        }
    },
    components: {
        SingleSelectField,
        TextInputField,
        SimpleDropdown,
        TextAreaInputField,
        RoundedButton,
    },
    setup(props, {emit}) {
        const buttonLoading = ref(false)
        const message = ref('')
        const {fetchCountriesData, countriesList, loading: countryDropdownLoader} = getCountries()
        const {fetchStatesData, statesList, loading: statesDropdownLoader} = getStates()
        const {fetchGender, genderList, loading: genderLoader} = getGender()
        const genderSelectOptions = ref([])
        const countrySelectOptions = ref([])
        const stateSelectOptions = ref([])
        const selectedGender = ref(props.itemData.gender)
        const selectedCountry = ref(props.itemData.country)
        const selectedState = ref(props.itemData.state)
        const firstName = ref(props.itemData.firstName)
        const lastName = ref(props.itemData.lastName)
        const address = ref(props.itemData.address)
        const countryIdMap = ref({});
        const pages = 2;
        const currentPage = ref(1);
        const earlyYearError = ref('')
        const hearAboutUsError = ref('')
        const purposeError = ref('')
        const firstNameError = ref('')
        const lastNameError = ref('')
        const genderError = ref('')
        const bachelorFieldError = ref('')
        const purpose_using_the_app = ref(props.itemData.purpose_using_the_app)
        const selectedEarlyYearEducation = ref('')
        const selectedHeardAboutUsEducation = ref('')
        const earlyYearsEducationOptions = ref([])
        const heardAboutUsOptions = ref([])
        const linkedin = ref(props.itemData.linkedin)
        const bachelorDegreeOptions = ref([])
        const years_of_experience = ref(props.itemData.years_of_experience)
        const selectedBachelorDegreeOption = ref('')

        // Flag to prevent recursive updates
        const heardAboutUsUpdated = ref(false);

        onMounted(() => {
            fetchGender()
            fetchCountriesData()
            fetchBachelorDegreeOptions()

            setTimeout(() => {
                selectedBachelorDegreeOption.value = props.itemData.has_bachelors_degree === true ? 'yes' : 'no'
                selectedEarlyYearEducation.value = props.itemData.early_years_education === true ? 'yes' : 'no'
            }, 1000)
        })

        // Early Years Education
        const fetchEarlyYearsEducationOptions = () => {
            setTimeout(() => {
                earlyYearsEducationOptions.value = [
                    {label: "Yes", value: 'yes'},
                    {label: "No", value: 'no'},
                ]
            }, 200)
        }


        // Bachelors Degree
        const fetchBachelorDegreeOptions = () => {
            setTimeout(() => {
                bachelorDegreeOptions.value = [
                    {label: "Yes", value: 'yes'},
                    {label: "No", value: 'no'},
                ]
            }, 200)
        }


        // Heard about us
        const fetchHeardAboutUsOptions = () => {
            setTimeout(() => {
                heardAboutUsOptions.value = [
                    {label: "Google Search", value: 'Google Search'},
                    {label: "Website/Blog", value: 'Website/Blog'},
                    {label: "Email Newsletter", value: 'Email Newsletter'},
                    {label: "Advertisement", value: 'Advertisement'},
                    {label: "Instagram", value: 'Instagram'},
                    {label: "X", value: 'X'},
                    {label: "Tiktok", value: 'Tiktok'},
                    {label: "Referral", value: 'Referral'},
                    {label: "Facebook", value: 'Facebook'},
                    {label: "Event", value: 'Event'},
                ]
            })
        }

        watch(heardAboutUsOptions, (newData) => {
            heardAboutUsUpdated.value = false; // Reset flag after watch
        });


        const updateGenderOptions = (newData) => {
            genderSelectOptions.value = newData.map(item => ({
                label: capitalizeFirstLetter(item.label), value: item.value
            }))

            if (genderSelectOptions.value.length > 0) {
                selectedGender.value = props.itemData.gender
            }
        }

        watch(genderList, (newData) => {
            updateGenderOptions(newData)
        })

        // COUNTRIES
        const handleCountrySelection = () => {
            const selectedCountryId = countryIdMap.value[selectedCountry.value]
            fetchStatesData(selectedCountryId)
        }

        // Update the country dropdown when the modal is opened
        const updateCountryOptions = (newData) => {
            countrySelectOptions.value = newData.map(country => ({
                label: country.country_name, value: country.country_name
            }))

            countryIdMap.value = newData.reduce((map, country) => {
                map[country.country_name] = country.id;
                return map;
            }, {});

            setTimeout(() => {
                if (countrySelectOptions.value.length > 0) {
                    selectedCountry.value = props.itemData.country
                    handleCountrySelection()
                }
            }, 100)
        }

        watch(countriesList, (newData) => {
            updateCountryOptions(newData)
        })

        // STATES
        // Update the states options
        const updateStatesOptions = (newData) => {
            stateSelectOptions.value = newData.map(state => ({
                label: state.state_name,
                value: state.state_name
            }))

            setTimeout(() => {
                if (stateSelectOptions.value.length > 0) {
                    selectedState.value = selectedCountry.value === props.itemData.country ? props.itemData.state : stateSelectOptions.value[0].value
                }
            }, 100)
        }

        watch(statesList, (newData) => {
            updateStatesOptions(newData)
        })

        // OTHERS
        const showLoaders = () => {
            buttonLoading.value = true;
        };

        const hideLoaders = () => {
            buttonLoading.value = false;
        };

        const validatePageOne = () => {
            let isValid = true

            firstNameError.value = ''
            lastNameError.value = ''
            genderError.value = ''
            bachelorFieldError.value = ''

            if (firstName.value.length === 0) {
                firstNameError.value = 'Enter a first name'
                isValid = false
            }

            if (lastName.value.length === 0) {
                lastNameError.value = 'Enter a last name'
                isValid = false
            }

            if (selectedGender.value === null) {
                genderError.value = 'Select at least one option'
                isValid = false
            }

            if (selectedBachelorDegreeOption.value === null) {
                bachelorFieldError.value = 'Select at least one option'
                isValid = false
            }

            return isValid
        }

        const validatePageTwo = () => {
            let isValid = true

            earlyYearError.value = ''
            hearAboutUsError.value = ''
            purposeError.value = ''

            if (selectedEarlyYearEducation.value === null) {
                earlyYearError.value = 'Select at least one option'
                isValid = false
            }

            if (selectedHeardAboutUsEducation.value === null) {
                hearAboutUsError.value = 'Select at least one option'
                isValid = false
            }

            if (purpose_using_the_app.value.length === 0) {
                purposeError.value = 'Enter a purpose'
                isValid = false
            }

            return isValid
        }

        const nextPage = () => {
            if (currentPage.value === 1 && validatePageOne()) {
                currentPage.value++;
                fetchEarlyYearsEducationOptions()
                fetchHeardAboutUsOptions()

                setTimeout(() => {
                    selectedHeardAboutUsEducation.value = props.itemData.how_you_heard_about_us
                    selectedEarlyYearEducation.value = props.itemData.early_years_education === true ? 'yes' : 'no'
                }, 1000)
            } else if (currentPage.value === 2 && validatePageTwo()) {
                updateItem()
            }
        };

        const apiUrl = `${API_BASE_URL}/teacher/update-teacher?user_id=${props.itemData.id}`
        const updateItem = async () => {
            const item = {
                school_id: 1,
                first_name: firstName.value,
                last_name: lastName.value,
                gender: selectedGender.value,
                country: selectedCountry.value,
                state: selectedState.value,
                address: address.value,
                purpose_using_the_app: purpose_using_the_app.value,
                linkedin: linkedin.value,
                years_of_experience: years_of_experience.value,
                early_years_education: selectedEarlyYearEducation.value,
                how_you_heard_about_us: selectedHeardAboutUsEducation.value,
                has_bachelors_degree: selectedBachelorDegreeOption.value === 'yes',
            }

            try {
                showLoaders()
                await checkAndRefreshToken()
                const accessToken = getValidToken()

                const response = await fetch(apiUrl, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    },
                    body: JSON.stringify(item)
                })


                if (response.ok) {
                    const result = await response.json();

                    if (result.code === 200) {
                        message.value = result.message;

                        emit("closeFormModal");
                        emit("refreshPage");
                        emit("message", {
                            apiResponse: "Co-Learner updated successfully",
                            type: "success",
                        });
                    } else {
                        console.log("error", result.message);
                    }
                } else {
                    const result = await response.json();
                    console.log("error", result.message);
                }
            } catch (error) {
                console.error("An error occured:", error);
            } finally {
                hideLoaders();
            }
        }

        const changePage = (page) => {
            currentPage.value = page;

            if (currentPage.value === 1) {
                fetchGender()
                fetchCountriesData()
                fetchBachelorDegreeOptions()
            } else if (currentPage.value === 2) {
                fetchHeardAboutUsOptions()
                fetchEarlyYearsEducationOptions()

                setTimeout(() => {
                    selectedHeardAboutUsEducation.value = props.itemData.how_you_heard_about_us
                    selectedEarlyYearEducation.value = props.itemData.early_years_education === true ? 'yes' : 'no'
                }, 1000)
            }
        }

        return {
            buttonLoading,
            updateItem,
            firstName,
            lastName,
            genderSelectOptions,
            selectedGender,
            countrySelectOptions,
            selectedCountry,
            countryDropdownLoader,
            statesDropdownLoader,
            handleCountrySelection,
            selectedState,
            stateSelectOptions,
            address,
            genderLoader,
            changePage,
            pages,
            currentPage,
            nextPage,
            firstNameError,
            lastNameError,
            genderError,
            bachelorFieldError,
            purposeError,
            hearAboutUsError,
            earlyYearError,
            selectedEarlyYearEducation,
            selectedHeardAboutUsEducation,
            earlyYearsEducationOptions,
            heardAboutUsOptions,
            purpose_using_the_app,
            linkedin,
            bachelorDegreeOptions,
            selectedBachelorDegreeOption,
            years_of_experience,
        }
    }
}
</script>
<style scoped>
@media (max-width: 767px) {
    .first_name, .country {
        margin-bottom: 30px;
    }
}

@media (max-width: 992px) {
    .first_name, .country {
        margin-bottom: 30px;
    }
}

.table-pagination {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.page-item.active .page-link {
    border-color: #2f5bcf;
    z-index: 3;
    color: #fff;
}

.page-item:first-child .page-link {
    margin-left: 0;
}

.pagination-rounded .page-link {
    border-radius: 30px !important;
    margin: 0 3px;
    border: none;
}

.page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.9rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #323a46;
    background-color: #fff;
    border: 1px solid #dee2e6;
}
</style>